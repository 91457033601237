import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import actions from '../../store/actions';
import { CallbackEventOneParam, CallbackNoParams } from '../../types/callbackTypes';

/** A simple Modal */

const Modal = (props: {
  cancelText?: string;
  children?: React.ReactNode;
  logoutHandler?: CallbackNoParams;
  okText?: string;
  onClose?: CallbackEventOneParam<string | undefined>;
  showButtons?: boolean;
  showData?: boolean;
  title: string;
  visible: boolean;
  showCloseButton?: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const forceLogin = useSelector((state: RootState) => state.forceLogin);
  const { cancelText, children, logoutHandler, okText, onClose, showButtons, showData, title, visible, showCloseButton } = props;
  const [data, setData] = useState('');
  const [error, setError] = useState('');

  const closeDialog = (e: Event) => {
    if (onClose) {
      if (data) {
        onClose(e, data);
      } else {
        onClose(e, 'ok');
      }
    } else {
      if (forceLogin && logoutHandler) {
        logoutHandler();
        dispatch(actions.setForceLogin(false));
      }
      dispatch(actions.setErrorMessage(''));
      dispatch(actions.setWarningMessage(''));
      dispatch(actions.setSystemMessage(''));
    }
  };

  const doClose = useCallback(
    (e) => {
      if (showData) {
        if (!data) {
          setError('Field is required');
        } else {
          setError('');
          closeDialog(e);
        }
      } else {
        closeDialog(e);
      }
    },
    [onClose, setError]
  );

  const doCancel = useCallback(
    (e) => {
      setError('');
      onClose && onClose(e, 'cancel');
    },
    [onClose, setError]
  );

  if (!visible) {
    return null as unknown as JSX.Element;
  }

  return (
    <div className="modalBackground">
      <div className="modal">
        <div className="modalHeader">
          <div className="modalTitle">{title}</div>
          {showCloseButton && (
            <button className="modalCloseButton" type="button" title="Close" onClick={(e) => doCancel(e)}>
              X
            </button>
          )}
        </div>
        {showData ? (
          <>
            <div className="modalContent">
              <input
                name="data"
                type="text"
                size={40}
                onChange={(e) => {
                  setData(e.target.value);
                }}
              />
            </div>
            <div className="error">{error}</div>
          </>
        ) : (
          <div>{children}</div>
        )}
        {showButtons && (
          <div className="modalButtonRowWrapper">
            <div className="modalButtonRow">
              <button className="modalButton" type="button" onClick={(e) => doClose(e)}>
                {okText || (showData ? 'Add' : 'OK')}
              </button>
              {cancelText && (
                <button className="modalButton" type="button" onClick={(e) => doCancel(e)}>
                  {cancelText.toUpperCase()}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  cancelText: 'Cancel',
  children: undefined,
  okText: '',
  onClose: undefined,
  showButtons: true,
  showData: false,
  showCloseButton: false,
  logoutHandler: undefined,
};

export default Modal;
