import Decimal from 'decimal.js';
import store from '../../store';
import actions from '../../store/actions';
import application from '../../utilities/application';
import calc from '../../utilities/calc';
import convert from '../../utilities/convert';
import PipeData from '../PipeData';
import SteamSaturatedPressure from './SteamSaturatedPressure';

export default class SteamSuperheated extends SteamSaturatedPressure {
  calculateValues = (pipeData: PipeData, minNomMax: string): PipeData => {
    const state = store.getState();

    if (minNomMax === 'min') {
      if (pipeData.flowMin === '' || pipeData.pressureMin === '' || pipeData.temperatureMin === '') {
        return pipeData;
      }
    } else if (minNomMax === 'nom') {
      if (pipeData.flowNom === '' || pipeData.pressureNom === '' || pipeData.temperatureNom === '') {
        return pipeData;
      }
    } else if (minNomMax === 'max') {
      if (pipeData.flowMax === '' || pipeData.pressureMax === '' || pipeData.temperatureMax === '') {
        return pipeData;
      }
    }

    const [tPipeData, conditions] = application.collectApplicationData(pipeData, minNomMax);

    let cond = { ...conditions };

    let [newPipeData, condComp] = application.collectApplicationData(tPipeData, minNomMax);

    condComp = this.calcSteamTempFromPressure(condComp);
    let satTemp = convert.temperatureTo(
      state.processConditions.temperatureUnit,
      condComp.dblTemperature,
      condComp.sTemperatureUnit
    );

    satTemp = new Decimal(satTemp.trunc());
    const aTemp = cond.dblTemperature;
    if (aTemp.lessThan(satTemp)) {
      store.dispatch(actions.setErrorMessage('Process Minimum Temperature Too Low.\nCorrected temperature entered.'));
      cond.dblTemperature = satTemp;
      if (minNomMax === 'min') {
        newPipeData.calcTemperatureMinVal = cond.dblTemperature;
        newPipeData.temperatureMin = cond.dblTemperature.toString();
      } else if (minNomMax === 'nom') {
        newPipeData.calcTemperatureNomVal = cond.dblTemperature;
        newPipeData.temperatureNom = cond.dblTemperature.toString();
      } else if (minNomMax === 'max') {
        newPipeData.calcTemperatureMaxVal = cond.dblTemperature;
        newPipeData.temperatureMax = cond.dblTemperature.toString();
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (minNomMax === 'min') {
        newPipeData.calcTemperatureMinVal = cond.dblTemperature;
        newPipeData.temperatureMin = cond.dblTemperature.toString();
      } else if (minNomMax === 'nom') {
        newPipeData.calcTemperatureNomVal = cond.dblTemperature;
        newPipeData.temperatureNom = cond.dblTemperature.toString();
      } else if (minNomMax === 'max') {
        newPipeData.calcTemperatureMaxVal = cond.dblTemperature;
        newPipeData.temperatureMax = cond.dblTemperature.toString();
      }
    }

    cond = this.calculateDensity(cond);
    newPipeData.isentropic = new Decimal('1.33');
    newPipeData.densityUnit = cond.sDensityUnit;
    let tmp;
    let val;

    if (minNomMax === 'min') {
      newPipeData.calcDensityMinVal = cond.dblDensityValue;
      newPipeData.densityMin = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'min');
      newPipeData.densityMinDisplay = tmp;
      newPipeData.densityMinInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'nom') {
      newPipeData.calcDensityNomVal = cond.dblDensityValue;
      newPipeData.densityNom = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'nom');
      newPipeData.densityNomDisplay = tmp;
      newPipeData.densityNomInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'max') {
      newPipeData.calcDensityMaxVal = cond.dblDensityValue;
      newPipeData.densityMax = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'max');
      newPipeData.densityMaxDisplay = tmp;
      newPipeData.densityMaxInternalUnit = cond.sDensityUnit;
    }

    store.dispatch(
      actions.setStandardConditions(this.calculateDensity(state.defaults.defaultProcessConditions.standardConditions))
    );

    store.dispatch(actions.setNormalConditions(this.calculateDensity(state.defaults.defaultProcessConditions.normalConditions)));

    cond = this.calculateViscosity(cond);

    if (minNomMax === 'min') {
      newPipeData.calcViscosityMinVal = cond.dblViscosityValue;
      newPipeData.viscosityMin = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'min');
      newPipeData.viscosityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcViscosityNomVal = cond.dblViscosityValue;
      newPipeData.viscosityNom = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'nom');
      newPipeData.viscosityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcViscosityMaxVal = cond.dblViscosityValue;
      newPipeData.viscosityMax = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'max');
      newPipeData.viscosityMaxDisplay = tmp;
    }

    newPipeData.viscosityUnit = cond.sViscosityUnit;

    cond.dblPressure = convert.pressureTo(
      `${newPipeData.originalPressurePrefix}-${newPipeData.originalPressureCoefficient}`,
      cond.dblPressure,
      cond.sPressureUnit,
      cond.sPressureGaugeAbsolute
    );
    newPipeData.pressurePrefix = newPipeData.originalPressurePrefix;
    newPipeData.pressureCoefficient = newPipeData.originalPressureCoefficient;

    if (minNomMax === 'min') {
      newPipeData.calcPressureMinVal = cond.dblPressure;
      newPipeData.pressureMin = cond.dblPressure.toString();

      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityMinVal = newPipeData.velocity;
      newPipeData.velocityMin = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'min');
      newPipeData.velocityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcPressureNomVal = cond.dblPressure;
      newPipeData.pressureNom = cond.dblPressure.toString();

      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityNomVal = newPipeData.velocity;
      newPipeData.velocityNom = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'nom');
      newPipeData.velocityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcPressureMaxVal = cond.dblPressure;
      newPipeData.pressureMax = cond.dblPressure.toString();

      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityMaxVal = newPipeData.velocity;
      newPipeData.velocityMax = newPipeData.velocity.toString();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'max');
      newPipeData.velocityMaxDisplay = tmp;
    }

    return newPipeData;
  };
}
