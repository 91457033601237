import Decimal from 'decimal.js';
import _ from 'lodash';
import store from '../store';
import ApiResponse from '../types/apiResponse';
import api from '../api';
import VortekDefaultUnits from '../types/vortekDefaultUnits';
import VortekDefaultValues from '../types/vortekDefaultValues';
import ProcessCondition from '../types/processCondition';
import VortekDefaultFormats from '../types/vortekDefaultFormats';
import VortekDefaults from '../types/vortekDefaults';
import PipeData from '../types/PipeData';
import actions from '../store/actions';
import SteamSaturatedPressure from '../types/fluid/SteamSaturatedPressure';
import SteamSaturatedTemperature from '../types/fluid/SteamSaturatedTemperature';
import SteamSuperheated from '../types/fluid/SteamSuperheated';
import convert from './convert';
import Water from '../types/fluid/Water';
import OtherGas from '../types/fluid/OtherGas';
import RealGas from '../types/fluid/RealGas';
import OtherLiquid from '../types/fluid/OtherLiquid';
import Liquid from '../types/fluid/Liquid';
import pipeUtils from './pipeUtils';
import constants from '../constants';
import Feature from '../types/Feature';

const addWarning = (warnings: string, warning: string): string => {
  let newWarnings = warnings;
  if (warnings.indexOf(warning) === -1) {
    newWarnings = `${warnings}${warning}<br />`;
  }

  return newWarnings;
};

const createFeature = (
  code: string,
  featureNumber: string,
  name: string,
  orderCode: string,
  description: string,
  note: string
): Feature => {
  const feature = new Feature();
  feature.Code = code;
  feature.FeatureNumber = featureNumber;
  feature.Name = name;
  feature.OrderCode = orderCode;
  feature.Description = description;
  feature.Note = note;

  return feature;
};

const loadDefaults = (data: any): VortekDefaults => {
  const newDefaults = new VortekDefaults();

  const newDefaultUnits: VortekDefaultUnits = {
    densityUnit: data.densityUnit,
    viscosityUnit: data.viscosityUnit,
    velocityUnit: data.velocityUnit,
    timeUnit: data.timeUnit,
    pressureDropUnit: data.pressureDropUnit,
    baroPressUnit: data.baroPressUnit,
    outputPressureDropUnit: 'psi',
  };

  const newDefaultValues: VortekDefaultValues = {
    baroPressValue: new Decimal(data.baroPressValue),
  };

  const newDefaultStdConditions = new ProcessCondition();
  newDefaultStdConditions.dblTemperature = new Decimal(data.stdConditions.dblTemperature);
  newDefaultStdConditions.sTemperatureUnit = data.stdConditions.sTemperatureUnit;
  newDefaultStdConditions.dblPressure = new Decimal(data.stdConditions.dblPressure);
  newDefaultStdConditions.sPressureUnit = data.stdConditions.sPressureUnit;
  newDefaultStdConditions.sPressureGaugeAbsolute = data.stdConditions.sPressureGaugeAbsolute;
  newDefaultStdConditions.dblDensityValue = new Decimal(data.stdConditions.dblDensityValue || 0);
  newDefaultStdConditions.dblFlowRate = new Decimal(data.stdConditions.dblFlowRate || 0);
  newDefaultStdConditions.dblViscosityValue = new Decimal(data.stdConditions.dblViscosityValue || 0);
  newDefaultStdConditions.sFlowRateTime = data.stdConditions.sFlowRateTime;
  newDefaultStdConditions.sFlowRateUnit = data.stdConditions.sFlowRateUnit;
  newDefaultStdConditions.sViscosityUnit = data.stdConditions.sViscosityUnit;

  const newDefaultNmlConditions = new ProcessCondition();
  newDefaultNmlConditions.dblTemperature = new Decimal(data.nmlConditions.dblTemperature);
  newDefaultNmlConditions.sTemperatureUnit = data.nmlConditions.sTemperatureUnit;
  newDefaultNmlConditions.dblPressure = new Decimal(data.nmlConditions.dblPressure);
  newDefaultNmlConditions.sPressureUnit = data.nmlConditions.sPressureUnit;
  newDefaultNmlConditions.sPressureGaugeAbsolute = data.nmlConditions.sPressureGaugeAbsolute;
  newDefaultNmlConditions.dblDensityValue = new Decimal(data.nmlConditions.dblDensityValue || 0);
  newDefaultNmlConditions.dblFlowRate = new Decimal(data.nmlConditions.dblFlowRate || 0);
  newDefaultNmlConditions.dblViscosityValue = new Decimal(data.nmlConditions.dblViscosityValue || 0);
  newDefaultNmlConditions.sFlowRateTime = data.nmlConditions.sFlowRateTime;
  newDefaultNmlConditions.sFlowRateUnit = data.nmlConditions.sFlowRateUnit;
  newDefaultNmlConditions.sViscosityUnit = data.nmlConditions.sViscosityUnit;

  const newDefaultFormats: VortekDefaultFormats = {
    flowRateFormat: data.flowRateFormat,
    lengthFormat: data.lengthFormat,
    massFormat: data.massFormat,
    pressureFormat: data.pressureFormat,
    temperatureFormat: data.temperatureFormat,
    volumeFormat: data.volumeFormat,
    areaFormat: data.areaFormat,
    densityFormat: data.densityFormat,
    velocityFormat: data.velocityFormat,
    viscosityFormat: data.viscosityFormat,
    unitlessFormat: data.unitlessFormat,
    generalNumberFormat: '0#',
    fixedFormat: '0.00##',
    standardFormat: '#,##0.00',
  };

  const newStandardConditions = newDefaultStdConditions.copy();
  const newNormalConditions = newDefaultNmlConditions.copy();

  newDefaults.defaultFormats = newDefaultFormats;
  newDefaults.defaultProcessConditions.defaultStdConditions = newDefaultStdConditions;
  newDefaults.defaultProcessConditions.defaultNmlConditions = newDefaultNmlConditions;
  newDefaults.defaultProcessConditions.standardConditions = newStandardConditions;
  newDefaults.defaultProcessConditions.normalConditions = newNormalConditions;
  newDefaults.defaultUnits = newDefaultUnits;
  newDefaults.defaultValues = newDefaultValues;

  return newDefaults;
};

const loadDefaultsFromDb = (userName: string, VORTEK_NAME: string): VortekDefaults => {
  const res = api.readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username: userName,
      collection: 'defaults',
    },
  });

  if (res.status !== 'fail' && !_.isEmpty(res.data.data)) {
    const data = res.data.data[0];
    return loadDefaults(data);
  }

  return new VortekDefaults();
};

const getBase64Image = (img: HTMLImageElement): string => {
  // Create an empty canvas element
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;

  // Copy the image contents to the canvas
  const ctx = canvas.getContext('2d');
  ctx?.drawImage(img, 0, 0);

  // Get the data-URL formatted image
  // Firefox supports PNG and JPEG. You could check img.src to guess the
  // original format, but be aware the using "image/jpg" will re-encode the image.
  const dataURL = canvas.toDataURL('image/png');

  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
};

const postData = (pipeDataIn: PipeData, master: boolean) => {
  const state = store.getState();
  const { defaults, processConditions } = state;

  const { defaultProcessConditions } = defaults;

  const { defaultNmlConditions, defaultStdConditions } = defaultProcessConditions;

  const { flowUnit } = processConditions;

  let pipeData = { ...pipeDataIn };

  // use Javascript to do the calculations here
  store.dispatch(actions.setAlertedNegative(false));

  let calculated = true;

  if (pipeData.fluidType === 'Steam') {
    if (pipeData.fluid === 'Saturated Pressure') {
      const saturatedPressure = new SteamSaturatedPressure();
      pipeData = saturatedPressure.calculateValues(pipeData, 'min');
      pipeData = saturatedPressure.calculateValues(pipeData, 'nom');
      pipeData = saturatedPressure.calculateValues(pipeData, 'max');
    } else if (pipeData.fluid === 'Saturated Temperature') {
      const saturatedTemperature = new SteamSaturatedTemperature();
      pipeData = saturatedTemperature.calculateValues(pipeData, 'min');
      pipeData = saturatedTemperature.calculateValues(pipeData, 'nom');
      pipeData = saturatedTemperature.calculateValues(pipeData, 'max');
    } else if (pipeData.fluid === 'Superheated') {
      const superheated = new SteamSuperheated();
      pipeData = superheated.calculateValues(pipeData, 'min');
      pipeData = superheated.calculateValues(pipeData, 'nom');
      pipeData = superheated.calculateValues(pipeData, 'max');
    } else {
      calculated = false;
    }

    if (calculated && (flowUnit === 'nm^3' || flowUnit === 'scf')) {
      let P: Decimal;
      let T: Decimal;
      if (flowUnit === 'nm^3') {
        P = defaultNmlConditions.dblPressure;
        P = convert.pressureTo('kPa-A', P, defaultNmlConditions.sPressureUnit, defaultNmlConditions.sPressureGaugeAbsolute);
        T = convert.temperatureTo('K', defaultNmlConditions.dblTemperature, defaultNmlConditions.sTemperatureUnit);
      } else {
        P = defaultStdConditions.dblPressure;
        P = convert.pressureTo('kPa-A', P, defaultStdConditions.sPressureUnit, defaultStdConditions.sPressureGaugeAbsolute);
        T = convert.temperatureTo('K', defaultStdConditions.dblTemperature, defaultStdConditions.sTemperatureUnit);
      }

      P = new Decimal(P?.toFixed(3));
      T = new Decimal(T?.toFixed(2));

      const v101p325 = new Decimal('101.325');
      const v373p15 = new Decimal('373.15');
      if (flowUnit === 'nm^3') {
        if (!v373p15.equals(T) || !v101p325.equals(P)) {
          store.dispatch(
            actions.setWarningMessage(
              'For steam and flow units of normal cubic meters it is\n' +
                'recommended that normal temperature be set to\n' +
                '100 degrees C and normal pressure be set to\n' +
                '101.325 kPa under Tools/Options/Normal Conditions.'
            )
          );
        }
      } else if (!v373p15.equals(T) || !v101p325.equals(P)) {
        store.dispatch(
          actions.setWarningMessage(
            'For steam and flow units of standard cubic feet it is\n' +
              'recommended that standard temperature be set to\n' +
              '212 degrees F and standard pressure be set to\n' +
              '14.696 psi under Tools/Options/Standard Conditions.'
          )
        );
      }
    }
  } else if (pipeData.fluidType === 'Water') {
    const water = new Water();
    pipeData = water.calculateValues(pipeData);

    if (master) {
      let sWarnings = '';

      if (pipeData.warningsMin.length) {
        pipeData.warningsMin.forEach((w) => {
          sWarnings = addWarning(sWarnings, w);
        });
      }

      if (pipeData.warningsNom.length) {
        pipeData.warningsNom.forEach((w) => {
          sWarnings = addWarning(sWarnings, w);
        });
      }

      if (pipeData.warningsMax.length) {
        pipeData.warningsMax.forEach((w) => {
          sWarnings = addWarning(sWarnings, w);
        });
      }

      if (sWarnings) {
        store.dispatch(actions.setWarningMessage(sWarnings));
      }
    }
  } else if (pipeData.fluidType === 'Gas' || pipeData.fluidType === 'Other Gas') {
    if (pipeData.fluidType === 'Other Gas') {
      if (pipeData.fluid) {
        const otherGas = new OtherGas();
        pipeData = otherGas.calculateValues(pipeData, 'min');
        pipeData = otherGas.calculateValues(pipeData, 'nom');
        pipeData = otherGas.calculateValues(pipeData, 'max');
      } else {
        calculated = false;
      }
    } else {
      const realGas = new RealGas();
      pipeData = realGas.calculateValues(pipeData, 'min');
      pipeData = realGas.calculateValues(pipeData, 'nom');
      pipeData = realGas.calculateValues(pipeData, 'max');
    }
  } else if (pipeData.fluidType === 'Liquid' || pipeData.fluidType === 'Other Liquid') {
    if (pipeData.fluidType === 'Other Liquid') {
      if (pipeData.fluid) {
        const otherLiquid = new OtherLiquid();
        pipeData = otherLiquid.calculateValues(pipeData, 'min');
        pipeData = otherLiquid.calculateValues(pipeData, 'nom');
        pipeData = otherLiquid.calculateValues(pipeData, 'max');
      } else {
        calculated = false;
      }
    } else {
      const liquid = new Liquid();
      pipeData = liquid.calculateValues(pipeData, 'min');
      pipeData = liquid.calculateValues(pipeData, 'nom');
      pipeData = liquid.calculateValues(pipeData, 'max');
    }
  } else {
    calculated = false;
  }

  if (master) {
    // Now that density, viscosity and pipe velocity have been caculated, place
    // the values into the labels.  The calculations have been completed
    // with english values.  These values may need to be converted into
    // the default units.
    if (calculated) {
      let [tmp, val] = convert.displayNumber(pipeData, 'min', 'temperature', 'temperatureFormat');

      pipeData.temperatureMin = tmp;
      pipeData.calcTemperatureMinVal = val;

      [tmp, val] = convert.displayNumber(pipeData, 'nom', 'temperature', 'temperatureFormat');
      pipeData.temperatureNom = tmp;
      pipeData.calcTemperatureNomVal = val;

      [tmp, val] = convert.displayNumber(pipeData, 'max', 'temperature', 'temperatureFormat');

      pipeData.temperatureMax = tmp;
      pipeData.calcTemperatureMaxVal = val;

      [tmp, val] = convert.displayNumber(pipeData, 'min', 'pressure', 'pressureFormat');
      pipeData.pressureMin = tmp;
      pipeData.calcPressureMinVal = val;

      [tmp, val] = convert.displayNumber(pipeData, 'nom', 'pressure', 'pressureFormat');
      pipeData.pressureNom = tmp;
      pipeData.calcPressureNomVal = val;

      [tmp, val] = convert.displayNumber(pipeData, 'max', 'pressure', 'pressureFormat');
      pipeData.pressureMax = tmp;
      pipeData.calcPressureMaxVal = val;

      [tmp, val] = convert.convertAndDisplayDensity(pipeData, 'min');
      pipeData.densityMinDisplay = tmp;

      [tmp, val] = convert.convertAndDisplayDensity(pipeData, 'nom');
      pipeData.densityNomDisplay = tmp;

      [tmp, val] = convert.convertAndDisplayDensity(pipeData, 'max');
      pipeData.densityMaxDisplay = tmp;

      [tmp, val] = convert.convertAndDisplayViscosity(pipeData, 'min');
      pipeData.viscosityMinDisplay = tmp;

      [tmp, val] = convert.convertAndDisplayViscosity(pipeData, 'nom');
      pipeData.viscosityNomDisplay = tmp;

      [tmp, val] = convert.convertAndDisplayViscosity(pipeData, 'max');
      pipeData.viscosityMaxDisplay = tmp;

      [tmp, val] = convert.convertAndDisplayVelocity(pipeData, 'min');
      pipeData.velocityMinDisplay = tmp;

      [tmp, val] = convert.convertAndDisplayVelocity(pipeData, 'nom');
      pipeData.velocityNomDisplay = tmp;

      [tmp, val] = convert.convertAndDisplayVelocity(pipeData, 'max');
      pipeData.velocityMaxDisplay = tmp;

      let temp = convert.formatNumber(pipeData.reynoldsMin);
      pipeData.reynoldsMin = temp;

      temp = convert.formatNumber(pipeData.reynoldsNom);
      pipeData.reynoldsNom = temp;

      temp = convert.formatNumber(pipeData.reynoldsMax);
      pipeData.reynoldsMax = temp;
      store.dispatch(actions.setProcessConditions(pipeData));
    } else {
      store.dispatch(actions.setDensityMin('*'));
      store.dispatch(actions.setDensityNom('*'));
      store.dispatch(actions.setDensityMax('*'));
      store.dispatch(actions.setViscosityMin('*'));
      store.dispatch(actions.setViscosityNom('*'));
      store.dispatch(actions.setViscosityMax('*'));
      store.dispatch(actions.setVelocityMin('*'));
      store.dispatch(actions.setVelocityNom('*'));
      store.dispatch(actions.setVelocityMax('*'));
    }
  }
};

const readyData = (): void => {
  const state = store.getState();
  const { pipeInsideDiameter, pipeOutsideDiameter, pipeSchedule, pipeUnit } = state;

  const pipeData: PipeData = pipeUtils.gatherPipeData(pipeOutsideDiameter, pipeSchedule, pipeInsideDiameter, pipeUnit);

  const dblArea = new Decimal(pipeData.pipeInsideDiameter).div(new Decimal('2'));
  pipeData.insideArea = constants.PI.times(dblArea.times(dblArea));

  store.dispatch(actions.setModelCode(''));
  postData(pipeData, true);
};

const checkExtendedValues = (): boolean => {
  const state = store.getState();
  const { processConditions } = state;

  const { densityMin, densityNom, densityMax, viscosityMin, viscosityNom, viscosityMax, velocityMin, velocityNom, velocityMax } =
    processConditions;

  const pass = !!(
    densityMin &&
    densityNom &&
    densityMax &&
    viscosityMin &&
    viscosityNom &&
    viscosityMax &&
    velocityMin &&
    velocityNom &&
    velocityMax
  );

  return pass;
};

const checkValues = (): boolean => {
  const state = store.getState();
  const { fluid, processConditions } = state;
  const { flowMin, flowNom, flowMax, temperatureMin, temperatureNom, temperatureMax, pressureMin, pressureNom, pressureMax } =
    processConditions;

  let valid = true;

  if (!flowMin || !flowNom || !flowMax) {
    valid = false;
  }

  if (valid && fluid !== 'Saturated Pressure') {
    if (!temperatureMin || !temperatureNom || !temperatureMax) {
      valid = false;
    }
  }

  if (valid && fluid !== 'Saturated Temperature') {
    if (!pressureMin || !pressureNom || !pressureMax) {
      valid = false;
    }
  }

  return valid;
};

const isNumber = (n: string | number): boolean => {
  if (_.isNaN(n)) {
    return false;
  }

  try {
    /* eslint-disable-next-line */
    const trydec = new Decimal(n);
    return true;
  } catch (err) {
    // TODO: show error modal
  }

  return false;
};

const isValid = (value: string | undefined | null): boolean => {
  return !(value === undefined || value === null || value?.trim() === '');
};

const validateNumber = (val: string): string => {
  try {
    /* eslint-disable-next-line */
    const trydec = new Decimal(val);
    return val;
  } catch (err) {
    // TODO: show error modal
  }

  return '';
};

// OEM names
const ARMSTRONG_NAME = 'Armstrong International, Inc.';
const AZBIL_NAME = 'Azbil North America, Inc.';
const GESTRA_NAME = 'Gestra';
const HEINRICH_NAME = 'Heinrichs Messtechnik GmbH';
const INNOVA_NAME = 'Sierra Instruments';
const ONICON_NAME = 'Onicon Incorporated';
const PANAFLOW_NAME = 'PanaflowMV';
const PROV_NAME = 'VorTek Instruments, LLC';
const SPIRAX_NAME = 'Spirax Sarco';
const WATSON_MCDANIEL_NAME = 'Watson McDaniel';

// Meter type names
const VORTEX_METER = 'vortex';
const VORTEX_NONREDUCE_METER = 'nonreduce_vortex';
const VORTEX_REDUCE_METER = 'reduce_vortex';
const TURBINE_METER = 'turbine';
const VORCONE_METER = 'vorcone';
const VORCONE_REDUCE_METER = 'reduce_vorcone';

const camelize = (str: string): string => {
  const s = str?.replace('/', ' ');
  return s?.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) {
      return '';
    } // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

const clientUrlParam = (): string => {
  const sPageURL = window?.location?.search?.substring(1);
  const sURLVariables = sPageURL?.split('&');
  let result;
  let i;
  let sParameterName;
  let clientName;

  /* eslint-disable no-console */
  console.log('detecting client');
  /* eslint-enable no-console */

  for (i = 0; i < sURLVariables?.length; i++) {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === 'client') {
      result = decodeURIComponent(sParameterName[1]);
      result = result.replace(/\+/g, ' ');
      clientName = result;
      break;
    }
  }

  if (!result) {
    clientName = window?.location?.hostname;
  }

  if (clientName) {
    clientName = clientName.toLowerCase();
    if (clientName.includes('armstrong')) {
      result = ARMSTRONG_NAME;
      document.title = 'Armstrong Sizer';
    } else if (clientName.includes('azbil')) {
      result = AZBIL_NAME;
      document.title = 'Azbil';
    } else if (clientName.includes('gestra')) {
      result = GESTRA_NAME;
      document.title = 'Gestra';
    } else if (clientName.includes('heinrichs')) {
      result = HEINRICH_NAME;
      document.title = 'Heinrichs';
    } else if (clientName.includes('onicon')) {
      result = ONICON_NAME;
      document.title = 'ONICON';
    } else if (clientName.includes('panaflow')) {
      result = PANAFLOW_NAME;
      document.title = 'PanaFlow&trade; Sizer';
    } else if (clientName.includes('sierra')) {
      result = INNOVA_NAME;
      document.title = 'Sierra';
    } else if (clientName.includes('spirax')) {
      result = SPIRAX_NAME;
      document.title = 'Spirax Sarco Sizer';
    } else if (clientName.includes('wm')) {
      result = WATSON_MCDANIEL_NAME;
      document.title = 'Watson McDaniel';
    }
  }

  if (!result) {
    result = PROV_NAME;
    document.title = 'Vortek';
  }

  return result;
};

const endsWith = (source: string, suffix: string): boolean => {
  return source?.indexOf(suffix, (source?.length || 0) - (suffix?.length || 0)) !== -1;
};

const findInArray = (someArray: Array<any>, unit: string): any | undefined => {
  let item;

  for (let i = 0; i < someArray.length; i++) {
    if (someArray[i].abbrv === unit) {
      item = someArray[i];
      break;
    }
  }

  return item;
};

const findInArrayByName = (someArray: Array<any>, name: string): any | undefined => {
  let item = '';

  for (let i = 0; i < someArray.length; i++) {
    if (someArray[i].name === name) {
      item = someArray[i];
      break;
    }
  }

  return item;
};

const findInArrayByFieldName = (someArray: Array<any>, fieldName: string, name: string): any | undefined => {
  let item = '';

  for (let i = 0; i < someArray.length; i++) {
    if (someArray[i][fieldName] === name) {
      item = someArray[i];
      break;
    }
  }

  return item;
};

const handleAjaxFailure = (data: ApiResponse): void => {
  if (data.error === 'Unauthorized') {
    store.dispatch(actions.setForceLogin(true));
    store.dispatch(actions.setErrorMessage('Your session has expired. Press OK to continue.'));
  } else {
    store.dispatch(actions.setErrorMessage(`An error occurred on the server. The error was '${data.error}'`));
  }
};

const includes = (source: string, str: string): boolean => {
  return source?.indexOf(str) !== -1;
};

const isHeinrichsOrOnicon = (): boolean => {
  const sPageURL = window?.document?.location?.search?.substring(1);
  const sURLVariables = sPageURL?.split('&');
  let result;

  for (let i = 0; i < sURLVariables?.length; i++) {
    const sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === 'client') {
      result = decodeURIComponent(sParameterName[1]);
      result = result.replace(/\+/g, ' ');
      break;
    }
  }

  if (!result) {
    const host = window?.document?.location?.hostname;
    const sHostPieces = host?.split('.');
    if (sHostPieces && sHostPieces[0] === 'heinrichs') {
      result = 'heinrichs';
    } else if (sHostPieces && sHostPieces[0] === 'onicon') {
      result = 'onicon';
    }
  }

  if (result && (result === 'heinrichs' || result === 'onicon')) {
    return true;
  }

  return false;
};

const scrubScriptTags = (val: string): string => {
  let v = val?.replace(/<[a-z-.0-9?/]/gi, '');
  v = v?.replace(/&lt/gi, '');
  v = v?.replace(/script/gi, '');
  v = v?.replace(/passwd/gi, '');
  v = v?.replace(/file/gi, '');
  v = v?.replace(/php/gi, '');
  v = v?.replace(/etc/gi, '');
  return v;
};

const scrubTextArea = (val: string): string => {
  return scrubScriptTags(val)?.replace(/\r?\n/g, '<br/>');
};

const startsWith = (source: string, str: string): boolean => {
  return source?.indexOf(str) === 0;
};

const trunc = (original: Decimal, digits: number): string => {
  // this truncates after the "digits" decimal place
  // go out to 10 places past to try and eliminate rounding of the digits place
  // since there is no real trunc() method
  let num = original.toFixed(digits + 10);
  num = num.substring(0, num.length - 10);
  return num;
};

const urlParam = (name: string): string | undefined => {
  let result;
  const sPageURL = window?.location?.search?.substring(1);
  const sURLVariables = sPageURL?.split('&');
  for (let i = 0; i < sURLVariables?.length; i++) {
    const sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === name) {
      result = decodeURIComponent(sParameterName[1]);
      result = result.replace(/\+/g, ' ');
      break;
    }
  }

  return result;
};

const deleteCookie = (name: string): void => {
  document.cookie = `${name}=foo; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

const getCookie = (name: string): string | null => {
  const dc = document.cookie;
  const prefix = `${name}=`;
  let begin = dc.indexOf(prefix);
  let end;
  if (begin === -1) {
    begin = dc.indexOf(prefix);
    if (begin !== 0) {
      return null;
    }
  } else {
    end = document.cookie.indexOf(';', begin);
    if (end === -1) {
      end = dc.length;
    }
  }

  // because unescape has been deprecated, replaced with decodeURI
  return decodeURI(dc.substring(begin + prefix.length, end));
};

/* eslint-disable */
const format = (m: string, v: string) => {
  if (!m || isNaN(+v)) {
    return v; // return as it is.
  }
  // convert any string to number according to formation sign.
  let vNum = m.charAt(0) === '-' ? -v : +v;
  const isNegative = vNum < 0 ? (vNum = -v) : 0; // process only abs(), and turn on flag.

  // search for separator for grp & decimal, anything not digit, not +/- sign, not #.
  const result = m.match(/[^\d\-\+#]/g);
  const decimal = (result && result[result.length - 1]) || '.'; // treat the right most symbol as decimal
  const Group = (result && result[1] && result[0]) || ','; // treat the left most symbol as group separator

  // split the decimal for the format string if any.
  const mArray = m.split(decimal);
  // Fix the decimal first, toFixed will auto fill trailing zero.
  let vStr = `${+vNum}`; // convert number to string to trim off *all* trailing decimal zero(es)
  const vDec = new Decimal(vStr);

  if (mArray[1]) {
    vStr = trunc(vDec, mArray[1].length);
  }
  // v = v.toFixed( m[1] && m[1].length);
  vStr = `${+vStr}`; // convert number to string to trim off *all* trailing decimal zero(es)

  // fill back any trailing zero according to format
  let posTrailZero = -1; // look for last zero in format
  if (mArray[1]) {
    posTrailZero = mArray[1].lastIndexOf('0');
  }

  const part = vStr.split('.');
  // integer will get !part[1]
  if (!part[1] || (part[1] && part[1].length <= posTrailZero)) {
    vStr = (+v).toFixed(posTrailZero + 1);
  }
  const szSep = mArray[0].split(Group); // look for separator
  mArray[0] = szSep.join(''); // join back without separator for counting the pos of any leading 0.

  let posLeadZero = -1;
  if (mArray[0]) {
    posLeadZero = mArray[0].indexOf('0');
  }

  if (posLeadZero > -1) {
    while (part[0].length < mArray[0].length - posLeadZero) {
      part[0] = `0${part[0]}`;
    }
  } else if (+part[0] === 0) {
    part[0] = '';
  }

  const vArray = vStr.split('.');
  vArray[0] = part[0];

  // process the first group separator from decimal (.) only, the rest ignore.
  // get the length of the last slice of split result.
  const posSeparator = szSep[1] && szSep[szSep.length - 1].length;
  if (posSeparator) {
    const integer = vArray[0];
    let str = '';
    const offset = integer.length % posSeparator;
    for (let i = 0, l = integer.length; i < l; i++) {
      str += integer.charAt(i); // ie6 only support charAt for sz.
      // -pos_separator so that won't trail separator on full length
      if ((i - offset + 1) % posSeparator === 0 && i < l - posSeparator) {
        str += Group;
      }
    }
    vArray[0] = str;
  }

  vArray[1] = mArray[1] && vArray[1] ? decimal + vArray[1] : '';
  return (isNegative ? '-' : '') + vArray[0] + vArray[1]; // put back any negation and combine integer and fraction.
};
/* eslint-enable */

export default {
  ARMSTRONG_NAME,
  AZBIL_NAME,
  GESTRA_NAME,
  HEINRICH_NAME,
  INNOVA_NAME,
  ONICON_NAME,
  PANAFLOW_NAME,
  PROV_NAME,
  SPIRAX_NAME,
  WATSON_MCDANIEL_NAME,
  VORTEX_METER,
  VORTEX_NONREDUCE_METER,
  VORTEX_REDUCE_METER,
  TURBINE_METER,
  VORCONE_METER,
  VORCONE_REDUCE_METER,
  addWarning,
  camelize,
  createFeature,
  readyData,
  checkExtendedValues,
  checkValues,
  clientUrlParam,
  deleteCookie,
  endsWith,
  findInArray,
  findInArrayByFieldName,
  findInArrayByName,
  format,
  getBase64Image,
  getCookie,
  handleAjaxFailure,
  includes,
  isHeinrichsOrOnicon,
  isNumber,
  isValid,
  scrubScriptTags,
  scrubTextArea,
  startsWith,
  trunc,
  urlParam,
  validateNumber,
  loadDefaultsFromDb,
};
