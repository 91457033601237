import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import actions from '../../store/actions';
import Meter from '../../types/Meter';
import calcMeter from '../../utilities/calc/calcMeter';
import meterSpecify from '../../utilities/meterSpecify';

const MeterSizing = (): JSX.Element => {
  const dispatch = useDispatch();
  const state = useSelector((globalState: RootState) => globalState);
  const { appMode, meterData, meterIndex, meterType, pipeOutsideDiameter, pipeType, sizingGlobals } = state;
  const { goodUsableColor } = sizingGlobals;

  const { UltrasonicS34Model, UltrasonicS36Model, UltrasonicU42Model, UltrasonicU43Model, UltrasonicU44Model } = sizingGlobals;

  const [hoverIndex, setHoverIndex] = useState(-1);

  const createColumn = (meter: Meter): Meter => {
    // const meter = { ...currentMeter };
    const { newPipeData } = meter;

    if (pipeType === 'din' && meter.name.indexOf('-') === -1 && meter.type !== 'MODEL_ProT') {
      newPipeData.pipeSize = `DN ${meter.name}`;
    } else if (meter.type === 'In-line Reducing') {
      // eslint-disable-next-line no-param-reassign
      meter.name = calcMeter.getReducedSize(pipeOutsideDiameter);
      newPipeData.pipeSize = meter.name;
    } else {
      newPipeData.pipeSize = meter.name;
    }

    if (meter.type === 'MODEL_ProT') {
      // eslint-disable-next-line no-param-reassign
      meter.type = 'Turbine';
    } else if (
      meterType === UltrasonicS34Model ||
      meterType === UltrasonicS36Model ||
      meterType === UltrasonicU42Model ||
      meterType === UltrasonicU43Model ||
      meterType === UltrasonicU44Model
    ) {
      // eslint-disable-next-line no-param-reassign
      meter.type = 'Clamp On';
    }

    return meter;
  };

  useEffect(() => {
    let meterColData = meterData;
    if (appMode === 'sizing' && meterData.length === 0) {
      meterColData = calcMeter.columnData();
      if (meterColData.length) {
        dispatch(actions.setMeterData(meterColData));
      }
    }
  }, [appMode, meterData]);

  const click = (index: number) => {
    meterSpecify.resetSpecifyMeterFields();
    dispatch(actions.setAccessories([]));
    if (index === meterIndex) {
      dispatch(actions.setMeterIndex(-1));
    } else {
      dispatch(actions.setMeterIndex(index));
    }
  };

  const hover = (index: number) => {
    setHoverIndex(index);
  };

  const noHover = () => {
    setHoverIndex(-1);
  };

  const getColumn = (val: string, index: number): JSX.Element => {
    return (
      // eslint-disable-next-line
      <td
        key={`${val}${index}`}
        className={hoverIndex === index || meterIndex === index ? 'metertblHover nowrap' : 'nowrap'}
        onMouseDown={() => click(index)}
        onMouseEnter={() => hover(index)}
        onMouseLeave={noHover}
      >
        {val}
      </td>
    );
  };

  const getHeader = (val: string, index: number): JSX.Element => {
    return (
      <th
        key={`${val}${index}`}
        className={hoverIndex === index || meterIndex === index ? 'metertblHover nowrap' : 'nowrap'}
        onMouseDown={() => click(index)}
        onMouseEnter={() => hover(index)}
        onMouseLeave={noHover}
      >
        {val}
      </th>
    );
  };

  return (
    <>
      <div className="clear" />
      <div className="sizeMeters">
        <div className="flexRow">
          <div className="titleLeft" />
          <div className="titleBackground">SIZED METERS</div>
          <div className="titleRight" />
        </div>
        <div className="table">
          <table className="metertbl">
            <thead>
              <tr>
                <th className="row_label">Meter Type</th>
                {meterData.map((meter, index) => {
                  const newMeter = createColumn(meter);
                  return getHeader(newMeter.type, index);
                })}
              </tr>
              <tr>
                <th className="row_label">Size</th>
                {meterData.map((meter, index) => {
                  const newMeter = createColumn(meter);
                  return getHeader(newMeter.newPipeData.pipeSize, index);
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="row_label">Usable Meter</th>
                {meterData.map((meter) => {
                  return (
                    <td
                      key={`${meter.name}-${meter.usable}`}
                      className="nowrap"
                      style={
                        meter.usable === 'Good'
                          ? {
                              backgroundColor: goodUsableColor,
                              color: 'white',
                              fontWeight: 'bold',
                            }
                          : {
                              backgroundColor: '#CC9900',
                              color: 'white',
                              fontWeight: 'bold',
                            }
                      }
                    >
                      {meter.usable}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th className="row_label">Meter Minimum Flow</th>
                {meterData.map((meter, index) => {
                  return getColumn(meter.minflow, index);
                })}
              </tr>
              <tr>
                <th className="row_label">Meter Maximum Flow</th>
                {meterData.map((meter, index) => {
                  return getColumn(meter.maxflow, index);
                })}
              </tr>
              <tr>
                <th className="row_label flow_rate_span">
                  Pressure Drop @ <span />
                </th>
                {meterData.map((meter, index) => {
                  return getColumn(meter.pressdrop, index);
                })}
              </tr>
              <tr>
                <th className="row_label">Min Reynolds</th>
                {meterData.map((meter, index) => {
                  return getColumn(meter.minrey, index);
                })}
              </tr>
              <tr>
                <th className="row_label">Max Reynolds</th>
                {meterData.map((meter, index) => {
                  return getColumn(meter.maxrey, index);
                })}
              </tr>
              <tr>
                <th className="row_label min_vel_span">
                  Min Velocity @ <span />
                </th>
                {meterData.map((meter, index) => {
                  return getColumn(meter.minvel, index);
                })}
              </tr>
              <tr>
                <th className="row_label nom_vel_span">
                  Nom Velocity @ <span />
                </th>
                {meterData.map((meter, index) => {
                  return getColumn(meter.nomvel, index);
                })}
              </tr>
              <tr>
                <th className="row_label max_vel_span">
                  Max Velocity @ <span />
                </th>
                {meterData.map((meter, index) => {
                  return getColumn(meter.maxvel, index);
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MeterSizing;
