import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { RootState } from '../../store';
import Modal from '../common/Modal';
import { CallbackNoParams } from '../../types/callbackTypes';
import application from '../../utilities/application';

const CreateApplication = (props: { onClose: CallbackNoParams }): JSX.Element | null => {
  const { onClose } = props;
  const [applicationStatus, setApplicationStatus] = useState('');
  const state = useSelector((globalState: RootState) => globalState);
  const { flowMin, flowNom, flowMax, temperatureMin, temperatureNom, temperatureMax, pressureMin, pressureNom, pressureMax } =
    state.processConditions;
  const { appName } = state;

  useEffect(() => {
    if (
      appName !== '' &&
      flowMin !== '' &&
      flowNom !== '' &&
      flowMax !== '' &&
      temperatureMin !== '' &&
      temperatureNom !== '' &&
      temperatureMax !== '' &&
      pressureMin !== '' &&
      pressureNom !== '' &&
      pressureMax !== ''
    ) {
      setApplicationStatus('complete');
    } else if (
      appName !== '' ||
      flowMin !== '' ||
      flowNom !== '' ||
      flowMax !== '' ||
      temperatureMin !== '' ||
      temperatureNom !== '' ||
      temperatureMax !== '' ||
      pressureMin !== '' ||
      pressureNom !== '' ||
      pressureMax !== ''
    ) {
      setApplicationStatus('incomplete');
    } else {
      application.newApplication('no');
      onClose();
    }
  }, []);

  return (
    <>
      {applicationStatus === 'incomplete' && (
        <Modal title="Continue?" visible showButtons={false} showCloseButton onClose={onClose}>
          <div className="flexCol modalContent">
            <p>An incomplete application is in progress. Do you really want to clear all data?</p>

            <div className="flexRow">
              <button
                type="button"
                onClick={() => {
                  application.newApplication('no');
                  onClose();
                }}
              >
                Yes
              </button>
              <button type="button" onClick={onClose}>
                No
              </button>
            </div>
          </div>
        </Modal>
      )}

      {applicationStatus === 'complete' && (
        <Modal title="Save Changes" visible showButtons={false} showCloseButton onClose={onClose}>
          <div className="flexCol modalContent">
            <p>Would you like to save the current application before creating a new one?</p>

            <div className="flexRow">
              <button
                type="button"
                onClick={() => {
                  application.saveApplicationToServer();
                  onClose();
                }}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => {
                  application.newApplication('no');
                  onClose();
                }}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CreateApplication;
