import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import actions from '../../../store/actions';

const DisplayedDigits = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const state = useSelector((globalState: RootState) => globalState);
  const { workingDefaults, defaults } = state;

  const dispDigitsTypeAry = [
    { name: 'Flow Rate', value: 'flowRateFormat' },
    { name: 'Length', value: 'lengthFormat' },
    { name: 'Mass', value: 'massFormat' },
    { name: 'Pressure', value: 'pressureFormat' },
    { name: 'Temperature', value: 'temperatureFormat' },
    { name: 'Volume', value: 'volumeFormat' },
    { name: 'Area', value: 'areaFormat' },
    { name: 'Density', value: 'densityFormat' },
    { name: 'Velocity', value: 'velocityFormat' },
    { name: 'Viscosity', value: 'viscosityFormat' },
    { name: 'Unitless', value: 'unitlessFormat' },
  ];

  const dispDigitsFormatAry = [
    '',
    '#,###,##0.00##',
    '####0.00##',
    '#,##0.00',
    '0.00##',
    'General Number',
    'Fixed',
    'Standard',
    'Scientific',
  ];

  const [unitType, setUnitType] = useState('');
  const [lastUnitType, setLastUnitType] = useState('flowRateFormat');
  const [namedFormat, setNamedFormat] = useState('');
  const [customFormat, setCustomFormat] = useState('');
  const [alreadySaved, setAlreadySaved] = useState(false);

  // When the main dropdown (the variable dropdown) changes, display the correct format currently saved for that varaible.
  // So, if the format exists in the 'named format' dropdown, display that format as the selected value.
  // If the format is NOT in the 'named format' dropdown, then display the value in the 'custom format' input field.
  const setDisplayedValues = (variableSelected: string) => {
    const format = variableSelected;
    const formatParam = format as keyof typeof workingDefaults.defaultFormats;
    const foundNamedFormat = dispDigitsFormatAry.findIndex((item) => {
      return item === workingDefaults.defaultFormats[formatParam];
    });
    setUnitType(variableSelected);
    if (foundNamedFormat >= 0) {
      setNamedFormat(workingDefaults.defaultFormats[formatParam]);
    } else {
      setCustomFormat(workingDefaults.defaultFormats[formatParam]);
    }
  };

  const variableSelectChanged = (e: any) => {
    setDisplayedValues(e.target.value);
  };

  useEffect(() => {
    setNamedFormat('');
    setCustomFormat('');
    setDisplayedValues(lastUnitType);
  }, [lastUnitType]);

  useEffect(() => {
    // reset the value for the last selection
    if (alreadySaved) {
      const lastFormatParam = lastUnitType as keyof typeof workingDefaults.defaultFormats;
      const lastFormatParam2 = lastUnitType as keyof typeof defaults.defaultFormats;
      workingDefaults.defaultFormats[lastFormatParam] = defaults.defaultFormats[lastFormatParam2];
    }

    // Save value to redux
    if (unitType !== '') {
      const formatParam = unitType as keyof typeof workingDefaults.defaultFormats;
      workingDefaults.defaultFormats[formatParam] = namedFormat;
      dispatch(actions.setWorkingDefaults(workingDefaults));

      setLastUnitType(unitType);
      setAlreadySaved(true);
    }
  }, [namedFormat]);

  useEffect(() => {
    // reset the value for the last selection
    if (alreadySaved) {
      const lastFormatParam = lastUnitType as keyof typeof workingDefaults.defaultFormats;
      const lastFormatParam2 = lastUnitType as keyof typeof defaults.defaultFormats;
      workingDefaults.defaultFormats[lastFormatParam] = defaults.defaultFormats[lastFormatParam2];
    }

    // Save value to redux
    if (unitType !== '') {
      const formatParam = unitType as keyof typeof workingDefaults.defaultFormats;
      workingDefaults.defaultFormats[formatParam] = customFormat;
      dispatch(actions.setWorkingDefaults(workingDefaults));

      setLastUnitType(unitType);
      setAlreadySaved(true);
    }
  }, [customFormat]);

  return (
    <div className="modalContentTabs">
      <div className="bottom20 fleft">To change the displayed digits for a variable:</div>
      <div className="fieldRow">
        <span className="field">
          <div className="fieldTitle">1. Select the variable</div>
          <div />
          <select
            name="flowUnit1"
            className="dropSmall2a"
            onChange={(e) => {
              variableSelectChanged(e);
            }}
          >
            {dispDigitsTypeAry.map((p) => {
              return (
                <option key={p.value} value={p.value}>
                  {p.name}
                </option>
              );
            })}
          </select>
        </span>
      </div>
      <div className="fieldTitle">2. Select a named format OR input a custom format (like ##0.00)</div>
      <div className="fieldRow">
        <span className="field">
          <div />
          <select
            name="flowUnit1"
            value={namedFormat}
            className="dropSmall2a"
            onChange={(e) => {
              setNamedFormat(e.target.value);
              setCustomFormat('');
            }}
          >
            {dispDigitsFormatAry.map((p) => {
              return (
                <option key={p} value={p}>
                  {p}
                </option>
              );
            })}
          </select>
        </span>
        <span className="field">
          <span className="leftRight30">or</span>
          <input
            value={customFormat}
            placeholder="Custom Format"
            onChange={(e) => {
              setCustomFormat(e.target.value);
              setNamedFormat('');
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default DisplayedDigits;
