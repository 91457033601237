import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../common/Modal';
import { RootState } from '../../store';
import { CallbackNoParams } from '../../types/callbackTypes';
import TabGroup from '../common/TabGroup';
import BarometricPressure from './tools/barometricPressure';
import DisplayedDigits from './tools/displayedDigits';
import DefaultUnits from './tools/defaultUnits';
import Conditions from './tools/conditions';
import utilities from '../../utilities';
import WorkingVortekDefaults from '../../types/workingVortekDefaults';
import actions from '../../store/actions';
import api from '../../api';

const Options = (props: { onClose: CallbackNoParams }): JSX.Element => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const tabItems = ['Barometric Pressure', 'Default Units', 'Displayed Digits', 'Standard Conditions', 'Normal Conditions'];
  const [active, setActive] = useState(tabItems[1]);

  const displayTab = (tabItem: string) => {
    setActive(tabItem);
  };

  const state = useSelector((globalState: RootState) => globalState);
  const { workingDefaults, sizingGlobals, username } = state;
  const { VORTEK_NAME } = sizingGlobals;

  const closeModal = useCallback(
    async (e, response) => {
      if (response === 'ok') {
        const newDefaults = [
          {
            densityUnit: workingDefaults.defaultUnits.densityUnit,
            viscosityUnit: workingDefaults.defaultUnits.viscosityUnit,
            velocityUnit: workingDefaults.defaultUnits.velocityUnit,
            timeUnit: workingDefaults.defaultUnits.timeUnit,
            pressureDropUnit: workingDefaults.defaultUnits.pressureDropUnit,
            stdConditions: {
              dblTemperature: workingDefaults.defaultProcessConditions.defaultStdConditions.dblTemperature.toString(),
              sTemperatureUnit: workingDefaults.defaultProcessConditions.defaultStdConditions.sTemperatureUnit,
              dblPressure: workingDefaults.defaultProcessConditions.defaultStdConditions.dblPressure.toString(),
              sPressureUnit: workingDefaults.defaultProcessConditions.defaultStdConditions.sPressureUnit,
              sPressureGaugeAbsolute: workingDefaults.defaultProcessConditions.defaultStdConditions.sPressureGaugeAbsolute,
            },
            nmlConditions: {
              dblTemperature: workingDefaults.defaultProcessConditions.defaultNmlConditions.dblTemperature.toString(),
              sTemperatureUnit: workingDefaults.defaultProcessConditions.defaultNmlConditions.sTemperatureUnit,
              dblPressure: workingDefaults.defaultProcessConditions.defaultNmlConditions.dblPressure.toString(),
              sPressureUnit: workingDefaults.defaultProcessConditions.defaultNmlConditions.sPressureUnit,
              sPressureGaugeAbsolute: workingDefaults.defaultProcessConditions.defaultNmlConditions.sPressureGaugeAbsolute,
            },
            baroPressValue: workingDefaults.defaultValues.baroPressValue.toString(),
            baroPressUnit: workingDefaults.defaultUnits.baroPressUnit,
            flowRateFormat: workingDefaults.defaultFormats.flowRateFormat,
            lengthFormat: workingDefaults.defaultFormats.lengthFormat,
            massFormat: workingDefaults.defaultFormats.massFormat,
            pressureFormat: workingDefaults.defaultFormats.pressureFormat,
            temperatureFormat: workingDefaults.defaultFormats.temperatureFormat,
            volumeFormat: workingDefaults.defaultFormats.volumeFormat,
            areaFormat: workingDefaults.defaultFormats.areaFormat,
            densityFormat: workingDefaults.defaultFormats.densityFormat,
            velocityFormat: workingDefaults.defaultFormats.velocityFormat,
            viscosityFormat: workingDefaults.defaultFormats.viscosityFormat,
            unitlessFormat: workingDefaults.defaultFormats.unitlessFormat,
          },
        ];

        const res = await api.writeData(VORTEK_NAME, username, 'defaults', newDefaults);

        if (res.status === 'fail') {
          utilities.handleAjaxFailure(res);
          onClose();
          return;
        }
      }

      // User hit 'Cancel' on modal, so reset any redux values that were changed
      if (response === 'cancel') {
        if (workingDefaults.newValuesSaved) {
          // reset all values
          const newDefaults = utilities.loadDefaultsFromDb(username, VORTEK_NAME) as WorkingVortekDefaults;
          newDefaults.newValuesSaved = false;
          dispatch(actions.setWorkingDefaults(newDefaults));
        }
      }

      workingDefaults.newValuesSaved = false;
      onClose();
    },
    [workingDefaults]
  );

  return (
    <Modal title="Options" visible showButtons onClose={closeModal} showCloseButton>
      <div className="leftRight10 topBottom10" style={{ color: 'white', width: 700, height: 325 }}>
        <div className="flexRow tabRow">
          <div className="leftRight10 flexCol white">
            <TabGroup
              tabItems={tabItems}
              selectedIndex={1}
              onClick={displayTab}
              tabStyle="toolOptionsTabStyle"
              tabStyleActive="toolOptionsTabStyleActive"
            />
          </div>
        </div>
        {active === 'Barometric Pressure' && <BarometricPressure />}
        {active === 'Default Units' && <DefaultUnits />}
        {active === 'Displayed Digits' && <DisplayedDigits />}
        {active === 'Standard Conditions' && <Conditions type="Standard" />}
        {active === 'Normal Conditions' && <Conditions type="Normal" />}
      </div>
    </Modal>
  );
};

export default Options;
