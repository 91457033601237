import Decimal from 'decimal.js';
import PipeData from '../../types/PipeData';
import api from '../../api';
import constants from '../../constants';
import convert from '../convert';
import ProcessCondition from '../../types/processCondition';

export class VortekMeters {
  meters: {
    M22: any[];
    M23: any[];
    All: any[];
    Inline: any[];
    Default: any[];
  };

  constructor() {
    this.meters = { M22: [], M23: [], All: [], Inline: [], Default: [] };
  }

  /*
   *  calculate Reynolds number based upon:
   *      velocity    ft/sec
   *      pipeID    inches
   *      density  lb/cu ft
   *      viscosity   centipoise
   */
  calculateReynolds = (velocity: Decimal, pipeID: string, density: Decimal, viscosity: Decimal, pipeData: PipeData): Decimal => {
    const convertedVelocity = velocity;
    const convertedPipe = convert.pipeInsideDiameter_To('in', pipeID, pipeData.pipeUnit);
    const convertedDensity = density;

    const convertedViscosity = convert.viscosityTo('cP', viscosity, pipeData.viscosityUnit);

    const top = convertedVelocity.times(convertedPipe).times(convertedDensity);
    const bottom = new Decimal('0.0080636616').times(convertedViscosity);
    return top.div(bottom);
  };

  // Darcy–Weisbach equation
  // id in ft or m
  // velocity in ft/sec or m/sec
  // density lb/ft3 or kg/m3
  calculatePressureDrop = (id: Decimal, velocity: Decimal, density: Decimal, reynolds: Decimal): Decimal => {
    const frictionFactor = new Decimal('64').div(reynolds);
    const locLength = new Decimal('1');
    const newId = id.div(new Decimal('12'));

    let result = density.times(velocity).times(velocity).div(new Decimal('2'));
    result = result.times(frictionFactor.times(locLength.div(newId)));
    return result;
    // return frictionFactor * (locLength / id) * ((density * velocity * velocity) / 2);
  };

  calculateHeadLoss = (id: Decimal, flowrate: Decimal, reynolds: Decimal): Decimal => {
    // g = 9.80444 m/s^2 or 32.16679 ft/sec^2
    const frictionFactor = new Decimal('64').div(reynolds);
    const locLength = new Decimal('1');
    const newId = id.div(new Decimal('12'));

    const top = frictionFactor.times(new Decimal('8')).times(locLength).times(flowrate).times(flowrate);
    const pisq = constants.PI.pow(new Decimal('2'));

    const bottom = new Decimal('32.16679').times(pisq).times(newId.pow(new Decimal('5')));

    return top.div(bottom);
    // return (frictionFactor * 8 * locLength * flowrate * flowrate) /
    //    (32.16679 * 3.1415926536 * 3.1415926536 * Math.pow(id, 5));
  };

  getArea = (id: Decimal): Decimal => {
    // return (id / 2) * (id / 2) * 3.14159265369;
    const idDiv2 = id.div(new Decimal('2'));

    return idDiv2.times(idDiv2).times(constants.PI);
  };

  convertPipeId = (value: Decimal, unitTo: string, unitFrom: string): Decimal => {
    // Find the to conversion factors.
    const itemTo = api.findMeasurement('length', unitTo);
    const dblFactorTo = itemTo.factor;

    const itemFrom = api.findMeasurement('length', unitFrom);
    const dblFactorFrom = itemFrom.factor;

    let dblResult = value.times(dblFactorFrom);
    dblResult = dblResult.div(dblFactorTo);

    return dblResult;
  };

  convertTime = (value: Decimal, unitTo: string, unitFrom: string) => {
    // Find the to conversion factors.
    const itemTo = api.findMeasurement('time', unitTo);
    const dblFactorTo = itemTo.factor;

    const itemFrom = api.findMeasurement('time', unitFrom);
    const dblFactorFrom = itemFrom.factor;

    let dblResult = value.times(dblFactorFrom);
    dblResult = dblResult.div(dblFactorTo);

    return dblResult;
  };

  getInlineId = (name: string) => {
    for (let i = 0; i < this.meters.Inline.length; i++) {
      if (this.meters.Inline[i].Name === name) {
        return this.meters.Inline[i].InsideDia;
      }
    }
    return false;
  };
}

const calcVelocityFromRe = (
  dblCalcRe: Decimal,
  dblInsideDiameter: Decimal,
  dblDensity: Decimal,
  densityUnits: string,
  dblViscosity: Decimal,
  viscosityUnits: string
): Decimal => {
  // Calculate the velocity from the Reynolds value, pipe inside diameter, and the process conditions
  // entered by the user.  The inside diameter is converted to inches from the start.
  // Ro in lb/ft^3
  // Re = vel(ft/sec) * ID(in) * Ro(lbm/ft^3) / (8.063616E-3 * visc(cP)

  // Verify that the density and viscosity have the correct units.
  const newViscosity = convert.viscosityTo('cP', dblViscosity, viscosityUnits);
  const newDensity = convert.densityTo('lb/ft^3', dblDensity, densityUnits);

  // Calculate the velocity.
  const top = dblCalcRe.times(new Decimal('0.008063616')).times(newViscosity);
  const bottom = dblInsideDiameter.times(newDensity);
  const dblResult = top.div(bottom);
  // dblResult = (dblCalcRe * 0.008063616 * dblViscosity) / (dblInsideDiameter * dblDensity)

  return dblResult;
};

const fluidCalculationVelocity = (pipeData: PipeData, processCondition: ProcessCondition): PipeData => {
  // This subroutine will calculate the fluid velocity through
  // the pipe.  The units will be returned in feet per second.
  const dblFlow = convert.flowRateTo(
    processCondition.dblFlowRate,
    'ft^3/sec',
    processCondition.sFlowRateUnit,
    processCondition.sFlowRateTime,
    processCondition.dblDensityValue,
    processCondition.sDensityUnit
  );

  // Calculate the pipe area.
  let dblArea = convert.pipeID_To('ft', pipeData);
  dblArea = dblArea.div(new Decimal('2'));

  const newPipeData = { ...pipeData };
  const areaVal = constants.PI.times(dblArea.times(dblArea));
  newPipeData.area = areaVal.toString();
  newPipeData.areaUnit = 'ft^2';

  // Calculate the velocity
  newPipeData.velocity = dblFlow.div(areaVal);
  newPipeData.velocityUnit = 'ft/sec';

  return newPipeData;
};

// these 2 use vortek.classes.VortekMeters, so they have to come after it is defined

const areaTo = (unitsTo: string, pipeData: PipeData): Decimal => {
  // This function will convert the pipe inside area to ft^2.  The unit passed will be
  // in a linear measure due to the units table not having an area table.  The length
  // table will be used, and the units squared.
  // all Decimal
  let dblFactorTo;
  let dblFactorFrom;
  let dblResult;

  const itemFrom = api.findMeasurement('length', pipeData.pipeUnit);
  dblFactorFrom = itemFrom.factor;

  const itemTo = api.findMeasurement('length', unitsTo);
  dblFactorTo = itemTo.factor;

  // These are in linear units.  Convert to area.
  dblFactorFrom = dblFactorFrom.pow(new Decimal('2'));
  dblFactorTo = dblFactorTo.pow(new Decimal('2'));

  // Convert to the new unit.
  const vMeters = new VortekMeters();
  const insideArea = vMeters.getArea(new Decimal(pipeData.pipeInsideDiameter));

  dblResult = insideArea.times(dblFactorFrom);
  dblResult = dblResult.div(dblFactorTo);
  return dblResult;
};

const idTo = (unitsFrom: string, pipeId: Decimal, unitsTo: string): Decimal => {
  // This function will convert the pipe inside area to ft^2.  The unit passed will be
  // in a linear measure due to the units table not having an area table.  The length
  // table will be used, and the units squared.
  // all Decimal
  let dblFactorTo;
  let dblFactorFrom;
  let dblResult;

  const itemTo = api.findMeasurement('length', unitsTo);
  dblFactorTo = itemTo.factor;

  const itemFrom = api.findMeasurement('length', unitsFrom);
  dblFactorFrom = itemFrom.factor;

  // These are in linear units.  Convert to area.
  dblFactorFrom = dblFactorFrom.pow(new Decimal('2'));
  dblFactorTo = dblFactorTo.pow(new Decimal('2'));

  // Convert to the new unit.
  const vMeters = new VortekMeters();
  const insideArea = vMeters.getArea(pipeId);

  dblResult = insideArea.times(dblFactorFrom);
  dblResult = dblResult.div(dblFactorTo);
  return dblResult;
};

export default {
  calcVelocityFromRe,
  fluidCalculationVelocity,
  areaTo,
  idTo,
};
