import store from '../store';
import utilities from '../utilities';

const state = store.getState();
const { sizingGlobals } = state;
const { VORTEK_NAME } = sizingGlobals;

let client = 'default';
import(`./${client}.css`);

client = 'ag-grid';
import(`./${client}.css`);

client = 'ag-theme-balham';
import(`./${client}.css`);

client = '';
if (VORTEK_NAME === utilities.ARMSTRONG_NAME) {
  client = 'armstrong';
} else if (VORTEK_NAME === utilities.AZBIL_NAME) {
  client = 'azbil';
} else if (VORTEK_NAME === utilities.GESTRA_NAME) {
  client = 'gestra';
} else if (VORTEK_NAME === utilities.HEINRICH_NAME) {
  client = 'heinrichs';
} else if (VORTEK_NAME === utilities.INNOVA_NAME) {
  client = 'sierra';
} else if (VORTEK_NAME === utilities.ONICON_NAME) {
  client = 'onicon';
} else if (VORTEK_NAME === utilities.PANAFLOW_NAME) {
  client = 'panaflow';
} else if (VORTEK_NAME === utilities.SPIRAX_NAME) {
  client = 'spirax';
}

import(`./${'faary'}.css`);
if (client.length > 0) {
  import(`./${client}.css`);
}
