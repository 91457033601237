import VortekDefaultFormats from './vortekDefaultFormats';
import VortekDefaultProcessConditions from './vortekDefaultProcessConditions';
import VortekDefaultUnits from './vortekDefaultUnits';
import VortekDefaultValues from './vortekDefaultValues';

export default class VortekDefaults {
  defaultFormats: VortekDefaultFormats = new VortekDefaultFormats();
  defaultProcessConditions = new VortekDefaultProcessConditions();
  defaultUnits = new VortekDefaultUnits();
  defaultValues = new VortekDefaultValues();
}
