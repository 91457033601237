import Decimal from 'decimal.js';
import constants from '../constants';
import PipeData from './PipeData';

export default class Meter {
  name: string;
  diameter: string;
  type: string;
  size: string;
  unit: string;
  wall: string | undefined;
  schedule: string | undefined;
  insidearea: Decimal;
  insideunit: string;
  usable: string;
  minflow: string;
  minFlowUnit: string;
  nomflow: string;
  nomFlowUnit: string;
  maxflow: string;
  maxFlowUnit: string;
  maxpressloss: string;
  pressdrop: string;
  pressdropunits: string;
  minrey: string;
  nomrey: string;
  maxrey: string;
  minvel: string;
  nomvel: string;
  maxvel: string;
  minflowwarn: string;
  maxflowwarn: string;
  mindpwarn: string;
  maxdpwarn: string;
  pressdropwarn: string;
  headlosswarn: string;
  minreynwarn: string;
  maxreynwarn: string;
  minvelowarn: string;
  nomvelowarn: string;
  maxvelowarn: string;
  minturvel: Decimal;
  maxturvel: Decimal;
  beta: string | undefined;
  mindp: string;
  mindpval: Decimal;
  maxdp: string;
  maxdpval: Decimal;
  newPipeData: PipeData;

  constructor() {
    this.name = '';
    this.diameter = '';
    this.type = '';
    this.size = '';
    this.unit = '';
    this.wall = '';
    this.schedule = '';
    this.insidearea = constants.ZERO;
    this.insideunit = '';
    this.usable = '';
    this.minflow = '';
    this.minFlowUnit = '';
    this.nomflow = '';
    this.nomFlowUnit = '';
    this.maxflow = '';
    this.maxFlowUnit = '';
    this.maxpressloss = '0';
    this.pressdrop = '';
    this.pressdropunits = '';
    this.minrey = '';
    this.nomrey = '';
    this.maxrey = '';
    this.minvel = '';
    this.nomvel = '';
    this.maxvel = '';
    this.minflowwarn = '';
    this.maxflowwarn = '';
    this.mindpwarn = '';
    this.maxdpwarn = '';
    this.pressdropwarn = '';
    this.headlosswarn = '';
    this.minreynwarn = '';
    this.maxreynwarn = '';
    this.minvelowarn = '';
    this.nomvelowarn = '';
    this.maxvelowarn = '';
    this.minturvel = constants.ZERO;
    this.maxturvel = constants.ZERO;
    this.beta = '';
    this.mindp = '';
    this.mindpval = constants.ZERO;
    this.maxdp = '';
    this.maxdpval = constants.ZERO;
    this.newPipeData = constants.defaultPipeData;
  }
}
