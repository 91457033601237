import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import { RootState } from '../../store';
import actions from '../../store/actions';
import Unit from '../../types/Unit';
import VortekDefaults from '../../types/vortekDefaults';
import WorkingVortekDefaults from '../../types/workingVortekDefaults';
import utilities from '../../utilities';

const ProcessConditions = (): JSX.Element => {
  const dispatch = useDispatch();
  const defaultListItems: string[] = [];
  const [allFlowUnits, setAllFlowUnits] = useState(defaultListItems);
  const [allFlowTimes, setAllFlowTimes] = useState(defaultListItems);
  const [allTemperatureUnits, setAllTemperatureUnits] = useState(defaultListItems);
  const [allPressureUnits, setAllPressureUnits] = useState(defaultListItems);

  const db = useSelector((globalState: RootState) => globalState.db);
  const { defaults: dbDefaults, flow, temperature, time, pressure } = db;

  const state = useSelector((globalState: RootState) => globalState);
  const {
    defaults,
    fluid,
    fluidType,
    meterType,
    pipeInsideDiameter,
    pipeOutsideDiameter,
    pipeReduceInsideDiameter,
    pipeReduceOutsideDiameter,
    pipeSchedule,
    pipeType,
    pipeUnit,
    processConditions,
    sizingGlobals,
    username,
  } = state;

  const { defaultUnits } = defaults;
  const { densityUnit, viscosityUnit, velocityUnit, timeUnit } = defaultUnits;

  const {
    flowMin,
    flowNom,
    flowMax,
    flowUnit,
    flowTime,
    temperatureMin,
    temperatureNom,
    temperatureMax,
    temperatureUnit,
    pressureMin,
    pressureNom,
    pressureMax,
    pressurePrefix,
    pressureCoefficient,
    densityMinDisplay,
    densityNomDisplay,
    densityMaxDisplay,
    viscosityMinDisplay,
    viscosityNomDisplay,
    viscosityMaxDisplay,
    velocityMinDisplay,
    velocityNomDisplay,
    velocityMaxDisplay,
  } = processConditions;

  const { VORTEK_NAME } = sizingGlobals;

  const flowMinRef = useRef<HTMLInputElement>(null);
  const flowNomRef = useRef<HTMLInputElement>(null);
  const flowMaxRef = useRef<HTMLInputElement>(null);
  const tempMinRef = useRef<HTMLInputElement>(null);
  const tempNomRef = useRef<HTMLInputElement>(null);
  const tempMaxRef = useRef<HTMLInputElement>(null);
  const pressMinRef = useRef<HTMLInputElement>(null);
  const pressNomRef = useRef<HTMLInputElement>(null);
  const pressMaxRef = useRef<HTMLInputElement>(null);

  const getDropDownData = (collection: string): string[] => {
    let data = [] as string[];
    const res = api.readOneSync(true, {
      requestData: {
        client: VORTEK_NAME,
        username,
        collection,
      },
    });

    if (res.status !== 'fail') {
      const table = res.data.data as unknown as Unit[];
      if (table && table.map) {
        data = table.map((r) => {
          return r.abbrv;
        });
      }
    }

    return data;
  };

  const fillDropDowns = (preserveCurrentSelections: boolean) => {
    let defaultFlowUnit = 'lb';
    const defaultFlowTime = 'hr';
    let defaultTemperatureUnit = 'deg F';
    let defaultPressureUnit = 'psi';
    const defaultPressureCoefficient = 'G';

    if (VORTEK_NAME === utilities.HEINRICH_NAME || VORTEK_NAME === utilities.AZBIL_NAME) {
      defaultFlowUnit = 'kg';
      defaultTemperatureUnit = 'deg C';

      if (VORTEK_NAME === utilities.HEINRICH_NAME) {
        defaultPressureUnit = 'bar';
      } else {
        defaultPressureUnit = 'kPa';
      }
    }

    const flowUnits = getDropDownData('flow');
    setAllFlowUnits(flowUnits);
    const flowTimes = getDropDownData('time');
    setAllFlowTimes(flowTimes);
    const tempUnits = getDropDownData('temperature');
    setAllTemperatureUnits(tempUnits);
    const pressureUnits = getDropDownData('pressure');
    setAllPressureUnits(pressureUnits);

    if (!preserveCurrentSelections || !flowUnits.includes(flowUnit)) {
      dispatch(actions.setFlowUnit(defaultFlowUnit));
    }

    if (!preserveCurrentSelections || !tempUnits.includes(temperatureUnit)) {
      dispatch(actions.setTemperatureUnit(defaultTemperatureUnit));
    }

    if (!preserveCurrentSelections || !pressureUnits.includes(pressurePrefix)) {
      dispatch(actions.setPressurePrefix(defaultPressureUnit));
    }

    if (!preserveCurrentSelections || !flowTimes.includes(flowTime)) {
      dispatch(actions.setFlowTime(defaultFlowTime));
    }

    if (!preserveCurrentSelections || !['G', 'A'].includes(pressureCoefficient)) {
      dispatch(actions.setPressureCoefficient(defaultPressureCoefficient));
    }
  };

  const loadDefaults = () => {
    const newDefaults = utilities.loadDefaultsFromDb(username, VORTEK_NAME);
    dispatch(actions.setDensityUnit(newDefaults.defaultUnits.densityUnit));
    dispatch(actions.setViscosityUnit(newDefaults.defaultUnits.viscosityUnit));
    dispatch(actions.setVelocityUnit(`${newDefaults.defaultUnits.velocityUnit}/${newDefaults.defaultUnits.timeUnit}`));

    dispatch(actions.setDefaults(newDefaults as VortekDefaults));

    const defaultsClone = JSON.parse(JSON.stringify(newDefaults)) as WorkingVortekDefaults;
    defaultsClone.newValuesSaved = false;
    dispatch(actions.setWorkingDefaults(defaultsClone));
  };

  useEffect(() => {
    fillDropDowns(false);
    loadDefaults();
  }, [dbDefaults, flow, temperature, pressure, time]);

  const checkProcessCondition = (inputType: string) => {
    // remove commas
    let inputMinFlow = flowMin;
    let inputNomFlow = flowNom;
    let inputMaxFlow = flowMax;
    let inputMinTemp = temperatureMin;
    let inputNomTemp = temperatureNom;
    let inputMaxTemp = temperatureMax;
    let inputMinPress = pressureMin;
    let inputNomPress = pressureNom;
    let inputMaxPress = pressureMax;

    inputMinFlow = inputMinFlow.replace(/,/g, '');
    inputNomFlow = inputNomFlow.replace(/,/g, '');
    inputMaxFlow = inputMaxFlow.replace(/,/g, '');
    inputMinTemp = inputMinTemp.replace(/,/g, '');
    inputNomTemp = inputNomTemp.replace(/,/g, '');
    inputMaxTemp = inputMaxTemp.replace(/,/g, '');
    inputMinPress = inputMinPress.replace(/,/g, '');
    inputNomPress = inputNomPress.replace(/,/g, '');
    inputMaxPress = inputMaxPress.replace(/,/g, '');

    // Flow Rate...
    if (inputType === 'flowMin' || inputType === 'flowNom' || inputType === 'flowMax') {
      const minFlowInvalid = inputMinFlow && (!utilities.isNumber(inputMinFlow) || Number(inputMinFlow) <= 0);
      const nomFlowInvalid = inputNomFlow && (!utilities.isNumber(inputNomFlow) || Number(inputNomFlow) <= 0);
      const maxFlowInvalid = inputMaxFlow && (!utilities.isNumber(inputMaxFlow) || Number(inputMaxFlow) <= 0);

      if (minFlowInvalid || nomFlowInvalid || maxFlowInvalid) {
        if (
          (inputType === 'flowMin' && minFlowInvalid) ||
          (inputType === 'flowNom' && nomFlowInvalid) ||
          (inputType === 'flowMax' && maxFlowInvalid)
        ) {
          dispatch(actions.setErrorMessage('Flow rate value error'));
          if (minFlowInvalid) {
            dispatch(actions.setFlowMin(''));
            if (flowMinRef?.current) {
              flowMinRef.current.focus();
            }
          } else if (nomFlowInvalid) {
            dispatch(actions.setFlowNom(''));
            if (flowNomRef?.current) {
              flowNomRef.current.focus();
            }
          } else if (maxFlowInvalid) {
            dispatch(actions.setFlowMax(''));
            if (flowMaxRef?.current) {
              flowMaxRef.current.focus();
            }
          }

          return;
        }
      }
    }

    // Temperature...
    if (inputType === 'tempMin' || inputType === 'tempNom' || inputType === 'tempMax') {
      const minTempInvalid = inputMinTemp && !utilities.isNumber(inputMinTemp);
      const nomTempInvalid = inputNomTemp && !utilities.isNumber(inputNomTemp);
      const maxTempInvalid = inputMaxTemp && !utilities.isNumber(inputMaxTemp);

      if (minTempInvalid || nomTempInvalid || maxTempInvalid) {
        if (
          (inputType === 'tempMin' && minTempInvalid) ||
          (inputType === 'tempNom' && nomTempInvalid) ||
          (inputType === 'tempMax' && maxTempInvalid)
        ) {
          dispatch(actions.setErrorMessage('Temperature value error'));

          if (minTempInvalid) {
            dispatch(actions.setTemperatureMin(''));
            if (tempMinRef?.current) {
              tempMinRef.current.focus();
            }
          } else if (nomTempInvalid) {
            dispatch(actions.setTemperatureNom(''));
            if (tempNomRef?.current) {
              tempNomRef.current.focus();
            }
          } else if (maxTempInvalid) {
            dispatch(actions.setTemperatureMax(''));
            if (tempMaxRef?.current) {
              tempMaxRef.current.focus();
            }
          }

          return;
        }
      }
    }

    // Pressure...
    if (inputType === 'pressMin' || inputType === 'pressNom' || inputType === 'pressMax') {
      const minPressInvalid = inputMinPress && (!utilities.isNumber(inputMinPress) || Number(inputMinPress) < -10);
      const nomPressInvalid = inputNomPress && (!utilities.isNumber(inputNomPress) || Number(inputNomPress) < -10);
      const maxPressInvalid = inputMaxPress && (!utilities.isNumber(inputMaxPress) || Number(inputMaxPress) < -10);

      if (minPressInvalid || nomPressInvalid || maxPressInvalid) {
        if (
          (inputType === 'pressMin' && minPressInvalid) ||
          (inputType === 'pressNom' && nomPressInvalid) ||
          (inputType === 'pressMax' && maxPressInvalid)
        ) {
          dispatch(actions.setErrorMessage('Pressure value error'));

          if (minPressInvalid) {
            dispatch(actions.setPressureMin(''));
            if (pressMinRef?.current) {
              pressMinRef.current.focus();
            }
          } else if (nomPressInvalid) {
            dispatch(actions.setPressureNom(''));
            if (pressNomRef?.current) {
              pressNomRef.current.focus();
            }
          } else if (maxPressInvalid) {
            dispatch(actions.setPressureMax(''));
            if (pressMaxRef?.current) {
              pressMaxRef.current.focus();
            }
          }

          return;
        }
      }
    }

    // set value in input type Flow Rate...

    // Convert to numeric to ensure
    // we are dealing with NUMBERS, NOT STRINGS
    const inputMinFlowVal = Number(inputMinFlow);
    let inputNomFlowVal = Number(inputNomFlow);
    const inputMaxFlowVal = Number(inputMaxFlow);

    const inputMinTempVal = inputMinTemp === '' ? -999 : Number(inputMinTemp);
    let inputNomTempVal = inputNomTemp === '' ? -999 : Number(inputNomTemp);
    const inputMaxTempVal = inputMaxTemp === '' ? -999 : Number(inputMaxTemp);

    const inputMinPressVal = inputMinPress === '' ? -999 : Number(inputMinPress);
    let inputNomPressVal = inputNomPress === '' ? -999 : Number(inputNomPress);
    const inputMaxPressVal = inputMaxPress === '' ? -999 : Number(inputMaxPress);

    // Front fill values (min->nom->max)
    if (inputNomFlowVal < inputMinFlowVal) {
      dispatch(actions.setFlowNom(inputMinFlow));
      if (inputMinFlowVal > inputNomFlowVal) {
        dispatch(actions.setFlowMax(inputMinFlow));
      } else {
        dispatch(actions.setFlowMax(inputNomFlow));
      }
    }

    if (inputMaxFlowVal < inputNomFlowVal) {
      if (inputMinFlowVal > inputNomFlowVal) {
        dispatch(actions.setFlowMax(inputMinFlow));
      } else {
        dispatch(actions.setFlowMax(inputNomFlow));
      }
    }

    // set value in input type Temperature...
    if (inputNomTempVal < inputMinTempVal) {
      dispatch(actions.setTemperatureNom(inputMinTemp));
      if (inputMinTempVal > inputNomTempVal) {
        dispatch(actions.setTemperatureMax(inputMinTemp));
      } else {
        dispatch(actions.setTemperatureMax(inputNomTemp));
      }
    }

    if (inputMaxTempVal < inputNomTempVal) {
      if (inputMinTempVal > inputNomTempVal) {
        dispatch(actions.setTemperatureMax(inputMinTemp));
      } else {
        dispatch(actions.setTemperatureMax(inputNomTemp));
      }
    }

    // set value in input type Pressure...
    if (inputNomPressVal < inputMinPressVal) {
      dispatch(actions.setPressureNom(inputMinPress));
      if (inputMinPressVal > inputNomPressVal) {
        dispatch(actions.setPressureMax(inputMinPress));
      } else {
        dispatch(actions.setPressureMax(inputNomPress));
      }
    }

    if (inputMaxPressVal < inputNomPressVal) {
      if (inputMinPressVal > inputNomPressVal) {
        dispatch(actions.setPressureMax(inputMinPress));
      } else {
        dispatch(actions.setPressureMax(inputNomPress));
      }
    }

    // Back-fill values (max->nom->min)
    if (inputMaxFlowVal && inputNomFlow === '') {
      dispatch(actions.setFlowNom(`${inputMaxFlowVal}`));
      inputNomFlowVal = inputMaxFlowVal;
    }

    if (inputNomFlowVal && inputMinFlow === '') {
      dispatch(actions.setFlowMin(`${inputNomFlowVal}`));
    }

    if (inputMaxTempVal > -999 && inputNomTemp === '') {
      dispatch(actions.setTemperatureNom(`${inputMaxTempVal}`));
      inputNomTempVal = inputMaxTempVal;
    }

    if (inputNomTempVal > -999 && inputMinTemp === '') {
      dispatch(actions.setTemperatureMin(`${inputNomTempVal}`));
    }

    if (inputMaxPressVal > -999 && inputNomPress === '') {
      dispatch(actions.setPressureNom(`${inputMaxPressVal}`));
      inputNomPressVal = inputMaxPressVal;
    }

    if (inputNomPressVal > -999 && !inputMinPress) {
      dispatch(actions.setPressureMin(`${inputNomPressVal}`));
    }

    if (utilities.checkValues()) {
      utilities.readyData();
    }
  };

  useEffect(() => {
    if (utilities.checkValues()) {
      utilities.readyData();
    }
  }, [
    meterType,
    fluid,
    fluidType,
    meterType,
    pipeInsideDiameter,
    pipeOutsideDiameter,
    pipeReduceInsideDiameter,
    pipeReduceOutsideDiameter,
    pipeSchedule,
    pipeType,
    pipeUnit,
    flowUnit,
    flowTime,
    temperatureUnit,
    pressurePrefix,
    pressureCoefficient,
    densityUnit,
    viscosityUnit,
    velocityUnit,
    timeUnit,
  ]);

  return (
    <div className="process_condition">
      <div className="flexRow">
        <div className="titleLeft" />
        <div className="titleBackground">PROCESS CONDITIONS</div>
        <div className="titleRight" />
      </div>
      <div className="contentBox conditions">
        <div className="flexRow topBottom5">
          <div className="conditionsPad" />
          <div className="conditionsPad">Minimum</div>
          <div className="conditionsPad">Nominal</div>
          <div className="conditionsPad">Maximum</div>
          <div className="padClear" />
          <div className="conditionsUnits">Units</div>
        </div>
        <div className="flexRow">
          <div className="conditionsLeftPad">Flow Rate</div>
          <div className="conditionsLeftPad">
            <input
              ref={flowMinRef}
              name="flowMin"
              className="txtboxSmall"
              type="number"
              step="any"
              min="0"
              value={flowMin}
              onChange={(e) => {
                dispatch(actions.setFlowMin(utilities.validateNumber(e.target.value)));
              }}
              onBlur={() => {
                checkProcessCondition('flowMin');
              }}
            />
          </div>
          <div className="conditionsLeftPad">
            <input
              ref={flowNomRef}
              name="flowNom"
              className="txtboxSmall"
              type="number"
              step="any"
              min="0"
              value={flowNom}
              onChange={(e) => {
                dispatch(actions.setFlowNom(utilities.validateNumber(e.target.value)));
              }}
              onBlur={() => {
                checkProcessCondition('flowNom');
              }}
            />
          </div>
          <div className="conditionsLeftPad">
            <input
              ref={flowMaxRef}
              name="flowMax"
              className="txtboxSmall"
              type="number"
              step="any"
              min="0"
              value={flowMax}
              onChange={(e) => {
                dispatch(actions.setFlowMax(utilities.validateNumber(e.target.value)));
              }}
              onBlur={() => {
                checkProcessCondition('flowMax');
              }}
            />
          </div>
          <div>
            <button
              type="button"
              className="btnGreen small"
              onClick={(e) => {
                e.preventDefault();
                dispatch(actions.setFlowMin(''));
                dispatch(actions.setFlowNom(''));
                dispatch(actions.setFlowMax(''));
              }}
            >
              C
            </button>
          </div>
          <div className="conditionsUnitsDropSmall">
            <select
              name="flowUnit1"
              className="dropSmall2"
              value={flowUnit}
              onChange={(e) => {
                const val = e.target.value;
                dispatch(actions.setFlowUnit(val));
              }}
            >
              {allFlowUnits.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="conditionsUnitsDropSmall">
            <select
              name="flowUnit2"
              className="dropSmall2"
              value={flowTime}
              onChange={(e) => {
                const val = e.target.value;
                dispatch(actions.setFlowTime(val));
              }}
            >
              {allFlowTimes.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="flexRow topBottom10">
          <div className="conditionsLeftPad">Temperature</div>
          <div className="conditionsLeftPad">
            <input
              ref={tempMinRef}
              name="tempMin"
              className="txtboxSmall"
              type="number"
              step="any"
              disabled={fluid === 'Saturated Pressure'}
              value={temperatureMin}
              onChange={(e) => {
                dispatch(actions.setTemperatureMin(utilities.validateNumber(e.target.value)));
              }}
              onBlur={() => {
                checkProcessCondition('tempMin');
              }}
            />
          </div>
          <div className="conditionsLeftPad">
            <input
              ref={tempNomRef}
              name="tempNom"
              className="txtboxSmall"
              type="number"
              step="any"
              disabled={fluid === 'Saturated Pressure'}
              value={temperatureNom}
              onChange={(e) => {
                dispatch(actions.setTemperatureNom(utilities.validateNumber(e.target.value)));
              }}
              onBlur={() => {
                checkProcessCondition('tempNom');
              }}
            />
          </div>
          <div className="conditionsLeftPad">
            <input
              ref={tempMaxRef}
              name="tempMax"
              className="txtboxSmall"
              type="number"
              step="any"
              disabled={fluid === 'Saturated Pressure'}
              value={temperatureMax}
              onChange={(e) => {
                dispatch(actions.setTemperatureMax(utilities.validateNumber(e.target.value)));
              }}
              onBlur={() => {
                checkProcessCondition('tempMax');
              }}
            />
          </div>
          <div>
            <button
              type="button"
              className="btnGreen small"
              onClick={(e) => {
                e.preventDefault();
                dispatch(actions.setTemperatureMin(''));
                dispatch(actions.setTemperatureNom(''));
                dispatch(actions.setTemperatureMax(''));
              }}
            >
              C
            </button>
          </div>
          <div>
            <select
              name="tempUnit"
              className="dropSmall3"
              value={temperatureUnit}
              onChange={(e) => {
                const val = e.target.value;
                dispatch(actions.setTemperatureUnit(val));
              }}
            >
              {allTemperatureUnits.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="flexRow">
          <div className="conditionsLeftPad">Pressure</div>
          <div className="conditionsLeftPad">
            <input
              ref={pressMinRef}
              name="pressMin"
              className="txtboxSmall"
              type="number"
              step="any"
              disabled={fluid === 'Saturated Temperature'}
              min="-10"
              value={pressureMin}
              onChange={(e) => {
                dispatch(actions.setPressureMin(utilities.validateNumber(e.target.value)));
              }}
              onBlur={() => {
                checkProcessCondition('pressMin');
              }}
            />
          </div>
          <div className="conditionsLeftPad">
            <input
              ref={pressNomRef}
              name="pressNom"
              className="txtboxSmall"
              type="number"
              step="any"
              disabled={fluid === 'Saturated Temperature'}
              min="-10"
              value={pressureNom}
              onChange={(e) => {
                dispatch(actions.setPressureNom(utilities.validateNumber(e.target.value)));
              }}
              onBlur={() => {
                checkProcessCondition('pressNom');
              }}
            />
          </div>
          <div className="conditionsLeftPad">
            <input
              ref={pressMaxRef}
              name="pressMax"
              className="txtboxSmall"
              type="number"
              step="any"
              disabled={fluid === 'Saturated Temperature'}
              min="-10"
              value={pressureMax}
              onChange={(e) => {
                dispatch(actions.setPressureMax(utilities.validateNumber(e.target.value)));
              }}
              onBlur={() => {
                checkProcessCondition('pressMax');
              }}
            />
          </div>
          <div>
            <button
              type="button"
              className="btnGreen small"
              onClick={(e) => {
                e.preventDefault();
                dispatch(actions.setPressureMin(''));
                dispatch(actions.setPressureNom(''));
                dispatch(actions.setPressureMax(''));
              }}
            >
              C
            </button>
          </div>
          <div className="conditionsUnitsDropSmall">
            <select
              name="pressureUnit1"
              className="dropSmall2"
              value={pressurePrefix}
              onChange={(e) => {
                const val = e.target.value;
                dispatch(actions.setPressurePrefix(val));
              }}
            >
              {allPressureUnits.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="conditionsUnitsDropSmall">
            <select
              name="pressureUnit2"
              className="dropSmall2"
              value={pressureCoefficient}
              onChange={(e) => {
                const val = e.target.value;
                dispatch(actions.setPressureCoefficient(val));
              }}
            >
              <option value="G">G</option>
              <option value="A">A</option>
            </select>
          </div>
        </div>
        <div className="flexRow conditionSpacer" />
        <div className="flexRow topBottom10">
          <div className="conditionsLeftPad">Density</div>
          <div className="conditionsInput conditionsPad">{densityMinDisplay || '*'}</div>
          <div className="conditionsInput conditionsPad">{densityNomDisplay || '*'}</div>
          <div className="conditionsInput conditionsPad">{densityMaxDisplay || '*'}</div>
          <div className="padClear" />
          <div className="conditionsUnitsDropSmall">{`${densityUnit || 'lb/ft^3'}`}</div>
        </div>
        <div className="flexRow topBottom10">
          <div className="conditionsLeftPad">Viscosity</div>
          <div className="conditionsInput conditionsPad">{viscosityMinDisplay || '*'}</div>
          <div className="conditionsInput conditionsPad">{viscosityNomDisplay || '*'}</div>
          <div className="conditionsInput conditionsPad">{viscosityMaxDisplay || '*'}</div>
          <div className="padClear" />
          <div className="conditionsUnitsDropSmall">{viscosityUnit || 'cP'}</div>
        </div>
        <div className="flexRow topBottom10">
          <div className="conditionsLeftPad">Pipe Velocity</div>
          <div className="conditionsInput conditionsPad">{velocityMinDisplay || '*'}</div>
          <div className="conditionsInput conditionsPad">{velocityNomDisplay || '*'}</div>
          <div className="conditionsInput conditionsPad">{velocityMaxDisplay || '*'}</div>
          <div className="padClear" />
          <div className="conditionsUnitsDropSmall">{`${velocityUnit || 'ft'}/${timeUnit || 'sec'}`}</div>
        </div>
      </div>
    </div>
  );
};

export default ProcessConditions;
