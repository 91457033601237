import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import Accessory from '../../types/accessory';
import utilities from '../../utilities';
import actions from '../../store/actions';
import meterValidate from '../../utilities/meterValidate';

const accessoryOptions = {
  ACCESSORIES_INLINE: [
    { order: 1, code: 'MC', description: 'Material Certificates' },
    { order: 2, code: 'PT', description: 'Pressure test Certificates' },
    { order: 3, code: 'CC', description: 'Certificate of Conformance' },
    { order: 4, code: 'NC', description: 'NACE Certification' },
    { order: 5, code: 'O2', description: 'Cleaned for O2 service (certificate)' },
  ] as Accessory[],
  ACCESSORIES_INSERTION: [
    { order: 1, code: 'RR', description: 'Removable Retractor' },
    { order: 2, code: 'RK1', description: '2" FNPT Gate Valve, Class 125, Bronze' },
    { order: 3, code: 'RK1.5', description: '2" FNPT Gate Valve, Class 150, Bronze' },
    { order: 4, code: 'RK10', description: '2" FNPT Gate Valve, Class 200, 316 SS' },
    { order: 5, code: 'RK11', description: '2" FNPT Gate Valve, Class 800, Carbon Steel' },
    { order: 6, code: 'RK110', description: '2" FNPT Gate Valve, Class 800, 316 SS' },
    { order: 7, code: 'RK2', description: '2" 150# Flanged Gate Valve, Carbon Steel' },
    { order: 8, code: 'RK20', description: '2" 150# Flanged Gate Valve, Stainless Steel' },
    { order: 9, code: 'RK3', description: '2" 300# Flanged Gate Valve, Carbon Steel' },
    { order: 10, code: 'RK30', description: '2" 300# Flanged Gate Valve, Stainless Steel' },
    { order: 11, code: 'RK4', description: '2" 600# Flanged Gate Valve, Carbon Steel' },
    { order: 12, code: 'RK40', description: '2" 600# Flanged Gate Valve, Stainless Steel' },
    { order: 13, code: 'MA', description: '2" FNPT Adapter, Carbon Steel' },
    { order: 14, code: 'MAS', description: '2" FNPT Adapter, Stainless Steel' },
    { order: 15, code: 'FMA', description: '2" 150# Flange Adapter, Carbon Steel' },
    { order: 16, code: 'FMAS', description: '2" 150# Flange Adapter, Stainless Steel' },
    { order: 17, code: 'GMA', description: '2" 300# Flange Adapter, Carbon Steel' },
    { order: 18, code: 'GMAS', description: '2" 300# Flange Adapter, Stainless Steel' },
    { order: 19, code: 'HMA', description: '2" 600# Flange Adapter, Carbon Steel' },
    { order: 20, code: 'HMAS', description: '2" 600# Flange Adapter, Stainless Steel' },
    { order: 21, code: 'MC', description: 'Material Certificates' },
    { order: 22, code: 'PT', description: 'Pressure Test Certificates' },
    { order: 23, code: 'CC', description: 'Certificate of Conformance' },
    { order: 24, code: 'NC', description: 'NACE Certification' },
    { order: 25, code: 'O2', description: 'Cleaned for O2 Service (certificate)' },
  ],
  ACCESSORIES_INSERTION_NEW: [
    { order: 1, code: 'RR', description: 'Removable Retractor' },
    { order: 2, code: 'VKBT-125(*)', description: '2" FNPT Gate Valve, Class 125, Bronze' },
    { order: 3, code: 'VKBT-150(*)', description: '2" FNPT Gate Valve, Class 150, Bronze' },
    { order: 4, code: 'VKBT-300(*)', description: '2" FNPT Gate Valve, Class 300, Bronze' },
    { order: 5, code: 'VKST-200(*)', description: '2" FNPT Gate Valve, Class 200, 316 SS' },
    { order: 6, code: 'VACF-150', description: '2" 150# Flanged Gate Valve, Carbon Steel' },
    { order: 7, code: 'VACF-300', description: '2" 300# Flanged Gate Valve, Carbon Steel' },
    { order: 8, code: 'VACF-600', description: '2" 600# Flanged Gate Valve, Carbon Steel' },
    { order: 9, code: 'VASF-150', description: '2" 150# Flanged Gate Valve, Stainless Steel' },
    { order: 10, code: 'VASF-300', description: '2" 300# Flanged Gate Valve, Stainless Steel' },
    { order: 11, code: 'VASF-600', description: '2" 600# Flanged Gate Valve, Stainless Steel' },
    { order: 12, code: 'PACT(*)', description: '2" FNPT Adapter, Carbon Steel' },
    { order: 13, code: 'PAST(*)', description: '2" FNPT Adapter, Stainless Steel' },
    { order: 14, code: 'PACF-150(*)', description: '2" 150# Flange Adapter, Carbon Steel' },
    { order: 15, code: 'PACF-300(*)', description: '2" 300# Flange Adapter, Carbon Steel' },
    { order: 16, code: 'PACF-600(*)', description: '2" 600# Flange Adapter, Carbon Steel' },
    { order: 17, code: 'PASF-150(*)', description: '2" 150# Flange Adapter, Stainless Steel' },
    { order: 18, code: 'PASF-300(*)', description: '2" 300# Flange Adapter, Stainless Steel' },
    { order: 19, code: 'PASF-600(*)', description: '2" 600# Flange Adapter, Stainless Steel' },
    { order: 20, code: 'MC', description: 'Material Certificates' },
    { order: 21, code: 'PT', description: 'Pressure Test Certificates' },
    { order: 22, code: 'CC', description: 'Certificate of Conformance' },
    { order: 23, code: 'NC', description: 'NACE Certification' },
    { order: 24, code: 'O2', description: 'Cleaned for O2 Service (certificate)' },
  ],
  ACCESSORIES_AZBIL: [
    { order: 0, code: 'IM', description: 'Additional Instruction Manual' },
    { order: 1, code: 'MC', description: 'Material Certificates' },
    { order: 2, code: 'PT', description: 'Pressure Test Certificates' },
    { order: 3, code: 'CC', description: 'Certificate of Conformance' },
    { order: 4, code: 'NC', description: 'NACE Certification' },
    { order: 5, code: 'O2', description: 'Cleaned for O2 Service (certificate)' },
    { order: 6, code: 'PD', description: 'PED certified meter body' },
    { order: 7, code: 'A1', description: "25' Armored Cable/Glands, V only" },
    { order: 8, code: 'A2', description: "50' Armored Cable/Glands, V only" },
    { order: 9, code: 'A3', description: "25' Armored Cable/Glands, VT, VTP" },
    { order: 10, code: 'A4', description: "50' Armored Cable/Glands, VT, VTP" },
    { order: 11, code: 'G5', description: 'G5 Metal cable glands (1 plug, 1 gland, Japan only)' },
    { order: 12, code: 'G6', description: 'G6 Metal cable glands (2 glands, Japan only)' },
    { order: 13, code: 'G7', description: 'Plastic cable glands (1 plug and 1 gland, Japan only)' },
    { order: 14, code: 'G8', description: 'Plastic cable glands (2 glands, Japan only)' },
    { order: 15, code: 'KX', description: 'KOSHA Explosion-protected apparatus (Korea)' },
    { order: 16, code: 'SA', description: 'Calibration in Japan (Japan only)' },
    { order: 17, code: 'C1', description: 'Inline meter strength calculation' },
    { order: 18, code: 'KS', description: 'Configure to customer request (Japan only)' },
  ],
  ACCESSORIES_AZBIL_INSERT: [
    { order: 0, code: 'IM', description: 'Additional Instruction Manual' },
    { order: 1, code: 'MC', description: 'Material Certificates' },
    { order: 2, code: 'PT', description: 'Pressure Test Certificates' },
    { order: 3, code: 'CC', description: 'Certificate of Conformance' },
    { order: 4, code: 'NC', description: 'NACE Certification' },
    { order: 5, code: 'O2', description: 'Cleaned for O2 Service (certificate)' },
    { order: 6, code: 'PD', description: 'PED certified meter body' },
    { order: 7, code: 'A1', description: "25' Armored Cable/Glands, V only" },
    { order: 8, code: 'A2', description: "50' Armored Cable/Glands, V only" },
    { order: 9, code: 'A3', description: "25' Armored Cable/Glands, VT, VTP" },
    { order: 10, code: 'A4', description: "50' Armored Cable/Glands, VT, VTP" },
    { order: 11, code: 'G5', description: 'G5 Metal cable glands (1 plug, 1 gland, Japan only)' },
    { order: 12, code: 'G6', description: 'G6 Metal cable glands (2 glands, Japan only)' },
    { order: 13, code: 'G7', description: 'Plastic cable glands (1 plug and 1 gland, Japan only)' },
    { order: 14, code: 'G8', description: 'Plastic cable glands (2 glands, Japan only)' },
    { order: 15, code: 'KX', description: 'KOSHA Explosion-protected apparatus (Korea)' },
    { order: 16, code: 'C2', description: 'Insertion meter strength calculation' },
    { order: 17, code: 'KS', description: 'Configure to customer request (Japan only)' },
    { order: 18, code: 'VE', description: 'Pressure vent' },
  ],
  ACCESSORIES_ARMSTRONG: [{ order: 0, code: 'RR', description: 'Removable Retractor' }],
  ACCESSORIES_S34: [
    { order: 0, code: 'BLU', description: 'Bluetooth Communication' },
    { order: 1, code: 'SC', description: 'SonoConnect™ Breakout Box' },
    { order: 2, code: 'PCC', description: 'Carrying Case, Telescoping Handle, Wheels, Foam Cut-Out' },
    {
      order: 3,
      code: 'CG',
      description: 'Container of Acoustic Coupling Grease, List with quantity on P.O.',
      quantity: 0,
    },
    { order: 4, code: 'CRTD', description: 'Clamp On RTDs' },
    { order: 5, code: 'SPCA', description: 'Special Calibration' },
  ],
  ACCESSORIES_S36: [
    {
      order: 0,
      code: 'BLU',
      description: 'Bluetooth® (with SonoConfig™ Instrument Interface Software)',
      quantity: 0,
    },
    {
      order: 1,
      code: 'CG',
      description: 'Container of Acoustic Coupling Grease, List with quantity on P.O.',
      quantity: 0,
    },
    { order: 2, code: 'CRTD', description: 'Clamp On RTDs (2)' },
    { order: 3, code: 'PMK', description: 'Pipe Mounting Kit for Transmitter' },
  ],
  ACCESSORIES_U42: [{ order: 0, code: 'PT1000', description: 'PT1000 temperature sensor' }],
  ACCESSORIES_U43: [{ order: 1, code: 'PT1000', description: 'PT1000 temperature sensor' }],
  ACCESSORIES_U44: [{ order: 2, code: 'PT1000', description: 'PT1000 temperature sensor' }],
  ACCESSORIES_PROM: [
    { order: 0, code: 'CRTD', description: 'Clamp On RTDs (2), (Must select “Energy Model” in Feature 1)' },
    { order: 1, code: 'IRTD', description: 'Insertion RTDs (2), (Must select “Energy Model” in Feature 1)' },
  ],
};

const AccessoriesTab = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const defaultAccessoryItems: Accessory[] = [];
  const defaultChosenItems: string[] = [];
  const [availableAccessories, setAvailableAccessories] = useState(defaultAccessoryItems);
  const [totalAccessoryCount, setTotalAccessoryCount] = useState(0);
  const [chosenAvailableAccessories, setChosenAvailableAccessories] = useState(defaultChosenItems);
  const [chosenSelectedAccessories, setChosenSelectedAccessories] = useState(defaultChosenItems);

  const state = useSelector((globalState: RootState) => globalState);
  const { accessories, meterData, meterIndex, meterType, sizingGlobals, specifyMeterProcessFluid } = state;

  useEffect(() => {
    const {
      InlineNonReduceModel,
      InlineReduceModel,
      UltrasonicS34Model,
      UltrasonicS36Model,
      UltrasonicU42Model,
      UltrasonicU43Model,
      UltrasonicU44Model,
      ElectromagneticProMModel,
      VorconeModel,
      VorconeReduceModel,
      VORTEK_NAME,
    } = sizingGlobals;

    const selectedMeter = meterData[meterIndex];
    const selectedMeterType = selectedMeter.type;
    let contain: Accessory[];

    if (meterType === ElectromagneticProMModel) {
      if (specifyMeterProcessFluid === '1') {
        contain = [];
      } else {
        contain = [...accessoryOptions.ACCESSORIES_PROM];
      }
    } else if (
      meterType === 'reduce_vorcone' ||
      meterType === 'reduce_vortex' ||
      meterType === 'nonreduce_vortex' ||
      selectedMeterType === 'In-line' ||
      selectedMeterType === 'In-line Reducing' ||
      selectedMeterType === InlineReduceModel ||
      selectedMeterType === VorconeModel ||
      selectedMeterType === VorconeReduceModel ||
      selectedMeterType === InlineNonReduceModel
    ) {
      if (VORTEK_NAME === utilities.ARMSTRONG_NAME) {
        contain = [...accessoryOptions.ACCESSORIES_ARMSTRONG];
      } else if (VORTEK_NAME === utilities.AZBIL_NAME) {
        contain = [...accessoryOptions.ACCESSORIES_AZBIL];
      } else {
        contain = [...accessoryOptions.ACCESSORIES_INLINE];
      }
    } else if (meterType === UltrasonicS34Model) {
      contain = [...accessoryOptions.ACCESSORIES_S34];
    } else if (meterType === UltrasonicS36Model) {
      contain = [...accessoryOptions.ACCESSORIES_S36];
    } else if (meterType === UltrasonicU42Model) {
      contain = [...accessoryOptions.ACCESSORIES_U42];
    } else if (meterType === UltrasonicU43Model) {
      contain = [...accessoryOptions.ACCESSORIES_U43];
    } else if (meterType === UltrasonicU44Model) {
      contain = [...accessoryOptions.ACCESSORIES_U44];
    } else if (VORTEK_NAME === utilities.ARMSTRONG_NAME) {
      contain = [...accessoryOptions.ACCESSORIES_ARMSTRONG];
    } else if (VORTEK_NAME === utilities.AZBIL_NAME) {
      contain = [...accessoryOptions.ACCESSORIES_AZBIL_INSERT];
    } else if (
      VORTEK_NAME === utilities.PROV_NAME ||
      VORTEK_NAME === utilities.SPIRAX_NAME ||
      VORTEK_NAME === utilities.ONICON_NAME ||
      VORTEK_NAME === utilities.GESTRA_NAME ||
      VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
    ) {
      contain = [...accessoryOptions.ACCESSORIES_INSERTION_NEW];
    } else {
      contain = [...accessoryOptions.ACCESSORIES_INSERTION];
    }

    // update order codes in case we just loaded an application,
    // because they are not saved with the application
    const newAccessories = [...accessories];
    newAccessories.forEach((val) => {
      if (val.order === -1) {
        const accessory = contain.find((a) => a.code === val.code);
        if (accessory) {
          // eslint-disable-next-line
          val.order = accessory.order;
        }
      }
    });

    let newAvailableAccessories = [...contain];
    newAccessories.forEach((val) => {
      if (newAvailableAccessories.find((a) => a.code === val.code)) {
        newAvailableAccessories = newAvailableAccessories.filter((item) => item.code !== val.code);
      }
    });

    dispatch(actions.setAccessories(newAccessories));
    setAvailableAccessories(newAvailableAccessories);
    setTotalAccessoryCount(26);

    dispatch(actions.setSpecifyMeterWarnings(meterValidate.validateAll()));
  }, [meterData, meterIndex, meterType]);

  const sortAccessories = (aray: Accessory[]) => {
    return aray.sort((a, b) => {
      return a.order < b.order ? -1 : a.order === b.order ? 0 : a.order > b.order ? 1 : 0;
    });
  };

  // eslint-disable-next-line
  const selectAccessories = (e: any) => {
    let newAvailableAccessories = [...availableAccessories];
    const newSelectedAccessories = [...accessories];
    chosenAvailableAccessories.forEach((val) => {
      if (!accessories.find((a) => a.code === val)) {
        const acc = availableAccessories.find((a) => a.code === val);
        if (acc) {
          acc.quantity = 1;
          newSelectedAccessories.push(acc);
          newAvailableAccessories = newAvailableAccessories.filter((item) => item.code !== val);
        }
      }
    });

    setAvailableAccessories(sortAccessories(newAvailableAccessories));
    dispatch(actions.setAccessories(sortAccessories(newSelectedAccessories)));
    setChosenAvailableAccessories([]);
    dispatch(actions.setSpecifyMeterWarnings(meterValidate.validateAll()));
  };

  // eslint-disable-next-line
  const removeAccessories = (e: any) => {
    const newAvailableAccessories = [...availableAccessories];
    let newSelectedAccessories = [...accessories];
    chosenSelectedAccessories.forEach((val) => {
      const code = val.split(',')[1];
      if (!availableAccessories.find((a) => a.code === code)) {
        const acc = accessories.find((a) => a.code === code);
        if (acc) {
          acc.quantity = 0;
          newSelectedAccessories = newSelectedAccessories.filter((item) => item.code !== code);
          newAvailableAccessories.push(acc);
        }
      }
    });

    setAvailableAccessories(sortAccessories(newAvailableAccessories));
    dispatch(actions.setAccessories(sortAccessories(newSelectedAccessories)));
    setChosenSelectedAccessories([]);
    dispatch(actions.setSpecifyMeterWarnings(meterValidate.validateAll()));
  };

  // eslint-disable-next-line
  const removeAllAccessories = (e: any) => {
    const newAvailableAccessories = [...availableAccessories];
    let newSelectedAccessories = [...accessories];
    accessories.forEach((val) => {
      if (!availableAccessories.find((a) => a.code === val.code)) {
        const acc = accessories.find((a) => a.code === val.code);
        if (acc) {
          acc.quantity = 0;
          newSelectedAccessories = newSelectedAccessories.filter((item) => item.code !== val.code);
          newAvailableAccessories.push(acc);
        }
      }
    });

    setAvailableAccessories(sortAccessories(newAvailableAccessories));
    dispatch(actions.setAccessories(sortAccessories(newSelectedAccessories)));
    setChosenSelectedAccessories([]);
    dispatch(actions.setSpecifyMeterWarnings(meterValidate.validateAll()));
  };

  // eslint-disable-next-line
  const chooseAvailableAccessories = (e: any) => {
    if (e?.target?.selectedOptions) {
      const values = Array.from(e.target.selectedOptions, (option: HTMLOptionElement) => option.value);
      setChosenAvailableAccessories(values);
    }
  };

  // eslint-disable-next-line
  const chooseSelectedAccessories = (e: any) => {
    if (e?.target?.selectedOptions) {
      const values = Array.from(e.target.selectedOptions, (option: HTMLOptionElement) => option.value);
      setChosenSelectedAccessories(values);
    }
  };

  // eslint-disable-next-line
  const increaseQuantity = (e: any) => {
    const newSelectedAccessories = [...accessories];
    chosenSelectedAccessories.forEach((val) => {
      const code = val.split(',')[1];
      const acc = accessories.find((a) => a.code === code);
      if (acc) {
        if (!acc.quantity) {
          acc.quantity = 1;
        } else {
          acc.quantity += 1;
        }
      }
    });

    dispatch(actions.setAccessories(newSelectedAccessories));
  };

  // eslint-disable-next-line
  const decreaseQuantity = (e: any) => {
    const newSelectedAccessories = [...accessories];
    chosenSelectedAccessories.forEach((val) => {
      const code = val.split(',')[1];
      const acc = accessories.find((a) => a.code === code);
      if (acc && acc.quantity && acc.quantity > 1) {
        acc.quantity -= 1;
      }
    });

    dispatch(actions.setAccessories(newSelectedAccessories));
  };

  return (
    <div className="modalContentTabs">
      <div className="flexRow iform">
        <div className="flexColCenter">
          <span className="flexCenter">Available Accessories</span>
          <select
            multiple
            size={totalAccessoryCount}
            className="iselectAccessory"
            name="Left"
            value={chosenAvailableAccessories}
            onDoubleClick={chooseAvailableAccessories}
            onChange={chooseAvailableAccessories}
          >
            {availableAccessories.map((item) => (
              <option key={item.code} value={item.code}>
                {`${item.code} ${item.description}`}
              </option>
            ))}
          </select>
        </div>
        <div className="flexColCenter">
          <button className="btnAccessory" title="Add Selected" type="button" onClick={selectAccessories}>
            <div className="flexRow">&nbsp;&nbsp;&nbsp;&gt;</div>
          </button>
          <div className="flexColCenter">&nbsp;</div>
          <button className="btnAccessory" title="Increase Quantity of Selected" type="button" onClick={increaseQuantity}>
            <div className="flexRow">+&nbsp;QTY</div>
          </button>
          <button className="btnAccessory" title="Decrease Quantity of Selected" type="button" onClick={decreaseQuantity}>
            <div className="flexRow">-&nbsp;QTY</div>
          </button>
          <div className="flexColCenter">&nbsp;</div>
          <button className="btnAccessory" title="Remove Selected" type="button" onClick={removeAccessories}>
            <div className="flexRow">&nbsp;&nbsp;&nbsp;&lt;</div>
          </button>
          <button className="btnAccessory" title="Remove All" type="button" onClick={removeAllAccessories}>
            <div className="flexRow">&nbsp;&nbsp;&lt;&lt;</div>
          </button>
        </div>
        <div className="flexColCenter">
          <span className="flexCenter">Selected Accessories</span>
          <select
            multiple
            size={totalAccessoryCount}
            className="iselectAccessory"
            name="Right"
            value={chosenSelectedAccessories}
            onDoubleClick={chooseSelectedAccessories}
            onChange={chooseSelectedAccessories}
          >
            {accessories.map((item) => (
              <option key={item.code} value={`${item.quantity},${item.code}`}>
                {`${item.quantity} ${item.code} ${item.description}`}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default AccessoriesTab;
