import Decimal from 'decimal.js';
import api from '../../api';
import constants from '../../constants';
import store from '../../store';
import actions from '../../store/actions';
import application from '../../utilities/application';
import calc from '../../utilities/calc';
import convert from '../../utilities/convert';
import OtherLiquidData from '../OtherLiquidData';
import OtherLiquidRow from '../OtherLiquidRow';
import PipeData from '../PipeData';
import ProcessCondition from '../processCondition';
import Fluid from './Fluid';

export default class OtherLiquid extends Fluid {
  protected dblViscosity: Decimal;
  protected sViscosityUnit: string;
  protected dblDensity: Decimal;
  protected sDensityUnit: string;

  constructor() {
    super();

    this.dblViscosity = constants.ZERO;
    this.sViscosityUnit = 'cP';
    this.dblDensity = constants.ZERO;
    this.sDensityUnit = 'lb/ft^3';
  }

  findAllOtherLiquids = (): OtherLiquidData[] => {
    const state = store.getState();

    const res = api.findAllDataForUserInReduxTable(state.sizingGlobals.VORTEK_NAME, state.username, 'otherliquid');

    const realres = [] as unknown as OtherLiquidData[];
    res.forEach((row) => {
      const { data } = row;
      const otherLiquidRow = data as OtherLiquidRow;
      realres.push({
        name: otherLiquidRow.liquid_name,
        density: new Decimal(otherLiquidRow.liquid_density.replace(',', '')),
        viscosity: new Decimal(otherLiquidRow.liquid_viscosity.replace(',', '')),
        soundSpeed: otherLiquidRow.liquid_sound_speed
          ? new Decimal(otherLiquidRow.liquid_sound_speed.replace(',', ''))
          : constants.ZERO,
        densityUnit: otherLiquidRow.liquid_select_1,
        viscosityUnit: otherLiquidRow.liquid_select_2,
        soundSpeedUnit: otherLiquidRow.liquid_select_3,
      });
    });

    return realres;
  };

  findOtherLiquid = (otherLiquidName: string): OtherLiquidData | undefined => {
    const state = store.getState();
    let item;

    const res = api.findAllDataForUserInReduxTable(state.sizingGlobals.VORTEK_NAME, state.username, 'otherliquid');

    res.forEach((row) => {
      const { data } = row;
      if (data.liquid_name === otherLiquidName) {
        const otherLiquidRow = data as OtherLiquidRow;
        item = {
          name: otherLiquidRow.liquid_name,
          density: new Decimal(otherLiquidRow.liquid_density.replace(',', '')),
          viscosity: new Decimal(otherLiquidRow.liquid_viscosity.replace(',', '')),
          soundSpeed: otherLiquidRow.liquid_sound_speed
            ? new Decimal(otherLiquidRow.liquid_sound_speed.replace(',', ''))
            : constants.ZERO,
          densityUnit: otherLiquidRow.liquid_select_1,
          viscosityUnit: otherLiquidRow.liquid_select_2,
          soundSpeedUnit: otherLiquidRow.liquid_select_3,
        };
      }
    });

    return item;
  };

  retrieveOtherLiquid = () => {
    const state = store.getState();
    const res = this.findOtherLiquid(state.fluid);
    if (res) {
      const otherLiquid = res as unknown as OtherLiquidData;
      this.dblViscosity = otherLiquid.viscosity;
      this.sViscosityUnit = otherLiquid.viscosityUnit;
      this.dblDensity = otherLiquid.density;
      this.sDensityUnit = otherLiquid.densityUnit;
    }
  };

  calculateDensity = (processCondition: ProcessCondition): ProcessCondition => {
    const newProcessCondition = { ...processCondition };
    newProcessCondition.dblDensityValue = this.dblDensity;
    newProcessCondition.sDensityUnit = this.sDensityUnit;
    return newProcessCondition;
  };

  calculateViscosity = (processCondition: ProcessCondition): ProcessCondition => {
    const newProcessCondition = { ...processCondition };
    newProcessCondition.dblViscosityValue = this.dblViscosity;
    newProcessCondition.sViscosityUnit = this.sViscosityUnit;
    return newProcessCondition;
  };

  calculateValues = (pipeData: PipeData, minNomMax: string): PipeData => {
    const state = store.getState();
    this.retrieveOtherLiquid();

    let [newPipeData, cond] = application.collectApplicationData(pipeData, minNomMax);
    newPipeData.calcPressureMinVal = new Decimal(newPipeData.pressureMin.replace(',', ''));
    newPipeData.calcPressureNomVal = new Decimal(newPipeData.pressureNom.replace(',', ''));
    newPipeData.calcPressureMaxVal = new Decimal(newPipeData.pressureMax.replace(',', ''));
    newPipeData.calcTemperatureMinVal = new Decimal(newPipeData.temperatureMin.replace(',', ''));
    newPipeData.calcTemperatureNomVal = new Decimal(newPipeData.temperatureNom.replace(',', ''));
    newPipeData.calcTemperatureMaxVal = new Decimal(newPipeData.temperatureMax.replace(',', ''));

    cond = this.calculateDensity(cond);
    newPipeData.isentropic = constants.ONE;
    let tmp;
    let val;

    if (minNomMax === 'min') {
      newPipeData.calcDensityMinVal = cond.dblDensityValue;
      newPipeData.densityMin = convert.densityTo('lb/ft^3', newPipeData.calcDensityMinVal, cond.sDensityUnit).toString();
      newPipeData.calcDensityMinVal = new Decimal(newPipeData.densityMin);
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'min');
      newPipeData.densityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcDensityNomVal = cond.dblDensityValue;
      newPipeData.densityNom = convert.densityTo('lb/ft^3', newPipeData.calcDensityNomVal, cond.sDensityUnit).toString();
      newPipeData.calcDensityNomVal = new Decimal(newPipeData.densityNom);
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'nom');
      newPipeData.densityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcDensityMaxVal = cond.dblDensityValue;
      newPipeData.densityMax = convert.densityTo('lb/ft^3', newPipeData.calcDensityMaxVal, cond.sDensityUnit).toString();
      newPipeData.calcDensityMaxVal = new Decimal(newPipeData.densityMax);
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'max');
      newPipeData.densityMaxDisplay = tmp;
    }

    store.dispatch(
      actions.setStandardConditions(this.calculateDensity(state.defaults.defaultProcessConditions.standardConditions))
    );

    store.dispatch(actions.setNormalConditions(this.calculateDensity(state.defaults.defaultProcessConditions.normalConditions)));

    cond = this.calculateViscosity(cond);

    if (minNomMax === 'min') {
      newPipeData.calcViscosityMinVal = cond.dblViscosityValue;
      newPipeData.viscosityMin = convert.viscosityTo('cP', newPipeData.calcViscosityMinVal, cond.sViscosityUnit).toString();
      newPipeData.calcViscosityMinVal = new Decimal(newPipeData.viscosityMin);
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'min');
      newPipeData.viscosityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcViscosityNomVal = cond.dblViscosityValue;
      newPipeData.viscosityNom = convert.viscosityTo('cP', newPipeData.calcViscosityNomVal, cond.sViscosityUnit).toString();
      newPipeData.calcViscosityNomVal = new Decimal(newPipeData.viscosityNom);
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'nom');
      newPipeData.viscosityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcViscosityMaxVal = cond.dblViscosityValue;
      newPipeData.viscosityMax = convert.viscosityTo('cP', newPipeData.calcViscosityMaxVal, cond.sViscosityUnit).toString();
      newPipeData.calcViscosityMaxVal = new Decimal(newPipeData.viscosityMax);
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'max');
      newPipeData.viscosityMaxDisplay = tmp;
    }

    // newPipeData.viscosityUnit = cond.sViscosityUnit;

    newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
    if (minNomMax === 'min') {
      newPipeData.calcVelocityMinVal = newPipeData.velocity;
      newPipeData.velocityMin = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'min');
      newPipeData.velocityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcVelocityNomVal = newPipeData.velocity;
      newPipeData.velocityNom = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'nom');
      newPipeData.velocityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcVelocityMaxVal = newPipeData.velocity;
      newPipeData.velocityMax = newPipeData.velocity.toString();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'max');
      newPipeData.velocityMaxDisplay = tmp;
    }

    cond.dblPressure = convert.pressureTo(
      `${newPipeData.originalPressurePrefix}-${newPipeData.originalPressureCoefficient}`,
      cond.dblPressure,
      cond.sPressureUnit,
      cond.sPressureGaugeAbsolute
    );
    newPipeData.pressurePrefix = newPipeData.originalPressurePrefix;
    newPipeData.pressureCoefficient = newPipeData.originalPressureCoefficient;

    if (minNomMax === 'min') {
      newPipeData.calcPressureMinVal = cond.dblPressure;
      newPipeData.pressureMin = cond.dblPressure.toString();
    } else if (minNomMax === 'nom') {
      newPipeData.calcPressureNomVal = cond.dblPressure;
      newPipeData.pressureNom = cond.dblPressure.toString();
    } else if (minNomMax === 'max') {
      newPipeData.calcPressureMaxVal = cond.dblPressure;
      newPipeData.pressureMax = cond.dblPressure.toString();
    }

    return newPipeData;
  };
}
