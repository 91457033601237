import Decimal from 'decimal.js';
import store from '../../store';
import actions from '../../store/actions';
import application from '../../utilities/application';
import calc from '../../utilities/calc';
import convert from '../../utilities/convert';
import PipeData from '../PipeData';
import ProcessCondition from '../processCondition';
import Steam from './Steam';

export default class SteamSaturatedTemperature extends Steam {
  calculateSteamPressureFromTemperature = (processCondition: ProcessCondition): ProcessCondition => {
    // Calculates saturated steam pressure (psia) as a function of temperature (deg F)
    // Based on the formaula for saturation pressure on page 71 of the ASME steam tables.
    // Uses constants K1-K9

    const k1 = new Decimal('-7.691234564');
    const k2 = new Decimal('-26.0802369');
    const k3 = new Decimal('-168.1706546');
    const k4 = new Decimal('64.23285504');
    const k5 = new Decimal('-118.9646225');
    const k6 = new Decimal('4.16711732');
    const k7 = new Decimal('20.9750676');
    const k8 = new Decimal('1000000000.0');
    const k9 = new Decimal('6.0');

    let A;

    // Convert the temperature to Kelvin.
    const dblTempK = convert.temperatureTo('K', processCondition.dblTemperature, processCondition.sTemperatureUnit);

    const t = dblTempK.div(new Decimal('647.3'));
    const T1 = new Decimal('1').minus(t);

    // A = ((((k5 * T1 + k4) * T1 + k3) * T1 + k2) * T1 + k1) * T1;
    A = k5.times(T1).plus(k4);
    A = A.times(T1).plus(k3);
    A = A.times(T1).plus(k2);
    A = A.times(T1).plus(k1);
    A = A.times(T1);

    const B = k7.times(T1).plus(k6).times(T1).plus(new Decimal('1'));
    const c = k9.plus(k8.times(T1).times(T1));

    // Calculate the pressure from the user input temperature.
    // processCondition.dblPressureValue = (3208.2347586 * Math.Exp(A / (B * t) - (T1 / c)));
    const val = new Decimal('3208.2347586');
    const BTimesT = B.times(t);
    const T1divc = T1.div(c);
    const bottom = BTimesT.minus(T1divc);
    let exp = A.div(bottom);
    exp = Decimal.exp(exp);

    const newProcessCondition = { ...processCondition };

    newProcessCondition.dblPressure = val.times(exp);
    newProcessCondition.sPressureUnit = 'psi';
    newProcessCondition.sPressureGaugeAbsolute = 'A';
    return newProcessCondition;
  };

  calculateValues = (pipeData: PipeData, minNomMax: string): PipeData => {
    const state = store.getState();

    if (minNomMax === 'min') {
      if (pipeData.flowMin === '' || pipeData.temperatureMin === '') {
        return pipeData;
      }
    } else if (minNomMax === 'nom') {
      if (pipeData.flowNom === '' || pipeData.temperatureNom === '') {
        return pipeData;
      }
    } else if (minNomMax === 'max') {
      if (pipeData.flowMax === '' || pipeData.temperatureMax === '') {
        return pipeData;
      }
    }

    let [newPipeData, cond] = application.collectApplicationData(pipeData, minNomMax);
    newPipeData.calcTemperatureMinVal = new Decimal(newPipeData.temperatureMin.replace(',', ''));
    newPipeData.calcTemperatureNomVal = new Decimal(newPipeData.temperatureNom.replace(',', ''));
    newPipeData.calcTemperatureMaxVal = new Decimal(newPipeData.temperatureMax.replace(',', ''));

    // if steam
    cond = this.calculateSteamPressureFromTemperature(cond);
    newPipeData.isentropic = new Decimal('1.33');
    let tmp;
    let val;

    if (minNomMax === 'min') {
      newPipeData.calcPressureMinVal = cond.dblPressure;
      newPipeData.pressureMin = cond.dblPressure.toString();
      cond = this.calculateDensity(cond);
      newPipeData.densityUnit = cond.sDensityUnit;
      newPipeData.calcDensityMinVal = cond.dblDensityValue;
      newPipeData.densityMin = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'min');
      newPipeData.densityMinDisplay = tmp;
      newPipeData.densityMinInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'nom') {
      newPipeData.calcPressureNomVal = cond.dblPressure;
      newPipeData.pressureNom = cond.dblPressure.toString();
      cond = this.calculateDensity(cond);
      newPipeData.densityUnit = cond.sDensityUnit;
      newPipeData.calcDensityNomVal = cond.dblDensityValue;
      newPipeData.densityNom = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'nom');
      newPipeData.densityNomDisplay = tmp;
      newPipeData.densityNomInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'max') {
      newPipeData.calcPressureMaxVal = cond.dblPressure;
      newPipeData.pressureMax = cond.dblPressure.toString();
      cond = this.calculateDensity(cond);
      newPipeData.densityUnit = cond.sDensityUnit;
      newPipeData.calcDensityMaxVal = cond.dblDensityValue;
      newPipeData.densityMax = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'max');
      newPipeData.densityMaxDisplay = tmp;
      newPipeData.densityMaxInternalUnit = cond.sDensityUnit;
    }

    store.dispatch(
      actions.setStandardConditions(this.calculateDensity(state.defaults.defaultProcessConditions.standardConditions))
    );

    store.dispatch(actions.setNormalConditions(this.calculateDensity(state.defaults.defaultProcessConditions.normalConditions)));

    cond = this.calculateViscosity(cond);

    if (minNomMax === 'min') {
      newPipeData.calcViscosityMinVal = cond.dblViscosityValue;
      newPipeData.viscosityMin = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'min');
      newPipeData.viscosityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcViscosityNomVal = cond.dblViscosityValue;
      newPipeData.viscosityNom = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'nom');
      newPipeData.viscosityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcViscosityMaxVal = cond.dblViscosityValue;
      newPipeData.viscosityMax = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'max');
      newPipeData.viscosityMaxDisplay = tmp;
    }

    newPipeData.viscosityUnit = cond.sViscosityUnit;

    cond.dblPressure = convert.pressureTo(
      `${newPipeData.originalPressurePrefix}-${newPipeData.originalPressureCoefficient}`,
      cond.dblPressure,
      cond.sPressureUnit,
      cond.sPressureGaugeAbsolute
    );
    newPipeData.pressurePrefix = newPipeData.originalPressurePrefix;
    newPipeData.pressureCoefficient = newPipeData.originalPressureCoefficient;

    if (minNomMax === 'min') {
      newPipeData.calcPressureMinVal = cond.dblPressure;
      newPipeData.pressureMin = cond.dblPressure.toString();

      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityMinVal = newPipeData.velocity;
      newPipeData.velocityMin = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'min');
      newPipeData.velocityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcPressureNomVal = cond.dblPressure;
      newPipeData.pressureNom = cond.dblPressure.toString();

      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityNomVal = newPipeData.velocity;
      newPipeData.velocityNom = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'nom');
      newPipeData.velocityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcPressureMaxVal = cond.dblPressure;
      newPipeData.pressureMax = cond.dblPressure.toString();

      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityMaxVal = newPipeData.velocity;
      newPipeData.velocityMax = newPipeData.velocity.toString();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'max');
      newPipeData.velocityMaxDisplay = tmp;
    }

    return newPipeData;
  };
}
