import ProcessCondition from './processCondition';

// Defaults for displaying data and handling
// conditions for calculating meter data
export default class VortekDefaultProcessConditions {
  defaultStdConditions = new ProcessCondition();
  defaultNmlConditions = new ProcessCondition();
  standardConditions = new ProcessCondition();
  normalConditions = new ProcessCondition();
}
