import store from '..';
import Accessory from '../../types/accessory';
import ActionReturn from '../../types/actionReturn';
import CustomerListItem from '../../types/customerListItem';
import DbRow from '../../types/dbRow';
import Meter from '../../types/Meter';
import OtherGasRow from '../../types/OtherGasRow';
import OtherLiquidRow from '../../types/OtherLiquidRow';
import PipeData from '../../types/PipeData';
import ProcessCondition from '../../types/processCondition';
import VortekDefaults from '../../types/vortekDefaults';
import WorkingVortekDefaults from '../../types/workingVortekDefaults';
import Types from './types';

const setAccessories = (accessories: Accessory[]): ActionReturn => {
  return {
    type: Types.SET_ACCESSORIES,
    data: accessories,
  };
};

const setAlertedNegative = (alertedNegative: boolean): ActionReturn => {
  return {
    type: Types.SET_ALERTED_NEGATIVE,
    data: alertedNegative,
  };
};

const setAppMode = (appMode: string): ActionReturn => {
  return {
    type: Types.SET_APP_MODE,
    data: appMode,
  };
};

const setAppName = (appName: string): ActionReturn => {
  return {
    type: Types.SET_APP_NAME,
    data: appName,
  };
};

const setClient = (client: string): ActionReturn => {
  return {
    type: Types.SET_CLIENT,
    data: client,
  };
};

const setClientParam = (clientParam: string): ActionReturn => {
  return {
    type: Types.SET_CLIENT_PARAM,
    data: clientParam,
  };
};

const setCustomer = (customer: CustomerListItem): ActionReturn => {
  return {
    type: Types.SET_CUSTOMER,
    data: customer,
  };
};

const setDatabase = (database: IDBDatabase): ActionReturn => {
  return {
    type: Types.SET_DATABASE,
    data: database,
  };
};

const setDefaults = (defaults: VortekDefaults): ActionReturn => {
  return {
    type: Types.SET_DEFAULTS,
    data: defaults,
  };
};

const setWorkingDefaults = (workingDefaults: WorkingVortekDefaults): ActionReturn => {
  return {
    type: Types.SET_WORKING_DEFAULTS,
    data: workingDefaults,
  };
};

const setDefaultStandardConditions = (defaultStdConditions: ProcessCondition): ActionReturn => {
  return {
    type: Types.SET_DEFAULT_STANDARD_CONDITIONS,
    data: defaultStdConditions,
  };
};

const setDefaultNormalConditions = (defaultNormalConditions: ProcessCondition): ActionReturn => {
  return {
    type: Types.SET_DEFAULT_NORMAL_CONDITIONS,
    data: defaultNormalConditions,
  };
};

const setErrorMessage = (errorMessage: string): ActionReturn => {
  return {
    type: Types.SET_ERROR_MESSAGE,
    data: errorMessage,
  };
};

const setFluid = (fluid: string): ActionReturn => {
  return {
    type: Types.SET_FLUID,
    data: fluid,
  };
};

const setFluidType = (fluidType: string): ActionReturn => {
  return {
    type: Types.SET_FLUID_TYPE,
    data: fluidType,
  };
};

const setForceLogin = (forceLogin: boolean): ActionReturn => {
  return {
    type: Types.SET_FORCE_LOGIN,
    data: forceLogin,
  };
};

const setForceRemote = (forceRemote: boolean): ActionReturn => {
  return {
    type: Types.SET_FORCE_REMOTE,
    data: forceRemote,
  };
};

const setIsAdmin = (isAdmin: boolean): ActionReturn => {
  return {
    type: Types.SET_IS_ADMIN,
    data: isAdmin,
  };
};

const setIsChrome = (isChrome: boolean): ActionReturn => {
  return {
    type: Types.SET_IS_CHROME,
    data: isChrome,
  };
};

const setIsIE89 = (isIE89: boolean): ActionReturn => {
  return {
    type: Types.SET_IS_IE89,
    data: isIE89,
  };
};

const setIsIE10or11orEdge = (isIE10or11orEdge: boolean): ActionReturn => {
  return {
    type: Types.SET_IS_IE10OR11OREDGE,
    data: isIE10or11orEdge,
  };
};

const setIsWinSafari = (isWinSafari: boolean): ActionReturn => {
  return {
    type: Types.SET_IS_WIN_SAFARI,
    data: isWinSafari,
  };
};

const setLoc = (loc: string): ActionReturn => {
  return {
    type: Types.SET_LOC,
    data: loc,
  };
};

const setMeterData = (meterData: Meter[]): ActionReturn => {
  return {
    type: Types.SET_METER_DATA,
    data: meterData,
  };
};

const setMeterIndex = (meterIndex: number): ActionReturn => {
  return {
    type: Types.SET_METER_INDEX,
    data: meterIndex,
  };
};

const setMeterType = (meterType: string): ActionReturn => {
  return {
    type: Types.SET_METER_TYPE,
    data: meterType,
  };
};

const setModelCode = (modelCode: string): ActionReturn => {
  return {
    type: Types.SET_MODEL_CODE,
    data: modelCode,
  };
};

const setNormalConditions = (normalConditions: ProcessCondition): ActionReturn => {
  return {
    type: Types.SET_NORMAL_CONDITIONS,
    data: normalConditions,
  };
};

const setOnlineToken = (onlineToken: string): ActionReturn => {
  return {
    type: Types.SET_ONLINE_TOKEN,
    data: onlineToken,
  };
};

const setOtherGas = (otherGas: OtherGasRow): ActionReturn => {
  return {
    type: Types.SET_OTHER_GAS,
    data: otherGas,
  };
};

const setOtherLiquid = (otherLiquid: OtherLiquidRow): ActionReturn => {
  return {
    type: Types.SET_OTHER_LIQUID,
    data: otherLiquid,
  };
};

const setPathName = (pathName: string): ActionReturn => {
  return {
    type: Types.SET_PATHNAME,
    data: pathName,
  };
};

const setPipeInsideDiameter = (pipeInsideDiameter: string): ActionReturn => {
  return {
    type: Types.SET_PIPE_INSIDE_DIAMETER,
    data: pipeInsideDiameter,
  };
};

const setPipeLinerMaterial = (pipeLinerMaterial: string): ActionReturn => {
  return {
    type: Types.SET_PIPE_LINER_MATERIAL,
    data: pipeLinerMaterial,
  };
};

const setPipeMaterial = (pipeMaterial: string): ActionReturn => {
  return {
    type: Types.SET_PIPE_MATERIAL,
    data: pipeMaterial,
  };
};

const setPipeOutsideDiameter = (pipeOutsideDiameter: string): ActionReturn => {
  return {
    type: Types.SET_PIPE_OUTSIDE_DIAMETER,
    data: pipeOutsideDiameter,
  };
};

const setPipeReduceInsideDiameter = (pipeReduceInsideDiameter: string): ActionReturn => {
  return {
    type: Types.SET_PIPE_REDUCE_INSIDE_DIAMETER,
    data: pipeReduceInsideDiameter,
  };
};

const setPipeReduceOutsideDiameter = (pipeReduceOutsideDiameter: string): ActionReturn => {
  return {
    type: Types.SET_PIPE_REDUCE_OUTSIDE_DIAMETER,
    data: pipeReduceOutsideDiameter,
  };
};

const setPipeSchedule = (pipeSchedule: string): ActionReturn => {
  return {
    type: Types.SET_PIPE_SCHEDULE,
    data: pipeSchedule,
  };
};

const setPipeType = (pipeType: string): ActionReturn => {
  return {
    type: Types.SET_PIPE_TYPE,
    data: pipeType,
  };
};

const setPipeUnit = (pipeUnit: string): ActionReturn => {
  return {
    type: Types.SET_PIPE_UNIT,
    data: pipeUnit,
  };
};

const setReducingVorcone = (reducingVorcone: boolean): ActionReturn => {
  return {
    type: Types.SET_REDUCING_VORCONE,
    data: reducingVorcone,
  };
};

const setReducingVortex = (reducingVortex: boolean): ActionReturn => {
  return {
    type: Types.SET_REDUCING_VORTEX,
    data: reducingVortex,
  };
};

const setRemoteCableLength = (remoteCableLength: string): ActionReturn => {
  return {
    type: Types.SET_REMOTE_CABLE_LENGTH,
    data: remoteCableLength,
  };
};

const setSelectedPipeInsideDiameter = (selectedPipeInsideDiameter: string): ActionReturn => {
  return {
    type: Types.SET_SELECTED_PIPE_INSIDE_DIAMETER,
    data: selectedPipeInsideDiameter,
  };
};

const setSelectedPipeOutsideDiameter = (selectedPipeOutsideDiameter: string): ActionReturn => {
  return {
    type: Types.SET_SELECTED_PIPE_OUTSIDE_DIAMETER,
    data: selectedPipeOutsideDiameter,
  };
};

const setSoundSpeed = (soundSpeed: string): ActionReturn => {
  return {
    type: Types.SET_SOUND_SPEED,
    data: soundSpeed,
  };
};

const setShowSpecifyMeter = (showSpecifyMeter: boolean): ActionReturn => {
  return {
    type: Types.SET_SHOW_SPECIFY_METER,
    data: showSpecifyMeter,
  };
};

const setSpecifyMeterAccuracy = (specifyMeterAccuracy: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_ACCURACY,
    data: specifyMeterAccuracy,
  };
};

const setSpecifyMeterApprovals = (specifyMeterApprovals: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_APPROVALS,
    data: specifyMeterApprovals,
  };
};

const setSpecifyMeterElectrodeMaterial = (specifyMeterElectrodeMaterial: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_ELECTRODE_MATERIAL,
    data: specifyMeterElectrodeMaterial,
  };
};

const setSpecifyMeterFlange = (specifyMeterFlange: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_FLANGE,
    data: specifyMeterFlange,
  };
};

const setSpecifyMeterGrounding = (specifyMeterGrounding: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_GROUNDING,
    data: specifyMeterGrounding,
  };
};

const setSpecifyMeterTagNumber = (specifyMeterTagNumber: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_TAG_NUMBER,
    data: specifyMeterTagNumber,
  };
};

const setSpecifyMeterParentModelNumber = (specifyMeterParentModelNumber: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_PARENT_MODEL_NUMBER,
    data: specifyMeterParentModelNumber,
  };
};

const setSpecifyMeterProcessFluid = (specifyMeterProcessFluid: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_PROCESS_FLUID,
    data: specifyMeterProcessFluid,
  };
};

const setSpecifyMeterPipeSize = (specifyMeterPipeSize: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_PIPE_SIZE,
    data: specifyMeterPipeSize,
  };
};

const setSpecifyMeterLinerMaterial = (specifyMeterLinerMaterial: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_LINER_MATERIAL,
    data: specifyMeterLinerMaterial,
  };
};

const setSpecifyMeterPipeMaterial = (specifyMeterPipeMaterial: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_PIPE_MATERIAL,
    data: specifyMeterPipeMaterial,
  };
};

const setSpecifyMeterProbeLength = (specifyMeterProbeLength: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_PROBE_LENGTH,
    data: specifyMeterProbeLength,
  };
};

const setSpecifyMeterElectronicsMounting = (specifyMeterElectronicsMounting: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_ELECTRONICS_MOUNTING,
    data: specifyMeterElectronicsMounting,
  };
};

const setSpecifyMeterDisplayOption = (specifyMeterDisplayOption: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_DISPLAY_OPTION,
    data: specifyMeterDisplayOption,
  };
};

const setSpecifyMeterInputPower = (specifyMeterInputPower: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_INPUT_POWER,
    data: specifyMeterInputPower,
  };
};

const setSpecifyMeterOutputSignal = (specifyMeterOutputSignal: string | undefined): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_OUTPUT_SIGNAL,
    data: specifyMeterOutputSignal,
  };
};

const setSpecifyMeterProcessTemperature = (specifyMeterProcessTemperature: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_PROCESS_TEMPERATURE,
    data: specifyMeterProcessTemperature,
  };
};

const setSpecifyMeterTransducer = (specifyMeterTransducer: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_TRANSDUCER,
    data: specifyMeterTransducer,
  };
};

const setSpecifyMeterProcessConnection = (specifyMeterProcessConnection: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_PROCESS_CONNECTION,
    data: specifyMeterProcessConnection,
  };
};

const setSpecifyMeterRotor = (specifyMeterRotor: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_ROTOR,
    data: specifyMeterRotor,
  };
};

const setSpecifyMeterDiffPressXMTR = (specifyMeterDiffPressXMTR: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_DIFF_PRESS_XMTR,
    data: specifyMeterDiffPressXMTR,
  };
};

const setSpecifyMeterDiffPressXMTRMani = (specifyMeterDiffPressXMTRMani: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_DIFF_PRESS_XMTR_MANI,
    data: specifyMeterDiffPressXMTRMani,
  };
};

const setSpecifyMeterDiagDiffPressXMTR = (specifyMeterDiagDiffPressXMTR: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_DIAG_DIFF_PRESS_XMTR,
    data: specifyMeterDiagDiffPressXMTR,
  };
};

const setSpecifyMeterDiagAddPressTap = (specifyMeterDiagAddPressTap: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_DIAG_ADD_PRESS_TAP,
    data: specifyMeterDiagAddPressTap,
  };
};

const setSpecifyMeterCableLength = (specifyMeterCableLength: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_CABLE_LENGTH,
    data: specifyMeterCableLength,
  };
};

const setSpecifyMeterCommunications = (specifyMeterCommunications: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_COMMUNICATIONS,
    data: specifyMeterCommunications,
  };
};

const setSpecifyMeterTransducerBracket = (specifyMeterTransducerBracket: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_TRANSDUCER_BRACKET,
    data: specifyMeterTransducerBracket,
  };
};

const setSpecifyMeterTempPressComp = (specifyMeterTempPressComp: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_TEMP_PRESS_COMP,
    data: specifyMeterTempPressComp,
  };
};

const setSpecifyMeterEnergyMeter = (specifyMeterEnergyMeter: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_ENERGY_METER,
    data: specifyMeterEnergyMeter,
  };
};

const setSpecifyMeterFaceToFace = (specifyMeterFaceToFace: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_FACE_TO_FACE,
    data: specifyMeterFaceToFace,
  };
};

const setSpecifyMeterSpecialNotes = (specifyMeterSpecialNotes: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_SPECIAL_NOTES,
    data: specifyMeterSpecialNotes,
  };
};

const setSpecifyMeterWarnings = (specifyMeterWarnings: string): ActionReturn => {
  return {
    type: Types.SET_SPECIFY_METER_WARNINGS,
    data: specifyMeterWarnings,
  };
};

const setStandardConditions = (standardConditions: ProcessCondition): ActionReturn => {
  return {
    type: Types.SET_STANDARD_CONDITIONS,
    data: standardConditions,
  };
};

const setSystemMessage = (systemMessage: string): ActionReturn => {
  return {
    type: Types.SET_SYSTEM_MESSAGE,
    data: systemMessage,
  };
};

const setTurbineActive = (turbineActive: boolean): ActionReturn => {
  return {
    type: Types.SET_TURBINE_ACTIVE,
    data: turbineActive,
  };
};

const setUsername = (username: string): ActionReturn => {
  return {
    type: Types.SET_USERNAME,
    data: username,
  };
};

const setVorconeActive = (vorconeActive: boolean): ActionReturn => {
  return {
    type: Types.SET_VORCONE_ACTIVE,
    data: vorconeActive,
  };
};

const setWarningMessage = (warningMessage: string): ActionReturn => {
  return {
    type: Types.SET_WARNING_MESSAGE,
    data: warningMessage,
  };
};

const setOniconSpc = (spc: string): ActionReturn => {
  return {
    type: Types.SET_ONICON_SPC,
    data: spc,
  };
};

// Process Condition Actions
const setProcessConditions = (processConditions: PipeData): ActionReturn => {
  return {
    type: Types.SET_PROCESS_CONDITIONS,
    data: processConditions,
  };
};

const setFlowMin = (flowMin: string): ActionReturn => {
  return {
    type: Types.SET_FLOW_MIN,
    data: flowMin,
  };
};

const setFlowNom = (flowNom: string): ActionReturn => {
  return {
    type: Types.SET_FLOW_NOM,
    data: flowNom,
  };
};

const setFlowMax = (flowMax: string): ActionReturn => {
  return {
    type: Types.SET_FLOW_MAX,
    data: flowMax,
  };
};

const setFlowUnit = (flowUnit: string): ActionReturn => {
  return {
    type: Types.SET_FLOW_UNIT,
    data: flowUnit,
  };
};

const setFlowTime = (flowTime: string): ActionReturn => {
  return {
    type: Types.SET_FLOW_TIME,
    data: flowTime,
  };
};

const setTemperatureMin = (temperatureMin: string): ActionReturn => {
  return {
    type: Types.SET_TEMPERATURE_MIN,
    data: temperatureMin,
  };
};

const setTemperatureNom = (temperatureNom: string): ActionReturn => {
  return {
    type: Types.SET_TEMPERATURE_NOM,
    data: temperatureNom,
  };
};

const setTemperatureMax = (temperatureMax: string): ActionReturn => {
  return {
    type: Types.SET_TEMPERATURE_MAX,
    data: temperatureMax,
  };
};

const setTemperatureUnit = (temperatureUnit: string): ActionReturn => {
  return {
    type: Types.SET_TEMPERATURE_UNIT,
    data: temperatureUnit,
  };
};

const setPressureMin = (pressureMin: string): ActionReturn => {
  return {
    type: Types.SET_PRESSURE_MIN,
    data: pressureMin,
  };
};

const setPressureNom = (pressureNom: string): ActionReturn => {
  return {
    type: Types.SET_PRESSURE_NOM,
    data: pressureNom,
  };
};

const setPressureMax = (pressureMax: string): ActionReturn => {
  return {
    type: Types.SET_PRESSURE_MAX,
    data: pressureMax,
  };
};

const setPressurePrefix = (pressurePrefix: string): ActionReturn => {
  return {
    type: Types.SET_PRESSURE_PREFIX,
    data: pressurePrefix,
  };
};

const setPressureCoefficient = (pressureCoefficient: string): ActionReturn => {
  return {
    type: Types.SET_PRESSURE_COEFFICIENT,
    data: pressureCoefficient,
  };
};

const setDensityMin = (densityMin: string): ActionReturn => {
  return {
    type: Types.SET_DENSITY_MIN,
    data: densityMin,
  };
};

const setDensityNom = (densityNom: string): ActionReturn => {
  return {
    type: Types.SET_DENSITY_NOM,
    data: densityNom,
  };
};

const setDensityMax = (densityMax: string): ActionReturn => {
  return {
    type: Types.SET_DENSITY_MAX,
    data: densityMax,
  };
};

const setDensityUnit = (densityUnit: string): ActionReturn => {
  return {
    type: Types.SET_DENSITY_UNIT,
    data: densityUnit,
  };
};

const setViscosityMin = (viscosityMin: string): ActionReturn => {
  return {
    type: Types.SET_VISCOSITY_MIN,
    data: viscosityMin,
  };
};

const setViscosityNom = (viscosityNom: string): ActionReturn => {
  return {
    type: Types.SET_VISCOSITY_NOM,
    data: viscosityNom,
  };
};

const setViscosityMax = (viscosityMax: string): ActionReturn => {
  return {
    type: Types.SET_VISCOSITY_MAX,
    data: viscosityMax,
  };
};

const setViscosityUnit = (viscosityUnit: string): ActionReturn => {
  return {
    type: Types.SET_VISCOSITY_UNIT,
    data: viscosityUnit,
  };
};

const setVelocityMin = (velocityMin: string): ActionReturn => {
  return {
    type: Types.SET_VELOCITY_MIN,
    data: velocityMin,
  };
};

const setVelocityNom = (velocityNom: string): ActionReturn => {
  return {
    type: Types.SET_VELOCITY_NOM,
    data: velocityNom,
  };
};

const setVelocityMax = (velocityMax: string): ActionReturn => {
  return {
    type: Types.SET_VELOCITY_MAX,
    data: velocityMax,
  };
};

const setVelocityUnit = (velocityUnit: string): ActionReturn => {
  return {
    type: Types.SET_VELOCITY_UNIT,
    data: velocityUnit,
  };
};

// Table Actions
const setTableAnsiPipeSize = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_ANSI_PIPE_SIZE,
    data,
    id,
  };
};

const setTableApplication = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_APPLICATION,
    data,
    id,
  };
};

const setTableCustomer = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_CUSTOMER,
    data,
    id,
  };
};

const setTableDefault = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_DEFAULT,
    data,
    id,
  };
};

const setTableDensity = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_DENSITY,
    data,
    id,
  };
};

const setTableDinPipeSize = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_DIN_PIPE_SIZE,
    data,
    id,
  };
};

const setTableFeature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_FEATURE,
    data,
    id,
  };
};

const setTableFlangeTestAsme = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_FLANGE_TEST_ASME,
    data,
    id,
  };
};

const setTableFlangeTestDin = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_FLANGE_TEST_DIN,
    data,
    id,
  };
};

const setTableFlangeTestJis = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_FLANGE_TEST_JIS,
    data,
    id,
  };
};

const setTableFlow = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_FLOW,
    data,
    id,
  };
};

const setTableFlowWorking = (data: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_FLOW_WORKING,
    data,
  };
};

const setTableDensityWorking = (data: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_DENSITY_WORKING,
    data,
  };
};

const setTableViscosityWorking = (data: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_VISCOSITY_WORKING,
    data,
  };
};

const setTableLengthWorking = (data: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_LENGTH_WORKING,
    data,
  };
};

const setTableTimeWorking = (data: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_TIME_WORKING,
    data,
  };
};

const setTableTemperatureWorking = (data: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_TEMPERATURE_WORKING,
    data,
  };
};

const setTablePressureWorking = (data: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_PRESSURE_WORKING,
    data,
  };
};

const setTableFluid = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_FLUID,
    data,
    id,
  };
};

const setTableGas = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_GAS,
    data,
    id,
  };
};

const setTableImage = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_IMAGE,
    data,
    id,
  };
};

const setTableInline = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_INLINE,
    data,
    id,
  };
};

const setTableInlineProM = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_INLINE_PRO_M,
    data,
    id,
  };
};

const setTableJisPipeSchedule = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_JIS_PIPE_SCHEDULE,
    data,
    id,
  };
};

const setTableLength = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_LENGTH,
    data,
    id,
  };
};

const setTableLinerMaterial = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_LINER_MATERIAL,
    data,
    id,
  };
};

const setTableLiquid = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_LIQUID,
    data,
    id,
  };
};

const setTableM22Feature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_M22_FEATURE,
    data,
    id,
  };
};

const setTableM23Feature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_M23_FEATURE,
    data,
    id,
  };
};

const setTableM24Feature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_M24_FEATURE,
    data,
    id,
  };
};

const setTableM24rFeature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_M24R_FEATURE,
    data,
    id,
  };
};

const setTableMvcFeature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_MVC_FEATURE,
    data,
    id,
  };
};

const setTableMvcrFeature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_MVCR_FEATURE,
    data,
    id,
  };
};

const setTableOtherGas = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_OTHER_GAS,
    data,
    id,
  };
};

const setTableOtherLiquid = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_OTHER_LIQUID,
    data,
    id,
  };
};

const setTablePipeDin = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_PIPE_DIN,
    data,
    id,
  };
};

const setTablePipeMaterial = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_PIPE_MATERIAL,
    data,
    id,
  };
};

const setTablePipeSchedule = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_PIPE_SCHEDULE,
    data,
    id,
  };
};

const setTableJisPipeSize = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_JIS_PIPE_SIZE,
    data,
    id,
  };
};

const setTablePressure = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_PRESSURE,
    data,
    id,
  };
};

const setTablePrintHeader = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_PRINT_HEADER,
    data,
    id,
  };
};

const setTableS34Feature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_S34_FEATURE,
    data,
    id,
  };
};

const setTableS36Feature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_S36_FEATURE,
    data,
    id,
  };
};

const setTableSoundSpeed = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_SOUND_SPEED,
    data,
    id,
  };
};

const setTableTemperature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_TEMPERATURE,
    data,
    id,
  };
};

const setTableTime = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_TIME,
    data,
    id,
  };
};

const setTableTurbineFeature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_TURBINE_FEATURE,
    data,
    id,
  };
};

const setTableU42Feature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_U42_FEATURE,
    data,
    id,
  };
};

const setTableU43Feature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_U43_FEATURE,
    data,
    id,
  };
};

const setTableU44Feature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_U44_FEATURE,
    data,
    id,
  };
};

const setTableProMFeature = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_PROM_FEATURE,
    data,
    id,
  };
};

const setTableViscosity = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_VISCOSITY,
    data,
    id,
  };
};

const setTableVUser = (data: DbRow[], id?: string): ActionReturn => {
  return {
    type: Types.SET_TABLE_VUSER,
    data,
    id,
  };
};

const mapCollectionToAction = (collection: string, data: DbRow[], id?: string): ActionReturn => {
  switch (collection) {
    case 'feature':
      return store.dispatch(setTableFeature(data, id));

    case 'm22Feature':
      return store.dispatch(setTableM22Feature(data, id));

    case 'm23Feature':
      return store.dispatch(setTableM23Feature(data, id));

    case 'm24Feature':
      return store.dispatch(setTableM24Feature(data, id));

    case 'm24rFeature':
      return store.dispatch(setTableM24rFeature(data, id));

    case 'mvcFeature':
      return store.dispatch(setTableMvcFeature(data, id));

    case 'mvcrFeature':
      return store.dispatch(setTableMvcrFeature(data, id));

    case 's34Feature':
      return store.dispatch(setTableS34Feature(data, id));

    case 's36Feature':
      return store.dispatch(setTableS36Feature(data, id));

    case 'u42Feature':
      return store.dispatch(setTableU42Feature(data, id));

    case 'u43Feature':
      return store.dispatch(setTableU43Feature(data, id));

    case 'u44Feature':
      return store.dispatch(setTableU44Feature(data, id));

    case 'proMFeature':
      return store.dispatch(setTableProMFeature(data, id));

    case 'turbineFeature':
      return store.dispatch(setTableTurbineFeature(data, id));

    case 'fluid':
      return store.dispatch(setTableFluid(data, id));

    case 'liquid':
      return store.dispatch(setTableLiquid(data, id));

    case 'pipeSchedules':
      return store.dispatch(setTablePipeSchedule(data, id));

    case 'ansiPipeSizes':
      return store.dispatch(setTableAnsiPipeSize(data, id));

    case 'dinPipeSizes':
      return store.dispatch(setTableDinPipeSize(data, id));

    case 'jisPipeSizes':
      return store.dispatch(setTableJisPipeSize(data, id));

    case 'jisPipeSchedules':
      return store.dispatch(setTableJisPipeSchedule(data, id));

    case 'gas':
      return store.dispatch(setTableGas(data, id));

    case 'pipesdin':
      return store.dispatch(setTablePipeDin(data, id));

    case 'inlines':
      return store.dispatch(setTableInline(data, id));

    case 'inlinesProM':
      return store.dispatch(setTableInlineProM(data, id));

    case 'flangeTestsAsme':
      return store.dispatch(setTableFlangeTestAsme(data, id));

    case 'flangeTestsDin':
      return store.dispatch(setTableFlangeTestDin(data, id));

    case 'flangeTestsJis':
      return store.dispatch(setTableFlangeTestJis(data, id));

    case 'density':
      return store.dispatch(setTableDensity(data, id));

    case 'viscosity':
      return store.dispatch(setTableViscosity(data, id));

    case 'length':
      return store.dispatch(setTableLength(data, id));

    case 'time':
      return store.dispatch(setTableTime(data, id));

    case 'temperature':
      return store.dispatch(setTableTemperature(data, id));

    case 'pressure':
      return store.dispatch(setTablePressure(data, id));

    case 'flow':
      return store.dispatch(setTableFlow(data, id));

    case 'image':
      return store.dispatch(setTableImage(data, id));

    case 'printheader':
      return store.dispatch(setTablePrintHeader(data, id));

    case 'defaults':
      return store.dispatch(setTableDefault(data, id));

    case 'vusers':
      return store.dispatch(setTableVUser(data, id));

    case 'customer':
      return store.dispatch(setTableCustomer(data, id));

    case 'othergas':
      return store.dispatch(setTableOtherGas(data, id));

    case 'otherliquid':
      return store.dispatch(setTableOtherLiquid(data, id));

    case 'application':
      return store.dispatch(setTableApplication(data, id));

    case 'soundspeed':
      return store.dispatch(setTableSoundSpeed(data, id));

    case 'pipeMaterial':
      return store.dispatch(setTablePipeMaterial(data, id));

    case 'linerMaterial':
      return store.dispatch(setTableLinerMaterial(data, id));

    default:
      throw new Error('Unknown redux type');
  }
};

export default {
  setAccessories,
  setAlertedNegative,
  setAppMode,
  setAppName,
  setClient,
  setClientParam,
  setCustomer,
  setDatabase,
  setDefaults,
  setWorkingDefaults,
  setDefaultNormalConditions,
  setDefaultStandardConditions,
  setErrorMessage,
  setFluid,
  setFluidType,
  setForceLogin,
  setForceRemote,
  setIsAdmin,
  setIsChrome,
  setIsIE89,
  setIsIE10or11orEdge,
  setIsWinSafari,
  setLoc,
  setMeterData,
  setMeterIndex,
  setMeterType,
  setModelCode,
  setNormalConditions,
  setOnlineToken,
  setOtherGas,
  setOtherLiquid,
  setPathName,
  setPipeInsideDiameter,
  setPipeLinerMaterial,
  setPipeMaterial,
  setPipeOutsideDiameter,
  setPipeReduceInsideDiameter,
  setPipeReduceOutsideDiameter,
  setPipeSchedule,
  setPipeType,
  setPipeUnit,
  setReducingVorcone,
  setReducingVortex,
  setRemoteCableLength,
  setSelectedPipeInsideDiameter,
  setSelectedPipeOutsideDiameter,
  setSoundSpeed,
  setShowSpecifyMeter,
  setSpecifyMeterAccuracy,
  setSpecifyMeterElectrodeMaterial,
  setSpecifyMeterGrounding,
  setSpecifyMeterTagNumber,
  setSpecifyMeterParentModelNumber,
  setSpecifyMeterProcessFluid,
  setSpecifyMeterPipeSize,
  setSpecifyMeterLinerMaterial,
  setSpecifyMeterPipeMaterial,
  setSpecifyMeterFlange,
  setSpecifyMeterProbeLength,
  setSpecifyMeterElectronicsMounting,
  setSpecifyMeterDisplayOption,
  setSpecifyMeterInputPower,
  setSpecifyMeterOutputSignal,
  setSpecifyMeterProcessTemperature,
  setSpecifyMeterTransducer,
  setSpecifyMeterProcessConnection,
  setSpecifyMeterRotor,
  setSpecifyMeterDiffPressXMTR,
  setSpecifyMeterDiffPressXMTRMani,
  setSpecifyMeterDiagDiffPressXMTR,
  setSpecifyMeterDiagAddPressTap,
  setSpecifyMeterCableLength,
  setSpecifyMeterCommunications,
  setSpecifyMeterTransducerBracket,
  setSpecifyMeterTempPressComp,
  setSpecifyMeterEnergyMeter,
  setSpecifyMeterApprovals,
  setSpecifyMeterFaceToFace,
  setSpecifyMeterSpecialNotes,
  setSpecifyMeterWarnings,
  setStandardConditions,
  setSystemMessage,
  setTurbineActive,
  setUsername,
  setVorconeActive,
  setWarningMessage,
  setOniconSpc,
  setProcessConditions,
  setFlowMin,
  setFlowNom,
  setFlowMax,
  setFlowUnit,
  setFlowTime,
  setTableFlow,
  setTableFlowWorking,
  setTableDensityWorking,
  setTableViscosityWorking,
  setTableLengthWorking,
  setTableTimeWorking,
  setTableTemperatureWorking,
  setTablePressureWorking,
  setTemperatureMin,
  setTemperatureNom,
  setTemperatureMax,
  setTemperatureUnit,
  setPressureMin,
  setPressureNom,
  setPressureMax,
  setPressurePrefix,
  setPressureCoefficient,
  setDensityMin,
  setDensityNom,
  setDensityMax,
  setDensityUnit,
  setViscosityMin,
  setViscosityNom,
  setViscosityMax,
  setViscosityUnit,
  setVelocityMin,
  setVelocityNom,
  setVelocityMax,
  setVelocityUnit,
  mapCollectionToAction,
};
