import Decimal from 'decimal.js';
import PipeData from '../PipeData';
import ProcessCondition from '../processCondition';
import Fluid from './Fluid';
import constants from '../../constants';
import convert from '../../utilities/convert';

export default class Steam extends Fluid {
  protected sTemperatureUnit: string;
  protected sType: string;
  protected dblTemperatureValue: Decimal;

  constructor() {
    super();

    this.sTemperatureUnit = '';
    this.sType = '';
    this.dblTemperatureValue = constants.ZERO;
  }

  calculateDensity = (processCondition: ProcessCondition): ProcessCondition => {
    // This routine will calculate the steam density based upon the user entered process conditions.
    const dblTemperatureK = convert.temperatureTo('K', processCondition.dblTemperature, processCondition.sTemperatureUnit);

    const dblPressureATM = convert.pressureTo(
      'atm(STD)-A',
      processCondition.dblPressure,
      processCondition.sPressureUnit,
      processCondition.sPressureGaugeAbsolute
    );

    // This subroutine will calculate the steam density based upon the temperature and pressure
    // parameters.  The density value will be stored in the process parameter.

    // MsgBox "CFluid_Steam::SteamDensity - Temperature K = " & TemperatureK
    const Tau = new Decimal('1').div(dblTemperatureK);

    // g1 = (82.546 * Tau) - (162460.0# * (Tau ^ 2))
    const g1left = new Decimal('82.546').times(Tau);
    const tau2 = Tau.pow(new Decimal('2'));
    const g1right = new Decimal('162460.0').times(tau2);
    const g1 = g1left.minus(g1right);

    // g2 = 0.21828 - (126970.0# * (Tau ^ 2))
    const g2 = new Decimal('0.21828').minus(new Decimal('126970.0').times(tau2));

    // B0 = 1.89 - (2641.62 * Tau * (10.0# ^ (80870 * Tau ^ 2)))
    const B0left = new Decimal('1.89');
    let val = new Decimal('80870').times(tau2);
    val = Decimal.pow(10, val);
    // val = new Decimal("" + Math.pow(10.0, parseFloat(val.toString())));
    const B0 = B0left.minus(new Decimal('2641.62').times(Tau).times(val));

    // B = B0 + ((B0 ^ 2) * g1 * Tau * dblPressure) + ((B0 ^ 4) * g2 * (Tau ^ 3) * (dblPressure ^ 3))
    let t1 = B0.pow(new Decimal('2'));
    t1 = t1.times(g1).times(Tau).times(dblPressureATM);
    t1 = B0.plus(t1);

    const B0pow4 = B0.pow(new Decimal('4'));
    const TauPow3 = Tau.pow(new Decimal('3'));
    const pressPow3 = dblPressureATM.pow(new Decimal('3'));
    const t2 = B0pow4.times(g2).times(TauPow3).times(pressPow3);
    const B = t1.plus(t2);

    // V = ((4.55504 * dblTemperatureK) / dblPressure) + B   //volume in cm^3/g
    let v1 = new Decimal('4.55504').times(dblTemperatureK);
    v1 = v1.div(dblPressureATM);
    const V = v1.plus(B);

    // Store the calculated information, and units.
    const newProcessCondition = { ...processCondition };
    newProcessCondition.dblDensityValue = new Decimal('1').div(V.div(new Decimal('62.4279514')));

    newProcessCondition.sDensityUnit = 'lb/ft^3';

    return newProcessCondition;
  };

  calculateViscosity = (processCondition: ProcessCondition): ProcessCondition => {
    // This routine will calculate the viscosity of the steam based upon user input.

    // Convert the temperature to Kelvin.
    const dblTempK = convert.temperatureTo('K', processCondition.dblTemperature, processCondition.sTemperatureUnit);

    // Calculate the viscosity, and update the process conditions.
    // The units are in cP.
    // vortek.classes.ProcessCondition.ViscosityValue = (0.001501 * Math.Sqrt(dblTempK)) / (1.0 + (446.8 / dblTempK))

    const sqrt = dblTempK.sqrt();
    const top = new Decimal('0.001501').times(sqrt);
    let bottom = new Decimal('446.8').div(dblTempK);
    bottom = new Decimal('1').plus(bottom);

    const newProcessCondition = { ...processCondition };
    newProcessCondition.dblViscosityValue = top.div(bottom);
    newProcessCondition.sViscosityUnit = 'cP';
    return newProcessCondition;
  };

  // eslint-disable-next-line
  calculateValues = (pipeData: PipeData, _minNomMax: string): PipeData => {
    return pipeData;
  };
}
