import SizingGlobals from '../../types/sizingGlobals';

/* Watson McDaniel */
const WatsonMcDanielSizingGlobals: SizingGlobals = {
  goodUsableColor: '#003300',
  reducing: true,
  turbine: true,
  vorcone: true,
  ultrasonic: true,
  s36: true,
  u44: true,
  proM: false,
  VorconeModel: 'MVC',
  VorconeReduceModel: 'MVCR',
  VorconeModelStr: 'MVC VorCone',
  VorconeReduceModelStr: 'MVCR Reducing VorCone',
  InlineReduceModel: 'M24R',
  InlineNonReduceModel: 'M24',
  InlineModel: 'M22',
  InlineModelPrev: '',
  InsertionModel: 'M23',
  InsertionModelPrev: '',
  TurbineModel: 'Turbo Pro Model Pro-T',
  TurbineModelShort: 'Pro-T',
  InlineModelStr: 'Pro-V Model M22',
  InlineNonReduceModelStr: 'Pro-V Model M24',
  InlineReduceModelStr: 'Pro-V Model M24R',
  InsertionModelStr: 'Pro-V Model M23',
  UltrasonicS34Model: 'S34',
  UltrasonicS34ModelStr: 'S34 Ultrasonic',
  UltrasonicS36Model: 'S36',
  UltrasonicS36ModelStr: 'S36 Ultrasonic',
  UltrasonicU42Model: 'U42',
  UltrasonicU42ModelStr: 'U42 Ultrasonic',
  UltrasonicU43Model: 'U43',
  UltrasonicU43ModelStr: 'U43 Ultrasonic',
  UltrasonicU44Model: 'U44',
  UltrasonicU44ModelStr: 'U44 Ultrasonic',
  ElectromagneticProMModel: 'Pro-M',
  ElectromagneticProMModelStr: 'Electromagnetic Flow Meter',
  ProductLineStr: 'Pro-V',
  HelpMenuStr: 'Vortex Sizing Help...',
  STANDARD_TEMP: 'ST',
  HIGH_TEMP: 'HT',
  WAFER: 'W',
  ANSI_150: '150',
  ANSI_300: '300',
  ANSI_600: '600',
  ANSI_900: '900',
  DN_PN16: '16',
  DN_PN40: '40',
  DN_PN64: '63',
  DN_PN100: '100',
  JIS_10K_J1: 'J10',
  JIS_16K_K: '',
  JIS_20K_J2: 'J20',
  JIS_30K_J3: 'J30',
  JIS_40K_J4: 'J40',
  JIS_10K_Y1: 'Y10',
  JIS_20K_Y2: 'Y20',
  LocalStr: 'L',
  RemoteStr: 'R()',
  RemoteStr1: '',
  RemoteStr2: '',
  RemoteStr3: '',
  RemoteStr4: '',
  CNPT: 'CNPT',
  C150: 'C150',
  C16: 'C16',
  CJ10: 'CJ10',
  CFJ1K: 'CFJ1K',
  C300: 'C300',
  C40: 'C40',
  CJ20: 'CJ20',
  CFJ2K: 'CFJ2K',
  C600: 'C600',
  C64: 'C63',
  CJ30: 'CJ30',
  CFJ3K: 'CFJ3K',
  C900: 'C900',
  CFJ4K: 'CFJ4K',
  PNPT: 'PNPT',
  P150: 'P150',
  P16: 'P16',
  PJ10: 'PJ10',
  PGJ1K: 'PGJ1K',
  P300: 'P300',
  P40: 'P40',
  PJ20: 'PJ20',
  P64: 'P63',
  PJ30: 'PJ30',
  PGJ2K: 'PGJ2K',
  PGJ3K: 'PGJ3K',
  PGJ4K: 'PGJ4K',
  PNPTR: 'PNPTR',
  PNPTRE: '',
  P150R: 'P150R',
  P150RE: '',
  P16R: 'P16R',
  P16RE: '',
  PJ10R: 'PJ10R',
  PGJ1R: 'PGJ1R',
  P300R: 'P300R',
  P300RE: '',
  P40R: 'P40R',
  P40RE: '',
  PJ20R: 'PJ20R',
  PGJ2R: 'PGJ2R',
  P600R: 'P600R',
  P600RE: '',
  P600: 'P600',
  P64R: 'P63R',
  P64RE: '',
  PJ30R: 'PJ30R',
  PGJ3R: 'PGJ3R',
  PGJ4R: 'PGJ4R',
  ENPTR: '',
  E5016R: '',
  E5040R: '',
  E5064R: '',
  E150R: '',
  E300R: '',
  E600R: '',
  StandardLength: 'SL',
  CompactLength: 'CL',
  ExtendedLength: 'EL',
  V_METER: 'V',
  VT_METER: 'VT',
  VTP_METER: 'VTP',
  VTP_METER_P1: '',
  VTP_METER_P2: '',
  VTP_METER_P3: '',
  VTP_METER_P4: '',
  VTEP_METER: 'VTEP',
  VETEP_METER: 'VETEP',
  VTEM_METER: 'VT-EM',
  VTPEM_METER: 'VTP-EM',
  VTEPEM_METER: 'VTEP-EM',
  VETEPEM_METER: 'VETEP-EM',
  LOOP_OUTPUT: '1AHL',
  LOOP_POWERED: 'DCL',
  VDC_1236_POWERED: 'DCH',
  AC_POWERED: 'AC',
  SPECIAL_STUFF: '',
  POE: 'DCHPOE',
  ONE_OUTPUT: '1AH',
  NO_ALARM_MODBUS_OUTPUT: '1AM',
  NO_ALARM_MODBUS_POE: '1AMIP',
  ALARM_MODBUS: '3AM',
  ALARM_MODBUS_POE: '3AMIP',
  BACNET: '1AB',
  BACNET_POE: '1ABIP',
  ALARM_BACNET: '3AB',
  ALARM_BACNET_POE: '3ABIP',
  ALARM_HART: '3AH',
  MORE_LOOP_POWERED: [],
  INLINE_NON_LOOP_ORDER: ['36', '37', '38', '39', '40', '41', '42', '43', '44', '45'],
  INSERTION_NON_LOOP_ORDER: [],
  NO_PRESSURE_TRANSDUCER: 'P0',
  DIFF_PRESS_XMTR_FACT: 'AZ',
  DIFF_PRESS_XMTR_CUST: 'CX',
  DIAG_ADD_PRESS_TAP_FT: 'FT',

  // This is the Meter and code information.
  CODE_NAME: 'Pro-V',
  CODE_DESCRIPTION: 'Pro-V Vortex Flow Meter',
  TURBINE_CODE_NAME: 'Pro-T',
  TURBINE_CODE_DESCRIPTION: 'Pro-T Turbine Flow Meter',
  DATA_SHEET: 'APPLICATION DATA SHEET',
  DESCRIPTION: 'Pro-V Model M22 and Model M23 Vortex Meter Sizing Program. Written for Watson McDaniel',
  COPYRIGHT:
    'Copyright 2005-2024 Vortek Instruments, LLC.\nAll rights reserved.\nNo warranty is expressed or implied about the accuracy of calculations used in this program.',

  // This is the Watson McDaniel information.
  VORTEK_DISPLAY_NAME: 'Watson McDaniel',
  VORTEK_PRINT_NAME: 'Watson McDaniel',
  VORTEK_NAME: 'Watson McDaniel',
  VORTEK_ADDRESS0: '428 Jones Boulevard',
  VORTEK_ADDRESS1: '',
  VORTEK_CITY: 'Pottstown',
  VORTEK_STATE: 'PA',
  VORTEK_POSTAL_CODE: '19464',
  VORTEK_COUNTRY: 'USA',
  VORTEK_TELEPHONE: 'Telephone: 610-310-5453',
  VORTEK_FAX: 'FAX: 610-495-5134',
};

export default WatsonMcDanielSizingGlobals;
