/* eslint-disable no-console */

//
// Database management
//

import _ from 'lodash';
import ApiConfig from '../types/apiConfig';
import ApiResponse from '../types/apiResponse';
import store from '../store';

let db: IDBDatabase | null;
const dbNamespace = 'vortek_data';

const collections = [
  'feature',
  'm22Feature',
  'm23Feature',
  'm24Feature',
  'm24rFeature',
  'mvcFeature',
  'mvcrFeature',
  's34Feature',
  's36Feature',
  'u42Feature',
  'u43Feature',
  'u44Feature',
  'proMFeature',
  'turbineFeature',
  'fluid',
  'liquid',
  'pipeSchedules',
  'ansiPipeSizes',
  'dinPipeSizes',
  'jisPipeSizes',
  'jisPipeSchedules',
  'gas',
  'pipesdin',
  'inlines',
  'inlinesProM',
  'flangeTestsAsme',
  'flangeTestsDin',
  'flangeTestsJis',
  'density',
  'viscosity',
  'length',
  'time',
  'temperature',
  'pressure',
  'flow',
  'image',
  'printheader',
  'defaults',
  'vusers',
  'customer',
  'othergas',
  'otherliquid',
  'application',
  'soundspeed',
  'pipeMaterial',
  'linerMaterial',
];

const resolveDb = (storeDb: IDBDatabase | undefined): IDBDatabase => {
  if (storeDb) {
    return storeDb;
  }

  const database = db as IDBDatabase;
  return database;
};

const deleteData = async (config: ApiConfig): Promise<ApiResponse> => {
  return new Promise((resolve) => {
    const state = store.getState();
    const { database } = state;
    const indexedDb = resolveDb(database);

    const objectResult: ApiResponse = {
      status: 'fail',
      user: config.requestData.username,
    };

    const hasCollection = indexedDb.objectStoreNames.contains(config.requestData.collection);

    if (!hasCollection) {
      // eslint-disable-next-line
      console.log(`collection ${config.requestData.collection} does not exist`);
      resolve(objectResult);
    }

    const tx = indexedDb.transaction(config.requestData.collection, 'readwrite');

    const dbStore = tx.objectStore(config.requestData.collection);
    const id = config.requestData._id as string;
    const result: IDBRequest = dbStore.delete(id);

    result.onsuccess = () => {
      objectResult.status = 'success';
      resolve(objectResult);
    };

    result.onerror = (event) => {
      const target = event?.target as any;
      objectResult.error = `error deleting data ${target?.errorCode}`;
      resolve(objectResult);
    };
  });
};

const getAll = async (config: ApiConfig, filter: boolean): Promise<any> => {
  return new Promise((resolve) => {
    const state = store.getState();
    const { database } = state;
    const indexedDb = resolveDb(database);

    const objectResult: ApiResponse = {
      status: 'fail',
      user: config.requestData.username,
    };

    const hasCollection = indexedDb.objectStoreNames.contains(config.requestData.collection);

    if (!hasCollection) {
      // eslint-disable-next-line
      console.log(`collection ${config.requestData.collection} does not exist`);
      resolve(objectResult);
    }

    const tx = indexedDb.transaction(config.requestData.collection, 'readonly');
    const dbStore = tx.objectStore(config.requestData.collection);
    const result: IDBRequest = dbStore.getAll();

    result.onsuccess = (event) => {
      const target = event?.target as any;
      const allData = target?.result as Array<any>;
      if (allData) {
        objectResult.status = 'success';
        if (config.requestData.username) {
          const constData = filter ? allData.filter((row) => row.username === config.requestData.username) : allData;
          objectResult.data = constData;
          resolve(objectResult);
        } else {
          const constData = allData;
          objectResult.data = constData;
          resolve(objectResult);
        }
      } else {
        objectResult.error = `unspecified error retrieving data`;
        resolve(objectResult);
      }
    };

    result.onerror = (event) => {
      const target = event?.target as any;
      objectResult.error = `error retrieving data ${target?.errorCode}`;
      resolve(objectResult);
    };
  });
};

const putData = async (config: ApiConfig): Promise<any> => {
  return new Promise((resolve) => {
    const state = store.getState();
    const { database } = state;
    const indexedDb = resolveDb(database);

    const objectResult: ApiResponse = {
      status: 'fail',
      user: config.requestData.username,
    };

    const hasCollection = indexedDb.objectStoreNames.contains(config.requestData.collection);

    if (!hasCollection) {
      // eslint-disable-next-line
      console.log(`collection ${config.requestData.collection} does not exist`);
      resolve(objectResult);
    }

    const tx = indexedDb.transaction(config.requestData.collection, 'readwrite');

    const dbStore = tx.objectStore(config.requestData.collection);
    let result: IDBRequest;
    if (config?.requestData?.data?._id) {
      result = dbStore.put(config.requestData.data, config.requestData.data?._id);

      result.onsuccess = () => {
        objectResult.status = 'success';
        objectResult.data = config.requestData.data;
        resolve(objectResult);
      };

      result.onerror = (event) => {
        const target = event?.target as any;
        objectResult.error = `error saving data ${target?.errorCode}`;
        resolve(objectResult);
      };
    } else {
      const arrayData = config?.requestData?.data;
      if (_.isArray(arrayData)) {
        let count = 0;
        arrayData.forEach((item) => {
          result = dbStore.put(item, item._id);

          result.onsuccess = () => {
            objectResult.status = 'success';
            objectResult.data = config.requestData.data;
            count += 1;
            if (count >= arrayData.length) {
              resolve(objectResult);
            }
          };

          result.onerror = (event) => {
            const target = event?.target as any;
            objectResult.error = `error saving data ${target?.errorCode}`;
            resolve(objectResult);
          };
        });
      }
    }
  });
};

const removeCollection = async (config: ApiConfig): Promise<ApiResponse> => {
  return new Promise((resolve) => {
    const state = store.getState();
    const { database } = state;
    const indexedDb = resolveDb(database);

    const objectResult: ApiResponse = {
      status: 'fail',
    };

    const hasCollection = indexedDb.objectStoreNames.contains(config.requestData.collection);
    if (!hasCollection) {
      // eslint-disable-next-line
      console.log(`collection ${config.requestData.collection} does not exist`);
      resolve(objectResult);
    }

    const tx = indexedDb.transaction(config.requestData.collection, 'readwrite');
    const dbStore = tx.objectStore(config.requestData.collection);
    const result: IDBRequest = dbStore.clear();

    result.onsuccess = () => {
      objectResult.status = 'success';
      resolve(objectResult);
    };

    result.onerror = (event) => {
      const target = event?.target as any;
      objectResult.error = `error removing collection ${target?.errorCode}`;
      resolve(objectResult);
    };
  });
};

const addCollection = (collection: string): void => {
  const indexedDb = resolveDb(undefined);
  const names = indexedDb.objectStoreNames;
  if (!names.contains(collection)) {
    indexedDb.createObjectStore(collection);
  }
};

const addCollections = (): void => {
  for (let i = 0; i < collections.length; i++) {
    const collection = collections[i];
    addCollection(collection);
  }
};

const clearCollections = async (config: ApiConfig): Promise<void> => {
  const state = store.getState();
  const { database } = state;
  const indexedDb = resolveDb(database);
  const names = indexedDb.objectStoreNames;
  const results = [];

  for (let i = 0; i < names.length; i++) {
    const collection = names[i];
    results.push(
      removeCollection({
        requestData: {
          client: config.requestData.client,
          username: config.requestData.username,
          collection,
        },
      })
    );
  }

  await Promise.all(results);
};

const setupDB = async (indexedDB: IDBFactory): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    // If setupDB has already been run and the database was set up, no need to
    // open the database again; just resolve and return!
    if (db) {
      resolve(db);
      return;
    }

    // increase this number any time a new table is added
    const dbReq = indexedDB.open(dbNamespace, 3);

    // Fires when the version of the database goes up, or the database is
    // created for the first time
    dbReq.onupgradeneeded = (event: Event) => {
      const target = event?.target as any;
      db = target?.result;

      if (db) {
        addCollections();
      }
    };

    // Fires once the database is opened (and onupgradeneeded completes, if
    // onupgradeneeded was called)
    dbReq.onsuccess = (event) => {
      // Set the db variable to our database so we can use it!
      const target = event?.target as any;
      db = target?.result as IDBDatabase;
      if (db) {
        const openedDb = db;
        resolve(openedDb);
      } else {
        reject(new Error(`unspecified error opening database`));
      }
    };

    // Fires when we can't open the database
    dbReq.onerror = (event) => {
      const target = event?.target as any;
      reject(new Error(`error opening database ${target?.errorCode}`));
    };
  });
};

export default {
  addCollection,
  addCollections,
  clearCollections,
  collections,
  deleteData,
  getAll,
  putData,
  setupDB,
};
