import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CallbackNoParams } from '../../types/callbackTypes';
import utilities from '../../utilities';
import Modal from '../common/Modal';
import TabGroup from '../common/TabGroup';
import AccessoriesTab from './AccessoriesTab';
import AddCustomerTab from './AddCustomerTab';
import ModelCodeTab from './ModelCodeTab';
import NotesTab from './NotesTab';
import SizingTab from './SizingTab';
import WarningsTab from './WarningsTab';

const MeterSpecify = (props: { onClose?: CallbackNoParams }): JSX.Element => {
  const errorMessage = useSelector((globalState: RootState) => globalState.errorMessage);
  const systemMessage = useSelector((globalState: RootState) => globalState.systemMessage);
  const warningMessage = useSelector((globalState: RootState) => globalState.warningMessage);

  const meterData = useSelector((globalState: RootState) => globalState.meterData);
  const meterIndex = useSelector((globalState: RootState) => globalState.meterIndex);
  const specifyMeterWarnings = useSelector((globalState: RootState) => globalState.specifyMeterWarnings);
  const sizingGlobals = useSelector((globalState: RootState) => globalState.sizingGlobals);
  const { VORTEK_NAME } = sizingGlobals;
  const { onClose } = props;
  const tabItems = ['Model Code', 'Accessories', 'Sizing', 'Warnings', 'Notes'];
  const [active, setActive] = useState(tabItems[0]);
  const defaultListItems: string[] = [];
  const [redTabs, setRedTabs] = useState(defaultListItems);

  useEffect(() => {
    const selectedMeter = meterData?.length && meterIndex !== undefined ? meterData[meterIndex] : undefined;
    if (selectedMeter) {
      const newRedTabs = [];

      if (
        selectedMeter.minflowwarn ||
        selectedMeter.maxflowwarn ||
        selectedMeter.mindpwarn ||
        selectedMeter.maxdpwarn ||
        selectedMeter.headlosswarn ||
        selectedMeter.minreynwarn ||
        selectedMeter.maxreynwarn ||
        selectedMeter.minvelowarn ||
        selectedMeter.nomvelowarn ||
        selectedMeter.maxvelowarn
      ) {
        newRedTabs.push('Sizing');
      }

      if (specifyMeterWarnings && specifyMeterWarnings !== 'No Warnings') {
        newRedTabs.push('Warnings');
      }

      setRedTabs(newRedTabs);
    }
  }, [meterData, meterIndex, specifyMeterWarnings]);

  if (VORTEK_NAME !== utilities.ONICON_NAME && VORTEK_NAME !== utilities.HEINRICH_NAME) {
    tabItems.push('Add Customer');
  }

  const displayTab = (tabItem: string) => {
    setActive(tabItem);
  };

  return (
    <>
      <Modal title="Specify Meter" visible showButtons={false} showCloseButton onClose={onClose} cancelText="">
        <div className="uiDialog">
          <div className="uiCornerAll uiWidget uiWidgetContent">
            <div className="flexRow tabRow leftRight10 topBottom5">
              <TabGroup
                tabItems={tabItems}
                selectedIndex={0}
                onClick={displayTab}
                tabStyle="tabStyle"
                tabStyleActive="tabStyleActive"
                redTabs={redTabs}
              />
            </div>
            {active === 'Model Code' && <ModelCodeTab />}
            {active === 'Accessories' && <AccessoriesTab />}
            {active === 'Sizing' && <SizingTab />}
            {active === 'Warnings' && <WarningsTab />}
            {active === 'Notes' && <NotesTab />}
            {active === 'Add Customer' && <AddCustomerTab onClose={onClose} />}
          </div>
        </div>
      </Modal>
      {systemMessage && (
        <Modal title="System Message" visible showButtons={false} showData={false}>
          <div className="modalContent">{systemMessage}</div>
        </Modal>
      )}
      {errorMessage && (
        <Modal title="Error" visible showButtons showData={false} okText="OK" cancelText="">
          <div className="modalContent">{errorMessage}</div>
        </Modal>
      )}
      {warningMessage && (
        <Modal title="Warning" visible showButtons showData={false} okText="OK" cancelText="">
          <div className="modalContent">{warningMessage}</div>
        </Modal>
      )}
    </>
  );
};

MeterSpecify.defaultProps = {
  onClose: undefined,
};

export default MeterSpecify;
