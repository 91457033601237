import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Decimal from 'decimal.js';
import pipeUtils from '../../utilities/pipeUtils';
import { RootState } from '../../store';
import actions from '../../store/actions';
import utilities from '../../utilities';
import constants from '../../constants';
import api from '../../api';
import PipeMaterial from '../../types/PipeMaterial';

const PipeConfiguration = (): JSX.Element => {
  const dispatch = useDispatch();
  const defaultListItems: string[] = [];
  const defaultPipeListItems: JSX.Element[] = [];
  const [allPipeTypes, setAllPipeTypes] = useState(defaultPipeListItems);
  const [allPipeUnits, setAllPipeUnits] = useState(defaultListItems);
  const [allPipeMaterial, setAllPipeMaterial] = useState(defaultListItems);
  const [allPipeOutsideDiameters, setAllPipeOutsideDiameters] = useState(defaultListItems);
  const [allPipeSchedules, setAllPipeSchedules] = useState(defaultListItems);
  const [allPipeLinerMaterial, setAllPipeLinerMaterial] = useState(defaultListItems);

  const state = useSelector((globalState: RootState) => globalState);
  const dbAnsiPipeSizes = useSelector((globalState: RootState) => globalState.db.ansiPipeSizes);
  const dbJisPipeSchedules = useSelector((globalState: RootState) => globalState.db.jisPipeSchedules);

  const {
    meterType,
    pipeInsideDiameter,
    pipeLinerMaterial,
    pipeMaterial,
    pipeOutsideDiameter,
    pipeSchedule,
    pipeType,
    pipeUnit,
    reducingVortex,
    vorconeActive,
    reducingVorcone,
    sizingGlobals,
  } = state;

  const { UltrasonicS34Model, UltrasonicS36Model, UltrasonicU42Model, UltrasonicU43Model, UltrasonicU44Model, VORTEK_NAME } =
    sizingGlobals;

  const ansiOption = (
    <option key="ansi" value="ansi">
      ANSI Pipe
    </option>
  );

  const dinOption = (
    <option key="din" value="din">
      DIN Pipe
    </option>
  );

  const jisOption = (
    <option key="jis" value="jis">
      JIS Pipe
    </option>
  );

  const otherOption = (
    <option key="other" value="other">
      Other Pipe
    </option>
  );

  const setSchedule = (pt: string) => {
    if (!pipeSchedule || (allPipeSchedules.length && !allPipeSchedules.includes(pipeSchedule))) {
      if (pt === 'ansi') {
        if (VORTEK_NAME === utilities.HEINRICH_NAME) {
          dispatch(actions.setPipeSchedule('80'));
        } else {
          dispatch(actions.setPipeSchedule('40'));
        }
      } else if (pt === 'din') {
        dispatch(actions.setPipeSchedule(''));
      } else if (pt === 'jis') {
        if (VORTEK_NAME === utilities.HEINRICH_NAME) {
          dispatch(actions.setPipeSchedule('80'));
        } else {
          dispatch(actions.setPipeSchedule('40'));
        }
      } else if (pt === 'other') {
        dispatch(actions.setPipeSchedule('0.5'));
      }
    } else if (pipeType === 'din' && pipeSchedule !== '') {
      dispatch(actions.setPipeSchedule(''));
    }
  };

  // set units and schedule based on pipe type change
  useEffect(() => {
    const newUnits = [];
    if (pipeType === 'ansi') {
      if (!pipeOutsideDiameter || (allPipeOutsideDiameters.length && !allPipeOutsideDiameters.includes(pipeOutsideDiameter))) {
        if (meterType === utilities.VORTEX_REDUCE_METER || meterType === utilities.VORCONE_REDUCE_METER) {
          dispatch(actions.setPipeOutsideDiameter('0.75 in'));
        } else {
          dispatch(actions.setPipeOutsideDiameter('0.5 in'));
        }
      }
      dispatch(actions.setPipeUnit('in'));
      newUnits.push('in');
    } else if (pipeType === 'din') {
      if (!pipeOutsideDiameter || (allPipeOutsideDiameters.length && !allPipeOutsideDiameters.includes(pipeOutsideDiameter))) {
        dispatch(actions.setPipeOutsideDiameter('15 mm'));
      }
      dispatch(actions.setPipeUnit('mm'));
      newUnits.push('mm');
    } else if (pipeType === 'jis') {
      if (!pipeOutsideDiameter || (allPipeOutsideDiameters.length && !allPipeOutsideDiameters.includes(pipeOutsideDiameter))) {
        dispatch(actions.setPipeOutsideDiameter('15'));
      }
      dispatch(actions.setPipeUnit('mm'));
      newUnits.push('mm');
    } else if (pipeType === 'other') {
      let trydec;
      try {
        /* eslint-disable-next-line */
        trydec = new Decimal(pipeInsideDiameter);
      } catch (err) {
        dispatch(actions.setPipeInsideDiameter('28.5'));
      }

      if (!pipeUnit) {
        dispatch(actions.setPipeUnit('in'));
      }
      newUnits.push('ft');
      newUnits.push('mm');
      newUnits.push('in');
      newUnits.push('km');
      newUnits.push('m');
      newUnits.push('cm');
    }

    setSchedule(pipeType);
    setAllPipeUnits(newUnits);

    if (!pipeUnit || !newUnits.includes(pipeUnit)) {
      dispatch(actions.setPipeUnit(newUnits[0]));
    }
  }, [pipeType, pipeUnit, allPipeSchedules]);

  useEffect(() => {
    const pipeTypes = [];
    pipeTypes.push(ansiOption);

    if (!reducingVortex && !vorconeActive && !reducingVorcone) {
      pipeTypes.push(dinOption);

      if (
        VORTEK_NAME === utilities.PROV_NAME ||
        VORTEK_NAME === utilities.PANAFLOW_NAME ||
        VORTEK_NAME === utilities.HEINRICH_NAME ||
        VORTEK_NAME === utilities.AZBIL_NAME ||
        VORTEK_NAME === utilities.SPIRAX_NAME ||
        VORTEK_NAME === utilities.GESTRA_NAME ||
        VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
      ) {
        pipeTypes.push(jisOption);
      }

      pipeTypes.push(otherOption);
    }

    setAllPipeTypes(pipeTypes);

    const currentOption =
      pipeType === 'ansi' ? ansiOption : pipeType === 'din' ? dinOption : pipeType === 'jis' ? jisOption : otherOption;

    if (!pipeType || !pipeTypes.includes(currentOption)) {
      if (VORTEK_NAME === utilities.AZBIL_NAME && !reducingVortex && !vorconeActive && !reducingVorcone) {
        dispatch(actions.setPipeType('jis'));
      } else {
        dispatch(actions.setPipeType('ansi'));
      }
    }
  }, [meterType]);

  useEffect(() => {
    let od = pipeOutsideDiameter || '0.5 in';
    let schedule = pipeSchedule;
    const pt = pipeType || 'ansi';
    const mt = meterType || utilities.VORTEX_METER;

    if (pt !== 'other') {
      schedule = pt === 'din' ? '' : schedule || '40';
      od = pipeOutsideDiameter.startsWith('12 in') ? '12 in' : pipeOutsideDiameter;

      if (mt === utilities.VORTEX_REDUCE_METER || mt === utilities.VORCONE_REDUCE_METER) {
        if (od === '0.5 in') {
          od = '0.75 in';
        }
        const reduceOd = pipeUtils.adjustPipeSizeForReducing(od, reducingVortex, reducingVorcone);
        dispatch(actions.setPipeReduceOutsideDiameter(reduceOd));
      }
    } else {
      od = pipeInsideDiameter;
      const odParts = od.split(' ');
      let isPipeScheduleValid = true;
      let trydec;
      try {
        trydec = new Decimal(schedule);
        isPipeScheduleValid = trydec.greaterThan(constants.ZERO);
      } catch (err) {
        isPipeScheduleValid = false;
      }

      if (isPipeScheduleValid) {
        dispatch(
          actions.setPipeOutsideDiameter(new Decimal(schedule).times(new Decimal('2')).plus(new Decimal(odParts[0])).toString())
        );
      }
    }
  }, [dbAnsiPipeSizes, dbJisPipeSchedules, meterType, pipeType, allPipeSchedules, pipeInsideDiameter, pipeSchedule]);

  useEffect(() => {
    let od = pipeOutsideDiameter || '0.5 in';
    let schedule = pipeSchedule;
    const pt = pipeType || 'ansi';
    const mt = meterType || utilities.VORTEX_METER;

    if (pt === 'jis') {
      if (od.endsWith('in') || od.endsWith('mm') || od.indexOf('.') > -1) {
        od = '15';
      }

      schedule = schedule || '40';
      dispatch(actions.setPipeInsideDiameter(pipeUtils.findJisPipeInsideDiameter(VORTEK_NAME, od, schedule)));
    } else if (pt !== 'other') {
      schedule = pt === 'din' ? '' : schedule || '40';
      let pipes;
      let osd;
      od = pipeOutsideDiameter.startsWith('12 in') ? '12 in' : pipeOutsideDiameter;

      if (mt === utilities.VORTEX_REDUCE_METER || mt === utilities.VORCONE_REDUCE_METER) {
        if (od === '0.5 in') {
          od = '0.75 in';
        }
        const reduceOd = pipeUtils.adjustPipeSizeForReducing(od, reducingVortex, reducingVorcone);
        osd = `${reduceOd}80`;
        pipes = pipeUtils.findPipes(osd);
        if (pipes) {
          dispatch(actions.setPipeReduceInsideDiameter(pipes.data[3].toString()));
        }
      }

      osd = `${od}${schedule}`;
      pipes = pipeUtils.findPipes(osd);
      if (pipes) {
        dispatch(actions.setPipeInsideDiameter(pipes.data[3].toString()));
      }
    }
  }, [dbAnsiPipeSizes, dbJisPipeSchedules, meterType, pipeType, allPipeSchedules, pipeOutsideDiameter, pipeSchedule]);

  const getPipeOutsideDiameterText = useCallback(() => {
    if (pipeType === 'din') {
      return 'DN Number';
    }

    if (pipeType === 'jis') {
      return 'Nominal Size';
    }

    if (pipeType === 'other') {
      return 'Inside Diameter';
    }

    // default to ANSI
    return 'Pipe O.D.';
  }, [pipeType]);

  const getPipeScheduleText = useCallback(() => {
    if (pipeType === 'din') {
      return '';
    }

    if (pipeType === 'other') {
      return 'Wall';
    }

    // ANSI and Jis both use this
    return 'Schedule';
  }, [pipeType]);

  const getInsideDiameterText = useCallback(() => {
    if (pipeType === 'other') {
      return 'Outside Diam.';
    }

    // ANSI, DIN, and Jis all use this
    return 'Inside Diam.';
  }, [pipeType]);

  useEffect(() => {
    let pipes = [] as string[];

    if (pipeType === 'ansi') {
      // default to ANSI
      pipes = pipeUtils.getAnsiPipeSizes(VORTEK_NAME, reducingVortex, vorconeActive, reducingVorcone);
    }

    if (pipeType === 'din') {
      pipes = pipeUtils.getDinPipeSizes(VORTEK_NAME);
    }

    if (pipeType === 'jis') {
      pipes = pipeUtils.getJisPipeSizes(VORTEK_NAME);
    }

    setAllPipeOutsideDiameters(pipes);
  }, [dbAnsiPipeSizes, meterType, pipeOutsideDiameter, pipeType]);

  useEffect(() => {
    let schedules = [] as string[];

    if (pipeType === 'ansi') {
      schedules = pipeUtils.getSchedulesForLength(
        VORTEK_NAME,
        pipeOutsideDiameter.startsWith('12 in') ? '12 in' : pipeOutsideDiameter
      );

      if (pipeOutsideDiameter === '28 in') {
        // find index of schedule 40 and remove if found
        const fortyIndex = schedules.indexOf('40');
        if (fortyIndex > -1) {
          schedules.splice(fortyIndex, 1);
        }
      }
    }

    if (pipeType === 'jis') {
      let od = pipeOutsideDiameter;
      if (od.endsWith('in') || od.endsWith('mm') || od.indexOf('.') > -1) {
        od = '15';
      }

      const scheduleObjects = pipeUtils.getJisDataForLength(VORTEK_NAME, od);

      schedules = scheduleObjects.map((p) => {
        return p.Schedule;
      });
    }

    setAllPipeSchedules(schedules);
    if (schedules.length && !schedules.includes(pipeSchedule)) {
      dispatch(actions.setPipeSchedule(schedules[0]));
    }
  }, [dbAnsiPipeSizes, meterType, pipeOutsideDiameter, pipeType]);

  const getMaterials = (collection: string): string[] | undefined => {
    const materialData = api.findAllDataForUserInReduxTable(VORTEK_NAME, 'DEFAULT', collection);

    let materialArray;

    if (materialData.length) {
      const materials = materialData[0].data as PipeMaterial[];
      const materialItems = materials.filter((item) => {
        return item.meterType === meterType;
      });

      materialArray = materialItems.map((p) => {
        return p.material;
      });
    }

    return materialArray;
  };

  useEffect(() => {
    if (
      meterType === UltrasonicS34Model ||
      meterType === UltrasonicS36Model ||
      meterType === UltrasonicU42Model ||
      meterType === UltrasonicU43Model ||
      meterType === UltrasonicU44Model
    ) {
      const newPipeMaterial = getMaterials('pipeMaterial');
      if (newPipeMaterial?.length) {
        setAllPipeMaterial(newPipeMaterial);
        dispatch(actions.setPipeMaterial(newPipeMaterial[0]));
      }

      const newPipeLinerMaterial = getMaterials('linerMaterial');
      if (newPipeLinerMaterial?.length) {
        setAllPipeLinerMaterial(newPipeLinerMaterial);
        dispatch(actions.setPipeLinerMaterial(newPipeLinerMaterial[0]));
      }
    } else {
      dispatch(actions.setPipeMaterial(''));
      dispatch(actions.setPipeLinerMaterial(''));
    }
  }, [meterType]);

  return (
    <div className="pipingConfig">
      <div className="flexRow">
        <div className="titleLeft" />
        <div className="titleBackground">PIPING CONFIGURATION</div>
        <div className="titleRight" />
      </div>
      <div className="contentBox">
        <div className="flexRow topBottom5">
          <div className="pipingPad">Type of Pipe</div>
          <div className="pipingPad">{getPipeOutsideDiameterText()}</div>
          <div className="pipingPad">{getPipeScheduleText()}</div>
          <div className="pipingPad">{getInsideDiameterText()}</div>
          <div className="pipingPad">Unit</div>
        </div>
        <div className="flexRow">
          <div className="pipingPad">
            <select
              value={pipeType}
              name="pipeTypes"
              className="dropSmall"
              onChange={(e) => {
                const val = e.target.value;
                dispatch(actions.setPipeType(val));
              }}
            >
              {allPipeTypes}
            </select>
          </div>
          <div className="pipingPad">
            {pipeType !== 'other' ? (
              <select
                name="pipeOdSelect"
                className="dropSmall"
                value={pipeOutsideDiameter}
                onChange={(e) => {
                  const val = e.target.value;
                  dispatch(actions.setPipeOutsideDiameter(val));
                  setSchedule(pipeType);
                }}
              >
                {allPipeOutsideDiameters.map((p) => {
                  return (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  );
                })}
              </select>
            ) : (
              <input
                name="pipeIdInput"
                type="number"
                maxLength={20}
                value={pipeInsideDiameter}
                className="txtboxPipeId"
                onChange={(e) => {
                  dispatch(actions.setPipeInsideDiameter(utilities.validateNumber(e.target.value)));
                }}
              />
            )}
          </div>
          <div className="pipingPad">
            {(pipeType === 'ansi' || pipeType === 'jis') && (
              <select
                name="pipeScheduleSelect"
                className="dropSmall"
                value={pipeSchedule}
                onChange={(e) => {
                  const val = e.target.value;
                  dispatch(actions.setPipeSchedule(val));
                }}
              >
                {allPipeSchedules.map((p) => {
                  return (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  );
                })}
              </select>
            )}

            {pipeType === 'other' && (
              <input
                name="pipeScheduleInput"
                type="number"
                maxLength={20}
                value={pipeSchedule}
                className="txtboxPipeId"
                onChange={(e) => {
                  dispatch(actions.setPipeSchedule(utilities.validateNumber(e.target.value)));
                }}
              />
            )}
          </div>
          <div className="pipingPad">
            <input
              name="pipeId"
              className="txtboxPipeId"
              type="number"
              step="any"
              value={pipeType !== 'other' ? pipeInsideDiameter : pipeOutsideDiameter}
              onChange={(e) => {
                if (pipeType !== 'other') {
                  dispatch(actions.setPipeInsideDiameter(utilities.validateNumber(e.target.value)));
                } else {
                  dispatch(actions.setPipeOutsideDiameter(utilities.validateNumber(e.target.value)));
                }
              }}
            />
          </div>
          <div className="pipingPad">
            <select
              name="pipeUnit"
              className="dropSmall"
              value={pipeUnit}
              onChange={(e) => {
                const val = e.target.value;
                dispatch(actions.setPipeUnit(val));
              }}
            >
              {allPipeUnits.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {(meterType === UltrasonicS34Model ||
          meterType === UltrasonicS36Model ||
          meterType === UltrasonicU42Model ||
          meterType === UltrasonicU43Model ||
          meterType === UltrasonicU44Model) && (
          <div className="flexRow topBottom10">
            <div className="pipingPad">Pipe Material</div>
            <div className="pipingPad">
              <select
                name="pipeMaterial"
                className="dropSmall"
                value={pipeMaterial}
                onChange={(e) => {
                  const val = e.target.value;
                  dispatch(actions.setPipeMaterial(val));
                }}
              >
                {allPipeMaterial.map((p) => {
                  return (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="pipingPad">Liner Material</div>
            <div className="pipingPad">
              <select
                name="linerMaterial"
                className="dropSmall"
                value={pipeLinerMaterial}
                onChange={(e) => {
                  const val = e.target.value;
                  dispatch(actions.setPipeLinerMaterial(val));
                }}
              >
                {allPipeLinerMaterial.map((p) => {
                  return (
                    <option key={p} value={p}>
                      {p}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="pipingPad" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PipeConfiguration;
