import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useSearchParams } from 'react-router-dom';

import ChangePassword from './components/user/ChangePassword';
import Login from './components/user/Login';
import Main from './components/Main';

import actions from './store/actions';
import { RootState } from './store';

import api from './api';
import db from './db';
import utilities from './utilities';
import './css/resolveCss';
import Modal from './components/common/Modal';

let initializingHookGuard = false;
let asyncAuthHookGuard = false;
let asyncCpHookGuard = false;

/*
  Flow:
    1. useEffect with no dependencies is executed to set up the database
    2. When the dbase variable is set in redux, the useEffect that checks
       the cookie is executed. If the cookie is set, the user is
       auto-logged in by calling authenticated(). The isInitializing var
       is then set.
    3. If no cookie is set, then login is shown.
    4. When the user logs in, the isInitializing var is set.
    5. When isInitializing is true, the effect executes that loads the
       redux store with the data in the database.
    6. The isInitializing var also tells the Main component to display a
       modal letting the user know that data is loading.
    7. When initialization is complete, the isInitializing var is set
       back to false to hide the modal.
    8. The effect with asyncHookGuard checks the query parameters on the
       URL to see if the page was loaded by clicking on a link in an
       email for either completing user registration or resetting their
       password.
*/

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const systemMessage = useSelector((state: RootState) => state.systemMessage);
  const username = useSelector((state: RootState) => state.username);
  const sizingGlobals = useSelector((state: RootState) => state.sizingGlobals);
  const dbase = useSelector((state: RootState) => state.database);
  const { VORTEK_NAME } = sizingGlobals;
  const logoData =
    VORTEK_NAME === utilities.HEINRICH_NAME
      ? ''
      : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [logo, setLogo] = useState(logoData);
  const [message, setMessage] = useState('');
  const [loginVisible, setLoginVisible] = useState(false);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [swRegistration, setSwRegistration] = useState<any | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();
  const uname = searchParams.get('username');
  const activate = searchParams.get('activate');
  const forgotpw = searchParams.get('forgotpasswordid');

  window.newVortekVersionAvailable = (registration: any) => {
    setSwRegistration(registration);
    setNewVersionAvailable(true);
  };

  const isOnline = () => {
    dispatch(actions.setOnlineToken('online'));
  };

  const isOffline = () => {
    dispatch(actions.setOnlineToken('offline'));
  };

  // one time setup when the page loads to initialize the database
  // and hook up online/offline detection
  useEffect(() => {
    (async () => {
      if (window.addEventListener) {
        /*
              Works well in Firefox and Opera with the
              Work Offline option in the File menu.
              Pulling the ethernet cable doesn't seem to trigger it.
              Later Google Chrome and Safari seem to trigger it well
          */
        window.addEventListener('online', isOnline, false);
        window.addEventListener('offline', isOffline, false);
      } else {
        /*
            Works in IE with the Work Offline option in the
            File menu and pulling the ethernet cable
          */
        document.body.ononline = isOnline;
        document.body.onoffline = isOffline;
      }

      if (!dbase) {
        const indexedDb = await db.setupDB(indexedDB);
        dispatch(actions.setDatabase(indexedDb));
        setMessage('');
        dispatch(actions.setSystemMessage('Initializing...Please wait'));
      }
    })();
  }, []);

  useEffect(() => {
    if (!asyncAuthHookGuard) {
      asyncAuthHookGuard = true;

      (async () => {
        if (dbase && username) {
          setLoginVisible(false);
          await api.retrieveRemoteOfflineData();
          await api.retrieveIndexedDbDataToMemory({
            requestData: {
              client: VORTEK_NAME,
              username,
              collection: '',
            },
          });

          if (VORTEK_NAME !== utilities.HEINRICH_NAME) {
            setLogo(api.loadLogo());
          }

          const resp = api.readItemSync(false, {
            requestData: {
              client: VORTEK_NAME,
              username,
              collection: 'vusers',
              key: username,
            },
          });

          if (resp.status === 'success') {
            dispatch(actions.setSystemMessage(''));
            dispatch(actions.setIsAdmin(resp.data.data[0].usertype === 'admin'));
          } else {
            // failed to load user
            dispatch(actions.setSystemMessage(''));
            utilities.deleteCookie(username);
            if (VORTEK_NAME === utilities.HEINRICH_NAME || VORTEK_NAME === utilities.ONICON_NAME) {
              dispatch(actions.setUsername('guest'));
            } else {
              dispatch(actions.setUsername(''));
              setLoginVisible(true);
            }
          }
        }

        asyncAuthHookGuard = false;
      })();
    }
  }, [dbase, username]);

  // This effect loads background data after login is successful
  useEffect(() => {
    if (dbase && !username && systemMessage && !initializingHookGuard) {
      // the hook guard keeps this code from executing multiple times
      // the isInitializing state var controls whether the "initializing"
      // modal is shown
      initializingHookGuard = true;
      (async () => {
        const cookie = utilities.getCookie('user');
        if (cookie) {
          // if we loaded off of a cookie, then the user
          // hasn't actually been loaded yet, so do that
          // now.
          const cookieUsername = decodeURIComponent(cookie);
          if (cookieUsername) {
            if (VORTEK_NAME === utilities.HEINRICH_NAME || VORTEK_NAME === utilities.ONICON_NAME) {
              dispatch(actions.setUsername('guest'));
              return;
            }

            api
              .validateToken({
                client: VORTEK_NAME,
                username: cookieUsername,
                collection: '',
              })
              .then(async (validated) => {
                if (validated.status === 'success') {
                  dispatch(actions.setUsername(cookieUsername));
                } else {
                  // eslint-disable-next-line no-console
                  console.log('cookie expired.');
                  dispatch(actions.setUsername(''));
                  dispatch(actions.setSystemMessage(''));
                  setLoginVisible(true);
                }
              });
          } else {
            dispatch(actions.setSystemMessage(''));
            dispatch(actions.setUsername(''));
            setLoginVisible(true);
          }
        } else if (VORTEK_NAME === utilities.HEINRICH_NAME || VORTEK_NAME === utilities.ONICON_NAME) {
          dispatch(actions.setUsername('guest'));
        } else {
          dispatch(actions.setSystemMessage(''));
          dispatch(actions.setUsername(''));
          setLoginVisible(true);
        }

        initializingHookGuard = false;
      })();
    }
  }, [dbase, systemMessage, username, VORTEK_NAME]);

  const logoutHandler = useCallback(async () => {
    await api.performLogout({
      requestData: {
        client: VORTEK_NAME,
        collection: '',
        username,
      },
    });

    window.location.reload();
  }, [message, sizingGlobals, username]);

  const changePasswordHandler = useCallback(
    (e) => {
      e.preventDefault();
      setMessage('You have successfully changed your password. Please login to continue.');
      const newSearchParams = new URLSearchParams();
      setSearchParams(newSearchParams, { replace: true });
      setIsChangingPassword(false);
    },
    [isChangingPassword, message]
  );

  // activation, change password
  useEffect(() => {
    if (!asyncCpHookGuard) {
      asyncCpHookGuard = true;
      (async () => {
        if (uname && dbase) {
          let response;
          const newSearchParams = new URLSearchParams();
          if (activate) {
            response = await api.performActivate({
              requestData: {
                collection: '',
                client: VORTEK_NAME,
                username: uname,
                activationId: activate,
              },
            });

            if (response.status === 'success') {
              setSearchParams(newSearchParams, { replace: true });
              setMessage('Thank you for activating your account. Please login!');
            } else {
              setMessage(response.error);
            }
          } else if (forgotpw) {
            if (!isChangingPassword) {
              response = await api.performAuthForgotPassword({
                requestData: {
                  collection: '',
                  client: VORTEK_NAME,
                  username: uname,
                  forgotpasswordid: forgotpw,
                },
              });

              if (response.status === 'success') {
                setIsChangingPassword(true);
                setSearchParams(newSearchParams, { replace: true });
                setMessage('');
              } else {
                setMessage(response.error);
              }
            }
          }
        }

        asyncCpHookGuard = false;
      })();
    }
  }, [dbase, message, isChangingPassword, username, uname, activate, forgotpw]);

  return (
    <Routes>
      <Route
        path="//*"
        element={
          <div className="app">
            <div className="maindiv">
              <div className="logoPad">
                <img className="logoImg" src={logo} style={{ padding: 0, borderWidth: 0 }} alt="" />
              </div>
              {newVersionAvailable ? (
                <Modal
                  title="System Message"
                  visible
                  showButtons
                  showData={false}
                  okText="OK"
                  cancelText=""
                  logoutHandler={logoutHandler}
                  onClose={() => {
                    // eslint-disable-next-line no-console
                    console.log('Loading new service worker now');
                    swRegistration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
                    window.location.reload();
                  }}
                >
                  <div className="modalContent">A new version is available. Press OK to reload.</div>
                </Modal>
              ) : (
                <>
                  <Main logoutHandler={logoutHandler} />
                  <Login message={message} visible={loginVisible} />
                  {isChangingPassword && <ChangePassword changePasswordHandler={changePasswordHandler} />}
                </>
              )}
            </div>
          </div>
        }
      />
    </Routes>
  );
};

export default App;
