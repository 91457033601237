import React, { useCallback, useState } from 'react';

function TabGroup(props: {
  tabItems: string[];
  selectedIndex: number;
  onClick: any;
  tabStyle: string;
  tabStyleActive: string;
  redTabs?: string[];
}): JSX.Element {
  const { tabItems, selectedIndex, onClick, tabStyle, tabStyleActive, redTabs } = props;
  const [active, setActive] = useState(tabItems[selectedIndex]);
  const redClass = 'redWarning';

  const onClickCallback = useCallback((tabItem) => {
    onClick(tabItem);
  }, []);

  return (
    <>
      <div className="uiCornerAll uiWidgetHeader">
        {tabItems.map((tabItem) => {
          let classes = active === tabItem ? tabStyleActive : tabStyle;
          if (redTabs?.includes(tabItem)) {
            classes += ` ${redClass}`;
          }

          return (
            <button
              type="button"
              key={tabItem}
              className={classes}
              onClick={() => {
                setActive(tabItem);
                onClickCallback(tabItem);
              }}
            >
              {tabItem}
            </button>
          );
        })}
      </div>
      <p />
    </>
  );
}

TabGroup.defaultProps = {
  redTabs: [],
};

export default TabGroup;
