import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../store/actions';
import { RootState } from '../../../store';
import api from '../../../api';
import Unit from '../../../types/Unit';

const Conditions = (props: { type: string }): JSX.Element | null => {
  const dispatch = useDispatch();
  const defaultListItems: string[] = [];
  const [allPressureUnits, setAllPressureUnits] = useState(defaultListItems);
  const [allTemperatureUnits, setAllTemperatureUnits] = useState(defaultListItems);

  const db = useSelector((globalState: RootState) => globalState.db);
  const { defaults: dbDefaults, pressure } = db;

  const state = useSelector((globalState: RootState) => globalState);
  const { workingDefaults } = state;

  const { defaultProcessConditions } = workingDefaults;

  const { type } = props;

  const getDropDownData = (collection: string): string[] => {
    const table = api.getSpecifiedFeatureList(collection) as unknown as Unit[];
    const data = table.map((r) => {
      return r.abbrv;
    });

    return data;
  };

  const fillDropDowns = () => {
    setAllPressureUnits(getDropDownData('pressure'));
    setAllTemperatureUnits(getDropDownData('temperature'));
  };

  let subTitleText;
  let temperatureInput;
  let temperatureOptionSelected;
  let pressureInput;
  let pressureOptionSelected;

  const standardOrNormal = () => {
    subTitleText = `Standard conditions are used to calculate standard flow units. For
  example, standard cubic feet are calculated using the density at
  flowing conditions and the density at the standard conditions.`;

    temperatureInput = String(defaultProcessConditions.defaultStdConditions.dblTemperature);

    temperatureOptionSelected = String(defaultProcessConditions.defaultStdConditions.sTemperatureUnit);

    pressureInput = String(defaultProcessConditions.defaultStdConditions.dblPressure);

    pressureOptionSelected = String(defaultProcessConditions.defaultStdConditions.sPressureUnit);

    if (type.toUpperCase() === 'NORMAL') {
      subTitleText = `Normal conditions are used to calculate normal flow units. For
    example, normal cubic meters are calculated using the density at
    flowing conditions and the density at the normal conditions.`;

      temperatureInput = String(defaultProcessConditions.defaultNmlConditions.dblTemperature);

      temperatureOptionSelected = String(defaultProcessConditions.defaultNmlConditions.sTemperatureUnit);

      pressureInput = String(defaultProcessConditions.defaultNmlConditions.dblPressure);

      pressureOptionSelected = String(defaultProcessConditions.defaultNmlConditions.sPressureUnit);
    }
  };

  standardOrNormal();

  const tempInputChange = (e: any) => {
    workingDefaults.newValuesSaved = true;
    if (type.toUpperCase() === 'STANDARD') {
      workingDefaults.defaultProcessConditions.defaultStdConditions.dblTemperature = e.target.value;
    } else {
      workingDefaults.defaultProcessConditions.defaultNmlConditions.dblTemperature = e.target.value;
    }

    dispatch(actions.setWorkingDefaults(workingDefaults));
  };

  const pressureInputChange = (e: any) => {
    workingDefaults.newValuesSaved = true;
    if (type.toUpperCase() === 'STANDARD') {
      workingDefaults.defaultProcessConditions.defaultStdConditions.dblPressure = e.target.value;
    } else {
      workingDefaults.defaultProcessConditions.defaultNmlConditions.dblPressure = e.target.value;
    }

    dispatch(actions.setWorkingDefaults(workingDefaults));
  };

  const tempSelectChange = (e: any) => {
    workingDefaults.newValuesSaved = true;
    if (type.toUpperCase() === 'STANDARD') {
      workingDefaults.defaultProcessConditions.defaultStdConditions.sTemperatureUnit = e.target.value;
    } else {
      workingDefaults.defaultProcessConditions.defaultNmlConditions.sTemperatureUnit = e.target.value;
    }
    dispatch(actions.setWorkingDefaults(workingDefaults));
  };

  const pressureSelectChange = (e: any) => {
    workingDefaults.newValuesSaved = true;
    if (type.toUpperCase() === 'STANDARD') {
      workingDefaults.defaultProcessConditions.defaultStdConditions.sPressureUnit = e.target.value;
    } else {
      workingDefaults.defaultProcessConditions.defaultNmlConditions.sPressureUnit = e.target.value;
    }
    dispatch(actions.setWorkingDefaults(workingDefaults));
  };

  useEffect(() => {
    fillDropDowns();
  }, [dbDefaults, pressure]);

  return (
    <>
      <div className="modalTabsSubTitle">{subTitleText}</div>
      <div className="modalContentTabs">
        <div className="fieldRow">
          <span className="field">
            <div className="fieldTitle">{type} Temperature</div>
            <input defaultValue={temperatureInput} onChange={tempInputChange} />
            <span className="leftRight10" />
            <select
              value={temperatureOptionSelected}
              name="flowUnit1"
              className="dropSmall2 leftRight10"
              onChange={tempSelectChange}
            >
              {allTemperatureUnits.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
          </span>
        </div>
        <div className="fieldRow">
          <span className="field">
            <div className="fieldTitle">{type} Pressure</div>
            <input defaultValue={pressureInput} onChange={pressureInputChange} />
            <span className="leftRight10" />
            <select
              value={pressureOptionSelected}
              name="flowUnit1"
              className="dropSmall2 leftRight10"
              onChange={pressureSelectChange}
            >
              {allPressureUnits.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
          </span>
        </div>
      </div>
    </>
  );
};

export default Conditions;
