import Decimal from 'decimal.js';
import api from '../../api';
import constants from '../../constants';
import store from '../../store';
import actions from '../../store/actions';
import utilities from '../../utilities';
import application from '../../utilities/application';
import calc from '../../utilities/calc';
import convert from '../../utilities/convert';
import LiquidData from '../LiquidData';
import PipeData from '../PipeData';
import ProcessCondition from '../processCondition';
import Fluid from './Fluid';

export default class Liquid extends Fluid {
  protected dblCriticalTemperature: Decimal;
  protected dblCriticalPressure: Decimal;
  protected dblMolecularWeight: Decimal;
  protected dblCriticalZ: Decimal;
  protected dblTemperature1: Decimal;
  protected dblViscosity1: Decimal;
  protected dblTemperature2: Decimal;
  protected dblViscosity2: Decimal;
  protected dblA: Decimal;
  protected dblB: Decimal;

  constructor() {
    super();

    this.dblCriticalTemperature = constants.ZERO;
    this.dblCriticalPressure = constants.ZERO;
    this.dblMolecularWeight = constants.ZERO;
    this.dblCriticalZ = constants.ZERO;
    this.dblTemperature1 = constants.ZERO;
    this.dblViscosity1 = constants.ZERO;
    this.dblTemperature2 = constants.ZERO;
    this.dblViscosity2 = constants.ZERO;
    this.dblA = constants.ZERO;
    this.dblB = constants.ZERO;
  }

  findAllLiquids = (): LiquidData[] => {
    const state = store.getState();

    const res = api.readOneSync(true, {
      requestData: {
        client: state.sizingGlobals.VORTEK_NAME,
        username: state.username,
        collection: 'liquid',
      },
    });

    if (res.status !== 'fail') {
      const realres = [];
      for (let i = 0; i < res.data.data.length; i++) {
        realres.push({
          Tc: new Decimal(res.data.data[i].Tc),
          Pc: new Decimal(res.data.data[i].Pc),
          Mw: new Decimal(res.data.data[i].Mw),
          Zc: new Decimal(res.data.data[i].Zc),
          T1: new Decimal(res.data.data[i].T1),
          V1: new Decimal(res.data.data[i].V1),
          T2: new Decimal(res.data.data[i].T2),
          V2: new Decimal(res.data.data[i].V2),
        });
      }

      return realres;
    }

    return [] as LiquidData[];
  };

  findLiquid = (liquidName: string): LiquidData | undefined => {
    const state = store.getState();
    let item;

    const res = api.readOneSync(true, {
      requestData: {
        client: state.sizingGlobals.VORTEK_NAME,
        username: state.username,
        collection: 'liquid',
      },
    });

    if (res.status !== 'fail') {
      item = utilities.findInArray(res.data.data, liquidName);
      if (item) {
        item = {
          Tc: new Decimal(item.Tc),
          Pc: new Decimal(item.Pc),
          Mw: new Decimal(item.Mw),
          Zc: new Decimal(item.Zc),
          T1: new Decimal(item.T1),
          V1: new Decimal(item.V1),
          T2: new Decimal(item.T2),
          V2: new Decimal(item.V2),
        };
      }
    }

    return item;
  };

  goyalDensity = (dblTempRk: Decimal): Decimal => {
    // Based on the Goyal-Dorias equations.
    // Miller Chapt 2.

    // a1 var dblX = ((dblCriticalPressure * dblMolecularWeight) / dblCriticalTemperature) *
    // a2          ((0.008 /
    // a2            dblCriticalZ ^ 0.773) -
    // a3           (0.01102 *
    // a3            (TempRk / dblCriticalTemperature)));

    let a1 = this.dblCriticalPressure.times(this.dblMolecularWeight);
    a1 = a1.div(this.dblCriticalTemperature);

    let a2 = new Decimal(this.dblCriticalZ.toString());
    a2 = a2.pow('0.773'); // new Decimal("" + Math.pow(parseFloat(this.dblCriticalZ.toString()), 0.773));
    a2 = new Decimal(a2.toString());
    a2 = new Decimal('0.008').div(a2);

    let a3 = dblTempRk.div(this.dblCriticalTemperature);
    a3 = new Decimal('0.01102').times(a3);

    let dblX = a1.times(a2.minus(a3));

    // Multiply by the base water density.
    dblX = dblX.times(new Decimal('62.3663'));

    return dblX;
  };

  // This routine will calculate the steam density based upon the user entered process conditions.
  calculateDensity = (processCondition: ProcessCondition): ProcessCondition => {
    const state = store.getState();

    let dblTempRo;

    const dblTempRk = convert.temperatureTo('Rk', processCondition.dblTemperature, processCondition.sTemperatureUnit);

    dblTempRo = this.goyalDensity(dblTempRk);

    if (dblTempRo.lte(new Decimal('0'))) {
      if (!state.alertedNegative) {
        store.dispatch(actions.setAlertedNegative(true));
        store.dispatch(
          actions.setErrorMessage(
            'The calculated Density is negative; this is not physically possible.\n\n' +
              'Please check the values entered and the units.'
          )
        );
      }

      dblTempRo = dblTempRo.neg();
    }

    const newProcessCondition = { ...processCondition };

    newProcessCondition.dblDensityValue = dblTempRo;
    newProcessCondition.sDensityUnit = 'lb/ft^3';
    return newProcessCondition;
  };

  calculateViscosity = (processCondition: ProcessCondition): ProcessCondition => {
    // This routine will calculate the viscosity of the steam based upon user input.

    // Convert the temperature to Rk.
    const dblTempR = convert.temperatureTo('Rk', processCondition.dblTemperature, processCondition.sTemperatureUnit);

    // The following calculation will return the viscosity in cP.
    // From Miller pg 2-87, Andrade's Equation.
    let exp = this.dblB.div(dblTempR);

    exp = new Decimal(Decimal.exp(exp.toString()).toString());

    const newProcessCondition = { ...processCondition };

    newProcessCondition.dblViscosityValue = this.dblA.times(exp);
    newProcessCondition.sViscosityUnit = 'cP';
    return newProcessCondition;
  };

  calculateValues = (pipeData: PipeData, minNomMax: string): PipeData => {
    const state = store.getState();
    const res = this.findLiquid(state.fluid);
    if (res) {
      const liquid = res as unknown as LiquidData;
      this.dblCriticalTemperature = liquid.Tc;
      this.dblCriticalPressure = liquid.Pc;
      this.dblMolecularWeight = liquid.Mw;
      this.dblCriticalZ = liquid.Zc;
      this.dblTemperature1 = liquid.T1;
      this.dblViscosity1 = liquid.V1;
      this.dblTemperature2 = liquid.T2;
      this.dblViscosity2 = liquid.V2;
    }

    // Calculate the coefficients for Andrede's Equation.
    // dblB = (dblTemperature1 * dblTemperature2 * Math.Log(dblViscosity1 / dblViscosity2)) / (dblTemperature2 - dblTemperature1)
    let temp = this.dblViscosity1.div(this.dblViscosity2);
    temp = Decimal.ln(temp);
    this.dblB = this.dblTemperature1.times(this.dblTemperature2).times(temp);
    this.dblB = this.dblB.div(this.dblTemperature2.minus(this.dblTemperature1));

    temp = this.dblB.div(this.dblTemperature1);
    temp = Decimal.exp(temp);

    this.dblA = this.dblViscosity1.div(temp);

    let [newPipeData, cond] = application.collectApplicationData(pipeData, minNomMax);
    newPipeData.calcPressureMinVal = new Decimal(newPipeData.pressureMin.replace(',', ''));
    newPipeData.calcPressureNomVal = new Decimal(newPipeData.pressureNom.replace(',', ''));
    newPipeData.calcPressureMaxVal = new Decimal(newPipeData.pressureMax.replace(',', ''));
    newPipeData.calcTemperatureMinVal = new Decimal(newPipeData.temperatureMin.replace(',', ''));
    newPipeData.calcTemperatureNomVal = new Decimal(newPipeData.temperatureNom.replace(',', ''));
    newPipeData.calcTemperatureMaxVal = new Decimal(newPipeData.temperatureMax.replace(',', ''));

    // Calculate the application density.
    cond = this.calculateDensity(cond);
    newPipeData.isentropic = constants.ONE;
    newPipeData.densityUnit = cond.sDensityUnit;
    let tmp;
    let val;

    if (minNomMax === 'min') {
      newPipeData.calcDensityMinVal = cond.dblDensityValue;
      newPipeData.densityMin = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'min');
      newPipeData.densityMinDisplay = tmp;
      newPipeData.densityMinInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'nom') {
      newPipeData.calcDensityNomVal = cond.dblDensityValue;
      newPipeData.densityNom = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'nom');
      newPipeData.densityNomDisplay = tmp;
      newPipeData.densityNomInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'max') {
      newPipeData.calcDensityMaxVal = cond.dblDensityValue;
      newPipeData.densityMax = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'max');
      newPipeData.densityMaxDisplay = tmp;
      newPipeData.densityMaxInternalUnit = cond.sDensityUnit;
    }

    store.dispatch(
      actions.setStandardConditions(this.calculateDensity(state.defaults.defaultProcessConditions.standardConditions))
    );

    store.dispatch(actions.setNormalConditions(this.calculateDensity(state.defaults.defaultProcessConditions.normalConditions)));

    cond = this.calculateViscosity(cond);

    if (minNomMax === 'min') {
      newPipeData.calcViscosityMinVal = cond.dblViscosityValue;
      newPipeData.viscosityMin = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'min');
      newPipeData.viscosityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcViscosityNomVal = cond.dblViscosityValue;
      newPipeData.viscosityNom = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'nom');
      newPipeData.viscosityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcViscosityMaxVal = cond.dblViscosityValue;
      newPipeData.viscosityMax = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'max');
      newPipeData.viscosityMaxDisplay = tmp;
    }

    newPipeData.viscosityUnit = cond.sViscosityUnit;

    newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
    if (minNomMax === 'min') {
      newPipeData.calcVelocityMinVal = newPipeData.velocity;
      newPipeData.velocityMin = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'min');
      newPipeData.velocityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcVelocityNomVal = newPipeData.velocity;
      newPipeData.velocityNom = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'nom');
      newPipeData.velocityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcVelocityMaxVal = newPipeData.velocity;
      newPipeData.velocityMax = newPipeData.velocity.toString();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'max');
      newPipeData.velocityMaxDisplay = tmp;
    }

    cond.dblPressure = convert.pressureTo(
      `${newPipeData.originalPressurePrefix}-${newPipeData.originalPressureCoefficient}`,
      cond.dblPressure,
      cond.sPressureUnit,
      cond.sPressureGaugeAbsolute
    );
    newPipeData.pressurePrefix = newPipeData.originalPressurePrefix;
    newPipeData.pressureCoefficient = newPipeData.originalPressureCoefficient;

    if (minNomMax === 'min') {
      newPipeData.calcPressureMinVal = cond.dblPressure;
      newPipeData.pressureMin = cond.dblPressure.toString();
    } else if (minNomMax === 'nom') {
      newPipeData.calcPressureNomVal = cond.dblPressure;
      newPipeData.pressureNom = cond.dblPressure.toString();
    } else if (minNomMax === 'max') {
      newPipeData.calcPressureMaxVal = cond.dblPressure;
      newPipeData.pressureMax = cond.dblPressure.toString();
    }

    return newPipeData;
  };
}
