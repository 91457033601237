import Decimal from 'decimal.js';
import constants from '../../constants';
import store from '../../store';
import convert from '../convert';
import calc, { VortekMeters } from './index';
import calcTurbine from './calcTurbineMeter';
import calcVorCone from './calcVorCone';
import application from '../application';
import Meter from '../../types/Meter';
import pipeUtils from '../pipeUtils';
import utilities from '..';
import PipeData from '../../types/PipeData';
import calcMeter from './calcMeter';
import ProcessCondition from '../../types/processCondition';

const calcFlow = (
  currentMeter: Meter,
  pipeData: PipeData,
  minNomMax: string,
  useEnglishDensity: boolean
): [ProcessCondition, Meter, PipeData] => {
  const state = store.getState();
  const { meterType, sizingGlobals } = state;
  const { ElectromagneticProMModel } = sizingGlobals;

  /* eslint-disable-next-line */
  let [appPipeData, cond] = application.collectApplicationData(pipeData, minNomMax);
  const tmpDensityUnit = cond.sDensityUnit;
  cond.sDensityUnit = useEnglishDensity ? 'lb/ft^3' : tmpDensityUnit;

  appPipeData = calc.fluidCalculationVelocity(appPipeData, cond);
  cond.sDensityUnit = tmpDensityUnit;

  if (minNomMax === 'min') {
    appPipeData.velocityMin = appPipeData.velocity.toFixed(4);
    appPipeData.calcVelocityMinVal = appPipeData.velocity;
  } else if (minNomMax === 'nom') {
    appPipeData.velocityNom = appPipeData.velocity.toFixed(4);
    appPipeData.calcVelocityNomVal = appPipeData.velocity;
  } else if (minNomMax === 'max') {
    appPipeData.velocityMax = appPipeData.velocity.toFixed(4);
    appPipeData.calcVelocityMaxVal = appPipeData.velocity;
  }

  /* eslint-disable-next-line */
  let [newPipeData, cConditions] = application.collectApplicationData(appPipeData, minNomMax);
  let dblPipeVelocity;
  let newMeter = new Meter();

  if (currentMeter.type === 'MODEL_ProT' && minNomMax === 'min') {
    newMeter = calcTurbine.turbineVelocityMin(newPipeData, cond, currentMeter);
    dblPipeVelocity = newMeter.minturvel;
  } else if (currentMeter.type === 'MODEL_ProT' && minNomMax === 'max') {
    newMeter = calcTurbine.turbineVelocityMax(newPipeData, cond, currentMeter);
    dblPipeVelocity = newMeter.maxturvel;
  } else if (meterType === ElectromagneticProMModel && minNomMax === 'min') {
    newMeter = { ...currentMeter };
    dblPipeVelocity = new Decimal('0.98');
  } else if (meterType === ElectromagneticProMModel && minNomMax === 'max') {
    newMeter = { ...currentMeter };
    dblPipeVelocity = new Decimal('32.8');
  } else {
    newMeter = { ...currentMeter };
    dblPipeVelocity = constants.ONE;
    dblPipeVelocity = calcMeter.measuredVelocity(currentMeter.type, newPipeData, cConditions);
  }

  // Calculate the flow rate for this meter with the user inputs.
  cConditions.dblFlowRate = dblPipeVelocity.times(calc.areaTo('ft', newPipeData));
  cConditions.sFlowRateUnit = 'ft^3';
  cConditions.sFlowRateTime = 'sec';

  let dblDensity = constants.ZERO;
  if (minNomMax === 'min') {
    dblDensity = newPipeData.calcDensityMinVal;
  } else if (minNomMax === 'nom') {
    dblDensity = newPipeData.calcDensityNomVal;
  } else if (minNomMax === 'max') {
    dblDensity = newPipeData.calcDensityMaxVal;
  }

  // Convert the calculated flow rate in lb/ft^3 into the user defined flow rate.
  cConditions.dblFlowRate = convert.flowRateTo(
    cConditions.dblFlowRate,
    `${newPipeData.flowUnit}/${newPipeData.flowTime}`,
    cConditions.sFlowRateUnit,
    cConditions.sFlowRateTime,
    dblDensity,
    'lb/ft^3'
  );
  cConditions.sFlowRateUnit = newPipeData.flowUnit;
  cConditions.sFlowRateTime = newPipeData.flowTime;

  // Store the calculated meter information.
  return [cConditions, newMeter, newPipeData];
};

const maximumFlow = (currentMeter: Meter, pipeData: PipeData, useEnglishDensity: boolean): [ProcessCondition, Meter, PipeData] => {
  const state = store.getState();
  const { meterType, sizingGlobals } = state;
  const { ElectromagneticProMModel } = sizingGlobals;

  let meter = { ...currentMeter };
  /* eslint-disable-next-line */
  let [appPipeData, condMax] = application.collectApplicationData(pipeData, 'max');
  const tmpDensityUnit = condMax.sDensityUnit;
  condMax.sDensityUnit = useEnglishDensity ? 'lb/ft^3' : tmpDensityUnit;

  appPipeData = calc.fluidCalculationVelocity(appPipeData, condMax);
  condMax.sDensityUnit = tmpDensityUnit;
  appPipeData.velocityMax = appPipeData.velocity.toString();
  appPipeData.calcVelocityMaxVal = appPipeData.velocity;
  meter.maxflow = appPipeData.velocityMax;

  /* eslint-disable-next-line */
  let [newPipeData, cConditions] = application.collectApplicationData(appPipeData, 'max');

  if (meter.type === 'MODEL_ProT') {
    meter = calcTurbine.turbineVelocityMax(newPipeData, cConditions, meter);
  }

  // Calculate the maximum pipe velocity based upon the minimum user inputs.
  let dblMaxReCalc = new Decimal('15000000.0');
  const dblInsideDiameter = calc.idTo(newPipeData.pipeUnit, new Decimal(newPipeData.pipeInsideDiameter), 'in');

  if (newPipeData.fluidType === 'Liquid' || newPipeData.fluidType === 'Water' || newPipeData.fluidType === 'Other Liquid') {
    if (dblMaxReCalc.greaterThan(constants.MAX_LIQUID_VEL) || meter.type === 'Insertion' || meter.type === 'Clamp On') {
      dblMaxReCalc = constants.MAX_LIQUID_VEL;
    }

    if (meterType === ElectromagneticProMModel) {
      dblMaxReCalc = new Decimal('32.8');
    } else if (meter.type === 'MODEL_ProT') {
      dblMaxReCalc = meter.maxturvel;
    }
  } else {
    if (dblMaxReCalc.greaterThan(constants.MAX_GAS_VEL) || meter.type === 'Insertion' || meter.type === 'Clamp On') {
      dblMaxReCalc = constants.MAX_GAS_VEL;
    }

    if (meter.type === 'MODEL_ProT') {
      dblMaxReCalc = meter.maxturvel;
    }

    if (dblInsideDiameter.lte(new Decimal('0.958'))) {
      dblMaxReCalc = constants.MAX_1_GAS_VEL;
    }

    if (dblInsideDiameter.lte(new Decimal('0.743'))) {
      dblMaxReCalc = constants.MAX_3_4_GAS_VEL;
    }

    if (dblInsideDiameter.lte(new Decimal('0.547'))) {
      dblMaxReCalc = constants.MAX_1_2_GAS_VEL;
    }
  }

  // Calculate the flow rate for this meter with the user inputs.
  cConditions.dblFlowRate = dblMaxReCalc.times(calc.idTo(newPipeData.pipeUnit, new Decimal(newPipeData.pipeInsideDiameter), 'ft'));
  cConditions.sFlowRateUnit = 'ft^3';
  cConditions.sFlowRateTime = 'sec';

  // Convert the calculated flow rate in ft^3/sec into the user defined flow rate.
  // var fps = convert.flowRateTo(new Decimal("5733.714"), "ft^3/sec", "kg", "hr", cConditions.dblDensityValue, cConditions.sDensityUnit);
  cConditions.dblFlowRate = convert.flowRateTo(
    cConditions.dblFlowRate,
    `${newPipeData.flowUnit}/${newPipeData.flowTime}`,
    cConditions.sFlowRateUnit,
    cConditions.sFlowRateTime,
    cConditions.dblDensityValue,
    'lb/ft^3'
  );

  cConditions.sFlowRateUnit = newPipeData.flowUnit;
  cConditions.sFlowRateTime = newPipeData.flowTime;

  meter.maxflow = cConditions.dblFlowRate.toString();
  meter.maxFlowUnit = `${cConditions.sFlowRateUnit}/${cConditions.sFlowRateTime}`;
  return [cConditions, meter, newPipeData];
};

const deltaPressure = (currentMeter: Meter, pipeData: PipeData): [Meter, PipeData] => {
  const state = store.getState();
  const { defaults } = state;
  const { defaultUnits } = defaults;
  const { outputPressureDropUnit } = defaultUnits;

  const meter = { ...currentMeter };
  // Calculate the pressure drop across the meter.
  let dblPressureDrop;
  const [newPipeData, condMax] = application.collectApplicationData(pipeData, 'max');

  // Calculate the area of the pipe.  The units will be in inches.
  // cPressureDrop.PipeAreaValue = CONST_PI * ((ConvertPipeID_To("in", cPressureDrop) / 2) ^ 2)
  const convertedPipe = convert.pipeInsideDiameter_To('in', newPipeData.pipeInsideDiameter, newPipeData.pipeUnit);
  const pipeDiv2 = convertedPipe.div(new Decimal('2'));
  const area = constants.PI.times(pipeDiv2.times(pipeDiv2));

  // Calculate the pressure drop.
  if ((meter.type === 'Insertion' || meter.type === 'MODEL_ProT') && area.gt(new Decimal('25.0'))) {
    dblPressureDrop = new Decimal('0.0');
  } else {
    // Calculate the pressure drop across the meter.  The units will be in PSI-A.
    const const24 = new Decimal('0.00024');
    const convertedDensity = newPipeData.calcDensityMaxVal;
    const velocityPow2 = newPipeData.calcVelocityMaxVal.pow(new Decimal('2'));
    dblPressureDrop = const24.times(convertedDensity).times(velocityPow2);
  }

  // Convert the pressure drop to the default values.
  condMax.dblPressure = dblPressureDrop;
  condMax.sPressureUnit = 'psi';
  condMax.sPressureGaugeAbsolute = 'A';
  dblPressureDrop = convert.pressure(`${outputPressureDropUnit}-A`, condMax.dblPressure, condMax.sPressureUnit);

  meter.pressdrop = dblPressureDrop.toFixed(4);
  meter.pressdropunits = 'psi-A';
  return [meter, newPipeData];
};

// const setFlowWarning = (currentMeter: Meter, lowHigh: string, minNomMax: string) => {
//   const meter = { ...currentMeter };
//   if (minNomMax === 'min') {
//     meter.minflowwarn = `Flow ${lowHigh}`;
//   } else if (minNomMax === 'max') {
//     meter.maxflowwarn = `Flow ${lowHigh}`;
//   }

//   meter.usable = 'Less Suitable';
//   return meter;
// };

const setVelocityWarning = (currentMeter: Meter, lowHigh: string, minNomMax: string) => {
  const meter = { ...currentMeter };
  if (minNomMax === 'min') {
    meter.minvelowarn = `Velocity ${lowHigh}`;
  } else if (minNomMax === 'nom') {
    meter.nomvelowarn = `Velocity ${lowHigh}`;
  } else if (minNomMax === 'max') {
    meter.maxvelowarn = `Velocity ${lowHigh}`;
  }

  meter.usable = 'Less Suitable';
  return meter;
};

const checkVelocity = (currentMeter: Meter, pipeData: PipeData, minNomMax: string) => {
  const state = store.getState();
  const { meterType, sizingGlobals } = state;
  // const { meterType, processConditions, sizingGlobals } = state;
  // const { densityMax, densityMin, densityUnit, flowTime, flowUnit } = processConditions;
  const {
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    ElectromagneticProMModel,
  } = sizingGlobals;

  let dblVelocity = constants.ZERO;
  if (minNomMax === 'min') {
    dblVelocity = pipeData.calcVelocityMinVal;
  } else if (minNomMax === 'nom') {
    dblVelocity = pipeData.calcVelocityNomVal;
  } else if (minNomMax === 'max') {
    dblVelocity = pipeData.calcVelocityMaxVal;
  }

  let meter = { ...currentMeter };
  dblVelocity = convert.velocityTo('ft/sec', dblVelocity, pipeData.velocityUnit);
  dblVelocity = new Decimal(dblVelocity.toFixed(4));
  const convertedPipe = convert.pipeInsideDiameter_To('in', pipeData.pipeInsideDiameter, pipeData.pipeUnit);

  if (
    pipeData.fluidType === constants.NAME_LIQUID ||
    pipeData.fluidType === constants.NAME_WATER ||
    pipeData.fluidType === constants.NAME_OTHER_LIQUID
  ) {
    if (meterType === ElectromagneticProMModel) {
      if (dblVelocity.gt(constants.PRO_M_MAX_VEL)) {
        meter = setVelocityWarning(meter, 'High', minNomMax);
      } else if (dblVelocity.lt(constants.PRO_M_MIN_VEL)) {
        meter = setVelocityWarning(meter, 'Low', minNomMax);
      }

      // let flow = constants.ZERO;
      // if (minNomMax === 'min') {
      //   // eslint-disable-next-line prefer-destructuring
      //   flow = new Decimal(meter.minflow.split(' ')[0]);
      //   flow = convert.flowRateTo(flow, 'gal/min', flowUnit, flowTime, new Decimal(densityMin), densityUnit);
      //   if (
      //     (convertedPipe.lte(new Decimal('0.622')) && flow.lt(constants.PRO_M_MIN_GPM_0_5)) ||
      //     (convertedPipe.lte(new Decimal('0.824')) && flow.lt(constants.PRO_M_MIN_GPM_0_75)) ||
      //     (convertedPipe.lte(new Decimal('1.049')) && flow.lt(constants.PRO_M_MIN_GPM_1_0)) ||
      //     (convertedPipe.lte(new Decimal('1.61')) && flow.lt(constants.PRO_M_MIN_GPM_1_5)) ||
      //     (convertedPipe.lte(new Decimal('2.067')) && flow.lt(constants.PRO_M_MIN_GPM_2_0)) ||
      //     (convertedPipe.lte(new Decimal('3.068')) && flow.lt(constants.PRO_M_MIN_GPM_3_0)) ||
      //     (convertedPipe.lte(new Decimal('4.026')) && flow.lt(constants.PRO_M_MIN_GPM_4_0)) ||
      //     (convertedPipe.lte(new Decimal('6.065')) && flow.lt(constants.PRO_M_MIN_GPM_6_0)) ||
      //     (convertedPipe.lte(new Decimal('7.981')) && flow.lt(constants.PRO_M_MIN_GPM_8_0)) ||
      //     (convertedPipe.lte(new Decimal('10.02')) && flow.lt(constants.PRO_M_MIN_GPM_10_0)) ||
      //     (convertedPipe.lte(new Decimal('11.938')) && flow.lt(constants.PRO_M_MIN_GPM_12_0)) ||
      //     (convertedPipe.lte(new Decimal('13.124')) && flow.lt(constants.PRO_M_MIN_GPM_14_0)) ||
      //     (convertedPipe.lte(new Decimal('15.0')) && flow.lt(constants.PRO_M_MIN_GPM_16_0)) ||
      //     (convertedPipe.lte(new Decimal('16.876')) && flow.lt(constants.PRO_M_MIN_GPM_18_0)) ||
      //     (convertedPipe.lte(new Decimal('18.812')) && flow.lt(constants.PRO_M_MIN_GPM_20_0)) ||
      //     (convertedPipe.lte(new Decimal('22.624')) && flow.lt(constants.PRO_M_MIN_GPM_24_0))
      //   ) {
      //     meter = setFlowWarning(meter, 'Low', minNomMax);
      //   }
      // } else if (minNomMax === 'max') {
      //   // eslint-disable-next-line prefer-destructuring
      //   flow = new Decimal(meter.maxflow.split(' ')[0]);
      //   flow = convert.flowRateTo(flow, 'gal/min', flowUnit, flowTime, new Decimal(densityMax), densityUnit);
      //   if (
      //     (convertedPipe.lte(new Decimal('0.622')) && flow.gt(constants.PRO_M_MAX_GPM_0_5)) ||
      //     (convertedPipe.lte(new Decimal('0.824')) && flow.gt(constants.PRO_M_MAX_GPM_0_75)) ||
      //     (convertedPipe.lte(new Decimal('1.049')) && flow.gt(constants.PRO_M_MAX_GPM_1_0)) ||
      //     (convertedPipe.lte(new Decimal('1.61')) && flow.gt(constants.PRO_M_MAX_GPM_1_5)) ||
      //     (convertedPipe.lte(new Decimal('2.067')) && flow.gt(constants.PRO_M_MAX_GPM_2_0)) ||
      //     (convertedPipe.lte(new Decimal('3.068')) && flow.gt(constants.PRO_M_MAX_GPM_3_0)) ||
      //     (convertedPipe.lte(new Decimal('4.026')) && flow.gt(constants.PRO_M_MAX_GPM_4_0)) ||
      //     (convertedPipe.lte(new Decimal('6.065')) && flow.gt(constants.PRO_M_MAX_GPM_6_0)) ||
      //     (convertedPipe.lte(new Decimal('7.981')) && flow.gt(constants.PRO_M_MAX_GPM_8_0)) ||
      //     (convertedPipe.lte(new Decimal('10.02')) && flow.gt(constants.PRO_M_MAX_GPM_10_0)) ||
      //     (convertedPipe.lte(new Decimal('11.938')) && flow.gt(constants.PRO_M_MAX_GPM_12_0)) ||
      //     (convertedPipe.lte(new Decimal('13.124')) && flow.gt(constants.PRO_M_MAX_GPM_14_0)) ||
      //     (convertedPipe.lte(new Decimal('15.0')) && flow.gt(constants.PRO_M_MAX_GPM_16_0)) ||
      //     (convertedPipe.lte(new Decimal('16.876')) && flow.gt(constants.PRO_M_MAX_GPM_18_0)) ||
      //     (convertedPipe.lte(new Decimal('18.812')) && flow.gt(constants.PRO_M_MAX_GPM_20_0)) ||
      //     (convertedPipe.lte(new Decimal('22.624')) && flow.gt(constants.PRO_M_MAX_GPM_24_0))
      //   ) {
      //     meter = setFlowWarning(meter, 'High', minNomMax);
      //   }
      // }
    } else if (meterType === 'turbine') {
      if (meter.maxturvel && dblVelocity.gt(meter.maxturvel)) {
        meter = setVelocityWarning(meter, 'High', minNomMax);
      } else if (meter.minturvel && dblVelocity.lt(meter.minturvel)) {
        meter = setVelocityWarning(meter, 'Low', minNomMax);
      }
    } else if (dblVelocity.gt(constants.MAX_LIQUID_VEL)) {
      meter = setVelocityWarning(meter, 'High', minNomMax);
    } else if (
      meterType === UltrasonicS34Model ||
      meterType === UltrasonicS36Model ||
      meterType === UltrasonicU42Model ||
      meterType === UltrasonicU43Model ||
      meterType === UltrasonicU44Model
    ) {
      if (dblVelocity.lt(new Decimal('1'))) {
        meter = setVelocityWarning(meter, 'Low', minNomMax);
      }
    }
  } else if (meterType === 'turbine') {
    if (meter.maxturvel && dblVelocity.gt(meter.maxturvel)) {
      meter = setVelocityWarning(meter, 'High', minNomMax);
    } else if (meter.minturvel && dblVelocity.lt(meter.minturvel)) {
      meter = setVelocityWarning(meter, 'Low', minNomMax);
    }
  } else if (
    dblVelocity.gt(constants.MAX_GAS_VEL) ||
    (convertedPipe.lte(new Decimal('1.51')) && dblVelocity.gt(constants.MAX_1_5_GAS_VEL)) ||
    (convertedPipe.lte(new Decimal('0.958')) && dblVelocity.gt(constants.MAX_1_GAS_VEL)) ||
    (convertedPipe.lte(new Decimal('0.743')) && dblVelocity.gt(constants.MAX_3_4_GAS_VEL)) ||
    (convertedPipe.lte(new Decimal('0.547')) && dblVelocity.gt(constants.MAX_1_2_GAS_VEL))
  ) {
    meter = setVelocityWarning(meter, 'High', minNomMax);
  } else {
    const density = pipeData.calcDensityMinVal;
    let dblRoVSquared = dblVelocity.times(dblVelocity);
    dblRoVSquared = dblRoVSquared.times(density);

    if (dblRoVSquared.lt(new Decimal('25'))) {
      meter = setVelocityWarning(meter, 'Low', minNomMax);
    }
  }

  return meter;
};

const loadValues = (currentMeter: Meter, type: string, pipeData: PipeData, vMeters: VortekMeters): [Meter, PipeData] => {
  const state = store.getState();
  const { defaults, meterType, processConditions, reducingVorcone, sizingGlobals, vorconeActive } = state;
  const { defaultUnits } = defaults;
  const { pressureDropUnit, timeUnit, velocityUnit } = defaultUnits;
  const { VORTEK_NAME } = sizingGlobals;
  const { flowUnit, flowTime } = processConditions;

  const {
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    ElectromagneticProMModel,
  } = sizingGlobals;

  let newPipeData = { ...pipeData };
  let meter = { ...currentMeter };
  let cMinConditions;
  let cNomConditions;
  let cMaxConditions;

  [cMinConditions, meter, newPipeData] = calcFlow(meter, newPipeData, 'min', true);
  meter.minflow = cMinConditions.dblFlowRate.toString();
  meter.minFlowUnit = `${cMinConditions.sFlowRateUnit}/${cMinConditions.sFlowRateTime}`;

  [cNomConditions, meter, newPipeData] = calcFlow(meter, newPipeData, 'nom', true);
  meter.nomflow = cNomConditions.dblFlowRate.toString();
  meter.nomFlowUnit = `${cNomConditions.sFlowRateUnit}/${cNomConditions.sFlowRateTime}`;

  [cMaxConditions, meter, newPipeData] = maximumFlow(meter, newPipeData, true);

  if (
    meter.type === 'Insertion' ||
    meterType === UltrasonicS34Model ||
    meterType === UltrasonicS36Model ||
    meterType === UltrasonicU42Model ||
    meterType === UltrasonicU43Model ||
    meterType === UltrasonicU44Model
  ) {
    const tmpPipeID = newPipeData.pipeInsideDiameter;
    const tmpPipeUnit = newPipeData.pipeUnit;
    if (
      meterType !== UltrasonicS34Model &&
      meterType !== UltrasonicS36Model &&
      meterType !== UltrasonicU42Model &&
      meterType !== UltrasonicU43Model &&
      meterType !== UltrasonicU44Model
    ) {
      newPipeData.pipeInsideDiameter = constants.INSERTION_ID.toString();
      newPipeData.pipeUnit = 'in';
    }

    meter.minrey = vMeters
      .calculateReynolds(
        newPipeData.calcVelocityMinVal,
        newPipeData.pipeInsideDiameter,
        newPipeData.calcDensityMinVal,
        newPipeData.calcViscosityMinVal,
        newPipeData
      )
      .toFixed(4);

    meter.nomrey = vMeters
      .calculateReynolds(
        newPipeData.calcVelocityNomVal,
        newPipeData.pipeInsideDiameter,
        newPipeData.calcDensityNomVal,
        newPipeData.calcViscosityNomVal,
        newPipeData
      )
      .toFixed(4);

    meter.maxrey = vMeters
      .calculateReynolds(
        newPipeData.calcVelocityMaxVal,
        newPipeData.pipeInsideDiameter,
        newPipeData.calcDensityMaxVal,
        newPipeData.calcViscosityMaxVal,
        newPipeData
      )
      .toFixed(4);

    newPipeData.pipeInsideDiameter = tmpPipeID;
    newPipeData.pipeUnit = tmpPipeUnit;
  } else if (
    meter.type === 'In-line' ||
    type === 'In-line Reducing' ||
    meter.type === 'MODEL_ProT' ||
    meterType === ElectromagneticProMModel
  ) {
    meter.minrey = vMeters
      .calculateReynolds(
        newPipeData.calcVelocityMinVal,
        newPipeData.pipeInsideDiameter,
        newPipeData.calcDensityMinVal,
        newPipeData.calcViscosityMinVal,
        newPipeData
      )
      .toFixed(4);

    meter.nomrey = vMeters
      .calculateReynolds(
        newPipeData.calcVelocityNomVal,
        newPipeData.pipeInsideDiameter,
        newPipeData.calcDensityNomVal,
        newPipeData.calcViscosityNomVal,
        newPipeData
      )
      .toFixed(4);

    meter.maxrey = vMeters
      .calculateReynolds(
        newPipeData.calcVelocityMaxVal,
        newPipeData.pipeInsideDiameter,
        newPipeData.calcDensityMaxVal,
        newPipeData.calcViscosityMaxVal,
        newPipeData
      )
      .toFixed(4);
  }

  [cMinConditions, meter, newPipeData] = calcFlow(meter, newPipeData, 'min', true);
  meter.minflow = cMinConditions.dblFlowRate.toString();
  meter.minFlowUnit = `${cMinConditions.sFlowRateUnit}/${cMinConditions.sFlowRateTime}`;

  [cNomConditions, meter, newPipeData] = calcFlow(meter, newPipeData, 'nom', true);
  meter.nomflow = cNomConditions.dblFlowRate.toString();
  meter.nomFlowUnit = `${cNomConditions.sFlowRateUnit}/${cNomConditions.sFlowRateTime}`;

  [cMaxConditions, meter, newPipeData] = maximumFlow(meter, newPipeData, true);

  [meter, newPipeData] = deltaPressure(meter, newPipeData);

  const minflow = new Decimal(meter.minflow);
  const maxflow = new Decimal(meter.maxflow);

  const flowLabel = `${flowUnit}/${flowTime}`;
  const velocityLabel = `${velocityUnit}/${timeUnit}`;
  const pressdropLabel = pressureDropUnit + (VORTEK_NAME === utilities.AZBIL_NAME ? '' : '');

  let minvelo = newPipeData.calcVelocityMinVal;
  minvelo = convert.velocityTo(velocityLabel, minvelo, newPipeData.velocityUnit);
  meter.minvel = `${minvelo.toFixed(4)} ${velocityLabel}`;

  let nomvelo = newPipeData.calcVelocityNomVal;
  nomvelo = convert.velocityTo(velocityLabel, nomvelo, newPipeData.velocityUnit);
  meter.nomvel = `${nomvelo.toFixed(4)} ${velocityLabel}`;

  let maxvelo = newPipeData.calcVelocityMaxVal;
  maxvelo = convert.velocityTo(velocityLabel, maxvelo, newPipeData.velocityUnit);
  meter.maxvel = `${maxvelo.toFixed(4)} ${velocityLabel}`;

  if (vorconeActive || reducingVorcone) {
    meter = calcVorCone.vorconeDpFlowMin(newPipeData, cMinConditions, meter);
    meter = calcVorCone.vorconeDpFlowMax(newPipeData, cMaxConditions, meter);

    if (meter.mindpval.lt(constants.ONE)) {
      meter.mindpwarn = 'Low DP';
    }

    if (meter.maxdpval.lt(constants.ONE)) {
      meter.maxdpwarn = 'Low DP';
    }

    if (newPipeData.fluidType !== 'Liquid' && newPipeData.fluidType !== 'Water' && newPipeData.fluidType !== 'Other Liquid') {
      let pressurePa = convert.pressureTo(
        'Pa',
        cMinConditions.dblPressure,
        cMinConditions.sPressureUnit,
        cMinConditions.sPressureGaugeAbsolute
      );
      const mindpval = meter.mindpval.div(new Decimal('0.00401865'));
      let p2 = pressurePa.minus(mindpval);
      if (p2.div(pressurePa).lt(new Decimal('0.75'))) {
        if (meter.mindpwarn.length) {
          meter.mindpwarn += ', ';
        }
        meter.mindpwarn += 'Low Expansion Factor';
      }

      pressurePa = convert.pressureTo(
        'Pa',
        cMaxConditions.dblPressure,
        cMaxConditions.sPressureUnit,
        cMaxConditions.sPressureGaugeAbsolute
      );
      const maxdpval = meter.maxdpval.div(new Decimal('0.00401865'));
      p2 = pressurePa.minus(maxdpval);
      if (p2.div(pressurePa).lt(new Decimal('0.75'))) {
        if (meter.maxdpwarn.length) {
          meter.maxdpwarn += ', ';
        }
        meter.maxdpwarn += 'Low Expansion Factor';
      }
    }

    meter.mindp = `${meter.mindp} INWC`;
    meter.maxdp = `${meter.maxdp} INWC`;

    let maxveloms = newPipeData.calcVelocityMaxVal;
    maxveloms = convert.velocityTo('m/sec', maxveloms, newPipeData.velocityUnit);

    const densityKgM3 = convert.densityTo('kg/m^3', cMaxConditions.dblDensityValue, 'lb/ft^3');
    const maxvelo2 = maxveloms.pow(new Decimal(2));
    const v766 = new Decimal('0.766');
    const v05 = new Decimal('0.5');
    const betaDec = meter.beta ? new Decimal(meter.beta) : constants.ZERO;
    let maxpressloss = betaDec.pow(new Decimal('4.5'));
    maxpressloss = v766.div(maxpressloss);
    const temp = v05.times(densityKgM3).times(maxvelo2);
    maxpressloss = maxpressloss.times(temp);
    meter.maxpressloss = maxpressloss.toFixed(6);
    maxpressloss = convert.pressure(pressdropLabel, new Decimal(meter.maxpressloss), 'Pa');
    meter.maxpressloss = `${maxpressloss.toFixed(4)} ${pressdropLabel}`;
  }

  meter.minflow = `${minflow.toFixed(4)} ${flowLabel}`;
  meter.nomflow = `${new Decimal(meter.nomflow).toFixed(4)} ${flowLabel}`;
  meter.maxflow = `${maxflow.toFixed(4)} ${flowLabel}`;
  meter.usable = 'Good';

  const pressdrop = convert.pressure(pressdropLabel, new Decimal(meter.pressdrop), 'psi');
  meter.pressdrop = `${pressdrop.toFixed(4)} ${pressdropLabel}`;

  // Per-meter warning checks

  // Check min and max flow against calculated values unless Pro-M
  if (new Decimal(newPipeData.flowMax).gt(maxflow)) {
    meter.usable = 'Less Suitable';
    meter.maxflowwarn = 'Entered maximum greater than meter maximum';
  }

  if (new Decimal(newPipeData.flowMin).lt(minflow)) {
    meter.usable = 'Less Suitable';
    meter.minflowwarn = 'Entered minimum less than meter minimum';
  }

  // Check min and max velocity against standards
  // Convert the velocity to ft/sec.
  meter = checkVelocity(meter, newPipeData, 'min');
  meter = checkVelocity(meter, newPipeData, 'nom');
  meter = checkVelocity(meter, newPipeData, 'max');

  // Check min and max reynolds against standards
  const convertedPipe = convert.pipeInsideDiameter_To('in', newPipeData.pipeInsideDiameter, newPipeData.pipeUnit);
  const minrey = new Decimal(meter.minrey);
  if (
    meterType === UltrasonicS34Model ||
    meterType === UltrasonicS36Model ||
    meterType === UltrasonicU42Model ||
    meterType === UltrasonicU43Model ||
    meterType === UltrasonicU44Model
  ) {
    if (minrey.lt(new Decimal('478.0'))) {
      meter.minreynwarn = 'Below minimum Reynolds value';
      meter.usable = 'Less Suitable';
    } else if (minrey.gt(new Decimal('15000000.0'))) {
      meter.minreynwarn = 'High Reynolds value';
    }
  } else if (
    ((meter.type === 'In-line' || meter.type === 'In-line Reducing') && convertedPipe.lt(new Decimal('3.0'))) ||
    meter.type === 'Insertion' ||
    meterType === ElectromagneticProMModel
  ) {
    if (minrey.lt(new Decimal('5000.0'))) {
      meter.minreynwarn = 'Below minimum Reynolds value';
      meter.usable = 'Less Suitable';
    } else if (minrey.lt(new Decimal('20000.0'))) {
      meter.minreynwarn = 'Low Reynolds value';
    } else if (minrey.gt(new Decimal('15000000.0'))) {
      meter.minreynwarn = 'High Reynolds value';
    }
  } else {
    // Inline larger than 3 inches.
    /* eslint-disable-next-line */
    if (minrey.lt(new Decimal('5000.0'))) {
      meter.minreynwarn = 'Below minimum Reynolds value';
      meter.usable = 'Less Suitable';
    } else if (minrey.lt(new Decimal('20000.0'))) {
      meter.minreynwarn = 'Low Reynolds value';
    } else if (minrey.gt(new Decimal('15000000.0'))) {
      meter.minreynwarn = 'High Reynolds value';
    }
  }

  if (meter.type === 'In-line' || type === 'In-line Reducing' || meterType === ElectromagneticProMModel) {
    // Insertion meters do not have a maximum Reynolds number.
    if (minrey.gt(new Decimal('15000000.0'))) {
      meter.minreynwarn = 'High Reynolds Value';
      meter.usable = 'Less Suitable';
    }
  }

  meter.minrey = new Decimal(meter.minrey).toFixed(4);
  meter.maxrey = new Decimal(meter.maxrey).toFixed(4);

  return [meter, newPipeData];
};

const useId = (pipeData: PipeData, id: string, unit: string, meter: Meter, type: string, vMeters: any): [Meter, PipeData] => {
  const state = store.getState();
  const newPipeData = { ...pipeData };
  newPipeData.pipeInsideDiameter = id;
  newPipeData.pipeUnit = unit;
  if (state.pipeType === 'din' && meter.name.indexOf('-') === -1) {
    newPipeData.pipeSize = `${meter.name} ${unit}`;
  } else {
    newPipeData.pipeSize = meter.name;
  }

  return loadValues(meter, type, newPipeData, vMeters);
};

const createMeter = (
  name: string,
  type: string,
  diameter: string,
  size: string,
  unit: string,
  wall: string | undefined,
  schedule: string,
  insideArea: Decimal,
  insideUnit: string,
  beta?: string
): Meter => {
  const state = store.getState();
  const { meterType, pipeType, sizingGlobals } = state;
  const { VORTEK_NAME, ElectromagneticProMModel } = sizingGlobals;

  let meter: Meter = {
    name,
    diameter,
    type,
    size,
    unit,
    wall,
    schedule,
    insidearea: insideArea,
    insideunit: insideUnit,
    usable: 'usuable',
    minflow: 'minflow',
    minFlowUnit: '',
    nomflow: 'nomflow',
    nomFlowUnit: '',
    maxflow: 'maxflow',
    maxFlowUnit: '',
    maxpressloss: '0',
    pressdrop: 'pressdrop',
    pressdropunits: '',
    minrey: 'minrey',
    nomrey: 'nomrey',
    maxrey: 'maxrey',
    minvel: 'minvel',
    nomvel: 'nomvel',
    maxvel: 'maxvel',
    minflowwarn: '',
    maxflowwarn: '',
    mindpwarn: '',
    maxdpwarn: '',
    pressdropwarn: '',
    headlosswarn: '',
    minreynwarn: '',
    maxreynwarn: '',
    minvelowarn: '',
    nomvelowarn: '',
    maxvelowarn: '',
    minturvel: constants.ZERO,
    maxturvel: constants.ZERO,
    beta,
    mindp: '',
    mindpval: constants.ZERO,
    maxdp: '',
    maxdpval: constants.ZERO,
    newPipeData: constants.defaultPipeData,
  };

  let newPipeData = pipeUtils.gatherSelectedPipeData(diameter, size, unit, schedule, insideArea, insideUnit, beta);

  const vMeters = new VortekMeters();
  let osd;
  let pipeId;

  if (type === 'In-line' || type === 'In-line Reducing' || type === 'MODEL_ProT' || meterType === ElectromagneticProMModel) {
    pipeId = meter.size;
    [meter, newPipeData] = useId(newPipeData, pipeId, unit, meter, type, vMeters);
  } else if (pipeType === 'other') {
    pipeId = meter.size;
    [meter, newPipeData] = useId(newPipeData, pipeId, unit, meter, type, vMeters);
  } else if (type === 'Insertion' || type === 'Clamp On') {
    let j;
    osd = meter.name;
    if (pipeType === 'jis') {
      if (newPipeData.pipeSchedule === meter.schedule) {
        j = pipeUtils.findJisPipe(VORTEK_NAME, name, meter.schedule);
      }

      if (j) {
        [meter, newPipeData] = useId(newPipeData, j.InsideDiameter, unit, meter, type, vMeters);
      }
    } else {
      if (pipeType === 'din' && meter.name.indexOf('-') === -1) {
        osd += ' mm';
      } else if (newPipeData.pipeSchedule) {
        osd += newPipeData.pipeSchedule;
      }

      j = pipeUtils.findPipes(osd);

      if (j) {
        [meter, newPipeData] = useId(newPipeData, j.data[3], unit, meter, type, vMeters);
      }
    }
  } else {
    [meter, newPipeData] = loadValues(meter, type, newPipeData, vMeters);
  }

  meter.newPipeData = { ...newPipeData };
  return meter;
};

export default {
  createMeter,
};
