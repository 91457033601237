import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../api';
import { RootState } from '../../store';
import actions from '../../store/actions';

function VortekAgGrid(props: { collection: string; showResetMessage: any }): JSX.Element {
  const dispatch = useDispatch();
  const { collection, showResetMessage } = props;
  let gridApi: any;
  const state = useSelector((globalState: RootState) => globalState);
  const [rowData, setRowData] = useState();
  const {
    username,
    sizingGlobals,
    flowTableWorking,
    densityTableWorking,
    viscosityTableWorking,
    lengthTableWorking,
    timeTableWorking,
    temperatureTableWorking,
    pressureTableWorking,
    workingDefaults,
  } = state;

  const { VORTEK_NAME } = sizingGlobals;

  const getDataFromDb = () => {
    const res = api.readOneSync(true, {
      requestData: {
        client: VORTEK_NAME,
        username,
        collection,
      },
    });

    if (res.status !== 'fail' && !_.isEmpty(res.data.data)) {
      return res.data.data;
    }

    res.data.data = [];
    return res.data.data;
  };

  const getWorkingDataFromRedux = () => {
    let data;
    switch (collection) {
      case 'flow':
        data = flowTableWorking;
        break;
      case 'density':
        data = densityTableWorking;
        break;
      case 'viscosity':
        data = viscosityTableWorking;
        break;
      case 'length':
        data = lengthTableWorking;
        break;
      case 'time':
        data = timeTableWorking;
        break;
      case 'temperature':
        data = temperatureTableWorking;
        break;
      case 'pressure':
        data = pressureTableWorking;
        break;
      default:
        break;
    }

    // for some reason, the first tab needs this
    if (!data) {
      data = getDataFromDb();
    }

    return data as any;
  };

  const onGridReady = useCallback((params) => {
    const data = getWorkingDataFromRedux();
    if (!_.isEmpty(data)) {
      setRowData(data);
    }

    gridApi = params.api;
  }, []);

  const dispatchTableData = (tableData: any) => {
    switch (collection) {
      case 'flow':
        dispatch(actions.setTableFlowWorking(tableData));
        break;
      case 'density':
        dispatch(actions.setTableDensityWorking(tableData));
        break;
      case 'viscosity':
        dispatch(actions.setTableViscosityWorking(tableData));
        break;
      case 'length':
        dispatch(actions.setTableLengthWorking(tableData));
        break;
      case 'time':
        dispatch(actions.setTableTimeWorking(tableData));
        break;
      case 'temperature':
        dispatch(actions.setTableTemperatureWorking(tableData));
        break;
      case 'pressure':
        dispatch(actions.setTablePressureWorking(tableData));
        break;
      default:
        break;
    }
  };

  const onRowDragEnd = useCallback((e) => {
    const row = e.node.rowIndex;
    const selectedRowData = gridApi.getDisplayedRowAtIndex(row);
    selectedRowData.data.keyorder = row;

    // reset keyorders
    gridApi.forEachNode((node: any, index: number) => {
      node.data.keyorder = index; // eslint-disable-line
    });

    gridApi.refreshCells();

    const tableData: { name: any; abbrv: any; factor: any; offset: any; keyorder: any; type: any }[] = [];
    gridApi.forEachNode((node: any) => {
      tableData.push({
        name: node.data.name,
        abbrv: node.data.abbrv,
        factor: node.data.factor,
        offset: node.data.offset,
        keyorder: node.data.keyorder,
        type: node.data.type,
      });
    });

    workingDefaults.newValuesSaved = true;
    dispatchTableData(tableData);
  }, []);

  let [columnDefs] = useState([
    { field: 'name', rowDrag: true, editable: true },
    { field: 'abbrv', width: 100, editable: true },
    { field: 'factor', width: 150, editable: true, type: 'numericColumn' },
    { field: 'offset', width: 100, editable: true },
    { field: 'keyorder', width: 100, editable: false },
    { field: 'type', width: 215, editable: true },
  ]);

  if (collection !== 'flow') {
    [columnDefs] = useState([
      { field: 'name', rowDrag: true, editable: true },
      { field: 'abbrv', width: 150, editable: true },
      { field: 'factor', width: 185, editable: true },
      { field: 'offset', width: 183, editable: true },
      { field: 'keyorder', width: 150, editable: false },
    ]);
  }
  const onResetAll = async () => {
    await api.removeUserUnits();
    const data = getDataFromDb();
    if (!_.isEmpty(data)) {
      setRowData(data);
    }
    workingDefaults.newValuesSaved = false;
    showResetMessage('User overrides of the units have been removed.');
  };

  const onAddRow = useCallback(() => {
    const newData = [];
    gridApi.forEachNode((node: any) => {
      newData.push(node.data);
    });

    if (collection === 'flow') {
      newData.push({ name: '', abbrv: '', factor: '', offset: '', keyorder: gridApi.getModel().getRowCount(), type: '' });
    } else {
      newData.push({ name: '', abbrv: '', factor: '', offset: '', keyorder: gridApi.getModel().getRowCount() });
    }

    gridApi.setRowData(newData);
    gridApi.refreshCells();
  }, [gridApi]);

  const onCellValueChanged = useCallback(() => {
    const tableData: { name: any; abbrv: any; factor: any; offset: any; keyorder: any; type: any }[] = [];
    gridApi.forEachNode((node: any) => {
      tableData.push({
        name: node.data.name,
        abbrv: node.data.abbrv,
        factor: node.data.factor,
        offset: node.data.offset,
        keyorder: node.data.keyorder,
        type: node.data.type,
      });
    });

    workingDefaults.newValuesSaved = true;
    dispatchTableData(tableData);
  }, [gridApi]);

  return (
    <div style={{ height: 440, width: 870, marginLeft: 16 }} className="ag-theme-balham">
      <AgGridReact
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        rowData={rowData}
        rowDragManaged
        rowSelection="multiple"
        animateRows
        onRowDragEnd={onRowDragEnd}
        onCellValueChanged={onCellValueChanged}
      />
      <div style={{ position: 'relative', bottom: -30, left: -5, maxWidth: 200 }}>
        <button className="modalButton" type="button" onClick={onAddRow}>
          Add Row
        </button>
        <button className="modalButton" type="button" onClick={onResetAll}>
          Reset All
        </button>
      </div>
    </div>
  );
}

export default VortekAgGrid;
