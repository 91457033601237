import { uniqueId } from 'lodash';
import Customer from './customer';

/* eslint-disable camelcase */
export default class CustomerListItem {
  customer: Customer;
  key: string;

  constructor() {
    this.customer = new Customer();
    this.key = uniqueId();
  }
}
