import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../api';
import { RootState } from '../../store';
import ApiConfig from '../../types/apiConfig';
import { CallbackNoParams } from '../../types/callbackTypes';
import Modal from '../common/Modal';
import TabGroup from '../common/TabGroup';
import VortekAgGrid from '../common/VortekAgGrid';
import actions from '../../store/actions';
import utilities from '../../utilities';

const ChangeUnits = (props: { onClose: CallbackNoParams }): JSX.Element => {
  const dispatch = useDispatch();
  const { onClose } = props;
  const tabItems = ['Flow', 'Temperature', 'Pressure', 'Density', 'Viscosity', 'Length', 'Time'];
  const [active, setActive] = useState(tabItems[0]);
  const state = useSelector((globalState: RootState) => globalState);
  const [resetMessage, setResetMessage] = useState('');
  const {
    sizingGlobals,
    username,
    flowTableWorking,
    densityTableWorking,
    viscosityTableWorking,
    lengthTableWorking,
    timeTableWorking,
    temperatureTableWorking,
    pressureTableWorking,
    workingDefaults,
  } = state;
  const { VORTEK_NAME } = sizingGlobals;
  const db = useSelector((globalState: RootState) => globalState.db);
  const { density, flow, temperature, time, pressure, viscosity, length } = db;

  const getData = (collection: string) => {
    const res = api.readItemSync(true, {
      requestData: {
        client: VORTEK_NAME,
        username,
        collection,
      },
    });

    if (res.status !== 'fail') {
      return res.data.data;
    }

    res.data.data = [];
    return res.data.data;
  };

  const getDefaultTableData = async () => {
    dispatch(actions.setTablePressureWorking(getData('pressure')));
    dispatch(actions.setTableViscosityWorking(getData('viscosity')));
    dispatch(actions.setTableLengthWorking(getData('length')));
    dispatch(actions.setTableTimeWorking(getData('time')));
    dispatch(actions.setTableTemperatureWorking(getData('temperature')));
    dispatch(actions.setTableFlowWorking(getData('flow')));
    dispatch(actions.setTableDensityWorking(getData('density')));
  };

  useEffect(() => {
    getDefaultTableData();
  }, [flow, temperature, pressure, time, viscosity, length, density]);

  const displayTab = (tabItem: string) => {
    setActive(tabItem);
  };

  const showResetMessage = (message: string) => {
    setResetMessage(message);
  };

  const closeResetModal = () => {
    setResetMessage('');
  };

  const closeModal = useCallback(
    async (e, response) => {
      if (response === 'ok' && workingDefaults.newValuesSaved) {
        const apiConfig: ApiConfig = {
          requestData: {
            client: VORTEK_NAME,
            username,
            collection: 'flow',
            trydefault: true,
            densityunits: JSON.parse(JSON.stringify(densityTableWorking)),
            viscosityunits: JSON.parse(JSON.stringify(viscosityTableWorking)),
            lengthunits: JSON.parse(JSON.stringify(lengthTableWorking)),
            timeunits: JSON.parse(JSON.stringify(timeTableWorking)),
            temperatureunits: JSON.parse(JSON.stringify(temperatureTableWorking)),
            pressureunits: JSON.parse(JSON.stringify(pressureTableWorking)),
            flowunits: JSON.parse(JSON.stringify(flowTableWorking)),
            overwrite: true,
          },
        };

        const res = await api.writeUnits(apiConfig);
        if (res.status === 'fail') {
          utilities.handleAjaxFailure(res);
          onClose();
          return;
        }
      } else if (response === 'ok' && !workingDefaults.newValuesSaved) {
        dispatch(actions.setWarningMessage('No changes were made.'));
      }

      workingDefaults.newValuesSaved = false;
      onClose();
    },
    [
      flowTableWorking,
      densityTableWorking,
      viscosityTableWorking,
      lengthTableWorking,
      timeTableWorking,
      temperatureTableWorking,
      pressureTableWorking,
    ]
  );

  return (
    <div>
      <Modal title="Change Units" visible showButtons showCloseButton onClose={closeModal}>
        <div className="leftRight10 topBottom10" style={{ color: 'white', width: 900, height: 'auto' }}>
          <div className="flexRow tabRow">
            <div className="leftRight10 flexCol white">
              <TabGroup
                tabItems={tabItems}
                onClick={displayTab}
                selectedIndex={0}
                tabStyle="tabStyle"
                tabStyleActive="tabStyleActive"
              />
            </div>
          </div>
          {active === 'Flow' && <VortekAgGrid collection="flow" showResetMessage={showResetMessage} />}
          {active === 'Temperature' && <VortekAgGrid collection="temperature" showResetMessage={showResetMessage} />}
          {active === 'Pressure' && <VortekAgGrid collection="pressure" showResetMessage={showResetMessage} />}
          {active === 'Density' && <VortekAgGrid collection="density" showResetMessage={showResetMessage} />}
          {active === 'Viscosity' && <VortekAgGrid collection="viscosity" showResetMessage={showResetMessage} />}
          {active === 'Length' && <VortekAgGrid collection="length" showResetMessage={showResetMessage} />}
          {active === 'Time' && <VortekAgGrid collection="time" showResetMessage={showResetMessage} />}
        </div>
      </Modal>
      {resetMessage && (
        <Modal title="Change Units" visible showCloseButton showButtons={false} onClose={closeResetModal}>
          <div style={{ color: 'white', padding: 25 }}>{resetMessage}</div>
        </Modal>
      )}
    </div>
  );
};

export default ChangeUnits;
