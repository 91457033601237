import { CallbackNoParams } from '../../types/callbackTypes';
import CustomerComponent from '../customer/customer';

const AddCustomerTab = (props: { onClose?: CallbackNoParams }): JSX.Element | null => {
  const { onClose } = props;

  return (
    <div className="modalContentTabs">
      <div className="flexRow iform">
        <div className="flexColNoGrowCenter topBottom20 font12">
          <CustomerComponent onClose={onClose} />
        </div>
      </div>
    </div>
  );
};

AddCustomerTab.defaultProps = {
  onClose: undefined,
};

export default AddCustomerTab;
