import SizingGlobals from '../../types/sizingGlobals';

const SpiraxSizingGlobals: SizingGlobals = {
  goodUsableColor: '#002d72',
  reducing: false,
  turbine: true,
  vorcone: true,
  ultrasonic: true,
  s36: false,
  u44: false,
  proM: false,
  VorconeModel: 'MVC',
  VorconeReduceModel: 'MVCR',
  VorconeModelStr: 'MVC VorCone',
  VorconeReduceModelStr: 'MVCR Reducing VorCone',
  InlineReduceModel: 'M24R',
  InlineNonReduceModel: 'VLM20',
  InlineModel: 'VLM20',
  InlineModelPrev: '',
  InsertionModel: 'VIM20',
  InsertionModelPrev: '',
  TurbineModel: 'Turbo Pro Model RIM20',
  TurbineModelShort: 'RIM20',
  InlineModelStr: 'Model VLM20',
  InlineNonReduceModelStr: 'Model VLM20',
  InlineReduceModelStr: 'Model VLM20',
  InsertionModelStr: 'Model VIM20',
  UltrasonicS34Model: 'S34',
  UltrasonicS34ModelStr: 'S34 Ultrasonic',
  UltrasonicS36Model: 'S36',
  UltrasonicS36ModelStr: 'S36 Ultrasonic',
  UltrasonicU42Model: 'U42',
  UltrasonicU42ModelStr: 'U42 Ultrasonic',
  UltrasonicU43Model: 'U43',
  UltrasonicU43ModelStr: 'U43 Ultrasonic',
  UltrasonicU44Model: 'U44',
  UltrasonicU44ModelStr: 'U44 Ultrasonic',
  ElectromagneticProMModel: 'Pro-M',
  ElectromagneticProMModelStr: 'Electromagnetic Flow Meter',
  ProductLineStr: '',
  HelpMenuStr: 'Spirax Sizing Help...',
  STANDARD_TEMP: 'S',
  HIGH_TEMP: 'H',
  WAFER: 'W',
  ANSI_150: '150',
  ANSI_300: '300',
  ANSI_600: '600',
  ANSI_900: '',
  DN_PN16: '16',
  DN_PN40: '40',
  DN_PN64: '63',
  DN_PN100: '100',
  JIS_10K_J1: '',
  JIS_16K_K: '',
  JIS_20K_J2: '',
  JIS_30K_J3: '',
  JIS_40K_J4: '',
  JIS_10K_Y1: '',
  JIS_20K_Y2: '',
  LocalStr: 'L',
  RemoteStr: 'R()',
  RemoteStr1: '',
  RemoteStr2: '',
  RemoteStr3: '',
  RemoteStr4: '',
  CNPT: 'CNPT',
  C150: 'C150',
  C16: 'C16',
  CJ10: 'CJ10',
  CFJ1K: 'CFJ1K',
  C300: 'C300',
  C40: 'C40',
  CJ20: 'CJ20',
  CFJ2K: 'CFJ2K',
  C600: 'C600',
  C64: 'C63',
  CJ30: 'CJ30',
  CFJ3K: 'CFJ3K',
  C900: '',
  CFJ4K: 'CFJ4K',
  PNPT: 'PNPT',
  P150: 'P150',
  P16: 'P16',
  PJ10: 'PJ10',
  PGJ1K: 'PGJ1K',
  P300: 'P300',
  P40: 'P40',
  PJ20: 'PJ20',
  P64: 'P63',
  PJ30: 'PJ30',
  PGJ2K: 'PGJ2K',
  PGJ3K: 'PGJ3K',
  PGJ4K: 'PGJ4K',
  PNPTR: 'PNPTR',
  PNPTRE: 'PNPTR-E',
  P150R: 'P150R',
  P150RE: 'P150R-E',
  P16R: 'P16R',
  P16RE: 'P16R-E',
  PJ10R: '',
  PGJ1R: '',
  P300R: 'P300R',
  P300RE: 'P300R-E',
  P40R: 'P40R',
  P40RE: 'P40R-E',
  PJ20R: '',
  PGJ2R: '',
  P600R: 'P600R',
  P600RE: 'P600R-E',
  P600: 'P600',
  P64R: 'P63R',
  P64RE: 'P63R-E',
  PJ30R: '',
  PGJ3R: '',
  PGJ4R: '',
  ENPTR: '',
  E5016R: '',
  E5040R: '',
  E5064R: '',
  E150R: '',
  E300R: '',
  E600R: '',
  StandardLength: 'S',
  CompactLength: 'C',
  ExtendedLength: 'E',
  V_METER: 'V',
  VT_METER: 'VT',
  VTP_METER: 'VTP',
  VTP_METER_P1: '',
  VTP_METER_P2: '',
  VTP_METER_P3: '',
  VTP_METER_P4: '',
  VTEP_METER: 'VTEP',
  VETEP_METER: 'VETEP',
  VTEM_METER: 'VT-EM',
  VTPEM_METER: 'VTP-EM',
  VTEPEM_METER: 'VTEP-EM',
  VETEPEM_METER: 'VETEP-EM',
  LOOP_OUTPUT: '1HL',
  LOOP_POWERED: 'DL',
  VDC_1236_POWERED: 'DH',
  AC_POWERED: 'AC',
  SPECIAL_STUFF: '',
  POE: 'DCHPOE',
  ONE_OUTPUT: '1H',
  NO_ALARM_MODBUS_OUTPUT: '1M',
  NO_ALARM_MODBUS_POE: '1AMIP',
  ALARM_MODBUS: '3M',
  ALARM_MODBUS_POE: '3AMIP',
  BACNET: '1B',
  BACNET_POE: '1ABIP',
  ALARM_BACNET: '3B',
  ALARM_BACNET_POE: '3ABIP',
  ALARM_HART: '3H',
  MORE_LOOP_POWERED: [],
  INLINE_NON_LOOP_ORDER: ['36', '37', '39', '41', '42', '44'],
  INSERTION_NON_LOOP_ORDER: [],
  NO_PRESSURE_TRANSDUCER: 'P0',
  DIFF_PRESS_XMTR_FACT: 'AZ',
  DIFF_PRESS_XMTR_CUST: 'CX',
  DIAG_ADD_PRESS_TAP_FT: 'FT',

  // This is the Meter and code information.
  CODE_NAME: '',
  CODE_DESCRIPTION: 'Vortex Flow Meter',
  TURBINE_CODE_NAME: 'RIM20',
  TURBINE_CODE_DESCRIPTION: 'RIM20 Turbine Flow Meter',
  DATA_SHEET: 'APPLICATION DATA SHEET',
  DESCRIPTION: 'Vortex Meter Sizing Program. Written for Vortek Instruments, LLC',
  COPYRIGHT:
    'Copyright 2005-2024 Vortek Instruments, LLC.\nAll rights reserved.\nNo warranty is expressed or implied about the accuracy of calculations used in this program.',

  // This is the VorTek Instruments, LLC information.
  VORTEK_DISPLAY_NAME: 'Spirax Sarco',
  VORTEK_PRINT_NAME: 'Spirax Sarco Limited',
  VORTEK_NAME: 'Spirax Sarco',
  VORTEK_ADDRESS0: '1150 Northpoint Blvd.',
  VORTEK_ADDRESS1: '',
  VORTEK_CITY: 'Blythewood',
  VORTEK_STATE: 'SC',
  VORTEK_POSTAL_CODE: '29016',
  VORTEK_COUNTRY: 'USA',
  VORTEK_TELEPHONE: 'T (800) 575-0394',
  VORTEK_FAX: 'F (803) 714-2222',
};

export default SpiraxSizingGlobals;
