import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../store/actions';
import { RootState } from '../../../store';
import api from '../../../api';
import Unit from '../../../types/Unit';

const DefaultUnits = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const defaultListItems: string[] = [];
  const [allPressureUnits, setAllPressureUnits] = useState(defaultListItems);
  const [allDensityUnits, setAllDensityUnits] = useState(defaultListItems);
  const [allViscosityUnits, setAllViscosityUnits] = useState(defaultListItems);
  const [allTimeUnits, setAllTimeUnits] = useState(defaultListItems);
  const [allVelocityUnits, setAllVelocityUnits] = useState(defaultListItems);
  const db = useSelector((globalState: RootState) => globalState.db);
  const { defaults: dbDefaults, pressure } = db;
  const state = useSelector((globalState: RootState) => globalState);
  const { workingDefaults } = state;

  const getDropDownData = (collection: string): string[] => {
    const table = api.getSpecifiedFeatureList(collection) as unknown as Unit[];
    const data = table.map((r) => {
      return r.abbrv;
    });

    return data;
  };

  const fillDropDowns = () => {
    setAllPressureUnits(getDropDownData('pressure'));
    setAllDensityUnits(getDropDownData('density'));
    setAllViscosityUnits(getDropDownData('viscosity'));
    setAllTimeUnits(getDropDownData('time'));
    setAllVelocityUnits(getDropDownData('length'));
  };

  useEffect(() => {
    fillDropDowns();
  }, [dbDefaults, pressure]);

  return (
    <>
      <div className="modalTabsSubTitle">
        The Vortex Sizing Program will display calculated output data in the units specified below.
      </div>
      <div className="modalContentTabs">
        <div className="field">
          <div className="fieldTitle">Density</div>
          <div />
          <select
            name="flowUnit1"
            className="dropSmall2"
            value={workingDefaults.defaultUnits.densityUnit}
            onChange={(e) => {
              workingDefaults.newValuesSaved = true;
              workingDefaults.defaultUnits.densityUnit = e.target.value;
              dispatch(actions.setWorkingDefaults(workingDefaults));
            }}
          >
            {allDensityUnits.map((p) => {
              return (
                <option key={p} value={p}>
                  {p}
                </option>
              );
            })}
          </select>
        </div>
        <div className="field">
          <div className="fieldTitle">Viscosity</div>
          <div />
          <select
            name="flowUnit1"
            className="dropSmall2"
            value={workingDefaults.defaultUnits.viscosityUnit}
            onChange={(e) => {
              workingDefaults.newValuesSaved = true;
              workingDefaults.defaultUnits.viscosityUnit = e.target.value;
              dispatch(actions.setWorkingDefaults(workingDefaults));
            }}
          >
            {allViscosityUnits.map((p) => {
              return (
                <option key={p} value={p}>
                  {p}
                </option>
              );
            })}
          </select>
        </div>
        <div className="field">
          <div className="fieldTitle">Pressure Drop</div>
          <div />
          <select
            name="flowUnit1"
            className="dropSmall2"
            value={workingDefaults.defaultUnits.pressureDropUnit}
            onChange={(e) => {
              workingDefaults.newValuesSaved = true;
              workingDefaults.defaultUnits.pressureDropUnit = e.target.value;
              dispatch(actions.setWorkingDefaults(workingDefaults));
            }}
          >
            {allPressureUnits.map((p) => {
              return (
                <option key={p} value={p}>
                  {p}
                </option>
              );
            })}
          </select>
        </div>
        <div className="fieldRow">
          <div className="field">
            <div className="fieldTitle">Velocity</div>
            <select
              name="flowUnit1"
              className="dropSmall2 fleft"
              value={workingDefaults.defaultUnits.velocityUnit}
              onChange={(e) => {
                workingDefaults.newValuesSaved = true;
                workingDefaults.defaultUnits.velocityUnit = e.target.value;
                dispatch(actions.setWorkingDefaults(workingDefaults));
              }}
            >
              {allVelocityUnits.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
            <div className="leftRight10 fleft">/</div>
            <select
              name="flowUnit1"
              className="dropSmall2 fleft"
              value={workingDefaults.defaultUnits.timeUnit}
              onChange={(e) => {
                workingDefaults.newValuesSaved = true;
                workingDefaults.defaultUnits.timeUnit = e.target.value;
                dispatch(actions.setWorkingDefaults(workingDefaults));
              }}
            >
              {allTimeUnits.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultUnits;
