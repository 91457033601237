export default class VortekDefaultFormats {
  flowRateFormat: string;
  lengthFormat: string;
  massFormat: string;
  pressureFormat: string;
  temperatureFormat: string;
  volumeFormat: string;
  areaFormat: string;
  densityFormat: string;
  velocityFormat: string;
  viscosityFormat: string;
  unitlessFormat: string;
  generalNumberFormat: string;
  fixedFormat: string;
  standardFormat: string;

  constructor() {
    this.flowRateFormat = '';
    this.lengthFormat = '';
    this.massFormat = '';
    this.pressureFormat = '';
    this.temperatureFormat = '';
    this.volumeFormat = '';
    this.areaFormat = '';
    this.densityFormat = '';
    this.velocityFormat = '';
    this.viscosityFormat = '';
    this.unitlessFormat = '';
    this.generalNumberFormat = '';
    this.fixedFormat = '';
    this.standardFormat = '';
  }
}
