import SizingGlobals from '../../types/sizingGlobals';

const AzbilSizingGlobals: SizingGlobals = {
  goodUsableColor: '#003300',
  reducing: true,
  turbine: false,
  vorcone: false,
  ultrasonic: false,
  s36: false,
  u44: false,
  proM: false,
  VorconeModel: 'MVC VorCone',
  VorconeReduceModel: 'MVCR Reducing VorCone',
  VorconeModelStr: 'MVC VorCone',
  VorconeReduceModelStr: 'MVCR Reducing VorCone',
  InlineReduceModel: 'AX24R',
  InlineNonReduceModel: 'AX24',
  InlineModel: 'AX22',
  InlineModelPrev: '',
  InsertionModel: 'AX23',
  InsertionModelPrev: '',
  TurbineModel: '',
  TurbineModelShort: '',
  InlineModelStr: 'AX Series',
  InlineNonReduceModelStr: 'AX Series Model AX24',
  InlineReduceModelStr: 'AX Series Model AX24R',
  InsertionModelStr: 'AX Series',
  UltrasonicS34Model: 'S34',
  UltrasonicS34ModelStr: 'S34 Ultrasonic',
  UltrasonicS36Model: 'S36',
  UltrasonicS36ModelStr: 'S36 Ultrasonic',
  UltrasonicU42Model: 'U42',
  UltrasonicU42ModelStr: 'U42 Ultrasonic',
  UltrasonicU43Model: 'U43',
  UltrasonicU43ModelStr: 'U43 Ultrasonic',
  UltrasonicU44Model: 'U44',
  UltrasonicU44ModelStr: 'U44 Ultrasonic',
  ElectromagneticProMModel: 'Pro-M',
  ElectromagneticProMModelStr: 'Electromagnetic Flow Meter',
  ProductLineStr: 'AX Series',
  HelpMenuStr: 'Vortex Sizing Help...',
  STANDARD_TEMP: 'S',
  HIGH_TEMP: 'H',
  WAFER: 'WC',
  ANSI_150: '150',
  ANSI_300: '300',
  ANSI_600: '600',
  ANSI_900: '900',
  DN_PN16: 'D1',
  DN_PN40: 'D2',
  DN_PN64: 'D3',
  DN_PN100: '',
  JIS_10K_J1: 'J10',
  JIS_16K_K: '',
  JIS_20K_J2: 'J20',
  JIS_30K_J3: 'J30',
  JIS_40K_J4: 'J40',
  JIS_10K_Y1: 'Y10',
  JIS_20K_Y2: 'Y20',
  LocalStr: 'LE',
  RemoteStr: '',
  RemoteStr1: 'R1',
  RemoteStr2: 'R2',
  RemoteStr3: 'R3',
  RemoteStr4: 'R4',
  CNPT: 'CFNPT',
  C150: 'CF150',
  C16: 'CFP16',
  CJ10: '',
  CFJ1K: 'CFJ1K',
  C300: 'CF300',
  C40: 'CFP40',
  CJ20: '',
  CFJ2K: 'CFJ2K',
  C600: 'CF600',
  C64: 'CFP63',
  CJ30: '',
  CFJ3K: 'CFJ3K',
  C900: 'CF900',
  CFJ4K: 'CFJ4K',
  PNPT: 'PGNPT',
  P150: 'PG150',
  P16: 'PGP16',
  PJ10: '',
  PGJ1K: 'PGJ1K',
  P300: 'PG300',
  P40: 'PGP40',
  PJ20: '',
  PGJ2K: 'PGJ2K',
  PGJ3K: '',
  PGJ4K: 'PGJ4K',
  PNPTR: 'PGNPR',
  PNPTRE: '',
  P150R: 'PG15R',
  P150RE: '',
  P16R: 'PGP1R',
  P16RE: '',
  PJ10R: '',
  PGJ1R: 'PGJ1R',
  P300R: 'PG30R',
  P300RE: '',
  P40R: 'PGP4R',
  P40RE: '',
  PJ20R: '',
  PGJ2R: 'PGJ2R',
  P64: 'PGP63',
  P64RE: '',
  PJ30: '',
  P600R: 'PG60R',
  P600RE: '',
  P600: 'PG600',
  P64R: 'PGP6R',
  PJ30R: '',
  PGJ3R: 'PGJ3R',
  PGJ4R: 'PGJ4R',
  ENPTR: '',
  E5016R: '',
  E5040R: '',
  E5064R: '',
  E150R: '',
  E300R: '',
  E600R: '',
  StandardLength: 'S',
  CompactLength: 'C',
  ExtendedLength: 'E',
  V_METER: '00',
  VT_METER: '11',
  VTP_METER: '22',
  VTP_METER_P1: '',
  VTP_METER_P2: '',
  VTP_METER_P3: '',
  VTP_METER_P4: '',
  VTEP_METER: '33',
  VETEP_METER: '66',
  VTEM_METER: '44',
  VTPEM_METER: '55',
  VTEPEM_METER: '',
  VETEPEM_METER: '77',
  LOOP_OUTPUT: 'A',
  LOOP_POWERED: '1',
  VDC_1236_POWERED: '2',
  AC_POWERED: '3',
  SPECIAL_STUFF: '',
  POE: '4',
  ONE_OUTPUT: 'B',
  NO_ALARM_MODBUS_OUTPUT: 'C',
  NO_ALARM_MODBUS_POE: 'H',
  ALARM_MODBUS: 'E',
  ALARM_MODBUS_POE: 'J',
  BACNET: 'F',
  BACNET_POE: 'I',
  ALARM_BACNET: 'G',
  ALARM_BACNET_POE: 'K',
  ALARM_HART: 'D',
  MORE_LOOP_POWERED: [],
  INLINE_NON_LOOP_ORDER: [],
  INSERTION_NON_LOOP_ORDER: [],
  NO_PRESSURE_TRANSDUCER: 'X',
  DIFF_PRESS_XMTR_FACT: '',
  DIFF_PRESS_XMTR_CUST: '',
  DIAG_ADD_PRESS_TAP_FT: '',

  // This is the Meter and code information.
  CODE_NAME: ' ',
  CODE_DESCRIPTION: 'AX Series Vortex Flow Meter',
  TURBINE_CODE_NAME: ' ',
  TURBINE_CODE_DESCRIPTION: 'AX Series Turbine Flow Meter',
  DATA_SHEET: 'APPLICATION DATA SHEET',
  DESCRIPTION: 'AX2000 Series Multi-Parameter Vortex Mass Flow Meters. Written for Vortek Instruments, LLC',
  COPYRIGHT:
    'Copyright 2005-2024 Vortek Instruments, LLC.\nAll rights reserved.\nNo warranty is expressed or implied about the accuracy of calculations used in this program.',

  // This is the Azbil information
  VORTEK_DISPLAY_NAME: 'Azbil',
  VORTEK_PRINT_NAME: 'Azbil North America, Inc.',
  VORTEK_NAME: 'Azbil North America, Inc.',
  VORTEK_ADDRESS0: '9033 N. 24th Avenue',
  VORTEK_ADDRESS1: 'Suite #5/6',
  VORTEK_CITY: 'Phoenix',
  VORTEK_STATE: 'Arizona',
  VORTEK_POSTAL_CODE: '85021',
  VORTEK_COUNTRY: 'USA',
  VORTEK_TELEPHONE: 'Telephone: 888-262-4639',
  VORTEK_FAX: 'FAX: 602-216-8213',
};

export default AzbilSizingGlobals;
