import SizingGlobals from '../../types/sizingGlobals';

const ArmstrongSizingGlobals: SizingGlobals = {
  goodUsableColor: '#3f3f3f',
  reducing: false,
  turbine: false,
  vorcone: false,
  ultrasonic: false,
  s36: false,
  u44: false,
  proM: false,
  VorconeModel: 'MVC VorCone',
  VorconeReduceModel: 'MVCR Reducing VorCone',
  VorconeModelStr: 'MVC VorCone',
  VorconeReduceModelStr: 'MVCR Reducing VorCone',
  InlineReduceModel: 'AVRR',
  InlineNonReduceModel: 'AVR',
  InlineModel: 'AVF',
  InlineModelPrev: '',
  InsertionModel: 'AVI',
  InsertionModelPrev: '',
  TurbineModel: '',
  TurbineModelShort: '',
  InlineModelStr: 'Armstrong Vortex Inline',
  InlineNonReduceModelStr: 'Armstrong Vortex Inline',
  InlineReduceModelStr: 'Armstrong Vortex Inline',
  InsertionModelStr: 'Armstrong Vortex Insertion',
  UltrasonicS34Model: 'S34',
  UltrasonicS34ModelStr: 'S34 Ultrasonic',
  UltrasonicS36Model: 'S36',
  UltrasonicS36ModelStr: 'S36 Ultrasonic',
  UltrasonicU42Model: 'U42',
  UltrasonicU42ModelStr: 'U42 Ultrasonic',
  UltrasonicU43Model: 'U43',
  UltrasonicU43ModelStr: 'U43 Ultrasonic',
  UltrasonicU44Model: 'U44',
  UltrasonicU44ModelStr: 'U44 Ultrasonic',
  ElectromagneticProMModel: 'Pro-M',
  ElectromagneticProMModelStr: 'Electromagnetic Flow Meter',
  ProductLineStr: 'Armstrong',
  HelpMenuStr: 'Armstrong Sizer Help...',
  STANDARD_TEMP: 'T',
  HIGH_TEMP: 'H',
  WAFER: 'W',
  ANSI_150: '150',
  ANSI_300: '300',
  ANSI_600: '600',
  ANSI_900: '',
  DN_PN16: '16',
  DN_PN40: '40',
  DN_PN64: '63',
  DN_PN100: '100',
  JIS_10K_J1: '',
  JIS_16K_K: '',
  JIS_20K_J2: '',
  JIS_30K_J3: '',
  JIS_40K_J4: '',
  JIS_10K_Y1: '',
  JIS_20K_Y2: '',
  LocalStr: 'L',
  RemoteStr: 'R()',
  RemoteStr1: '',
  RemoteStr2: '',
  RemoteStr3: '',
  RemoteStr4: '',
  CNPT: 'CT8',
  C150: 'CF8150',
  C16: 'CF5016',
  CJ10: '',
  CFJ1K: '',
  C300: 'CF8300',
  C40: 'CF5040',
  CJ20: '',
  CFJ2K: '',
  C600: 'CF8600',
  C64: 'CF5063',
  CJ30: '',
  CFJ3K: '',
  C900: '',
  CFJ4K: '',
  PNPT: 'PT8',
  P150: 'PF8150',
  P16: 'PF5016',
  PJ10: '',
  PGJ1K: '',
  P300: 'PF8300',
  P40: 'PF5040',
  PJ20: '',
  PJ30: '',
  PGJ2K: '',
  PGJ3K: '',
  PGJ4K: '',
  PNPTR: 'PT8R',
  PNPTRE: '',
  P150R: 'PF8150R',
  P150RE: '',
  P16R: 'PF5016R',
  P16RE: '',
  PJ10R: '',
  PGJ1R: '',
  P300R: 'PF8300R',
  P300RE: '',
  P40R: 'PF5040R',
  P40RE: '',
  PJ20R: '',
  PGJ2R: '',
  P600R: 'PF8600R',
  P600RE: '',
  P600: 'PF8600R',
  P64R: 'PF5063R',
  PJ30R: '',
  P64: 'PF5063R',
  P64RE: '',
  PGJ3R: '',
  PGJ4R: '',
  ENPTR: '',
  E5016R: '',
  E5040R: '',
  E5064R: '',
  E150R: '',
  E300R: '',
  E600R: '',
  StandardLength: 'SL',
  CompactLength: 'CL',
  ExtendedLength: 'EL',
  V_METER: 'V1',
  VT_METER: 'V2',
  VTP_METER: 'V3',
  VTP_METER_P1: '',
  VTP_METER_P2: '',
  VTP_METER_P3: '',
  VTP_METER_P4: '',
  VTEP_METER: 'V4',
  VETEP_METER: '',
  VTEM_METER: 'E1',
  VTPEM_METER: 'E2',
  VTEPEM_METER: '',
  VETEPEM_METER: '',
  LOOP_OUTPUT: '1',
  LOOP_POWERED: 'LP',
  VDC_1236_POWERED: 'DC',
  AC_POWERED: 'AC',
  SPECIAL_STUFF: '',
  POE: 'DCHPOE',
  ONE_OUTPUT: '2',
  NO_ALARM_MODBUS_OUTPUT: '3',
  NO_ALARM_MODBUS_POE: '3IP',
  ALARM_MODBUS: '6',
  ALARM_MODBUS_POE: '6IP',
  BACNET: '4',
  BACNET_POE: '4IP',
  ALARM_BACNET: '7',
  ALARM_BACNET_POE: '7IP',
  ALARM_HART: '5',
  MORE_LOOP_POWERED: [],
  INLINE_NON_LOOP_ORDER: ['24', '25', '45', '26', '27', '46'],
  INSERTION_NON_LOOP_ORDER: [],
  NO_PRESSURE_TRANSDUCER: 'N',
  DIFF_PRESS_XMTR_FACT: '',
  DIFF_PRESS_XMTR_CUST: '',
  DIAG_ADD_PRESS_TAP_FT: '',

  // This is the Meter and code information.
  CODE_NAME: 'Armstrong',
  CODE_DESCRIPTION: 'Armstrong Vortex Flow Meter',
  TURBINE_CODE_NAME: '',
  TURBINE_CODE_DESCRIPTION: '',
  DATA_SHEET: 'APPLICATION DATA SHEET',
  DESCRIPTION: 'Armstrong Model AVF and Model AVI Vortex Meter Sizing Program. Written for Armstrong International, Inc.',
  COPYRIGHT:
    'Copyright 2007-2024 Vortek Instruments, LLC.\nAll rights reserved.\nNo warranty is expressed or implied about the accuracy of the calculations used in this program.',

  // This is the Armstrong information.
  VORTEK_DISPLAY_NAME: 'Armstrong',
  VORTEK_PRINT_NAME: 'Armstrong International, Inc.',
  VORTEK_NAME: 'Armstrong International, Inc.',
  VORTEK_ADDRESS0: '5820 Glacier Way',
  VORTEK_ADDRESS1: '',
  VORTEK_CITY: 'Frederick',
  VORTEK_STATE: 'CO',
  VORTEK_POSTAL_CODE: '80516',
  VORTEK_COUNTRY: 'USA',
  VORTEK_TELEPHONE: 'Telephone: 978-437-1000',
  VORTEK_FAX: 'FAX: 502-479-6722',
};

export default ArmstrongSizingGlobals;
