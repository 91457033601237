import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { CallbackEvent } from '../../types/callbackTypes';
import Modal from '../common/Modal';
import { RootState } from '../../store';
import api from '../../api';
import constants from '../../constants';

const ChangePassword = (props: { changePasswordHandler: CallbackEvent }): JSX.Element => {
  const { changePasswordHandler } = props;
  const sizingGlobals = useSelector((state: RootState) => state.sizingGlobals);
  const username = useSelector((state: RootState) => state.username);
  const { VORTEK_NAME } = sizingGlobals;
  const [error, setError] = useState('');
  const [pwUsername, setPwUsername] = useState(username);
  const [password, setPassword] = useState('');
  const [repPassword, setRepPassword] = useState('');

  const changePassword = useCallback(
    async (e) => {
      e.preventDefault();
      const whitespaceRegex = /\s/g;

      if (!pwUsername) {
        setError('Username is required');
        return;
      }

      const match = pwUsername.match(/^[A-Za-z][A-Za-z0-9 -]*$/);
      if (!match || pwUsername.length < 4 || pwUsername.length > 8) {
        setError('Username must start with a letter, be 4-8 chars, and only consist of letters and/or numbers');
        return;
      }

      if (pwUsername === 'guest' || pwUsername === 'admin' || pwUsername === 'DEFAULT') {
        setError('User already exists');
        return;
      }

      if (!password) {
        setError('Password is required');
        return;
      }

      if (password.match(whitespaceRegex)) {
        setError('Password cannot contain any whitespace');
        return;
      }

      if (!repPassword || repPassword !== password) {
        setError('Repeat password does not match password');
        return;
      }

      const response = await api.performChangePassword({
        requestData: {
          client: VORTEK_NAME,
          collection: '',
          username: pwUsername,
          password,
          rep_password: repPassword,
        },
      });

      if (response.status === 'success') {
        setError('');
        changePasswordHandler(e);
      } else {
        setError(response.error);
      }
    },
    [VORTEK_NAME, pwUsername, password, repPassword]
  );

  return (
    <Modal showButtons={false} title="Change Password" visible>
      <div className="leftRight10">
        <div className="flexRow white topBottom20">
          Version:{'  '}
          <div className="version_changepw">{constants.REVISION}</div>
        </div>
        <p className="flexRow white">NOTE: Username is case sensitive</p>
        <fieldset className="changePwFieldset">
          <div className="changepw_username_div flexRow">
            <div className="flexColumn right10 white">Username:</div>
            <input
              value={pwUsername}
              className="changepw_username"
              type="text"
              size={20}
              maxLength={8}
              tabIndex={0}
              onChange={(e) => {
                setPwUsername(e.target.value);
              }}
            />
          </div>
          <div className="flexRow changepw_password_div">
            <div className="flexColumn right10 white">Password:</div>
            <input
              value={password}
              className="changepw_password"
              type="password"
              size={20}
              maxLength={50}
              tabIndex={0}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="flexRow changepw_password_repeat_div">
            <div className="flexColumn right10 white">Repeat Password:</div>
            <input
              value={repPassword}
              className="changepw_password_repeat"
              type="password"
              size={20}
              maxLength={50}
              tabIndex={0}
              onChange={(e) => {
                setRepPassword(e.target.value);
              }}
            />
          </div>
        </fieldset>
        <div className="topBottom10 flexRow">
          <button type="button" className="changepw_button" onClick={changePassword} tabIndex={0}>
            Change Password
          </button>
        </div>
        <div className="flexTextRow clientBack redWarning changepw_error_div">{error}</div>
      </div>
    </Modal>
  );
};

export default ChangePassword;
