// For a given meter, entered and calculated values

import Decimal from 'decimal.js';
import constants from '../constants';

// that determine the specifications
export default class ProcessCondition {
  dblFlowRate: import('decimal.js').Decimal;
  sFlowRateUnit: string;
  sFlowRateTime: string;

  dblTemperature: import('decimal.js').Decimal;
  sTemperatureUnit: string;

  dblPressure: import('decimal.js').Decimal;
  sPressureUnit: string;
  sPressureGaugeAbsolute: string;

  // These are calculated values.
  dblDensityValue: import('decimal.js').Decimal;
  sDensityUnit: string;
  dblViscosityValue: import('decimal.js').Decimal;
  sViscosityUnit: string;

  // accumulate warnings to show to the user
  warnings: Array<string>;

  constructor() {
    this.dblFlowRate = constants.ZERO;
    this.sFlowRateUnit = '';
    this.sFlowRateTime = '';

    this.dblTemperature = constants.ZERO;
    this.sTemperatureUnit = '';

    this.dblPressure = constants.ZERO;
    this.sPressureUnit = '';
    this.sPressureGaugeAbsolute = '';

    // These are calculated values.
    this.dblDensityValue = constants.ZERO;
    this.sDensityUnit = '';
    this.dblViscosityValue = constants.ZERO;
    this.sViscosityUnit = '';

    // accumulate warnings to show to the user
    this.warnings = [];
  }

  copy = () => {
    const target = new ProcessCondition();
    target.dblFlowRate = new Decimal(this.dblFlowRate);
    target.sFlowRateUnit = this.sFlowRateUnit;
    target.sFlowRateTime = this.sFlowRateTime;
    target.dblTemperature = new Decimal(this.dblTemperature);
    target.sTemperatureUnit = this.sTemperatureUnit;
    target.dblPressure = new Decimal(this.dblPressure);
    target.sPressureUnit = this.sPressureUnit;
    target.sPressureGaugeAbsolute = this.sPressureGaugeAbsolute;
    target.dblDensityValue = new Decimal(this.dblDensityValue);
    target.sDensityUnit = this.sDensityUnit;
    target.dblViscosityValue = new Decimal(this.dblViscosityValue);
    target.sViscosityUnit = this.sViscosityUnit;

    return target;
  };
}
