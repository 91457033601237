import Decimal from 'decimal.js';
import store from '../../store';
import actions from '../../store/actions';
import application from '../../utilities/application';
import calc from '../../utilities/calc';
import convert from '../../utilities/convert';
import PipeData from '../PipeData';
import ProcessCondition from '../processCondition';
import Fluid from './Fluid';
import SteamSaturatedPressure from './SteamSaturatedPressure';

export default class Water extends Fluid {
  calculateDensity = (processCondition: ProcessCondition): ProcessCondition => {
    // This subroutine will calculate the water density based upon the temperature
    // parameter.  The density value will be stored in the process parameter.
    const Vc = new Decimal('3.1975');
    const Tc = new Decimal('374.11');
    const A = new Decimal('-0.3151548');
    const B = new Decimal('-0.001203375');
    const C = new Decimal('0.000000000000748908');
    const D = new Decimal('0.1342489');
    const E = new Decimal('-0.003946263');
    let dblVs;
    const oneThird = new Decimal('1').div(new Decimal('3'));

    const dblTempC = convert.temperatureTo('deg C', processCondition.dblTemperature, processCondition.sTemperatureUnit);

    const dblDT = Tc.minus(dblTempC);
    const dblTtoThird = Decimal.pow(dblDT, oneThird);

    dblVs = Vc.plus(A.times(dblTtoThird));
    dblVs = dblVs.plus(B.times(dblDT));
    dblVs = dblVs.plus(C.times(dblDT.pow(4)));

    const bottom = new Decimal('1').plus(D.times(dblTtoThird)).plus(E.times(dblDT));
    dblVs = dblVs.div(bottom);

    // dblVs = (Vc + (A * dblTtoThird) + (B * dblDT) + (C * (dblDT ^ 4.0))) / (1.0 + (D * dblTtoThird) + (E * dblDT))

    const newProcessCondition = { ...processCondition };

    // Store the calculated information, and units.
    // vortek.classes.ProcessCondition.DensityValue = (1.0 / dblVs) * 62.4279514
    newProcessCondition.dblDensityValue = new Decimal('1').div(dblVs).times(new Decimal('62.4279514'));

    newProcessCondition.sDensityUnit = 'lb/ft^3';
    return newProcessCondition;
  };

  calculateViscosity = (processCondition: ProcessCondition, minNomMax: string): ProcessCondition => {
    // This routine will calculate the viscosity of the steam based upon user input.
    let dblVisc;
    let pow;

    // Convert the temperature to F.
    const dblTempF = convert.temperatureTo('deg F', processCondition.dblTemperature, processCondition.sTemperatureUnit);

    const jsTempF = dblTempF.toString();

    // Determine the water viscosity based upon the temperature.
    if (dblTempF.greaterThan(new Decimal('212.0'))) {
      pow = Decimal.pow(jsTempF, '-1.0737018');
      dblVisc = new Decimal('83.040272').times(pow);
    } else if (dblTempF.greaterThan(new Decimal('68.0'))) {
      pow = Decimal.pow(jsTempF, '-1.1234488');
      dblVisc = new Decimal('118.33509').times(pow);
    } else if (dblTempF.greaterThan(new Decimal('32.0'))) {
      pow = Decimal.pow(jsTempF, '-0.77173993');
      dblVisc = new Decimal('26.44787').times(pow);
    } else {
      // The water is frozen.
      dblVisc = new Decimal('1');
      processCondition.warnings.push(`The fluid is frozen at the ${minNomMax} process conditions.`);
    }

    const newProcessCondition = { ...processCondition };

    newProcessCondition.dblViscosityValue = dblVisc;
    newProcessCondition.sViscosityUnit = 'cP';
    return newProcessCondition;
  };

  calculateValuesWater = (steam: SteamSaturatedPressure, pipeData: PipeData, minNomMax: string): PipeData => {
    const state = store.getState();

    let newPipeData = { ...pipeData };
    let temp;
    let satTemp;

    // test to see if it should be steam
    newPipeData.isentropic = new Decimal(1);
    newPipeData.fluidType = 'Steam';
    newPipeData.fluid = 'Saturated Pressure';

    if (minNomMax === 'min') {
      temp = newPipeData.temperatureMin;
    } else if (minNomMax === 'nom') {
      temp = newPipeData.temperatureNom;
    } else if (minNomMax === 'max') {
      temp = newPipeData.temperatureMax;
    }

    newPipeData = steam.calculateValues(newPipeData, minNomMax);

    // save off the temperatures
    if (minNomMax === 'min') {
      satTemp = newPipeData.temperatureMin;
      newPipeData.temperatureMin = temp as string;
      newPipeData.calcTemperatureMinVal = new Decimal(newPipeData.temperatureMin.replace(',', ''));
    } else if (minNomMax === 'nom') {
      satTemp = newPipeData.temperatureNom;
      newPipeData.temperatureNom = temp as string;
      newPipeData.calcTemperatureNomVal = new Decimal(newPipeData.temperatureNom.replace(',', ''));
    } else if (minNomMax === 'max') {
      satTemp = newPipeData.temperatureMax;
      newPipeData.temperatureMax = temp as string;
      newPipeData.calcTemperatureMaxVal = new Decimal(newPipeData.temperatureMax.replace(',', ''));
    }

    // reset
    newPipeData.fluidType = 'Water';
    newPipeData.fluid = 'Water';

    const [tPipeData, tCond] = application.collectApplicationData(newPipeData, minNomMax);
    tPipeData.calcPressureMinVal = new Decimal(tPipeData.pressureMin.replace(',', ''));
    tPipeData.calcPressureNomVal = new Decimal(tPipeData.pressureNom.replace(',', ''));
    tPipeData.calcPressureMaxVal = new Decimal(tPipeData.pressureMax.replace(',', ''));
    tPipeData.calcTemperatureMinVal = new Decimal(tPipeData.temperatureMin.replace(',', ''));
    tPipeData.calcTemperatureNomVal = new Decimal(tPipeData.temperatureNom.replace(',', ''));
    tPipeData.calcTemperatureMaxVal = new Decimal(tPipeData.temperatureMax.replace(',', ''));

    newPipeData = { ...tPipeData };
    let cond = { ...tCond };

    // Calculate the application density.
    cond = this.calculateDensity(cond);
    let tmp;
    let val;

    if (minNomMax === 'min') {
      newPipeData.calcDensityMinVal = cond.dblDensityValue;
      newPipeData.densityMin = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'min');
      newPipeData.densityMinDisplay = tmp;
      newPipeData.densityMinInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'nom') {
      newPipeData.calcDensityNomVal = cond.dblDensityValue;
      newPipeData.densityNom = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'nom');
      newPipeData.densityNomDisplay = tmp;
      newPipeData.densityNomInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'max') {
      newPipeData.calcDensityMaxVal = cond.dblDensityValue;
      newPipeData.densityMax = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'max');
      newPipeData.densityMaxDisplay = tmp;
      newPipeData.densityMaxInternalUnit = cond.sDensityUnit;
    }

    store.dispatch(
      actions.setStandardConditions(this.calculateDensity(state.defaults.defaultProcessConditions.standardConditions))
    );

    store.dispatch(actions.setNormalConditions(this.calculateDensity(state.defaults.defaultProcessConditions.normalConditions)));

    cond = this.calculateViscosity(cond, minNomMax);

    if (minNomMax === 'min') {
      newPipeData.calcViscosityMinVal = cond.dblViscosityValue;
      newPipeData.viscosityMin = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'min');
      newPipeData.viscosityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcViscosityNomVal = cond.dblViscosityValue;
      newPipeData.viscosityNom = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'nom');
      newPipeData.viscosityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcViscosityMaxVal = cond.dblViscosityValue;
      newPipeData.viscosityMax = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'max');
      newPipeData.viscosityMaxDisplay = tmp;
    }

    newPipeData.viscosityUnit = cond.sViscosityUnit;

    if (minNomMax === 'min') {
      newPipeData.calcPressureMinVal = cond.dblPressure;
      newPipeData.pressureMin = cond.dblPressure.toString();
      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityMinVal = newPipeData.velocity;
      newPipeData.velocityMin = newPipeData.velocity.toString();
      newPipeData.warningsMin = cond.warnings;
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'min');
      newPipeData.velocityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcPressureNomVal = cond.dblPressure;
      newPipeData.pressureNom = cond.dblPressure.toString();
      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityNomVal = newPipeData.velocity;
      newPipeData.velocityNom = newPipeData.velocity.toString();
      newPipeData.warningsNom = cond.warnings;
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'nom');
      newPipeData.velocityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcPressureMaxVal = cond.dblPressure;
      newPipeData.pressureMax = cond.dblPressure.toString();
      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityMaxVal = newPipeData.velocity;
      newPipeData.velocityMax = newPipeData.velocity.toString();
      newPipeData.warningsMax = cond.warnings;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'max');
      newPipeData.velocityMaxDisplay = tmp;
    }

    cond.dblPressure = convert.pressureTo(
      `${newPipeData.originalPressurePrefix}-${newPipeData.originalPressureCoefficient}`,
      cond.dblPressure,
      cond.sPressureUnit,
      cond.sPressureGaugeAbsolute
    );
    newPipeData.pressurePrefix = newPipeData.originalPressurePrefix;
    newPipeData.pressureCoefficient = newPipeData.originalPressureCoefficient;

    if (minNomMax === 'min') {
      newPipeData.calcPressureMinVal = cond.dblPressure;
      newPipeData.pressureMin = cond.dblPressure.toString();
    } else if (minNomMax === 'nom') {
      newPipeData.calcPressureNomVal = cond.dblPressure;
      newPipeData.pressureNom = cond.dblPressure.toString();
    } else if (minNomMax === 'max') {
      newPipeData.calcPressureMaxVal = cond.dblPressure;
      newPipeData.pressureMax = cond.dblPressure.toString();
    }

    if (new Decimal(temp as string).greaterThan(new Decimal(satTemp as string))) {
      newPipeData.warningsMin.push(`The fluid is steam at the ${minNomMax} process conditions.`);
    }

    return newPipeData;
  };

  calculateValues = (pipeData: PipeData): PipeData => {
    const steam = new SteamSaturatedPressure();
    let newPipeData = this.calculateValuesWater(steam, pipeData, 'min');
    newPipeData = this.calculateValuesWater(steam, newPipeData, 'nom');
    newPipeData = this.calculateValuesWater(steam, newPipeData, 'max');
    return newPipeData;
  };
}
