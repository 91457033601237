import Decimal from 'decimal.js';
import api from '../../api';
import constants from '../../constants';
import store from '../../store';
import actions from '../../store/actions';
import application from '../../utilities/application';
import calc from '../../utilities/calc';
import convert from '../../utilities/convert';
import OtherGasData from '../OtherGasData';
import PipeData from '../PipeData';
import ProcessCondition from '../processCondition';
import Fluid from './Fluid';

export default class OtherGas extends Fluid {
  protected dblViscosity: Decimal;
  protected sViscosityUnit: string;
  public dblCompressibility: Decimal;
  public dblSpecificGravity: Decimal;

  constructor() {
    super();

    this.dblViscosity = constants.ZERO;
    this.sViscosityUnit = 'cP';
    this.dblCompressibility = constants.ZERO;
    this.dblSpecificGravity = constants.ZERO;
  }

  findAllOtherGases = (): OtherGasData[] => {
    const state = store.getState();

    const res = api.findAllDataForUserInReduxTable(state.sizingGlobals.VORTEK_NAME, state.username, 'othergas');

    const realres = [] as unknown as OtherGasData[];
    res.forEach((row) => {
      const { data } = row;
      realres.push({
        gasName: data.gas_name,
        gasCompressibility: new Decimal(data.gas_compressibility.replace(',', '')),
        gasGravity: new Decimal(data.gas_gravity.replace(',', '')),
        gasViscosity: new Decimal(data.gas_viscosity.replace(',', '')),
        gasSelect: data.gas_select,
      });
    });

    return realres;
  };

  findOtherGas = (otherGasName: string): OtherGasData | undefined => {
    const state = store.getState();
    let item;

    const res = api.findAllDataForUserInReduxTable(state.sizingGlobals.VORTEK_NAME, state.username, 'othergas');

    res.forEach((row) => {
      const { data } = row;
      if (data.gas_name === otherGasName) {
        item = {
          gasName: data.gas_name,
          gasCompressibility: new Decimal(data.gas_compressibility.replace(',', '')),
          gasGravity: new Decimal(data.gas_gravity.replace(',', '')),
          gasViscosity: new Decimal(data.gas_viscosity.replace(',', '')),
          gasSelect: data.gas_select,
        };
      }
    });

    return item;
  };

  retrieveOtherGas = () => {
    const state = store.getState();
    const res = this.findOtherGas(state.fluid);
    if (res) {
      const otherGas = res as unknown as OtherGasData;
      this.dblCompressibility = otherGas.gasCompressibility;
      this.dblSpecificGravity = otherGas.gasGravity;
      this.dblViscosity = otherGas.gasViscosity;
    }
  };

  calculateDensity = (processCondition: ProcessCondition): ProcessCondition => {
    const dblTempK = convert.temperatureTo('K', processCondition.dblTemperature, processCondition.sTemperatureUnit);

    const dblPressATM = convert.pressureTo(
      'atm(STD)-A',
      processCondition.dblPressure,
      processCondition.sPressureUnit,
      processCondition.sPressureGaugeAbsolute
    );

    // Store the calculated information, and units.
    // vortek.classes.ProcessCondition.DensityValue = (dblOtherGasSpecificGravity / dblOtherGasCompressibility) * ((0.0807223 * dblPressATM) / (dblTempK / 273.15))
    const magicNum = new Decimal('0.0807223');
    const kelvin = new Decimal('273.15');
    const right = dblTempK.div(kelvin);
    const middle = magicNum.times(dblPressATM);
    const left = this.dblSpecificGravity.div(this.dblCompressibility);

    const newProcessCondition = { ...processCondition };

    newProcessCondition.dblDensityValue = left.times(middle.div(right));
    newProcessCondition.sDensityUnit = 'lb/ft^3';
    return newProcessCondition;
  };

  calculateViscosity = (processCondition: ProcessCondition): ProcessCondition => {
    const newProcessCondition = { ...processCondition };
    newProcessCondition.dblViscosityValue = this.dblViscosity;
    newProcessCondition.sViscosityUnit = this.sViscosityUnit;
    return newProcessCondition;
  };

  calculateValues = (pipeData: PipeData, minNomMax: string): PipeData => {
    const state = store.getState();
    this.retrieveOtherGas();

    let [newPipeData, cond] = application.collectApplicationData(pipeData, minNomMax);
    newPipeData.calcPressureMinVal = new Decimal(newPipeData.pressureMin.replace(',', ''));
    newPipeData.calcPressureNomVal = new Decimal(newPipeData.pressureNom.replace(',', ''));
    newPipeData.calcPressureMaxVal = new Decimal(newPipeData.pressureMax.replace(',', ''));
    newPipeData.calcTemperatureMinVal = new Decimal(newPipeData.temperatureMin.replace(',', ''));
    newPipeData.calcTemperatureNomVal = new Decimal(newPipeData.temperatureNom.replace(',', ''));
    newPipeData.calcTemperatureMaxVal = new Decimal(newPipeData.temperatureMax.replace(',', ''));

    cond = this.calculateDensity(cond);
    newPipeData.isentropic = new Decimal('1.33');
    let tmp;
    let val;

    if (minNomMax === 'min') {
      newPipeData.calcDensityMinVal = cond.dblDensityValue;
      newPipeData.densityMin = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'min');
      newPipeData.densityMinDisplay = tmp;
      newPipeData.densityMinInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'nom') {
      newPipeData.calcDensityNomVal = cond.dblDensityValue;
      newPipeData.densityNom = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'nom');
      newPipeData.densityNomDisplay = tmp;
      newPipeData.densityNomInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'max') {
      newPipeData.calcDensityMaxVal = cond.dblDensityValue;
      newPipeData.densityMax = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'max');
      newPipeData.densityMaxDisplay = tmp;
      newPipeData.densityMaxInternalUnit = cond.sDensityUnit;
    }

    store.dispatch(
      actions.setStandardConditions(this.calculateDensity(state.defaults.defaultProcessConditions.standardConditions))
    );

    store.dispatch(actions.setNormalConditions(this.calculateDensity(state.defaults.defaultProcessConditions.normalConditions)));

    cond = this.calculateViscosity(cond);

    if (minNomMax === 'min') {
      newPipeData.calcViscosityMinVal = cond.dblViscosityValue;
      newPipeData.viscosityMin = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'min');
      newPipeData.viscosityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcViscosityNomVal = cond.dblViscosityValue;
      newPipeData.viscosityNom = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'nom');
      newPipeData.viscosityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcViscosityMaxVal = cond.dblViscosityValue;
      newPipeData.viscosityMax = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'max');
      newPipeData.viscosityMaxDisplay = tmp;
    }

    newPipeData.viscosityUnit = cond.sViscosityUnit;

    newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
    if (minNomMax === 'min') {
      newPipeData.calcVelocityMinVal = newPipeData.velocity;
      newPipeData.velocityMin = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'min');
      newPipeData.velocityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcVelocityNomVal = newPipeData.velocity;
      newPipeData.velocityNom = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'nom');
      newPipeData.velocityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcVelocityMaxVal = newPipeData.velocity;
      newPipeData.velocityMax = newPipeData.velocity.toString();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'max');
      newPipeData.velocityMaxDisplay = tmp;
    }

    cond.dblPressure = convert.pressureTo(
      `${newPipeData.originalPressurePrefix}-${newPipeData.originalPressureCoefficient}`,
      cond.dblPressure,
      cond.sPressureUnit,
      cond.sPressureGaugeAbsolute
    );
    newPipeData.pressurePrefix = newPipeData.originalPressurePrefix;
    newPipeData.pressureCoefficient = newPipeData.originalPressureCoefficient;

    if (minNomMax === 'min') {
      newPipeData.calcPressureMinVal = cond.dblPressure;
      newPipeData.pressureMin = cond.dblPressure.toString();
    } else if (minNomMax === 'nom') {
      newPipeData.calcPressureNomVal = cond.dblPressure;
      newPipeData.pressureNom = cond.dblPressure.toString();
    } else if (minNomMax === 'max') {
      newPipeData.calcPressureMaxVal = cond.dblPressure;
      newPipeData.pressureMax = cond.dblPressure.toString();
    }

    return newPipeData;
  };
}
