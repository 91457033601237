import Decimal from 'decimal.js';
import api from '../../api';
import store from '../../store';

import constants from '../../constants';
import PipeData from '../../types/PipeData';
import utilities from '..';

const densityTo = (toUnit: string, value: Decimal, fromUnit: string): Decimal => {
  // This function converts the calculated density to the units in the process condition.
  // Neither the database or the value entered by the user are changed.

  // densityTable item
  const itemTo = api.findMeasurement('density', toUnit);

  // Get the factor of the unit to be converted to.
  const dblFactorTo = itemTo.factor;

  const itemFrom = api.findMeasurement('density', fromUnit);

  const dblFactorFrom = itemFrom.factor;

  // Convert to a standard Unit.
  let dblResult = value.times(dblFactorFrom);

  // Convert to the new unit.
  dblResult = dblResult.div(dblFactorTo);

  // Return the result.
  return dblResult;
};

// toUnit   viscosity abbrv String
// value    viscosity Decimal
// fromUnit viscosity abbrv String
const viscosityTo = (toUnit: string, value: Decimal, fromUnit: string): Decimal => {
  // This function will convert the viscosity to the passed units.

  // all Decimal
  let dblResult;

  // viscosityTable item
  const itemTo = api.findMeasurement('viscosity', toUnit);

  // Get the factor of the unit to be converted to.
  const dblFactorTo = itemTo.factor;

  const itemFrom = api.findMeasurement('viscosity', fromUnit);

  const dblFactorFrom = itemFrom.factor;

  // Convert to the new viscosity units.

  // Convert to a standard Unit.
  dblResult = value.times(dblFactorFrom);

  // Convert to the new unit.
  dblResult = dblResult.div(dblFactorTo);

  // Return the result.
  return dblResult;
};

const velocityTo = (toUnit: string, value: Decimal, fromUnit: string): Decimal => {
  // all Decimal
  let dblResult;

  // This function will convert the velocity of the pipe flow data into the units specified.
  // Parse the velocity units.
  const sUnitsTo = toUnit.substring(0, toUnit.indexOf('/'));
  const sTimeTo = toUnit.substring(toUnit.indexOf('/') + 1);

  const sUnitsFrom = fromUnit.substring(0, fromUnit.indexOf('/'));
  const sTimeFrom = fromUnit.substring(fromUnit.indexOf('/') + 1);

  // lengthTable item
  const itemFrom = api.findMeasurement('length', sUnitsFrom);
  let dblFactorFrom = itemFrom.factor;

  const itemTo = api.findMeasurement('length', sUnitsTo);
  let dblFactorTo = itemTo.factor;

  // Find the time from factor.
  const timeFrom = api.findMeasurement('time', sTimeFrom);
  dblFactorFrom = dblFactorFrom.div(timeFrom.factor);

  // Find the time to factor.
  const timeTo = api.findMeasurement('time', sTimeTo);
  dblFactorTo = dblFactorTo.div(timeTo.factor);

  // Calculate the new velocity.
  dblResult = value.times(dblFactorFrom);
  dblResult = dblResult.div(dblFactorTo);

  // Return the result.
  return dblResult;
};

// toUnit abbr temperature String
// value temperature Decimal
// fromUnit abbr temperature String
const temperatureTo = (toUnit: string, value: Decimal, fromUnit: string): Decimal => {
  // This function converts the temperature input by the user to the units passed
  // by the calling routine.  Neither the database or the value entered by the
  // user are changed.

  // Open the units database, and get the factor and offset from the temperature table.
  // all Decimal
  let dblResult;

  const itemTo = api.findTemperature(toUnit);

  // Get the factor and offset of the unit to be converted to.
  const dblFactorTo = itemTo.factor;
  const dblOffsetTo = itemTo.offset;

  const itemFrom = api.findTemperature(fromUnit);

  // Get the factor and offset of the unit to be converted from.
  const dblFactorFrom = itemFrom.factor;
  const dblOffsetFrom = itemFrom.offset;

  // Convert to a standard Unit.
  dblResult = value.plus(dblOffsetFrom).times(dblFactorFrom);

  // Convert to the new unit.
  dblResult = dblResult.div(dblFactorTo).minus(dblOffsetTo);

  // Return the result.
  return dblResult;
};

const pressure = (unitsTo: string, value: Decimal, unitsFrom: string, dblOffset = new Decimal(0.0)): Decimal => {
  // Convert the pressure based upon the passed parameters.
  // Open the units database, and get the factor from the pressure table.

  // all Decimal
  let dblResult;

  // all String
  let sUnitsTo;
  let sUnitsFrom;

  // Because of Barometric Pressure conversion using this function, the
  // units need to be re-parsed.
  if (unitsTo.lastIndexOf('-') !== -1) {
    sUnitsTo = unitsTo.substring(0, unitsTo.lastIndexOf('-'));
  } else {
    sUnitsTo = unitsTo;
  }

  if (unitsFrom.lastIndexOf('-') !== -1) {
    sUnitsFrom = unitsFrom.substring(0, unitsFrom.lastIndexOf('-'));
  } else {
    sUnitsFrom = unitsFrom;
  }

  const itemTo = api.findMeasurement('pressure', sUnitsTo);
  const dblFactorTo = itemTo.factor;

  const itemFrom = api.findMeasurement('pressure', sUnitsFrom);
  const dblFactorFrom = itemFrom.factor;

  // Convert to the standard unit.
  dblResult = value.times(dblFactorFrom).plus(dblOffset);

  // Convert to the new unit.
  dblResult = dblResult.div(dblFactorTo);

  // Return the result.
  return dblResult;
};

const pressureTo = (unitsTo: string, value: Decimal, unitsFrom: string, gauge: string): Decimal => {
  const state = store.getState();
  const { defaults } = state;

  // This function converts the pressure input by the user to the units passed
  // by the calling routine.  Neither the database or the value entered by the
  // user are changed.

  // Determine if the units to be converted from are the same as the
  // units to be converted to.
  if (`${unitsFrom}-${gauge}` === unitsTo) {
    return value;
  }
  // Parse the unit to be converted to.
  const sOffsetTo = unitsTo.substring(unitsTo.lastIndexOf('-') + 1);
  const sUnitsFrom = unitsFrom;
  const sOffsetFrom = gauge;
  let dblOffset;

  if (sOffsetTo !== sOffsetFrom) {
    // Use the barometric pressure unit here.  Always use the
    // ABSOLUTE unit when converting to barometric pressure.
    dblOffset = pressure(
      'Pa-A',
      new Decimal(defaults.defaultValues.baroPressValue),
      `${defaults.defaultUnits.baroPressUnit}-A`,
      new Decimal(0.0)
    );

    if (sOffsetTo === 'G') {
      // Convert from A->G
      dblOffset = dblOffset.negated();
    }
  } else {
    dblOffset = new Decimal('0');
  }

  // Convert the pressure.
  const dblResult = pressure(unitsTo, value, `${sUnitsFrom}-${sOffsetFrom}`, dblOffset);
  return dblResult;
};

const getFlowRateType = (sUnit: string): number => {
  const item = api.findMeasurement('flow', sUnit);
  return item.offset;
};

const flowRateTo = (
  flowRate: Decimal,
  sUnit: string,
  sUnitsFrom: string,
  sTimeFrom: string,
  density: Decimal,
  sDensityUnit: string
): Decimal => {
  const state = store.getState();
  const { defaults } = state;

  // This function will calculate the fluid pipe velocity from the user input.
  // The offset for the flow units holds the mass, volume, or standard unit type.

  // Parse the units and time strings.
  const sUnitsTo = sUnit.substring(0, sUnit.indexOf('/'));
  const sTimeTo = sUnit.substring(sUnit.indexOf('/') + 1);

  // Find the from units factor.
  const itemFrom = api.findMeasurement('flow', sUnitsFrom);
  const dblFlowFactorFrom = itemFrom.factor;
  const iFlowOffsetFrom = itemFrom.offset;

  // Find the to units factor.
  const itemTo = api.findMeasurement('flow', sUnitsTo);
  const dblFlowFactorTo = itemTo.factor;
  const iFlowOffsetTo = itemTo.offset;

  // Calculate the units and time factors.
  const dblFactor = dblFlowFactorFrom.div(dblFlowFactorTo);

  // Find the time factors.
  const timeTo = api.findMeasurement('time', sTimeTo);
  const dblTimeFactorTo = timeTo.factor;

  const timeFrom = api.findMeasurement('time', sTimeFrom);
  const dblTimeFactorFrom = timeFrom.factor;

  // Calculate the time factor.
  const dblTime = dblTimeFactorTo.div(dblTimeFactorFrom);

  // Calculate the density.
  let dblDensity = new Decimal('1.0');

  // Determine if the calculation is necessary.
  if (iFlowOffsetTo !== iFlowOffsetFrom) {
    // eslint-disable-next-line default-case
    switch (iFlowOffsetTo) {
      case 0: // the to unit is volume
        // eslint-disable-next-line default-case
        switch (iFlowOffsetFrom) {
          case 2: // the current unit is Standard.
            dblDensity = densityTo(
              'lb/ft^3',
              defaults.defaultProcessConditions.standardConditions.dblDensityValue,
              defaults.defaultProcessConditions.standardConditions.sDensityUnit
            ).div(densityTo('lb/ft^3', density, sDensityUnit));
            break;

          case 3: // the current unit is normal.
            dblDensity = densityTo(
              'lb/ft^3',
              defaults.defaultProcessConditions.normalConditions.dblDensityValue,
              defaults.defaultProcessConditions.normalConditions.sDensityUnit
            ).div(densityTo('lb/ft^3', density, sDensityUnit));
            break;

          case 1: // the current unit is mass
            dblDensity = new Decimal('1.0').div(densityTo('lb/ft^3', density, sDensityUnit));
            break;

          // case default:
          //   break;
        }
        break;

      case 1: // the to unit is mass
        // eslint-disable-next-line default-case
        switch (iFlowOffsetFrom) {
          case 0: // the current unit is volume
            dblDensity = densityTo('lb/ft^3', density, sDensityUnit);
            break;

          case 2: // the current unit is standard.
            dblDensity = new Decimal('1.0').div(
              densityTo(
                'lb/ft^3',
                defaults.defaultProcessConditions.standardConditions.dblDensityValue,
                defaults.defaultProcessConditions.standardConditions.sDensityUnit
              )
            );
            break;

          case 3: // the current unit is normal.
            dblDensity = new Decimal('1.0').div(
              densityTo(
                'lb/ft^3',
                defaults.defaultProcessConditions.normalConditions.dblDensityValue,
                defaults.defaultProcessConditions.normalConditions.sDensityUnit
              )
            );
            break;
        }
        break;

      case 2: // the unit is standard.
        // eslint-disable-next-line default-case
        switch (iFlowOffsetFrom) {
          case 0: // the current unit is volume
            dblDensity = densityTo('lb/ft^3', density, sDensityUnit).div(
              densityTo(
                'lb/ft^3',
                defaults.defaultProcessConditions.standardConditions.dblDensityValue,
                defaults.defaultProcessConditions.standardConditions.sDensityUnit
              )
            );
            break;

          case 1: // the current unit is standard.
            dblDensity = densityTo(
              'lb/ft^3',
              defaults.defaultProcessConditions.standardConditions.dblDensityValue,
              defaults.defaultProcessConditions.standardConditions.sDensityUnit
            );
            break;

          case 2: // the current unit is normal.
            dblDensity = densityTo('lb/ft^3', density, sDensityUnit).div(
              densityTo(
                'lb/ft^3',
                defaults.defaultProcessConditions.normalConditions.dblDensityValue,
                defaults.defaultProcessConditions.normalConditions.sDensityUnit
              )
            );
            break;
        }
        break;

      case 3: // the unit is normal.
        // eslint-disable-next-line default-case
        switch (iFlowOffsetFrom) {
          case 0: // the current unit is volume
            dblDensity = densityTo('lb/ft^3', density, sDensityUnit).div(
              densityTo(
                'lb/ft^3',
                defaults.defaultProcessConditions.normalConditions.dblDensityValue,
                defaults.defaultProcessConditions.normalConditions.sDensityUnit
              )
            );
            break;

          case 1: // the current unit is normal.
            dblDensity = densityTo(
              'lb/ft^3',
              defaults.defaultProcessConditions.normalConditions.dblDensityValue,
              defaults.defaultProcessConditions.normalConditions.sDensityUnit
            );
            break;

          case 2:
            dblDensity = densityTo('lb/ft^3', density, sDensityUnit).div(
              densityTo(
                'lb/ft^3',
                defaults.defaultProcessConditions.standardConditions.dblDensityValue,
                defaults.defaultProcessConditions.standardConditions.sDensityUnit
              )
            );
            break;
        }
        break;
    }
  }

  // Calculate the velocity.  The returned value will be in ft^3/sec.
  return flowRate.times(dblDensity).times(dblFactor).times(dblTime);
};

// eslint-disable-next-line camelcase
const pipeID_To = (unitsTo: string, pipeData: PipeData): Decimal => {
  // Convert the passed pipe inside diameter to the units passed.

  // Find the to conversion factors.
  const itemTo = api.findMeasurement('length', unitsTo);
  const dblFactorTo = itemTo.factor;

  const itemFrom = api.findMeasurement('length', pipeData.pipeUnit);
  const dblFactorFrom = itemFrom.factor;

  // Calculate the new value.
  const id = new Decimal(pipeData.pipeInsideDiameter);
  let dblResult = id.times(dblFactorFrom);
  dblResult = dblResult.div(dblFactorTo);
  return dblResult;
};

// eslint-disable-next-line camelcase
const pipeInsideDiameter_To = (unitsTo: string, pipeID: string, unitsFrom: string): Decimal => {
  // Convert the passed pipe inside diameter to the units passed.

  let dblResult;

  // Find the to conversion factors.
  const itemTo = api.findMeasurement('length', unitsTo);
  const dblFactorTo = itemTo.factor;

  const itemFrom = api.findMeasurement('length', unitsFrom);
  const dblFactorFrom = itemFrom.factor;

  // Calculate the new value.
  const id = new Decimal(pipeID);
  dblResult = id.times(dblFactorFrom);
  dblResult = dblResult.div(dblFactorTo);
  return dblResult;
};

const getArea = (id: Decimal): Decimal => {
  // return (id / 2) * (id / 2) * 3.14159265369;
  const idDiv2 = id.div(new Decimal('2'));

  return idDiv2.times(idDiv2).times(constants.PI);
};

const pipeId = (value: Decimal, unitTo: string, unitFrom: string): Decimal => {
  // Find the to conversion factors.
  const itemTo = api.findMeasurement('length', unitTo);
  const dblFactorTo = itemTo.factor;

  const itemFrom = api.findMeasurement('length', unitFrom);
  const dblFactorFrom = itemFrom.factor;

  let dblResult = value.times(dblFactorFrom);
  dblResult = dblResult.div(dblFactorTo);

  return dblResult;
};

// remove trailing .0000 if present
const formatNumber = (sNum: string, format?: string | undefined): string => {
  const state = store.getState();
  const { defaults } = state;

  let numDigits = 10;
  let formatToUse = format || '';
  if (format) {
    if (format === 'General Number') {
      formatToUse = defaults.defaultFormats.generalNumberFormat;
    } else if (format === 'Fixed') {
      formatToUse = defaults.defaultFormats.fixedFormat;
    } else if (format === 'Standard') {
      formatToUse = defaults.defaultFormats.standardFormat;
    } else if (format === 'Scientific') {
      return new Decimal(sNum).toExponential();
    }

    const index = formatToUse.indexOf('.');
    if (index > -1) {
      numDigits = formatToUse.length - index - 1;
    }
  }

  let num = sNum;
  if (sNum) {
    num = sNum.replace(/,/g, '');
    num = new Decimal(num).toFixed(numDigits);

    let endsWith = '.';
    for (let i = 0; i < numDigits; i++) {
      endsWith += '0';
    }

    if (num.endsWith(endsWith)) {
      num = num.substring(0, num.length - numDigits + 1);
    }
  }

  return num;
};

// return masked value, replacing defined mask names with format mask
const formatThroughMask = (sNum: string, mask: string): string => {
  const state = store.getState();
  const { defaults } = state;

  let useMask = mask;
  let result = '';
  if (mask === 'General Number') {
    useMask = defaults.defaultFormats.generalNumberFormat;
    result = utilities.format(useMask, sNum);
  } else if (mask === 'Fixed') {
    useMask = defaults.defaultFormats.fixedFormat;
    result = utilities.format(useMask, sNum);
  } else if (mask === 'Standard') {
    useMask = defaults.defaultFormats.standardFormat;
    result = utilities.format(useMask, sNum);
  } else if (mask === 'Scientific') {
    result = new Decimal(sNum).toExponential();
  } else {
    result = utilities.format(useMask, sNum);
  }

  return result;
};

const totalFormat = (decimal: Decimal, format?: string): string => {
  let dispTemp = formatNumber(decimal.toString(), format);
  if (format) {
    dispTemp = formatThroughMask(new Decimal(dispTemp).toString(), format);
  }
  return dispTemp;
};

// format and return a number for display, taking into account the user's preference
// for the format
const displayNumber = (
  pipeData: PipeData, // pipe data... {fluid_type: '', densityMin: '', temperatureMin: '', densityUnit: '', viscosityUnit: ''}
  minNomMax: string, // 'min', 'nom', 'max'
  valType: string, // 'density', 'temperature', 'pressure'
  format: string // 'temperatureFormat'... ('#,###,##0.00##'), 'pressureFormat'... ('#,###,###0.00##')
): [string, Decimal] => {
  const state = store.getState();
  const { defaults } = state;

  // example: temperatureMin
  const calculTypeName = `${valType}${minNomMax.substring(0, 1).toUpperCase()}${minNomMax.substring(1)}`; // temperatureMin

  // example: calcTemperatureMinVal
  const calculValTypeName = `calc${valType.substring(0, 1).toUpperCase()}${valType.substring(1)}${minNomMax
    .substring(0, 1)
    .toUpperCase()}${minNomMax.substring(1)}Val`;
  // eslint-disable-next-line
  // @ts-ignore
  let val = pipeData[calculValTypeName];
  if (!val?.greaterThan(constants.ZERO)) {
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    val = new Decimal(pipeData[calculTypeName]);
  }

  const formatParam = format as keyof typeof defaults.defaultFormats;
  const temp = totalFormat(val, defaults.defaultFormats[formatParam]);
  return [temp, val];
};

const convertAndDisplayDensity = (pipeData: PipeData, minNomMax: string): [string, Decimal] => {
  const state = store.getState();
  const { defaults } = state;

  const calculTypeName = `density${minNomMax.substring(0, 1).toUpperCase()}${minNomMax.substring(1)}`;
  const calculValTypeName = `calcDensity${minNomMax.substring(0, 1).toUpperCase()}${minNomMax.substring(1)}Val`;

  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line
  let val = pipeData[calculValTypeName];
  if (!val?.greaterThan(constants.ZERO)) {
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    val = new Decimal(pipeData[calculTypeName]);
  }

  val = densityTo(defaults.defaultUnits.densityUnit, val, 'lb/ft^3');
  const dispTemp = totalFormat(val, defaults.defaultFormats.densityFormat);
  return [dispTemp, val];
};

const convertAndDisplayViscosity = (pipeData: PipeData, minNomMax: string): [string, Decimal] => {
  const state = store.getState();
  const { defaults } = state;

  const calculTypeName = `viscosity${minNomMax.substring(0, 1).toUpperCase()}${minNomMax.substring(1)}`;
  const calculValTypeName = `calcViscosity${minNomMax.substring(0, 1).toUpperCase()}${minNomMax.substring(1)}Val`;

  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line
  let val = pipeData[calculValTypeName];

  if (!val?.greaterThan(constants.ZERO)) {
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    val = new Decimal(pipeData[calculTypeName]);
  }

  val = viscosityTo(defaults.defaultUnits.viscosityUnit, val, 'cP');
  const dispTemp = totalFormat(val, defaults.defaultFormats.viscosityFormat);
  return [dispTemp, val];
};

const convertAndDisplayVelocity = (pipeData: PipeData, minNomMax: string): [string, Decimal] => {
  const state = store.getState();
  const { defaults } = state;

  const calculTypeName = `velocity${minNomMax.substring(0, 1).toUpperCase()}${minNomMax.substring(1)}`;
  const calculValTypeName = `calcVelocity${minNomMax.substring(0, 1).toUpperCase()}${minNomMax.substring(1)}Val`;

  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line
  let val = pipeData[calculValTypeName];

  if (!val?.greaterThan(constants.ZERO)) {
    // eslint-disable-next-line no-param-reassign
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    val = new Decimal(pipeData[calculTypeName]);
  }

  val = velocityTo(`${defaults.defaultUnits.velocityUnit}/${defaults.defaultUnits.timeUnit}`, val, 'ft/sec');
  const dispTemp = totalFormat(val, defaults.defaultFormats.velocityFormat);
  return [dispTemp, val];
};

export default {
  densityTo,
  viscosityTo,
  velocityTo,
  temperatureTo,
  pressure,
  pressureTo,
  getFlowRateType,
  flowRateTo,
  pipeID_To,
  pipeInsideDiameter_To,
  getArea,
  pipeId,
  formatNumber,
  formatThroughMask,
  displayNumber,
  convertAndDisplayDensity,
  convertAndDisplayViscosity,
  convertAndDisplayVelocity,
  totalFormat,
};
