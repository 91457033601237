import SizingGlobals from '../../types/sizingGlobals';

const PanaflowSizingGlobals: SizingGlobals = {
  goodUsableColor: '#003300',
  reducing: true,
  turbine: false,
  vorcone: false,
  ultrasonic: false,
  s36: false,
  u44: false,
  proM: false,
  VorconeModel: 'MVC VorCone',
  VorconeReduceModel: 'MVCR Reducing VorCone',
  VorconeModelStr: 'MVC VorCone',
  VorconeReduceModelStr: 'MVCR Reducing VorCone',
  InlineReduceModel: 'MV84R',
  InlineNonReduceModel: 'MV84',
  InlineModel: 'MV80',
  InlineModelPrev: '',
  InsertionModel: 'MV82',
  InsertionModelPrev: '',
  TurbineModel: '',
  TurbineModelShort: '',
  InlineModelStr: 'PanaFlow™ MV Model MV80',
  InlineNonReduceModelStr: 'PanaFlow™ MV Model MV84',
  InlineReduceModelStr: 'PanaFlow™ Model MV84R',
  InsertionModelStr: 'PanaFlow™ MV Model MV82',
  UltrasonicS34Model: 'S34',
  UltrasonicS34ModelStr: 'S34 Ultrasonic',
  UltrasonicS36Model: 'S36',
  UltrasonicS36ModelStr: 'S36 Ultrasonic',
  UltrasonicU42Model: 'U42',
  UltrasonicU42ModelStr: 'U42 Ultrasonic',
  UltrasonicU43Model: 'U43',
  UltrasonicU43ModelStr: 'U43 Ultrasonic',
  UltrasonicU44Model: 'U44',
  UltrasonicU44ModelStr: 'U44 Ultrasonic',
  ElectromagneticProMModel: 'Pro-M',
  ElectromagneticProMModelStr: 'Electromagnetic Flow Meter',
  ProductLineStr: 'PanaFlow™ MV',
  HelpMenuStr: 'PanaFlow™ Sizer Help...',
  STANDARD_TEMP: 'ST',
  HIGH_TEMP: 'HT',
  WAFER: 'W',
  ANSI_150: '150',
  ANSI_300: '300',
  ANSI_600: '600',
  ANSI_900: '',
  DN_PN16: '16',
  DN_PN40: '40',
  DN_PN64: '63',
  DN_PN100: '100',
  JIS_10K_J1: '',
  JIS_16K_K: '',
  JIS_20K_J2: '',
  JIS_30K_J3: '',
  JIS_40K_J4: '',
  JIS_10K_Y1: '',
  JIS_20K_Y2: '',
  LocalStr: 'L',
  RemoteStr: 'R()',
  RemoteStr1: '',
  RemoteStr2: '',
  RemoteStr3: '',
  RemoteStr4: '',
  CNPT: 'CNPT',
  C150: 'C150',
  C16: 'C16',
  CJ10: '',
  CFJ1K: '',
  C300: 'C300',
  C40: 'C40',
  CJ20: '',
  CFJ2K: '',
  C600: 'C600',
  C64: 'C63',
  CJ30: '',
  CFJ3K: '',
  C900: '',
  CFJ4K: '',
  PNPT: 'PNPT',
  P150: 'P150',
  P16: 'P16',
  PJ10: '',
  PGJ1K: '',
  P300: 'P300',
  P40: 'P40',
  PJ20: '',
  PJ30: '',
  PGJ2K: '',
  PGJ3K: '',
  PGJ4K: '',
  PNPTR: 'PNPTR',
  PNPTRE: '',
  P150R: 'P150R',
  P150RE: '',
  P16R: 'P16R',
  P16RE: '',
  PJ10R: '',
  PGJ1R: '',
  P300R: 'P300R',
  P300RE: '',
  P40R: 'P40R',
  P40RE: '',
  PJ20R: '',
  PGJ2R: '',
  P600R: 'P600R',
  P600RE: '',
  P600: 'P600',
  P64R: 'P63R',
  P64RE: '',
  PJ30R: '',
  P64: 'P63',
  PGJ3R: '',
  PGJ4R: '',
  ENPTR: '',
  E5016R: '',
  E5040R: '',
  E5064R: '',
  E150R: '',
  E300R: '',
  E600R: '',
  StandardLength: 'SL',
  CompactLength: 'CL',
  ExtendedLength: 'EL',
  V_METER: 'V',
  VT_METER: 'VT',
  VTP_METER: 'VTP',
  VTP_METER_P1: '',
  VTP_METER_P2: '',
  VTP_METER_P3: '',
  VTP_METER_P4: '',
  VTEP_METER: 'VTEP',
  VETEP_METER: 'VETEP',
  VTEM_METER: 'VT-EM',
  VTPEM_METER: 'VTP-EM',
  VTEPEM_METER: 'VTEP-EM',
  VETEPEM_METER: 'VETEP-EM',
  LOOP_OUTPUT: '1AHL',
  LOOP_POWERED: 'DC2',
  VDC_1236_POWERED: 'DC4',
  AC_POWERED: 'AC',
  SPECIAL_STUFF: '',
  POE: 'DC4POE',
  ONE_OUTPUT: '1AH',
  NO_ALARM_MODBUS_OUTPUT: '1AM',
  BACNET: '1AB',
  NO_ALARM_MODBUS_POE: '1AMIP',
  ALARM_MODBUS: '3AM',
  ALARM_MODBUS_POE: '3AMIP',
  BACNET_POE: '1ABIP',
  ALARM_BACNET: '3AB',
  ALARM_BACNET_POE: '3ABIP',
  ALARM_HART: '3AH',
  MORE_LOOP_POWERED: [],
  INLINE_NON_LOOP_ORDER: ['36', '37', '38', '39', '40', '41', '42', '43', '44', '45'],
  INSERTION_NON_LOOP_ORDER: [],
  NO_PRESSURE_TRANSDUCER: 'P0',
  DIFF_PRESS_XMTR_FACT: '',
  DIFF_PRESS_XMTR_CUST: '',
  DIAG_ADD_PRESS_TAP_FT: '',

  // This is the Meter and code information.
  CODE_NAME: 'PanaFlow&trade; MV',
  CODE_DESCRIPTION: 'PanaFlow&trade; MV Vortex Flow Meter',
  TURBINE_CODE_NAME: 'PanaFlow&trade; MV',
  TURBINE_CODE_DESCRIPTION: 'PanaFlow&trade; MV Turbine Flow Meter',
  DATA_SHEET: 'APPLICATION DATA SHEET',
  DESCRIPTION: 'PanaFlow&trade; MV Model MV80 and Model MV82 Vortex Meter Sizing Program. Written for GE Sensing',
  COPYRIGHT:
    'Copyright 2007-2024 Vortek Instruments, LLC.\nAll rights reserved.\nNo warranty is expressed or implied about the accuracy of the calculations used in this program.',

  // This is the GE Sensing information.
  VORTEK_DISPLAY_NAME: 'PanaflowMV',
  VORTEK_PRINT_NAME: 'PanaflowMV',
  VORTEK_NAME: 'PanaflowMV',
  VORTEK_ADDRESS0: '1100 Technology Park Drive',
  VORTEK_ADDRESS1: '',
  VORTEK_CITY: 'Billerica',
  VORTEK_STATE: 'Massachusetts',
  VORTEK_POSTAL_CODE: '01821-4111',
  VORTEK_COUNTRY: 'USA',
  VORTEK_TELEPHONE: 'Telephone: 978-437-1000',
  VORTEK_FAX: 'Customer Care FAX: 502-479-6722',
};

export default PanaflowSizingGlobals;
