import { createStore } from 'redux';
// import logger from 'redux-logger';
import rootReducer from './reducers';

const configureAppStore = () => {
  const store = createStore(rootReducer);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
};

const store = configureAppStore();

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
