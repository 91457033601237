import { CallbackNoParams } from '../../types/callbackTypes';
import Modal from '../common/Modal';
import CustomerComponent from './customer';

const CustomerModal = (props: { onClose?: CallbackNoParams }): JSX.Element | null => {
  const { onClose } = props;

  return (
    <Modal title="Customer" visible showButtons={false} showCloseButton onClose={onClose}>
      <div className="flexColCenter modalContentTabs">
        <div className="flexColNoGrowCenter leftRight10 topBottom20 font12">
          <CustomerComponent onClose={onClose} />
        </div>
      </div>
    </Modal>
  );
};

CustomerModal.defaultProps = {
  onClose: undefined,
};

export default CustomerModal;
