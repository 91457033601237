import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import { RootState } from '../../store';
import actions from '../../store/actions';
import OtherGasRow from '../../types/OtherGasRow';
import OtherLiquidRow from '../../types/OtherLiquidRow';

const Fluids = (): JSX.Element => {
  const dispatch = useDispatch();

  const defaultAllFluidTypes: any[] = [];
  const [allFluidTypes, setAllFluidTypes] = useState(defaultAllFluidTypes);
  const [fluidTypes, setFluidTypes] = useState(defaultAllFluidTypes);
  const [fluids, setFluids] = useState(defaultAllFluidTypes);

  const state = useSelector((globalState: RootState) => globalState);
  const dbFluid = useSelector((globalState: RootState) => globalState.db.fluid);

  const { fluid, fluidType, meterType, sizingGlobals, username, otherGas, otherLiquid } = state;

  const {
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    ElectromagneticProMModel,
    VORTEK_NAME,
  } = sizingGlobals;

  // Load the fluid types once so the fluid types effect does not have to be async
  useEffect(() => {
    setAllFluidTypes(api.findFluids());
  }, [dbFluid]);

  // this runs when the types are available in redux, or the meter type changes
  useEffect(() => {
    const newFluidTypes = [] as string[];
    setFluidTypes(newFluidTypes);

    if (allFluidTypes?.length) {
      let firstFluidType;

      for (let i = 0; i < allFluidTypes.length; i++) {
        const val = allFluidTypes[i].abbrv;
        if (
          meterType === UltrasonicS34Model ||
          meterType === UltrasonicS36Model ||
          meterType === UltrasonicU42Model ||
          meterType === UltrasonicU44Model ||
          meterType === ElectromagneticProMModel
        ) {
          if (val === 'Steam' || val === 'Gas' || val === 'Other Gas') {
            continue;
          }
        } else if (meterType === UltrasonicU43Model) {
          if (val !== 'Water') {
            continue;
          }
        }

        if (!firstFluidType) {
          firstFluidType = val;
        }

        newFluidTypes.push(val);
      }

      setFluidTypes(newFluidTypes);
      if (firstFluidType && (!fluid || !newFluidTypes.includes(fluidType))) {
        dispatch(actions.setFluidType(firstFluidType));
      }
    }
  }, [allFluidTypes, meterType]);

  useEffect(() => {
    const newFluids = [] as string[];
    const currentFluidType = fluidType || 'Steam';
    const fluidsForType = api.findFluids(currentFluidType);
    let firstFluid = '';

    if (fluidsForType?.types?.length > 0) {
      fluidsForType.types.forEach((f: any) => {
        const { abbrv } = f;
        let skip = false;

        if (currentFluidType === 'Liquid') {
          if (
            meterType === UltrasonicS34Model ||
            meterType === UltrasonicS36Model ||
            meterType === UltrasonicU42Model ||
            meterType === UltrasonicU44Model ||
            meterType === ElectromagneticProMModel
          ) {
            if (
              abbrv === 'Ammonium hydroxide' ||
              abbrv === 'Brine 7%' ||
              abbrv === 'Butyl acetate' ||
              abbrv === 'Ethylene' ||
              abbrv === 'Gasoline' ||
              abbrv === 'Naphthalene' ||
              abbrv === 'Phenylbenzene' ||
              abbrv === 'Phosgene'
            ) {
              skip = true;
            }
          }
        }

        if (!skip) {
          if (!firstFluid) {
            firstFluid = abbrv;
          }

          newFluids.push(abbrv);
        }
      });
    }

    if (currentFluidType === 'Other Gas') {
      dispatch(actions.setSoundSpeed(''));
      const res = api.findAllDataForUserInReduxTable(VORTEK_NAME, username, 'othergas');

      res.forEach((row) => {
        const { data } = row;
        const og = data as unknown as OtherGasRow;
        if (!firstFluid) {
          firstFluid = og.gas_name;
        }

        newFluids.push(og.gas_name);
      });

      if (!firstFluid && otherGas.gas_name) {
        firstFluid = otherGas.gas_name;
      }
    } else if (currentFluidType === 'Other Liquid') {
      dispatch(actions.setSoundSpeed(''));
      const res = api.findAllDataForUserInReduxTable(VORTEK_NAME, username, 'otherliquid');

      res.forEach((row: any) => {
        const { data } = row;
        const ol = data as unknown as OtherLiquidRow;
        if (!firstFluid) {
          firstFluid = ol.liquid_name;
        }

        newFluids.push(ol.liquid_name);
      });

      if (firstFluid && otherLiquid.liquid_name) {
        firstFluid = otherLiquid.liquid_name;
      }
    }

    setFluids(newFluids);
    if (firstFluid && (!fluid || !newFluids.includes(fluid))) {
      dispatch(actions.setFluid(firstFluid));
    }
  }, [dbFluid, meterType, fluidType]);

  const changeFluidType = useCallback(
    (e) => {
      dispatch(actions.setFluidType(e.target.value));
    },
    [fluidType]
  );

  const changeFluid = useCallback(
    (e) => {
      dispatch(actions.setFluid(e.target.value));
    },
    [fluid]
  );

  return (
    <div className="flexRowNoGrow topBottom5">
      <div className="namePad">Fluid</div>
      <div className="fluidSelect">
        <select value={fluidType} name="fluidTypes" className="drop" onChange={changeFluidType}>
          {fluidTypes.map((p) => {
            return (
              <option key={p} value={p}>
                {p}
              </option>
            );
          })}
        </select>
      </div>
      <div className="fluidTypeSelect">
        <select value={fluid} name="fluids" className="drop" onChange={changeFluid}>
          {fluids.map((p) => {
            return (
              <option key={p} value={p}>
                {p}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default Fluids;
