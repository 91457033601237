import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const WarningsTab = (): JSX.Element | null => {
  const specifyMeterWarnings = useSelector((globalState: RootState) => globalState.specifyMeterWarnings);
  let warnings = [] as string[];

  if (specifyMeterWarnings && specifyMeterWarnings !== 'No Warnings') {
    warnings = specifyMeterWarnings.split('\n');
  }

  return (
    <div className="modalContentTabs">
      <div className="flexCol iform">
        {warnings.map((warning) => (
          <div key={warning} className="flexRow left20 nowrap">
            {warning}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WarningsTab;
