import Decimal from 'decimal.js';
import utilities from '.';
import api from '../api';
import store from '../store';
import JisPipeSchedule from '../types/JisPipeSchedule';
import PipeData from '../types/PipeData';

// This file contains the code for pipe sizes and details

const gatherPipeData = (od: string, schedule: string, size: string, unit: string): PipeData => {
  const state = store.getState();
  const { processConditions } = state;

  const pipeData = { ...processConditions };
  pipeData.fluidType = state.fluidType;
  pipeData.fluid = state.fluid;
  pipeData.pipeInsideDiameter = size;
  pipeData.pipeReduceInsideDiameter = state.pipeReduceInsideDiameter;
  pipeData.pipeSchedule = schedule;
  pipeData.pipeSize = od;
  pipeData.pipeReduceSize = state.pipeReduceOutsideDiameter;
  pipeData.pipeType = state.pipeType;
  pipeData.pipeUnit = unit;
  return pipeData;
};

const gatherSelectedPipeData = (
  outsideDiameter: string,
  size: string,
  unit: string,
  schedule: string,
  insideArea: Decimal,
  insideUnit: string,
  beta?: string
): PipeData => {
  const state = store.getState();

  // fixup values for other because of the way the fields
  // are populated
  const pipeData = gatherPipeData(outsideDiameter, schedule, size, unit);

  if (state.pipeType === 'other') {
    const temp = pipeData.pipeInsideDiameter;
    // eslint-disable-next-line prefer-destructuring
    pipeData.pipeInsideDiameter = pipeData.pipeSize.split(' ')[0];
    pipeData.pipeSize = temp;
  }

  pipeData.insideArea = insideArea;
  pipeData.insideUnit = insideUnit;
  pipeData.beta = beta;
  return pipeData;
};

const findReferenceData = (VORTEK_NAME: string, username: string, collection: string): any[] => {
  let data = [] as any[];

  const ret = api.findDataInRedux(VORTEK_NAME, username, collection);

  if (ret) {
    data = Array.from(ret.data as unknown as any[]);
  }

  return data;
};

const getAnsiPipeSizes = (
  VORTEK_NAME: string,
  reducingVortex: boolean,
  vorconeActive: boolean,
  reducingVorcone: boolean
): string[] => {
  const state = store.getState();
  const { meterType, sizingGlobals } = state;
  const { ElectromagneticProMModel } = sizingGlobals;

  const data = findReferenceData(VORTEK_NAME, 'DEFAULT', 'ansiPipeSizes');

  if (reducingVortex || vorconeActive || reducingVorcone) {
    // remove sizes that aren't in the inlines table
    data.splice(15, 26);
    data.splice(10, 1);
    data.splice(8, 1);
    data.splice(6, 1);
    data.splice(3, 1);

    // remove sizes from .5" to 1.5" for vorcone
    if (vorconeActive || reducingVorcone) {
      data.splice(0, 4);
      data.splice(6, 1);
    }

    if (reducingVortex) {
      data.splice(10, 1);
    }

    if (VORTEK_NAME === utilities.ONICON_NAME) {
      data.push('12 in');
    } else {
      data.push('12 in Stainless Steel');
      data.push('12 in Carbon Steel');
    }

    // for reducing vortex, remove .5"
    // for reducing vorcone, remove 2"
    if (!vorconeActive) {
      data.splice(0, 1);
    }
  } else if (meterType === ElectromagneticProMModel) {
    data.splice(25, 1);
    data.splice(23, 1);
    data.splice(21, 1);
    data.splice(19, 1);
    data.splice(10, 1);
    data.splice(8, 1);
    data.splice(6, 1);
    data.splice(3, 1);
  }

  return data;
};

const getSchedules = (VORTEK_NAME: string): string[] => {
  return findReferenceData(VORTEK_NAME, 'DEFAULT', 'pipeSchedules');
};

const getDinPipeSizes = (VORTEK_NAME: string): string[] => {
  return findReferenceData(VORTEK_NAME, 'DEFAULT', 'dinPipeSizes');
};

const getJisPipeSizes = (VORTEK_NAME: string): string[] => {
  return findReferenceData(VORTEK_NAME, 'DEFAULT', 'jisPipeSizes');
};

const getJisSchedules = (VORTEK_NAME: string): JisPipeSchedule[] => {
  return findReferenceData(VORTEK_NAME, 'DEFAULT', 'jisPipeSchedules') as JisPipeSchedule[];
};

// get all schedules for a given outside diameter
const getJisDataForLength = (VORTEK_NAME: string, od: string): JisPipeSchedule[] => {
  const ret = [];
  const pipes = getJisSchedules(VORTEK_NAME);

  for (let i = 0; i < pipes.length; i++) {
    if (pipes[i].NominalSize === od) {
      ret.push(pipes[i]);
    }
  }

  return ret;
};

const findJisPipeInsideDiameter = (VORTEK_NAME: string, od: string, sch: string): string => {
  const pipes = getJisSchedules(VORTEK_NAME);

  for (let i = 0; i < pipes.length; i++) {
    if (pipes[i].NominalSize === od && pipes[i].Schedule === sch) {
      return pipes[i].InsideDiameter;
    }
  }

  return '0';
};

const findJisPipe = (VORTEK_NAME: string, od: string, sch: string): JisPipeSchedule | undefined => {
  const pipes = getJisSchedules(VORTEK_NAME);

  for (let i = 0; i < pipes.length; i++) {
    if (pipes[i].NominalSize === od && pipes[i].Schedule === sch) {
      return pipes[i];
    }
  }

  return undefined;
};

const findPipes = (type?: string): any => {
  const state = store.getState();

  const { meterType, sizingGlobals } = state;
  const { ElectromagneticProMModel } = sizingGlobals;

  const lengthIn = api.findMeasurement('length', 'in');
  const lengthMm = api.findMeasurement('length', 'mm');
  const pipesTable = [
    {
      abbrv: '0.5 in40',
      data: ['0.5 in', '40', new Decimal('0.109'), new Decimal('0.622'), lengthIn],
    },
    {
      abbrv: '0.5 in80',
      data: ['0.5 in', '80', new Decimal('0.147'), new Decimal('0.546'), lengthIn],
    },
    {
      abbrv: '0.5 in160',
      data: ['0.5 in', '160', new Decimal('0.187'), new Decimal('0.466'), lengthIn],
    },
    {
      abbrv: '0.5 inSTD',
      data: ['0.5 in', 'STD', new Decimal('0.109'), new Decimal('0.622'), lengthIn],
    },
    {
      abbrv: '0.5 inXS',
      data: ['0.5 in', 'XS', new Decimal('0.147'), new Decimal('0.546'), lengthIn],
    },
    {
      abbrv: '0.5 inXXS',
      data: ['0.5 in', 'XXS', new Decimal('0.294'), new Decimal('0.252'), lengthIn],
    },
    {
      abbrv: '0.75 in40',
      data: ['0.75 in', '40', new Decimal('0.113'), new Decimal('0.824'), lengthIn],
    },
    {
      abbrv: '0.75 in80',
      data: ['0.75 in', '80', new Decimal('0.154'), new Decimal('0.742'), lengthIn],
    },
    {
      abbrv: '0.75 in160',
      data: ['0.75 in', '160', new Decimal('0.219'), new Decimal('0.612'), lengthIn],
    },
    {
      abbrv: '0.75 inSTD',
      data: ['0.75 in', 'STD', new Decimal('0.113'), new Decimal('0.824'), lengthIn],
    },
    {
      abbrv: '0.75 inXS',
      data: ['0.75 in', 'XS', new Decimal('0.154'), new Decimal('0.742'), lengthIn],
    },
    {
      abbrv: '0.75 inXXS',
      data: ['0.75 in', 'XXS', new Decimal('0.308'), new Decimal('0.434'), lengthIn],
    },
    {
      abbrv: '1 in40',
      data: ['1 in', '40', new Decimal('0.133'), new Decimal('1.049'), lengthIn],
    },
    {
      abbrv: '1 in80',
      data: ['1 in', '80', new Decimal('0.179'), new Decimal('0.957'), lengthIn],
    },
    {
      abbrv: '1 in160',
      data: ['1 in', '160', new Decimal('0.25'), new Decimal('0.815'), lengthIn],
    },
    {
      abbrv: '1 inSTD',
      data: ['1 in', 'STD', new Decimal('0.133'), new Decimal('1.049'), lengthIn],
    },
    {
      abbrv: '1 inXS',
      data: ['1 in', 'XS', new Decimal('0.179'), new Decimal('0.957'), lengthIn],
    },
    {
      abbrv: '1 inXXS',
      data: ['1 in', 'XXS', new Decimal('0.358'), new Decimal('0.599'), lengthIn],
    },
    {
      abbrv: '1.25 in40',
      data: ['1.25 in', '40', new Decimal('0.14'), new Decimal('1.38'), lengthIn],
    },
    {
      abbrv: '1.25 in80',
      data: ['1.25 in', '80', new Decimal('0.191'), new Decimal('1.278'), lengthIn],
    },
    {
      abbrv: '1.25 in160',
      data: ['1.25 in', '160', new Decimal('0.25'), new Decimal('1.16'), lengthIn],
    },
    {
      abbrv: '1.25 inSTD',
      data: ['1.25 in', 'STD', new Decimal('0.14'), new Decimal('1.38'), lengthIn],
    },
    {
      abbrv: '1.25 inXS',
      data: ['1.25 in', 'XS', new Decimal('0.191'), new Decimal('1.278'), lengthIn],
    },
    {
      abbrv: '1.25 inXXS',
      data: ['1.25 in', 'XXS', new Decimal('0.382'), new Decimal('0.896'), lengthIn],
    },
    {
      abbrv: '1.5 in40',
      data: ['1.5 in', '40', new Decimal('0.145'), new Decimal('1.61'), lengthIn],
    },
    {
      abbrv: '1.5 in80',
      data: ['1.5 in', '80', new Decimal('0.2'), new Decimal('1.5'), lengthIn],
    },
    {
      abbrv: '1.5 in160',
      data: ['1.5 in', '160', new Decimal('0.281'), new Decimal('1.388'), lengthIn],
    },
    {
      abbrv: '1.5 inSTD',
      data: ['1.5 in', 'STD', new Decimal('0.145'), new Decimal('1.61'), lengthIn],
    },
    {
      abbrv: '1.5 inXS',
      data: ['1.5 in', 'XS', new Decimal('0.2'), new Decimal('1.5'), lengthIn],
    },
    {
      abbrv: '1.5 inXXS',
      data: ['1.5 in', 'XXS', new Decimal('0.4'), new Decimal('1.1'), lengthIn],
    },
    {
      abbrv: '2 in40',
      data: ['2 in', '40', new Decimal('0.154'), new Decimal('2.067'), lengthIn],
    },
    {
      abbrv: '2 in80',
      data: ['2 in', '80', new Decimal('0.218'), new Decimal('1.939'), lengthIn],
    },
    {
      abbrv: '2 in160',
      data: ['2 in', '160', new Decimal('0.344'), new Decimal('1.687'), lengthIn],
    },
    {
      abbrv: '2 inSTD',
      data: ['2 in', 'STD', new Decimal('0.154'), new Decimal('2.067'), lengthIn],
    },
    {
      abbrv: '2 inXS',
      data: ['2 in', 'XS', new Decimal('0.218'), new Decimal('1.939'), lengthIn],
    },
    {
      abbrv: '2 inXXS',
      data: ['2 in', 'XXS', new Decimal('0.436'), new Decimal('1.503'), lengthIn],
    },
    {
      abbrv: '2.5 in40',
      data: ['2.5 in', '40', new Decimal('0.203'), new Decimal('2.469'), lengthIn],
    },
    {
      abbrv: '2.5 in80',
      data: ['2.5 in', '80', new Decimal('0.276'), new Decimal('2.323'), lengthIn],
    },
    {
      abbrv: '2.5 in160',
      data: ['2.5 in', '160', new Decimal('0.375'), new Decimal('2.125'), lengthIn],
    },
    {
      abbrv: '2.5 inSTD',
      data: ['2.5 in', 'STD', new Decimal('0.203'), new Decimal('2.469'), lengthIn],
    },
    {
      abbrv: '2.5 inXS',
      data: ['2.5 in', 'XS', new Decimal('0.552'), new Decimal('1.771'), lengthIn],
    },
    {
      abbrv: '2.5 inXXS',
      data: ['2.5 in', 'XXS', new Decimal('0.552'), new Decimal('1.771'), lengthIn],
    },
    {
      abbrv: '3 in40',
      data: ['3 in', '40', new Decimal('0.216'), new Decimal('3.068'), lengthIn],
    },
    {
      abbrv: '3 in80',
      data: ['3 in', '80', new Decimal('0.3'), new Decimal('2.9'), lengthIn],
    },
    {
      abbrv: '3 in160',
      data: ['3 in', '160', new Decimal('0.438'), new Decimal('2.624'), lengthIn],
    },
    {
      abbrv: '3 inSTD',
      data: ['3 in', 'STD', new Decimal('0.216'), new Decimal('3.068'), lengthIn],
    },
    {
      abbrv: '3 inXS',
      data: ['3 in', 'XS', new Decimal('0.3'), new Decimal('2.9'), lengthIn],
    },
    {
      abbrv: '3 inXXS',
      data: ['3 in', 'XXS', new Decimal('0.6'), new Decimal('2.3'), lengthIn],
    },
    {
      abbrv: '3.5 in40',
      data: ['3.5 in', '40', new Decimal('0.226'), new Decimal('3.548'), lengthIn],
    },
    {
      abbrv: '3.5 in80',
      data: ['3.5 in', '80', new Decimal('0.318'), new Decimal('3.64'), lengthIn],
    },
    {
      abbrv: '3.5 inSTD',
      data: ['3.5 in', 'STD', new Decimal('0.226'), new Decimal('3.548'), lengthIn],
    },
    {
      abbrv: '3.5 inXS',
      data: ['3.5 in', 'XS', new Decimal('0.318'), new Decimal('3.64'), lengthIn],
    },
    {
      abbrv: '4 in40',
      data: ['4 in', '40', new Decimal('0.237'), new Decimal('4.026'), lengthIn],
    },
    {
      abbrv: '4 in80',
      data: ['4 in', '80', new Decimal('0.337'), new Decimal('3.826'), lengthIn],
    },
    {
      abbrv: '4 in120',
      data: ['4 in', '120', new Decimal('0.438'), new Decimal('3.624'), lengthIn],
    },
    {
      abbrv: '4 in160',
      data: ['4 in', '160', new Decimal('0.531'), new Decimal('3.438'), lengthIn],
    },
    {
      abbrv: '4 inSTD',
      data: ['4 in', 'STD', new Decimal('0.237'), new Decimal('4.026'), lengthIn],
    },
    {
      abbrv: '4 inXS',
      data: ['4 in', 'XS', new Decimal('0.337'), new Decimal('3.826'), lengthIn],
    },
    {
      abbrv: '4 inXXS',
      data: ['4 in', 'XXS', new Decimal('0.674'), new Decimal('3.152'), lengthIn],
    },
    {
      abbrv: '5 in40',
      data: ['5 in', '40', new Decimal('0.258'), new Decimal('5.047'), lengthIn],
    },
    {
      abbrv: '5 in80',
      data: ['5 in', '80', new Decimal('0.375'), new Decimal('4.813'), lengthIn],
    },
    {
      abbrv: '5 in120',
      data: ['5 in', '120', new Decimal('0.5'), new Decimal('4.63'), lengthIn],
    },
    {
      abbrv: '5 in160',
      data: ['5 in', '160', new Decimal('0.625'), new Decimal('4.313'), lengthIn],
    },
    {
      abbrv: '5 inSTD',
      data: ['5 in', 'STD', new Decimal('0.258'), new Decimal('5.047'), lengthIn],
    },
    {
      abbrv: '5 inXS',
      data: ['5 in', 'XS', new Decimal('0.375'), new Decimal('4.813'), lengthIn],
    },
    {
      abbrv: '5 inXXS',
      data: ['5 in', 'XXS', new Decimal('0.75'), new Decimal('4.063'), lengthIn],
    },
    {
      abbrv: '6 in40',
      data: ['6 in', '40', new Decimal('0.28'), new Decimal('6.065'), lengthIn],
    },
    {
      abbrv: '6 in80',
      data: ['6 in', '80', new Decimal('0.432'), new Decimal('5.761'), lengthIn],
    },
    {
      abbrv: '6 in120',
      data: ['6 in', '120', new Decimal('0.562'), new Decimal('5.501'), lengthIn],
    },
    {
      abbrv: '6 in160',
      data: ['6 in', '160', new Decimal('0.719'), new Decimal('5.187'), lengthIn],
    },
    {
      abbrv: '6 inSTD',
      data: ['6 in', 'STD', new Decimal('0.28'), new Decimal('6.065'), lengthIn],
    },
    {
      abbrv: '6 inXS',
      data: ['6 in', 'XS', new Decimal('0.432'), new Decimal('5.761'), lengthIn],
    },
    {
      abbrv: '6 inXXS',
      data: ['6 in', 'XXS', new Decimal('0.864'), new Decimal('4.897'), lengthIn],
    },
    {
      abbrv: '8 in20',
      data: ['8 in', '20', new Decimal('0.25'), new Decimal('8.125'), lengthIn],
    },
    {
      abbrv: '8 in30',
      data: ['8 in', '30', new Decimal('0.277'), new Decimal('8.071'), lengthIn],
    },
    {
      abbrv: '8 in40',
      data: ['8 in', '40', new Decimal('0.322'), new Decimal('7.981'), lengthIn],
    },
    {
      abbrv: '8 in60',
      data: ['8 in', '60', new Decimal('0.406'), new Decimal('7.813'), lengthIn],
    },
    {
      abbrv: '8 in80',
      data: ['8 in', '80', new Decimal('0.5'), new Decimal('7.625'), lengthIn],
    },
    {
      abbrv: '8 in100',
      data: ['8 in', '100', new Decimal('0.594'), new Decimal('7.437'), lengthIn],
    },
    {
      abbrv: '8 in120',
      data: ['8 in', '120', new Decimal('0.719'), new Decimal('7.187'), lengthIn],
    },
    {
      abbrv: '8 in140',
      data: ['8 in', '140', new Decimal('0.812'), new Decimal('7.001'), lengthIn],
    },
    {
      abbrv: '8 in160',
      data: ['8 in', '160', new Decimal('0.603'), new Decimal('3.813'), lengthIn],
    },
    {
      abbrv: '8 inSTD',
      data: ['8 in', 'STD', new Decimal('0.322'), new Decimal('7.981'), lengthIn],
    },
    {
      abbrv: '8 inXS',
      data: ['8 in', 'XS', new Decimal('0.5'), new Decimal('7.625'), lengthIn],
    },
    {
      abbrv: '8 inXXS',
      data: ['8 in', 'XXS', new Decimal('0.875'), new Decimal('6.8725'), lengthIn],
    },
    {
      abbrv: '10 in20',
      data: ['10 in', '20', new Decimal('0.25'), new Decimal('10.25'), lengthIn],
    },
    {
      abbrv: '10 in30',
      data: ['10 in', '30', new Decimal('0.307'), new Decimal('10.136'), lengthIn],
    },
    {
      abbrv: '10 in40',
      data: ['10 in', '40', new Decimal('0.365'), new Decimal('10.02'), lengthIn],
    },
    {
      abbrv: '10 in60',
      data: ['10 in', '60', new Decimal('0.5'), new Decimal('9.75'), lengthIn],
    },
    {
      abbrv: '10 in80',
      data: ['10 in', '80', new Decimal('0.594'), new Decimal('9.562'), lengthIn],
    },
    {
      abbrv: '10 in100',
      data: ['10 in', '100', new Decimal('0.719'), new Decimal('9.312'), lengthIn],
    },
    {
      abbrv: '10 in120',
      data: ['10 in', '120', new Decimal('0.844'), new Decimal('9.062'), lengthIn],
    },
    {
      abbrv: '10 in140',
      data: ['10 in', '140', new Decimal('1'), new Decimal('8.75'), lengthIn],
    },
    {
      abbrv: '10 in160',
      data: ['10 in', '160', new Decimal('1.125'), new Decimal('8.5'), lengthIn],
    },
    {
      abbrv: '10 inSTD',
      data: ['10 in', 'STD', new Decimal('0.365'), new Decimal('10.02'), lengthIn],
    },
    {
      abbrv: '10 inXS',
      data: ['10 in', 'XS', new Decimal('0.5'), new Decimal('9.75'), lengthIn],
    },
    {
      abbrv: '10 inXXS',
      data: ['10 in', 'XXS', new Decimal('1'), new Decimal('8.75'), lengthIn],
    },
    {
      abbrv: '12 in20',
      data: ['12 in', '20', new Decimal('0.25'), new Decimal('12.25'), lengthIn],
    },
    {
      abbrv: '12 in30',
      data: ['12 in', '30', new Decimal('0.33'), new Decimal('12.09'), lengthIn],
    },
    {
      abbrv: '12 in40',
      data: ['12 in', '40', new Decimal('0.406'), new Decimal('11.938'), lengthIn],
    },
    {
      abbrv: '12 in60',
      data: ['12 in', '60', new Decimal('0.562'), new Decimal('11.626'), lengthIn],
    },
    {
      abbrv: '12 in80',
      data: ['12 in', '80', new Decimal('0.688'), new Decimal('11.374'), lengthIn],
    },
    {
      abbrv: '12 in100',
      data: ['12 in', '100', new Decimal('0.844'), new Decimal('11.062'), lengthIn],
    },
    {
      abbrv: '12 in120',
      data: ['12 in', '120', new Decimal('1'), new Decimal('10.75'), lengthIn],
    },
    {
      abbrv: '12 in140',
      data: ['12 in', '140', new Decimal('1.125'), new Decimal('10.5'), lengthIn],
    },
    {
      abbrv: '12 in160',
      data: ['12 in', '160', new Decimal('1.312'), new Decimal('10.125'), lengthIn],
    },
    {
      abbrv: '12 inSTD',
      data: ['12 in', 'STD', new Decimal('0.375'), new Decimal('12'), lengthIn],
    },
    {
      abbrv: '12 inXS',
      data: ['12 in', 'XS', new Decimal('0.5'), new Decimal('11.75'), lengthIn],
    },
    {
      abbrv: '12 inXXS',
      data: ['12 in', 'XXS', new Decimal('1'), new Decimal('10.75'), lengthIn],
    },
    {
      abbrv: '14 in10',
      data: ['14 in', '10', new Decimal('0.25'), new Decimal('13.5'), lengthIn],
    },
    {
      abbrv: '14 in20',
      data: ['14 in', '20', new Decimal('0.312'), new Decimal('13.376'), lengthIn],
    },
    {
      abbrv: '14 in30',
      data: ['14 in', '30', new Decimal('0.375'), new Decimal('13.25'), lengthIn],
    },
    {
      abbrv: '14 in40',
      data: ['14 in', '40', new Decimal('0.438'), new Decimal('13.124'), lengthIn],
    },
    {
      abbrv: '14 in60',
      data: ['14 in', '60', new Decimal('0.594'), new Decimal('12.812'), lengthIn],
    },
    {
      abbrv: '14 in80',
      data: ['14 in', '80', new Decimal('0.75'), new Decimal('12.5'), lengthIn],
    },
    {
      abbrv: '14 in100',
      data: ['14 in', '100', new Decimal('0.938'), new Decimal('12.124'), lengthIn],
    },
    {
      abbrv: '14 in120',
      data: ['14 in', '120', new Decimal('1.094'), new Decimal('11.812'), lengthIn],
    },
    {
      abbrv: '14 in140',
      data: ['14 in', '140', new Decimal('1.25'), new Decimal('11.5'), lengthIn],
    },
    {
      abbrv: '14 in160',
      data: ['14 in', '160', new Decimal('1.406'), new Decimal('11.188'), lengthIn],
    },
    {
      abbrv: '14 inSTD',
      data: ['14 in', 'STD', new Decimal('0.375'), new Decimal('13.25'), lengthIn],
    },
    {
      abbrv: '14 inXS',
      data: ['14 in', 'XS', new Decimal('0.5'), new Decimal('13'), lengthIn],
    },
    {
      abbrv: '16 in10',
      data: ['16 in', '10', new Decimal('0.25'), new Decimal('15.5'), lengthIn],
    },
    {
      abbrv: '16 in20',
      data: ['16 in', '20', new Decimal('0.312'), new Decimal('15.376'), lengthIn],
    },
    {
      abbrv: '16 in30',
      data: ['16 in', '30', new Decimal('0.375'), new Decimal('15.25'), lengthIn],
    },
    {
      abbrv: '16 in40',
      data: ['16 in', '40', new Decimal('0.5'), new Decimal('15'), lengthIn],
    },
    {
      abbrv: '16 in60',
      data: ['16 in', '60', new Decimal('0.656'), new Decimal('14.688'), lengthIn],
    },
    {
      abbrv: '16 in80',
      data: ['16 in', '80', new Decimal('0.844'), new Decimal('14.312'), lengthIn],
    },
    {
      abbrv: '16 in100',
      data: ['16 in', '100', new Decimal('1.031'), new Decimal('13.938'), lengthIn],
    },
    {
      abbrv: '16 in120',
      data: ['16 in', '120', new Decimal('1.219'), new Decimal('13.532'), lengthIn],
    },
    {
      abbrv: '16 in140',
      data: ['16 in', '140', new Decimal('1.438'), new Decimal('13.124'), lengthIn],
    },
    {
      abbrv: '16 in160',
      data: ['16 in', '160', new Decimal('1.594'), new Decimal('12.812'), lengthIn],
    },
    {
      abbrv: '16 inSTD',
      data: ['16 in', 'STD', new Decimal('0.375'), new Decimal('15.25'), lengthIn],
    },
    {
      abbrv: '16 inXS',
      data: ['16 in', 'XS', new Decimal('0.5'), new Decimal('15'), lengthIn],
    },
    {
      abbrv: '18 in10',
      data: ['18 in', '10', new Decimal('0.25'), new Decimal('17.5'), lengthIn],
    },
    {
      abbrv: '18 in20',
      data: ['18 in', '20', new Decimal('0.312'), new Decimal('17.376'), lengthIn],
    },
    {
      abbrv: '18 in30',
      data: ['18 in', '30', new Decimal('0.438'), new Decimal('17.124'), lengthIn],
    },
    {
      abbrv: '18 in40',
      data: ['18 in', '40', new Decimal('0.562'), new Decimal('16.876'), lengthIn],
    },
    {
      abbrv: '18 in60',
      data: ['18 in', '60', new Decimal('0.75'), new Decimal('16.5'), lengthIn],
    },
    {
      abbrv: '18 in80',
      data: ['18 in', '80', new Decimal('0.938'), new Decimal('16.124'), lengthIn],
    },
    {
      abbrv: '18 in100',
      data: ['18 in', '100', new Decimal('1.156'), new Decimal('15.688'), lengthIn],
    },
    {
      abbrv: '18 in120',
      data: ['18 in', '120', new Decimal('1.375'), new Decimal('15.25'), lengthIn],
    },
    {
      abbrv: '18 in140',
      data: ['18 in', '140', new Decimal('1.562'), new Decimal('14.876'), lengthIn],
    },
    {
      abbrv: '18 in160',
      data: ['18 in', '160', new Decimal('1.781'), new Decimal('14.438'), lengthIn],
    },
    {
      abbrv: '18 inSTD',
      data: ['18 in', 'STD', new Decimal('0.375'), new Decimal('17.25'), lengthIn],
    },
    {
      abbrv: '18 inXS',
      data: ['18 in', 'XS', new Decimal('0.5'), new Decimal('17'), lengthIn],
    },
    {
      abbrv: '20 in10',
      data: ['20 in', '10', new Decimal('0.25'), new Decimal('19.5'), lengthIn],
    },
    {
      abbrv: '20 in20',
      data: ['20 in', '20', new Decimal('0.375'), new Decimal('19.25'), lengthIn],
    },
    {
      abbrv: '20 in30',
      data: ['20 in', '30', new Decimal('0.5'), new Decimal('19'), lengthIn],
    },
    {
      abbrv: '20 in40',
      data: ['20 in', '40', new Decimal('0.594'), new Decimal('18.812'), lengthIn],
    },
    {
      abbrv: '20 in60',
      data: ['20 in', '60', new Decimal('0.812'), new Decimal('18.376'), lengthIn],
    },
    {
      abbrv: '20 in80',
      data: ['20 in', '80', new Decimal('1.031'), new Decimal('17.938'), lengthIn],
    },
    {
      abbrv: '20 in100',
      data: ['20 in', '100', new Decimal('1.281'), new Decimal('17.438'), lengthIn],
    },
    {
      abbrv: '20 in120',
      data: ['20 in', '120', new Decimal('1.5'), new Decimal('17'), lengthIn],
    },
    {
      abbrv: '20 in140',
      data: ['20 in', '140', new Decimal('1.75'), new Decimal('16.5'), lengthIn],
    },
    {
      abbrv: '20 in160',
      data: ['20 in', '160', new Decimal('1.969'), new Decimal('16.062'), lengthIn],
    },
    {
      abbrv: '20 inSTD',
      data: ['20 in', 'STD', new Decimal('0.375'), new Decimal('19.25'), lengthIn],
    },
    {
      abbrv: '20 inXS',
      data: ['20 in', 'XS', new Decimal('0.5'), new Decimal('19'), lengthIn],
    },
    {
      abbrv: '22 in10',
      data: ['22 in', '10', new Decimal('0.25'), new Decimal('21.5'), lengthIn],
    },
    {
      abbrv: '22 in20',
      data: ['22 in', '20', new Decimal('0.3175'), new Decimal('21.25'), lengthIn],
    },
    {
      abbrv: '22 in30',
      data: ['22 in', '30', new Decimal('0.5'), new Decimal('21'), lengthIn],
    },
    {
      abbrv: '22 in60',
      data: ['22 in', '60', new Decimal('0.5'), new Decimal('21'), lengthIn],
    },
    {
      abbrv: '22 in80',
      data: ['22 in', '80', new Decimal('1.125'), new Decimal('19.75'), lengthIn],
    },
    {
      abbrv: '22 in100',
      data: ['22 in', '100', new Decimal('1.375'), new Decimal('19.25'), lengthIn],
    },
    {
      abbrv: '22 in120',
      data: ['22 in', '120', new Decimal('1.625'), new Decimal('18.75'), lengthIn],
    },
    {
      abbrv: '22 in140',
      data: ['22 in', '140', new Decimal('1.875'), new Decimal('18.25'), lengthIn],
    },
    {
      abbrv: '22 in160',
      data: ['22 in', '160', new Decimal('2.125'), new Decimal('17.75'), lengthIn],
    },
    {
      abbrv: '22 inSTD',
      data: ['22 in', 'STD', new Decimal('0.3175'), new Decimal('21.25'), lengthIn],
    },
    {
      abbrv: '22 inXS',
      data: ['22 in', 'XS', new Decimal('0.5'), new Decimal('21'), lengthIn],
    },
    {
      abbrv: '24 in10',
      data: ['24 in', '10', new Decimal('0.25'), new Decimal('23.5'), lengthIn],
    },
    {
      abbrv: '24 in20',
      data: ['24 in', '20', new Decimal('0.375'), new Decimal('23.25'), lengthIn],
    },
    {
      abbrv: '24 in30',
      data: ['24 in', '30', new Decimal('0.562'), new Decimal('22.876'), lengthIn],
    },
    {
      abbrv: '24 in40',
      data: ['24 in', '40', new Decimal('0.688'), new Decimal('22.624'), lengthIn],
    },
    {
      abbrv: '24 in60',
      data: ['24 in', '60', new Decimal('0.969'), new Decimal('22.062'), lengthIn],
    },
    {
      abbrv: '24 in80',
      data: ['24 in', '80', new Decimal('1.219'), new Decimal('21.562'), lengthIn],
    },
    {
      abbrv: '24 in100',
      data: ['24 in', '100', new Decimal('1.531'), new Decimal('20.928'), lengthIn],
    },
    {
      abbrv: '24 in120',
      data: ['24 in', '120', new Decimal('1.812'), new Decimal('20.376'), lengthIn],
    },
    {
      abbrv: '24 in140',
      data: ['24 in', '140', new Decimal('2.062'), new Decimal('19.876'), lengthIn],
    },
    {
      abbrv: '24 in160',
      data: ['24 in', '160', new Decimal('2.344'), new Decimal('19.312'), lengthIn],
    },
    {
      abbrv: '24 inSTD',
      data: ['24 in', 'STD', new Decimal('0.375'), new Decimal('23.25'), lengthIn],
    },
    {
      abbrv: '24 inXS',
      data: ['24 in', 'XS', new Decimal('0.5'), new Decimal('23'), lengthIn],
    },
    {
      abbrv: '26 in10',
      data: ['26 in', '10', new Decimal('0.312'), new Decimal('25.376'), lengthIn],
    },
    {
      abbrv: '26 in20',
      data: ['26 in', '20', new Decimal('0.5'), new Decimal('25'), lengthIn],
    },
    {
      abbrv: '26 inSTD',
      data: ['26 in', 'STD', new Decimal('0.375'), new Decimal('25.25'), lengthIn],
    },
    {
      abbrv: '26 inXS',
      data: ['26 in', 'XS', new Decimal('0.5'), new Decimal('25'), lengthIn],
    },
    {
      abbrv: '28 in10',
      data: ['28 in', '10', new Decimal('0.312'), new Decimal('27.376'), lengthIn],
    },
    {
      abbrv: '28 in20',
      data: ['28 in', '20', new Decimal('0.5'), new Decimal('27'), lengthIn],
    },
    {
      abbrv: '28 in30',
      data: ['28 in', '30', new Decimal('0.625'), new Decimal('26.75'), lengthIn],
    },
    {
      abbrv: '28 in40',
      data: ['28 in', '40', new Decimal('0.625'), new Decimal('26.75'), lengthIn],
    },
    {
      abbrv: '28 inSTD',
      data: ['28 in', 'STD', new Decimal('0.375'), new Decimal('27.25'), lengthIn],
    },
    {
      abbrv: '28 inXS',
      data: ['28 in', 'XS', new Decimal('0.5'), new Decimal('27'), lengthIn],
    },
    {
      abbrv: '30 in10',
      data: ['30 in', '10', new Decimal('0.312'), new Decimal('29.376'), lengthIn],
    },
    {
      abbrv: '30 in20',
      data: ['30 in', '20', new Decimal('0.5'), new Decimal('29'), lengthIn],
    },
    {
      abbrv: '30 in30',
      data: ['30 in', '30', new Decimal('0.625'), new Decimal('28.75'), lengthIn],
    },
    {
      abbrv: '30 inSTD',
      data: ['30 in', 'STD', new Decimal('0.375'), new Decimal('29.25'), lengthIn],
    },
    {
      abbrv: '30 inXS',
      data: ['30 in', 'XS', new Decimal('0.5'), new Decimal('29'), lengthIn],
    },
    {
      abbrv: '32 in10',
      data: ['32 in', '10', new Decimal('0.312'), new Decimal('31.376'), lengthIn],
    },
    {
      abbrv: '32 in20',
      data: ['32 in', '20', new Decimal('0.5'), new Decimal('31'), lengthIn],
    },
    {
      abbrv: '32 in30',
      data: ['32 in', '30', new Decimal('0.625'), new Decimal('30.75'), lengthIn],
    },
    {
      abbrv: '32 in40',
      data: ['32 in', '40', new Decimal('0.688'), new Decimal('30.624'), lengthIn],
    },
    {
      abbrv: '32 inSTD',
      data: ['32 in', 'STD', new Decimal('0.375'), new Decimal('31.25'), lengthIn],
    },
    {
      abbrv: '32 inXS',
      data: ['32 in', 'XS', new Decimal('0.5'), new Decimal('31'), lengthIn],
    },
    {
      abbrv: '34 in10',
      data: ['34 in', '10', new Decimal('0.344'), new Decimal('33.312'), lengthIn],
    },
    {
      abbrv: '34 in20',
      data: ['34 in', '20', new Decimal('0.5'), new Decimal('33'), lengthIn],
    },
    {
      abbrv: '34 in30',
      data: ['34 in', '30', new Decimal('0.625'), new Decimal('32.75'), lengthIn],
    },
    {
      abbrv: '34 in40',
      data: ['34 in', '40', new Decimal('0.688'), new Decimal('32.624'), lengthIn],
    },
    {
      abbrv: '34 inSTD',
      data: ['34 in', 'STD', new Decimal('0.375'), new Decimal('33.25'), lengthIn],
    },
    {
      abbrv: '34 inXS',
      data: ['34 in', 'XS', new Decimal('0.5'), new Decimal('33'), lengthIn],
    },
    {
      abbrv: '36 in10',
      data: ['36 in', '10', new Decimal('0.312'), new Decimal('35.376'), lengthIn],
    },
    {
      abbrv: '36 in20',
      data: ['36 in', '20', new Decimal('0.5'), new Decimal('35'), lengthIn],
    },
    {
      abbrv: '36 in30',
      data: ['36 in', '30', new Decimal('0.625'), new Decimal('34.75'), lengthIn],
    },
    {
      abbrv: '36 in40',
      data: ['36 in', '40', new Decimal('0.75'), new Decimal('34.5'), lengthIn],
    },
    {
      abbrv: '36 inSTD',
      data: ['36 in', 'STD', new Decimal('0.375'), new Decimal('35.25'), lengthIn],
    },
    {
      abbrv: '36 inXS',
      data: ['36 in', 'XS', new Decimal('0.5'), new Decimal('35'), lengthIn],
    },
    {
      abbrv: '15 mm',
      data: ['15', '', new Decimal('0.0'), new Decimal('21.34'), lengthMm],
    },
    {
      abbrv: '20 mm',
      data: ['20', '', new Decimal('0.0'), new Decimal('26.67'), lengthMm],
    },
    {
      abbrv: '25 mm',
      data: ['25', '', new Decimal('0.0'), new Decimal('28.5'), lengthMm],
    },
    {
      abbrv: '32 mm',
      data: ['32', '', new Decimal('0.0'), new Decimal('36.3'), lengthMm],
    },
    {
      abbrv: '40 mm',
      data: ['40', '', new Decimal('0.0'), new Decimal('43.1'), lengthMm],
    },
    {
      abbrv: '50 mm',
      data: ['50', '', new Decimal('0.0'), new Decimal('54.5'), lengthMm],
    },
    {
      abbrv: '65 mm',
      data: ['65', '', new Decimal('0.0'), new Decimal('70.3'), lengthMm],
    },
    {
      abbrv: '80 mm',
      data: ['80', '', new Decimal('0.0'), new Decimal('82.5'), lengthMm],
    },
    {
      abbrv: '100 mm',
      data: ['100', '', new Decimal('0.0'), new Decimal('107.1'), lengthMm],
    },
    {
      abbrv: '125 mm',
      data: ['125', '', new Decimal('0.0'), new Decimal('131.7'), lengthMm],
    },
    {
      abbrv: '150 mm',
      data: ['150', '', new Decimal('0.0'), new Decimal('159.3'), lengthMm],
    },
    {
      abbrv: '200 mm',
      data: ['200', '', new Decimal('0.0'), new Decimal('207.3'), lengthMm],
    },
    {
      abbrv: '250 mm',
      data: ['250', '', new Decimal('0.0'), new Decimal('260.4'), lengthMm],
    },
    {
      abbrv: '300 mm',
      data: ['300', '', new Decimal('0.0'), new Decimal('309.7'), lengthMm],
    },
    {
      abbrv: '350 mm',
      data: ['350', '', new Decimal('0.0'), new Decimal('339.6'), lengthMm],
    },
    {
      abbrv: '400 mm',
      data: ['400', '', new Decimal('0.0'), new Decimal('390.4'), lengthMm],
    },
    {
      abbrv: '500 mm',
      data: ['500', '', new Decimal('0.0'), new Decimal('492'), lengthMm],
    },
    {
      abbrv: '600 mm',
      data: ['600', '', new Decimal('0.0'), new Decimal('592.4'), lengthMm],
    },
    {
      abbrv: 'Other',
      data: ['Other', '', new Decimal('0.0'), new Decimal('0.0'), lengthIn],
    },
  ];

  if (meterType !== ElectromagneticProMModel) {
    pipesTable.splice(187, 1);
  }

  if (!type) {
    return pipesTable;
  }

  return utilities.findInArray(pipesTable, type);
};

const findHeinrichsPipes = (type?: string): any => {
  const lengthIn = api.findMeasurement('length', 'in');
  const lengthMm = api.findMeasurement('length', 'mm');
  const pipesTable = [
    {
      abbrv: '0.5 in40',
      data: ['0.5 in', '40', new Decimal('0.109'), new Decimal('0.622'), lengthIn],
    },
    {
      abbrv: '0.5 inSTD',
      data: ['0.5 in', 'STD', new Decimal('0.109'), new Decimal('0.622'), lengthIn],
    },
    {
      abbrv: '0.5 in80',
      data: ['0.5 in', '80', new Decimal('0.147'), new Decimal('0.546'), lengthIn],
    },
    {
      abbrv: '0.5 inXS',
      data: ['0.5 in', 'XS', new Decimal('0.147'), new Decimal('0.546'), lengthIn],
    },
    {
      abbrv: '0.5 in160',
      data: ['0.5 in', '160', new Decimal('0.187'), new Decimal('0.466'), lengthIn],
    },
    {
      abbrv: '0.5 inXXS',
      data: ['0.5 in', 'XXS', new Decimal('0.294'), new Decimal('0.252'), lengthIn],
    },
    {
      abbrv: '0.75 in40',
      data: ['0.75 in', '40', new Decimal('0.113'), new Decimal('0.824'), lengthIn],
    },
    {
      abbrv: '0.75 inSTD',
      data: ['0.75 in', 'STD', new Decimal('0.113'), new Decimal('0.824'), lengthIn],
    },
    {
      abbrv: '0.75 in80',
      data: ['0.75 in', '80', new Decimal('0.154'), new Decimal('0.742'), lengthIn],
    },
    {
      abbrv: '0.75 inXS',
      data: ['0.75 in', 'XS', new Decimal('0.154'), new Decimal('0.742'), lengthIn],
    },
    {
      abbrv: '0.75 in160',
      data: ['0.75 in', '160', new Decimal('0.219'), new Decimal('0.612'), lengthIn],
    },
    {
      abbrv: '0.75 inXXS',
      data: ['0.75 in', 'XXS', new Decimal('0.308'), new Decimal('0.434'), lengthIn],
    },
    {
      abbrv: '1 in40',
      data: ['1 in', '40', new Decimal('0.133'), new Decimal('1.049'), lengthIn],
    },
    {
      abbrv: '1 inSTD',
      data: ['1 in', 'STD', new Decimal('0.133'), new Decimal('1.049'), lengthIn],
    },
    {
      abbrv: '1 in80',
      data: ['1 in', '80', new Decimal('0.179'), new Decimal('0.957'), lengthIn],
    },
    {
      abbrv: '1 inXS',
      data: ['1 in', 'XS', new Decimal('0.179'), new Decimal('0.957'), lengthIn],
    },
    {
      abbrv: '1 in160',
      data: ['1 in', '160', new Decimal('0.25'), new Decimal('0.815'), lengthIn],
    },
    {
      abbrv: '1 inXXS',
      data: ['1 in', 'XXS', new Decimal('0.358'), new Decimal('0.599'), lengthIn],
    },
    {
      abbrv: '1.25 in40',
      data: ['1.25 in', '40', new Decimal('0.14'), new Decimal('1.38'), lengthIn],
    },
    {
      abbrv: '1.25 inSTD',
      data: ['1.25 in', 'STD', new Decimal('0.14'), new Decimal('1.38'), lengthIn],
    },
    {
      abbrv: '1.25 in80',
      data: ['1.25 in', '80', new Decimal('0.191'), new Decimal('1.278'), lengthIn],
    },
    {
      abbrv: '1.25 inXS',
      data: ['1.25 in', 'XS', new Decimal('0.191'), new Decimal('1.278'), lengthIn],
    },
    {
      abbrv: '1.25 in160',
      data: ['1.25 in', '160', new Decimal('0.25'), new Decimal('1.16'), lengthIn],
    },
    {
      abbrv: '1.25 inXXS',
      data: ['1.25 in', 'XXS', new Decimal('0.382'), new Decimal('0.896'), lengthIn],
    },
    {
      abbrv: '1.5 in40',
      data: ['1.5 in', '40', new Decimal('0.145'), new Decimal('1.61'), lengthIn],
    },
    {
      abbrv: '1.5 inSTD',
      data: ['1.5 in', 'STD', new Decimal('0.145'), new Decimal('1.61'), lengthIn],
    },
    {
      abbrv: '1.5 in80',
      data: ['1.5 in', '80', new Decimal('0.2'), new Decimal('1.5'), lengthIn],
    },
    {
      abbrv: '1.5 inXS',
      data: ['1.5 in', 'XS', new Decimal('0.2'), new Decimal('1.5'), lengthIn],
    },
    {
      abbrv: '1.5 in160',
      data: ['1.5 in', '160', new Decimal('0.281'), new Decimal('1.388'), lengthIn],
    },
    {
      abbrv: '1.5 inXXS',
      data: ['1.5 in', 'XXS', new Decimal('0.4'), new Decimal('1.1'), lengthIn],
    },
    {
      abbrv: '2 in40',
      data: ['2 in', '40', new Decimal('0.154'), new Decimal('2.067'), lengthIn],
    },
    {
      abbrv: '2 inSTD',
      data: ['2 in', 'STD', new Decimal('0.154'), new Decimal('2.067'), lengthIn],
    },
    {
      abbrv: '2 in80',
      data: ['2 in', '80', new Decimal('0.218'), new Decimal('1.939'), lengthIn],
    },
    {
      abbrv: '2 inXS',
      data: ['2 in', 'XS', new Decimal('0.218'), new Decimal('1.939'), lengthIn],
    },
    {
      abbrv: '2 in160',
      data: ['2 in', '160', new Decimal('0.344'), new Decimal('1.687'), lengthIn],
    },
    {
      abbrv: '2 inXXS',
      data: ['2 in', 'XXS', new Decimal('0.436'), new Decimal('1.503'), lengthIn],
    },
    {
      abbrv: '2.5 in40',
      data: ['2.5 in', '40', new Decimal('0.203'), new Decimal('2.469'), lengthIn],
    },
    {
      abbrv: '2.5 inSTD',
      data: ['2.5 in', 'STD', new Decimal('0.203'), new Decimal('2.469'), lengthIn],
    },
    {
      abbrv: '2.5 in80',
      data: ['2.5 in', '80', new Decimal('0.276'), new Decimal('2.323'), lengthIn],
    },
    {
      abbrv: '2.5 inXS',
      data: ['2.5 in', 'XS', new Decimal('0.552'), new Decimal('1.771'), lengthIn],
    },
    {
      abbrv: '2.5 in160',
      data: ['2.5 in', '160', new Decimal('0.375'), new Decimal('2.125'), lengthIn],
    },
    {
      abbrv: '2.5 inXXS',
      data: ['2.5 in', 'XXS', new Decimal('0.552'), new Decimal('1.771'), lengthIn],
    },
    {
      abbrv: '3 in40',
      data: ['3 in', '40', new Decimal('0.216'), new Decimal('3.068'), lengthIn],
    },
    {
      abbrv: '3 inSTD',
      data: ['3 in', 'STD', new Decimal('0.216'), new Decimal('3.068'), lengthIn],
    },
    {
      abbrv: '3 in80',
      data: ['3 in', '80', new Decimal('0.3'), new Decimal('2.9'), lengthIn],
    },
    {
      abbrv: '3 inXS',
      data: ['3 in', 'XS', new Decimal('0.3'), new Decimal('2.9'), lengthIn],
    },
    {
      abbrv: '3 in160',
      data: ['3 in', '160', new Decimal('0.438'), new Decimal('2.624'), lengthIn],
    },
    {
      abbrv: '3 inXXS',
      data: ['3 in', 'XXS', new Decimal('0.6'), new Decimal('2.3'), lengthIn],
    },
    {
      abbrv: '3.5 in40',
      data: ['3.5 in', '40', new Decimal('0.226'), new Decimal('3.548'), lengthIn],
    },
    {
      abbrv: '3.5 inSTD',
      data: ['3.5 in', 'STD', new Decimal('0.226'), new Decimal('3.548'), lengthIn],
    },
    {
      abbrv: '3.5 in80',
      data: ['3.5 in', '80', new Decimal('0.318'), new Decimal('3.64'), lengthIn],
    },
    {
      abbrv: '3.5 inXS',
      data: ['3.5 in', 'XS', new Decimal('0.318'), new Decimal('3.64'), lengthIn],
    },
    {
      abbrv: '4 in40',
      data: ['4 in', '40', new Decimal('0.237'), new Decimal('4.026'), lengthIn],
    },
    {
      abbrv: '4 inSTD',
      data: ['4 in', 'STD', new Decimal('0.237'), new Decimal('4.026'), lengthIn],
    },
    {
      abbrv: '4 in80',
      data: ['4 in', '80', new Decimal('0.337'), new Decimal('3.826'), lengthIn],
    },
    {
      abbrv: '4 inXS',
      data: ['4 in', 'XS', new Decimal('0.337'), new Decimal('3.826'), lengthIn],
    },
    {
      abbrv: '4 in120',
      data: ['4 in', '120', new Decimal('0.438'), new Decimal('3.624'), lengthIn],
    },
    {
      abbrv: '4 in160',
      data: ['4 in', '160', new Decimal('0.531'), new Decimal('3.438'), lengthIn],
    },
    {
      abbrv: '4 inXXS',
      data: ['4 in', 'XXS', new Decimal('0.674'), new Decimal('3.152'), lengthIn],
    },
    {
      abbrv: '5 in40',
      data: ['5 in', '40', new Decimal('0.258'), new Decimal('5.047'), lengthIn],
    },
    {
      abbrv: '5 inSTD',
      data: ['5 in', 'STD', new Decimal('0.258'), new Decimal('5.047'), lengthIn],
    },
    {
      abbrv: '5 in80',
      data: ['5 in', '80', new Decimal('0.375'), new Decimal('4.813'), lengthIn],
    },
    {
      abbrv: '5 inXS',
      data: ['5 in', 'XS', new Decimal('0.375'), new Decimal('4.813'), lengthIn],
    },
    {
      abbrv: '5 in120',
      data: ['5 in', '120', new Decimal('0.5'), new Decimal('4.63'), lengthIn],
    },
    {
      abbrv: '5 in160',
      data: ['5 in', '160', new Decimal('0.625'), new Decimal('4.313'), lengthIn],
    },
    {
      abbrv: '5 inXXS',
      data: ['5 in', 'XXS', new Decimal('0.75'), new Decimal('4.063'), lengthIn],
    },
    {
      abbrv: '6 in40',
      data: ['6 in', '40', new Decimal('0.28'), new Decimal('6.065'), lengthIn],
    },
    {
      abbrv: '6 inSTD',
      data: ['6 in', 'STD', new Decimal('0.28'), new Decimal('6.065'), lengthIn],
    },
    {
      abbrv: '6 in80',
      data: ['6 in', '80', new Decimal('0.432'), new Decimal('5.761'), lengthIn],
    },
    {
      abbrv: '6 inXS',
      data: ['6 in', 'XS', new Decimal('0.432'), new Decimal('5.761'), lengthIn],
    },
    {
      abbrv: '6 in120',
      data: ['6 in', '120', new Decimal('0.562'), new Decimal('5.501'), lengthIn],
    },
    {
      abbrv: '6 in160',
      data: ['6 in', '160', new Decimal('0.719'), new Decimal('5.187'), lengthIn],
    },
    {
      abbrv: '6 inXXS',
      data: ['6 in', 'XXS', new Decimal('0.864'), new Decimal('4.897'), lengthIn],
    },
    {
      abbrv: '8 in20',
      data: ['8 in', '20', new Decimal('0.25'), new Decimal('8.125'), lengthIn],
    },
    {
      abbrv: '8 in30',
      data: ['8 in', '30', new Decimal('0.277'), new Decimal('8.071'), lengthIn],
    },
    {
      abbrv: '8 in40',
      data: ['8 in', '40', new Decimal('0.322'), new Decimal('7.981'), lengthIn],
    },
    {
      abbrv: '8 inSTD',
      data: ['8 in', 'STD', new Decimal('0.322'), new Decimal('7.981'), lengthIn],
    },
    {
      abbrv: '8 in60',
      data: ['8 in', '60', new Decimal('0.406'), new Decimal('7.813'), lengthIn],
    },
    {
      abbrv: '8 in80',
      data: ['8 in', '80', new Decimal('0.5'), new Decimal('7.625'), lengthIn],
    },
    {
      abbrv: '8 inXS',
      data: ['8 in', 'XS', new Decimal('0.5'), new Decimal('7.625'), lengthIn],
    },
    {
      abbrv: '8 in100',
      data: ['8 in', '100', new Decimal('0.594'), new Decimal('7.437'), lengthIn],
    },
    {
      abbrv: '8 in160',
      data: ['8 in', '160', new Decimal('0.603'), new Decimal('3.813'), lengthIn],
    },
    {
      abbrv: '8 in120',
      data: ['8 in', '120', new Decimal('0.719'), new Decimal('7.187'), lengthIn],
    },
    {
      abbrv: '8 in140',
      data: ['8 in', '140', new Decimal('0.812'), new Decimal('7.001'), lengthIn],
    },
    {
      abbrv: '8 inXXS',
      data: ['8 in', 'XXS', new Decimal('0.875'), new Decimal('6.8725'), lengthIn],
    },
    {
      abbrv: '10 in20',
      data: ['10 in', '20', new Decimal('0.25'), new Decimal('10.25'), lengthIn],
    },
    {
      abbrv: '10 in30',
      data: ['10 in', '30', new Decimal('0.307'), new Decimal('10.136'), lengthIn],
    },
    {
      abbrv: '10 in40',
      data: ['10 in', '40', new Decimal('0.365'), new Decimal('10.02'), lengthIn],
    },
    {
      abbrv: '10 inSTD',
      data: ['10 in', 'STD', new Decimal('0.365'), new Decimal('10.02'), lengthIn],
    },
    {
      abbrv: '10 in60',
      data: ['10 in', '60', new Decimal('0.5'), new Decimal('9.75'), lengthIn],
    },
    {
      abbrv: '10 inXS',
      data: ['10 in', 'XS', new Decimal('0.5'), new Decimal('9.75'), lengthIn],
    },
    {
      abbrv: '10 in80',
      data: ['10 in', '80', new Decimal('0.594'), new Decimal('9.562'), lengthIn],
    },
    {
      abbrv: '10 in100',
      data: ['10 in', '100', new Decimal('0.719'), new Decimal('9.312'), lengthIn],
    },
    {
      abbrv: '10 in120',
      data: ['10 in', '120', new Decimal('0.844'), new Decimal('9.062'), lengthIn],
    },
    {
      abbrv: '10 in140',
      data: ['10 in', '140', new Decimal('1'), new Decimal('8.75'), lengthIn],
    },
    {
      abbrv: '10 inXXS',
      data: ['10 in', 'XXS', new Decimal('1'), new Decimal('8.75'), lengthIn],
    },
    {
      abbrv: '10 in160',
      data: ['10 in', '160', new Decimal('1.125'), new Decimal('8.5'), lengthIn],
    },
    {
      abbrv: '12 in20',
      data: ['12 in', '20', new Decimal('0.25'), new Decimal('12.25'), lengthIn],
    },
    {
      abbrv: '12 in30',
      data: ['12 in', '30', new Decimal('0.33'), new Decimal('12.09'), lengthIn],
    },
    {
      abbrv: '12 inSTD',
      data: ['12 in', 'STD', new Decimal('0.375'), new Decimal('12'), lengthIn],
    },
    {
      abbrv: '12 in40',
      data: ['12 in', '40', new Decimal('0.406'), new Decimal('11.938'), lengthIn],
    },
    {
      abbrv: '12 inXS',
      data: ['12 in', 'XS', new Decimal('0.5'), new Decimal('11.75'), lengthIn],
    },
    {
      abbrv: '12 in60',
      data: ['12 in', '60', new Decimal('0.562'), new Decimal('11.626'), lengthIn],
    },
    {
      abbrv: '12 in80',
      data: ['12 in', '80', new Decimal('0.688'), new Decimal('11.374'), lengthIn],
    },
    {
      abbrv: '12 in100',
      data: ['12 in', '100', new Decimal('0.844'), new Decimal('11.062'), lengthIn],
    },
    {
      abbrv: '12 in120',
      data: ['12 in', '120', new Decimal('1'), new Decimal('10.75'), lengthIn],
    },
    {
      abbrv: '12 inXXS',
      data: ['12 in', 'XXS', new Decimal('1'), new Decimal('10.75'), lengthIn],
    },
    {
      abbrv: '12 in140',
      data: ['12 in', '140', new Decimal('1.125'), new Decimal('10.5'), lengthIn],
    },
    {
      abbrv: '12 in160',
      data: ['12 in', '160', new Decimal('1.312'), new Decimal('10.125'), lengthIn],
    },
    {
      abbrv: '14 in10',
      data: ['14 in', '10', new Decimal('0.25'), new Decimal('13.5'), lengthIn],
    },
    {
      abbrv: '14 in20',
      data: ['14 in', '20', new Decimal('0.312'), new Decimal('13.376'), lengthIn],
    },
    {
      abbrv: '14 in30',
      data: ['14 in', '30', new Decimal('0.375'), new Decimal('13.25'), lengthIn],
    },
    {
      abbrv: '14 inSTD',
      data: ['14 in', 'STD', new Decimal('0.375'), new Decimal('13.25'), lengthIn],
    },
    {
      abbrv: '14 in40',
      data: ['14 in', '40', new Decimal('0.438'), new Decimal('13.124'), lengthIn],
    },
    {
      abbrv: '14 inXS',
      data: ['14 in', 'XS', new Decimal('0.5'), new Decimal('13'), lengthIn],
    },
    {
      abbrv: '14 in60',
      data: ['14 in', '60', new Decimal('0.594'), new Decimal('12.812'), lengthIn],
    },
    {
      abbrv: '14 in80',
      data: ['14 in', '80', new Decimal('0.75'), new Decimal('12.5'), lengthIn],
    },
    {
      abbrv: '14 in100',
      data: ['14 in', '100', new Decimal('0.938'), new Decimal('12.124'), lengthIn],
    },
    {
      abbrv: '14 in120',
      data: ['14 in', '120', new Decimal('1.094'), new Decimal('11.812'), lengthIn],
    },
    {
      abbrv: '14 in140',
      data: ['14 in', '140', new Decimal('1.25'), new Decimal('11.5'), lengthIn],
    },
    {
      abbrv: '14 in160',
      data: ['14 in', '160', new Decimal('1.406'), new Decimal('11.188'), lengthIn],
    },
    {
      abbrv: '16 in10',
      data: ['16 in', '10', new Decimal('0.25'), new Decimal('15.5'), lengthIn],
    },
    {
      abbrv: '16 in20',
      data: ['16 in', '20', new Decimal('0.312'), new Decimal('15.376'), lengthIn],
    },
    {
      abbrv: '16 in30',
      data: ['16 in', '30', new Decimal('0.375'), new Decimal('15.25'), lengthIn],
    },
    {
      abbrv: '16 inSTD',
      data: ['16 in', 'STD', new Decimal('0.375'), new Decimal('15.25'), lengthIn],
    },
    {
      abbrv: '16 in40',
      data: ['16 in', '40', new Decimal('0.5'), new Decimal('15'), lengthIn],
    },
    {
      abbrv: '16 inXS',
      data: ['16 in', 'XS', new Decimal('0.5'), new Decimal('15'), lengthIn],
    },
    {
      abbrv: '16 in60',
      data: ['16 in', '60', new Decimal('0.656'), new Decimal('14.688'), lengthIn],
    },
    {
      abbrv: '16 in80',
      data: ['16 in', '80', new Decimal('0.844'), new Decimal('14.312'), lengthIn],
    },
    {
      abbrv: '16 in100',
      data: ['16 in', '100', new Decimal('1.031'), new Decimal('13.938'), lengthIn],
    },
    {
      abbrv: '16 in120',
      data: ['16 in', '120', new Decimal('1.219'), new Decimal('13.532'), lengthIn],
    },
    {
      abbrv: '16 in140',
      data: ['16 in', '140', new Decimal('1.438'), new Decimal('13.124'), lengthIn],
    },
    {
      abbrv: '16 in160',
      data: ['16 in', '160', new Decimal('1.594'), new Decimal('12.812'), lengthIn],
    },
    {
      abbrv: '18 in10',
      data: ['18 in', '10', new Decimal('0.25'), new Decimal('17.5'), lengthIn],
    },
    {
      abbrv: '18 in20',
      data: ['18 in', '20', new Decimal('0.312'), new Decimal('17.376'), lengthIn],
    },
    {
      abbrv: '18 inSTD',
      data: ['18 in', 'STD', new Decimal('0.375'), new Decimal('17.25'), lengthIn],
    },
    {
      abbrv: '18 in30',
      data: ['18 in', '30', new Decimal('0.438'), new Decimal('17.124'), lengthIn],
    },
    {
      abbrv: '18 inXS',
      data: ['18 in', 'XS', new Decimal('0.5'), new Decimal('17'), lengthIn],
    },
    {
      abbrv: '18 in40',
      data: ['18 in', '40', new Decimal('0.562'), new Decimal('16.876'), lengthIn],
    },
    {
      abbrv: '18 in60',
      data: ['18 in', '60', new Decimal('0.75'), new Decimal('16.5'), lengthIn],
    },
    {
      abbrv: '18 in80',
      data: ['18 in', '80', new Decimal('0.938'), new Decimal('16.124'), lengthIn],
    },
    {
      abbrv: '18 in100',
      data: ['18 in', '100', new Decimal('1.156'), new Decimal('15.688'), lengthIn],
    },
    {
      abbrv: '18 in120',
      data: ['18 in', '120', new Decimal('1.375'), new Decimal('15.25'), lengthIn],
    },
    {
      abbrv: '18 in140',
      data: ['18 in', '140', new Decimal('1.562'), new Decimal('14.876'), lengthIn],
    },
    {
      abbrv: '18 in160',
      data: ['18 in', '160', new Decimal('1.781'), new Decimal('14.438'), lengthIn],
    },
    {
      abbrv: '20 in10',
      data: ['20 in', '10', new Decimal('0.25'), new Decimal('19.5'), lengthIn],
    },
    {
      abbrv: '20 in20',
      data: ['20 in', '20', new Decimal('0.375'), new Decimal('19.25'), lengthIn],
    },
    {
      abbrv: '20 inSTD',
      data: ['20 in', 'STD', new Decimal('0.375'), new Decimal('19.25'), lengthIn],
    },
    {
      abbrv: '20 in30',
      data: ['20 in', '30', new Decimal('0.5'), new Decimal('19'), lengthIn],
    },
    {
      abbrv: '20 inXS',
      data: ['20 in', 'XS', new Decimal('0.5'), new Decimal('19'), lengthIn],
    },
    {
      abbrv: '20 in40',
      data: ['20 in', '40', new Decimal('0.594'), new Decimal('18.812'), lengthIn],
    },
    {
      abbrv: '20 in60',
      data: ['20 in', '60', new Decimal('0.812'), new Decimal('18.376'), lengthIn],
    },
    {
      abbrv: '20 in80',
      data: ['20 in', '80', new Decimal('1.031'), new Decimal('17.938'), lengthIn],
    },
    {
      abbrv: '20 in100',
      data: ['20 in', '100', new Decimal('1.281'), new Decimal('17.438'), lengthIn],
    },
    {
      abbrv: '20 in120',
      data: ['20 in', '120', new Decimal('1.5'), new Decimal('17'), lengthIn],
    },
    {
      abbrv: '20 in140',
      data: ['20 in', '140', new Decimal('1.75'), new Decimal('16.5'), lengthIn],
    },
    {
      abbrv: '20 in160',
      data: ['20 in', '160', new Decimal('1.969'), new Decimal('16.062'), lengthIn],
    },
    {
      abbrv: '22 in10',
      data: ['22 in', '10', new Decimal('0.25'), new Decimal('21.5'), lengthIn],
    },
    {
      abbrv: '22 in20',
      data: ['22 in', '20', new Decimal('0.3175'), new Decimal('21.25'), lengthIn],
    },
    {
      abbrv: '22 inSTD',
      data: ['22 in', 'STD', new Decimal('0.3175'), new Decimal('21.25'), lengthIn],
    },
    {
      abbrv: '22 in30',
      data: ['22 in', '30', new Decimal('0.5'), new Decimal('21'), lengthIn],
    },
    {
      abbrv: '22 in60',
      data: ['22 in', '60', new Decimal('0.5'), new Decimal('21'), lengthIn],
    },
    {
      abbrv: '22 inXS',
      data: ['22 in', 'XS', new Decimal('0.5'), new Decimal('21'), lengthIn],
    },
    {
      abbrv: '22 in80',
      data: ['22 in', '80', new Decimal('1.125'), new Decimal('19.75'), lengthIn],
    },
    {
      abbrv: '22 in100',
      data: ['22 in', '100', new Decimal('1.375'), new Decimal('19.25'), lengthIn],
    },
    {
      abbrv: '22 in120',
      data: ['22 in', '120', new Decimal('1.625'), new Decimal('18.75'), lengthIn],
    },
    {
      abbrv: '22 in140',
      data: ['22 in', '140', new Decimal('1.875'), new Decimal('18.25'), lengthIn],
    },
    {
      abbrv: '22 in160',
      data: ['22 in', '160', new Decimal('2.125'), new Decimal('17.75'), lengthIn],
    },
    {
      abbrv: '24 in10',
      data: ['24 in', '10', new Decimal('0.25'), new Decimal('23.5'), lengthIn],
    },
    {
      abbrv: '24 in20',
      data: ['24 in', '20', new Decimal('0.375'), new Decimal('23.25'), lengthIn],
    },
    {
      abbrv: '24 inSTD',
      data: ['24 in', 'STD', new Decimal('0.375'), new Decimal('23.25'), lengthIn],
    },
    {
      abbrv: '24 inXS',
      data: ['24 in', 'XS', new Decimal('0.5'), new Decimal('23'), lengthIn],
    },
    {
      abbrv: '24 in30',
      data: ['24 in', '30', new Decimal('0.562'), new Decimal('22.876'), lengthIn],
    },
    {
      abbrv: '24 in40',
      data: ['24 in', '40', new Decimal('0.688'), new Decimal('22.624'), lengthIn],
    },
    {
      abbrv: '24 in60',
      data: ['24 in', '60', new Decimal('0.969'), new Decimal('22.062'), lengthIn],
    },
    {
      abbrv: '24 in80',
      data: ['24 in', '80', new Decimal('1.219'), new Decimal('21.562'), lengthIn],
    },
    {
      abbrv: '24 in100',
      data: ['24 in', '100', new Decimal('1.531'), new Decimal('20.928'), lengthIn],
    },
    {
      abbrv: '24 in120',
      data: ['24 in', '120', new Decimal('1.812'), new Decimal('20.376'), lengthIn],
    },
    {
      abbrv: '24 in140',
      data: ['24 in', '140', new Decimal('2.062'), new Decimal('19.876'), lengthIn],
    },
    {
      abbrv: '24 in160',
      data: ['24 in', '160', new Decimal('2.344'), new Decimal('19.312'), lengthIn],
    },
    {
      abbrv: '26 in10',
      data: ['26 in', '10', new Decimal('0.312'), new Decimal('25.376'), lengthIn],
    },
    {
      abbrv: '26 inSTD',
      data: ['26 in', 'STD', new Decimal('0.375'), new Decimal('25.25'), lengthIn],
    },
    {
      abbrv: '26 in20',
      data: ['26 in', '20', new Decimal('0.5'), new Decimal('25'), lengthIn],
    },
    {
      abbrv: '26 inXS',
      data: ['26 in', 'XS', new Decimal('0.5'), new Decimal('25'), lengthIn],
    },
    {
      abbrv: '28 in10',
      data: ['28 in', '10', new Decimal('0.312'), new Decimal('27.376'), lengthIn],
    },
    {
      abbrv: '28 inSTD',
      data: ['28 in', 'STD', new Decimal('0.375'), new Decimal('27.25'), lengthIn],
    },
    {
      abbrv: '28 in20',
      data: ['28 in', '20', new Decimal('0.5'), new Decimal('27'), lengthIn],
    },
    {
      abbrv: '28 inXS',
      data: ['28 in', 'XS', new Decimal('0.5'), new Decimal('27'), lengthIn],
    },
    {
      abbrv: '28 in30',
      data: ['28 in', '30', new Decimal('0.625'), new Decimal('26.75'), lengthIn],
    },
    {
      abbrv: '30 in10',
      data: ['30 in', '10', new Decimal('0.312'), new Decimal('29.376'), lengthIn],
    },
    {
      abbrv: '30 inSTD',
      data: ['30 in', 'STD', new Decimal('0.375'), new Decimal('29.25'), lengthIn],
    },
    {
      abbrv: '30 in20',
      data: ['30 in', '20', new Decimal('0.5'), new Decimal('29'), lengthIn],
    },
    {
      abbrv: '30 inXS',
      data: ['30 in', 'XS', new Decimal('0.5'), new Decimal('29'), lengthIn],
    },
    {
      abbrv: '30 in30',
      data: ['30 in', '30', new Decimal('0.625'), new Decimal('28.75'), lengthIn],
    },
    {
      abbrv: '32 in10',
      data: ['32 in', '10', new Decimal('0.312'), new Decimal('31.376'), lengthIn],
    },
    {
      abbrv: '32 inSTD',
      data: ['32 in', 'STD', new Decimal('0.375'), new Decimal('31.25'), lengthIn],
    },
    {
      abbrv: '32 in20',
      data: ['32 in', '20', new Decimal('0.5'), new Decimal('31'), lengthIn],
    },
    {
      abbrv: '32 inXS',
      data: ['32 in', 'XS', new Decimal('0.5'), new Decimal('31'), lengthIn],
    },
    {
      abbrv: '32 in30',
      data: ['32 in', '30', new Decimal('0.625'), new Decimal('30.75'), lengthIn],
    },
    {
      abbrv: '32 in40',
      data: ['32 in', '40', new Decimal('0.688'), new Decimal('30.624'), lengthIn],
    },
    {
      abbrv: '34 in10',
      data: ['34 in', '10', new Decimal('0.344'), new Decimal('33.312'), lengthIn],
    },
    {
      abbrv: '34 inSTD',
      data: ['34 in', 'STD', new Decimal('0.375'), new Decimal('33.25'), lengthIn],
    },
    {
      abbrv: '34 in20',
      data: ['34 in', '20', new Decimal('0.5'), new Decimal('33'), lengthIn],
    },
    {
      abbrv: '34 inXS',
      data: ['34 in', 'XS', new Decimal('0.5'), new Decimal('33'), lengthIn],
    },
    {
      abbrv: '34 in30',
      data: ['34 in', '30', new Decimal('0.625'), new Decimal('32.75'), lengthIn],
    },
    {
      abbrv: '34 in40',
      data: ['34 in', '40', new Decimal('0.688'), new Decimal('32.624'), lengthIn],
    },
    {
      abbrv: '36 in10',
      data: ['36 in', '10', new Decimal('0.312'), new Decimal('35.376'), lengthIn],
    },
    {
      abbrv: '36 inSTD',
      data: ['36 in', 'STD', new Decimal('0.375'), new Decimal('35.25'), lengthIn],
    },
    {
      abbrv: '36 in20',
      data: ['36 in', '20', new Decimal('0.5'), new Decimal('35'), lengthIn],
    },
    {
      abbrv: '36 inXS',
      data: ['36 in', 'XS', new Decimal('0.5'), new Decimal('35'), lengthIn],
    },
    {
      abbrv: '36 in30',
      data: ['36 in', '30', new Decimal('0.625'), new Decimal('34.75'), lengthIn],
    },
    {
      abbrv: '36 in40',
      data: ['36 in', '40', new Decimal('0.75'), new Decimal('34.5'), lengthIn],
    },
    {
      abbrv: '15 mm',
      data: ['15', '', new Decimal('0.0'), new Decimal('21.34'), lengthMm],
    },
    {
      abbrv: '20 mm',
      data: ['20', '', new Decimal('0.0'), new Decimal('26.67'), lengthMm],
    },
    {
      abbrv: '25 mm',
      data: ['25', '', new Decimal('0.0'), new Decimal('28.5'), lengthMm],
    },
    {
      abbrv: '32 mm',
      data: ['32', '', new Decimal('0.0'), new Decimal('36.3'), lengthMm],
    },
    {
      abbrv: '40 mm',
      data: ['40', '', new Decimal('0.0'), new Decimal('43.1'), lengthMm],
    },
    {
      abbrv: '50 mm',
      data: ['50', '', new Decimal('0.0'), new Decimal('54.5'), lengthMm],
    },
    {
      abbrv: '65 mm',
      data: ['65', '', new Decimal('0.0'), new Decimal('70.3'), lengthMm],
    },
    {
      abbrv: '80 mm',
      data: ['80', '', new Decimal('0.0'), new Decimal('82.5'), lengthMm],
    },
    {
      abbrv: '100 mm',
      data: ['100', '', new Decimal('0.0'), new Decimal('107.1'), lengthMm],
    },
    {
      abbrv: '125 mm',
      data: ['125', '', new Decimal('0.0'), new Decimal('131.7'), lengthMm],
    },
    {
      abbrv: '150 mm',
      data: ['150', '', new Decimal('0.0'), new Decimal('159.3'), lengthMm],
    },
    {
      abbrv: '200 mm',
      data: ['200', '', new Decimal('0.0'), new Decimal('207.3'), lengthMm],
    },
    {
      abbrv: '250 mm',
      data: ['250', '', new Decimal('0.0'), new Decimal('260.4'), lengthMm],
    },
    {
      abbrv: '300 mm',
      data: ['300', '', new Decimal('0.0'), new Decimal('309.7'), lengthMm],
    },
    {
      abbrv: '350 mm',
      data: ['350', '', new Decimal('0.0'), new Decimal('339.6'), lengthMm],
    },
    {
      abbrv: '400 mm',
      data: ['400', '', new Decimal('0.0'), new Decimal('390.4'), lengthMm],
    },
    {
      abbrv: '500 mm',
      data: ['500', '', new Decimal('0.0'), new Decimal('492'), lengthMm],
    },
    {
      abbrv: '600 mm',
      data: ['600', '', new Decimal('0.0'), new Decimal('592.4'), lengthMm],
    },
    {
      abbrv: 'Other',
      data: ['Other', '', new Decimal('0.0'), new Decimal('0.0'), lengthIn],
    },
  ];

  if (!type) {
    return pipesTable;
  }

  return utilities.findInArray(pipesTable, type);
};

// get all schedules for a given outside diameter
const getSchedulesForLength = (VORTEK_NAME: string, od: string): string[] => {
  const { length } = od;
  const ret = [] as string[];
  let pipes;

  if (!length) {
    return ret;
  }

  if (VORTEK_NAME === utilities.HEINRICH_NAME) {
    pipes = findHeinrichsPipes();
  } else {
    pipes = findPipes();
  }

  for (let i = 0; i < pipes.length; i++) {
    if (pipes[i].abbrv.substring(0, length) === od) {
      ret.push(pipes[i].data[1]);
    }
  }

  return ret;
};

const getAnsiPipes = (VORTEK_NAME: string): any[] => {
  const ret = [];
  let pipes;

  if (VORTEK_NAME === utilities.HEINRICH_NAME) {
    pipes = findHeinrichsPipes();
  } else {
    pipes = findPipes();
  }

  for (let i = 0; i < pipes.length; i++) {
    if (pipes[i].data[1] !== '') {
      ret.push(pipes[i].data);
    }
  }

  return ret;
};

const adjustPipeSizeForReducing = (pipeOdParam: string, reducingVortex: boolean, reducingVorcone: boolean): string => {
  let pipeOd = pipeOdParam;
  if (reducingVortex || reducingVorcone) {
    if (!pipeOd) {
      pipeOd = '0.75 in';
    }

    switch (pipeOd) {
      case '0.75 in':
        pipeOd = '0.5 in';
        break;
      case '1 in':
        pipeOd = '0.5 in';
        break;
      case '1.5 in':
        pipeOd = '1 in';
        break;
      case '2 in':
        pipeOd = '1.5 in';
        break;
      case '3 in':
        pipeOd = '2 in';
        break;
      case '4 in':
        pipeOd = '3 in';
        break;
      case '6 in':
        pipeOd = '4 in';
        break;
      case '8 in':
        pipeOd = '6 in';
        break;
      case '10 in':
        pipeOd = '8 in';
        break;
      case '12 in':
        pipeOd = '10 in';
        break;
      default:
        pipeOd = '0.5 in';
    }
  } else if (!pipeOd) {
    pipeOd = '0.5 in';
  }

  return pipeOd;
};

const mapInlineToElectromagneticPipeSize = (pipeOdParam: string): string => {
  let pipeOd = pipeOdParam;
  switch (pipeOd) {
    case '0.75-inch':
      pipeOd = '3/4-inch Nominal Bore (20mm)';
      break;
    case '1-inch':
      pipeOd = '1-inch Nominal Bore (25mm)';
      break;
    case '1.5-inch':
      pipeOd = '1.5-inch Nominal Bore (40mm)';
      break;
    case '2-inch':
      pipeOd = '2-inch Nominal Bore (50mm)';
      break;
    case '3-inch':
      pipeOd = '3-inch Nominal Bore (80mm)';
      break;
    case '4-inch':
      pipeOd = '4-inch Nominal Bore (100mm)';
      break;
    case '6-inch':
      pipeOd = '6-inch Nominal Bore (150mm)';
      break;
    case '8-inch':
      pipeOd = '8-inch Nominal Bore (200mm)';
      break;
    case '10-inch':
      pipeOd = '10-inch Nominal Bore (250mm)';
      break;
    case '12-inch':
      pipeOd = '12-inch Nominal Bore (300mm)';
      break;
    case '14-inch':
      pipeOd = '14-inch Nominal Bore (350mm)';
      break;
    case '16-inch':
      pipeOd = '16-inch Nominal Bore (400mm)';
      break;
    case '18-inch':
      pipeOd = '18-inch Nominal Bore (450mm)';
      break;
    case '20-inch':
      pipeOd = '20-inch Nominal Bore (500mm)';
      break;
    case '24-inch':
      pipeOd = '24-inch Nominal Bore (600mm)';
      break;
    case '28-inch':
      pipeOd = '28-inch Nominal Bore (700mm)';
      break;
    case '32-inch':
      pipeOd = '32-inch Nominal Bore (800mm)';
      break;
    case '36-inch':
      pipeOd = '36-inch Nominal Bore (900mm)';
      break;
    default:
      pipeOd = '1/2-inch Nominal Bore (15mm)';
  }

  return pipeOd;
};

export default {
  gatherPipeData,
  gatherSelectedPipeData,
  getAnsiPipeSizes,
  getSchedules,
  getDinPipeSizes,
  getJisPipeSizes,
  getJisSchedules,
  getJisDataForLength,
  findJisPipeInsideDiameter,
  findJisPipe,
  findPipes,
  findHeinrichsPipes,
  getSchedulesForLength,
  getAnsiPipes,
  adjustPipeSizeForReducing,
  mapInlineToElectromagneticPipeSize,
};
