import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import actions from '../store/actions';
import utilities from '../utilities';
import meterSpecify from '../utilities/meterSpecify';
import Modal from './common/Modal';
import MeterSpecify from './meterSpecify/MeterSpecify';
import Gas from './gas/gas';
import Liquid from './liquid/liquid';
import CustomerModal from './customer/customerModal';
import application from '../utilities/application';

const RightPanel = (): JSX.Element => {
  const dispatch = useDispatch();
  const appMode = useSelector((state: RootState) => state.appMode);
  const isAdmin = useSelector((state: RootState) => state.isAdmin);
  const username = useSelector((state: RootState) => state.username);
  const meterIndex = useSelector((state: RootState) => state.meterIndex);
  const showSpecifyMeter = useSelector((state: RootState) => state.showSpecifyMeter);
  const [valuesNeeded, setValuesNeeded] = useState(false);
  const [showGas, setShowGas] = useState(false);
  const [showLiquid, setShowLiquid] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);

  const showCustomerUser = !utilities.isHeinrichsOrOnicon();

  const switchAppMode = (mode: string) => {
    if (mode === 'sizing') {
      if (!utilities.checkValues() || !utilities.checkExtendedValues()) {
        setValuesNeeded(true);
        return;
      }

      meterSpecify.resetSpecifyMeterFields();
      dispatch(actions.setMeterData([]));
      dispatch(actions.setMeterIndex(-1));
      dispatch(actions.setAppMode('sizing'));
    } else {
      dispatch(actions.setAppMode('meter_conditions'));
    }
  };

  return (
    <>
      {valuesNeeded && (
        <Modal
          showData={false}
          okText="Ok"
          cancelText=""
          title="Incomplete Data"
          visible
          onClose={() => {
            setValuesNeeded(false);
          }}
        >
          <div className="modalContent">Please enter all calculation data</div>
        </Modal>
      )}
      <div className="flexCol">
        {showCustomerUser && <div className="userdiv">{username && `${isAdmin ? 'Admin ' : ''}logged in: ${username}`}</div>}
        <div className="flexColEnd">
          {showCustomerUser && (
            <button
              type="button"
              name="buttonCustomer"
              className="btnGreen topBottom5"
              onClick={() => {
                setShowCustomer(true);
              }}
            >
              <b className="iconCustomer">CUSTOMER</b>
            </button>
          )}
          {appMode === 'sizing' && (
            <button
              type="button"
              name="buttonApplication"
              className="btnGreen topBottom5"
              onClick={() => {
                switchAppMode('meter_conditions');
              }}
            >
              <b className="iconApplication">APPLICATION</b>
            </button>
          )}
          <button type="button" name="buttonAddFluid" className="btnGreen topBottom5" onClick={() => setShowGas(true)}>
            <b className="iconGas">ADD/EDIT GAS</b>
          </button>
          <button type="button" name="buttonAddLiquid" className="btnGreen topBottom5" onClick={() => setShowLiquid(true)}>
            <b className="iconLiquid">ADD/EDIT LIQUID</b>
          </button>
          {appMode === 'meter_conditions' && (
            <button
              type="button"
              name="button_clear"
              className="btnGreen topBottom5"
              onClick={() => application.newApplication('no')}
            >
              <b className="iconClear">CLEAR ALL</b>
            </button>
          )}
          <button
            type="button"
            name="buttonSizeMeters"
            className="btnGreen topBottom5"
            onClick={() => {
              switchAppMode('sizing');
            }}
          >
            <b className="iconSize">SIZE METERS</b>
          </button>
          <button
            type="button"
            name="buttonSpecifyMeter"
            className="btnGreen topBottom5"
            onClick={() => {
              if (meterIndex > -1) {
                dispatch(actions.setShowSpecifyMeter(true));
              } else {
                dispatch(
                  actions.setErrorMessage('Please enter calculation data and size meters before trying to specify a meter.')
                );
              }
            }}
          >
            <b className="iconMeter">SPECIFY METER</b>
          </button>
        </div>
      </div>
      {showSpecifyMeter && (
        <MeterSpecify
          onClose={() => {
            dispatch(actions.setShowSpecifyMeter(false));
          }}
        />
      )}

      {showGas && <Gas onClose={() => setShowGas(false)} />}
      {showLiquid && <Liquid onClose={() => setShowLiquid(false)} />}
      {showCustomer && <CustomerModal onClose={() => setShowCustomer(false)} />}
    </>
  );
};

export default RightPanel;
