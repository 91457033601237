import Decimal from 'decimal.js';
import calc from '.';
import utilities from '..';
import api from '../../api';
import constants from '../../constants';
import store from '../../store';
import actions from '../../store/actions';
import Meter from '../../types/Meter';
import PipeData from '../../types/PipeData';
import ProcessCondition from '../../types/processCondition';
import convert from '../convert';
import pipeUtils from '../pipeUtils';
import calcMeterSize from './calcMeterSize';
import calcTurbinePipe from './calcTurbinePipe';

const getReducedSize = (pipeOutsideDiameter: string): string => {
  const state = store.getState();
  const { sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  let meterName = '';

  if (VORTEK_NAME === utilities.ONICON_NAME) {
    switch (pipeOutsideDiameter) {
      case '0.75 in':
        meterName = '0.75" x 0.5"';
        break;
      case '1 in':
        meterName = '1" x 0.5"';
        break;
      case '1.5 in':
        meterName = '1.5" x 1"';
        break;
      case '2 in':
        meterName = '2" x 1.5"';
        break;
      case '3 in':
        meterName = '3" x 2"';
        break;
      case '4 in':
        meterName = '4" x 3"';
        break;
      case '6 in':
        meterName = '6" x 4"';
        break;
      case '8 in':
        meterName = '8" x 6"';
        break;
      case '10 in':
        meterName = '10" x 8"';
        break;
      case '12 in':
        meterName = '12" x 10"';
        break;
      default:
        meterName = '';
        break;
    }
  } else if (VORTEK_NAME === utilities.INNOVA_NAME) {
    switch (pipeOutsideDiameter) {
      case '0.75 in':
        meterName = '0.75" x 0.5"';
        break;
      case '1 in':
        meterName = '1" x 0.5"';
        break;
      case '1.5 in':
        meterName = '1.5" x 1"';
        break;
      case '2 in':
        meterName = '2" x 1.5"';
        break;
      case '3 in':
        meterName = '3" x 2"';
        break;
      case '4 in':
        meterName = '4" x 3"';
        break;
      case '6 in':
        meterName = '6" x 4"';
        break;
      case '8 in':
        meterName = '8" x 6"';
        break;
      case '10 in':
        meterName = '10" x 8"';
        break;
      case '12 in Stainless Steel':
        meterName = '12" x 10" Stainless Steel';
        break;
      case '12 in Carbon Steel':
        meterName = '12" x 10" Carbon Steel';
        break;
      default:
        meterName = '';
        break;
    }
  } else {
    switch (pipeOutsideDiameter) {
      case '0.75 in':
        meterName = '0.75 x 0.5-inch';
        break;
      case '1 in':
        meterName = '1 x 0.5-inch';
        break;
      case '1.5 in':
        meterName = '1.5 x 1-inch';
        break;
      case '2 in':
        meterName = '2 x 1.5-inch';
        break;
      case '3 in':
        meterName = '3 x 2-inch';
        break;
      case '4 in':
        meterName = '4 x 3-inch';
        break;
      case '6 in':
        meterName = '6 x 4-inch';
        break;
      case '8 in':
        meterName = '8 x 6-inch';
        break;
      case '10 in':
        meterName = '10 x 8-inch';
        break;
      case '12 in Stainless Steel':
        meterName = '12 x 10-inch Stainless Steel';
        break;
      case '12 in Carbon Steel':
        meterName = '12 x 10-inch Carbon Steel';
        break;
      default:
        meterName = '';
        break;
    }
  }

  return meterName;
};

const measuredVelocity = (type: string, pipeData: PipeData, cConditions: ProcessCondition): Decimal => {
  const state = store.getState();
  const { meterType, sizingGlobals } = state;
  const {
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    ElectromagneticProMModel,
  } = sizingGlobals;

  let dblReCalc = new Decimal('5000.0');
  let dblRoVSquare;
  const pipeId = new Decimal(pipeData.pipeInsideDiameter);

  if (
    meterType === UltrasonicS34Model ||
    meterType === UltrasonicS36Model ||
    meterType === UltrasonicU42Model ||
    meterType === UltrasonicU43Model ||
    meterType === UltrasonicU44Model
  ) {
    dblReCalc = new Decimal('478.0');
  }

  // Calculate the minimum velocity from Reynolds number.
  if (type === 'In-line' || type === 'In-line Reducing' || meterType === ElectromagneticProMModel) {
    dblReCalc = calc.calcVelocityFromRe(
      dblReCalc,
      pipeId,
      cConditions.dblDensityValue,
      'lb/ft^3',
      cConditions.dblViscosityValue,
      'cP'
    );
  } else {
    // The Reynolds calculation is based upon the sensor head diameter.
    // This information is already in inches and does not need the conversion.
    dblReCalc = calc.calcVelocityFromRe(
      dblReCalc,
      constants.INSERTION_ID,
      cConditions.dblDensityValue,
      'lb/ft^3',
      cConditions.dblViscosityValue,
      'cP'
    );
  }

  if (pipeData.fluidType === 'Liquid' || pipeData.fluidType === 'Water' || pipeData.fluidType === 'Other Liquid') {
    if (
      meterType === UltrasonicS34Model ||
      meterType === UltrasonicS36Model ||
      meterType === UltrasonicU42Model ||
      meterType === UltrasonicU43Model ||
      meterType === UltrasonicU44Model ||
      meterType === ElectromagneticProMModel
    ) {
      if (dblReCalc.lessThan(new Decimal('0.25'))) {
        dblReCalc = new Decimal('0.25');
      }
    } else if (dblReCalc.lessThan(new Decimal('1'))) {
      dblReCalc = new Decimal('1');
    }
  } else {
    let val = cConditions.dblDensityValue;
    val = new Decimal('25').div(val);
    dblRoVSquare = val.sqrt();

    // dblRoVSquare = Math.Sqrt(25 / convert.densityTo("lb/ft^3", condMin));

    if (dblRoVSquare.greaterThan(dblReCalc)) {
      dblReCalc = dblRoVSquare;
    }
  }

  return dblReCalc;
};

const calcInsertionSTDPipe = (punit: string, pipeId: string, schedule: string): Meter[] => {
  const state = store.getState();
  const { meterType, selectedPipeOutsideDiameter, sizingGlobals } = state;
  const {
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    ElectromagneticProMModel,
    VORTEK_NAME,
  } = sizingGlobals;

  const pipes = [];
  const stdPipeSchedules = pipeUtils.getAnsiPipes(VORTEK_NAME);
  let i;
  let potential;
  let diameter;
  let insideArea;

  if (
    meterType === UltrasonicS34Model ||
    meterType === UltrasonicS36Model ||
    meterType === UltrasonicU42Model ||
    meterType === UltrasonicU43Model ||
    meterType === UltrasonicU44Model ||
    meterType === ElectromagneticProMModel
  ) {
    const strSize = `${selectedPipeOutsideDiameter.split(' ')[0]} in`;
    for (i = 0; i < stdPipeSchedules.length; i++) {
      /* eslint-disable-next-line */
      potential = stdPipeSchedules[i][0];
      if (stdPipeSchedules[i][1] === schedule && strSize === potential) {
        /* eslint-disable-next-line */
        diameter = stdPipeSchedules[i][0];
        if (diameter === 'Other') {
          diameter = pipeId;
        } else {
          /* eslint-disable-next-line */
          diameter = diameter.split(' ')[0];
        }

        insideArea = convert.getArea(stdPipeSchedules[i][3]);
        // CHANGED THIS FROM INSERTION TO IN-LINE
        pipes.push(
          calcMeterSize.createMeter(
            stdPipeSchedules[i][0],
            'In-line',
            diameter,
            stdPipeSchedules[i][3],
            'in',
            stdPipeSchedules[i][2],
            schedule,
            insideArea,
            'in'
          )
        );
        break;
      }
    }
  } else {
    let mmSize = new Decimal(pipeId);

    if (punit === 'mm') {
      mmSize = convert.pipeId(mmSize, 'in', 'mm');
    }

    const smallest = constants.SMALLEST_INSERTION.times(mmSize);
    const largest = constants.LARGEST_INSERTION.times(mmSize);

    for (i = 0; i < stdPipeSchedules.length; i++) {
      /* eslint-disable-next-line */
      potential = stdPipeSchedules[i][3];
      if (
        stdPipeSchedules[i][1] === schedule &&
        potential.gt(new Decimal('1.939')) &&
        potential.gt(smallest) &&
        potential.lt(largest)
      ) {
        /* eslint-disable-next-line */
        diameter = stdPipeSchedules[i][0];
        if (diameter === 'Other') {
          diameter = pipeId.toString();
        } else {
          /* eslint-disable-next-line */
          diameter = diameter.split(' ')[0];
        }

        insideArea = convert.getArea(potential);
        pipes.push(
          calcMeterSize.createMeter(
            stdPipeSchedules[i][0],
            'Insertion',
            diameter,
            potential,
            'in',
            stdPipeSchedules[i][2],
            schedule,
            insideArea,
            'in'
          )
        );
      }
    }
  }

  return pipes;
};

const calcDINPipe = (punit: string, psize: string): Meter[] => {
  const state = store.getState();
  const { username, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  let mmSize = new Decimal(psize);
  if (punit === 'in') {
    mmSize = convert.pipeId(mmSize, 'mm', 'in');
  }

  const smallest = constants.SMALLEST_INSERTION.times(mmSize);
  const largest = constants.LARGEST_INSERTION.times(mmSize);
  const pipes = [];

  const res = api.readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'pipesdin',
    },
  });

  if (res.status !== 'fail') {
    const pipesdin = res.data.data;
    let insideDia;
    for (let i = 0; i < pipesdin.length; i++) {
      insideDia = new Decimal(pipesdin[i].InsideDia);

      if (insideDia.gte(constants.METRIC_TWO_INCH) && insideDia.gte(smallest) && insideDia.lte(largest)) {
        const diameter = pipesdin[i].DN.split(' ')[0];
        const insideArea = convert.getArea(insideDia);
        pipes.push(
          calcMeterSize.createMeter(
            pipesdin[i].DN,
            'Insertion',
            diameter,
            insideDia.toString(),
            'mm',
            undefined,
            '',
            insideArea,
            'mm'
          )
        );
      }
    }
  }

  return pipes;
};

const calcJisPipe = (punit: string, psize: string, schedule: string): Meter[] => {
  const state = store.getState();
  const { username, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  let mmSize = new Decimal(psize);
  if (punit === 'in') {
    mmSize = convert.pipeId(mmSize, 'mm', 'in');
  }

  const smallest = constants.SMALLEST_INSERTION.times(mmSize);
  const largest = constants.LARGEST_INSERTION.times(mmSize);
  const pipes = [];
  let pipesjis;
  let insideDia;
  let i;
  let diameter;
  let insideArea;

  const res = api.readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'jisPipeSchedules',
    },
  });

  if (res.status !== 'fail') {
    pipesjis = res.data.data;
    for (i = 0; i < pipesjis.length; i++) {
      insideDia = new Decimal(pipesjis[i].InsideDiameter);

      if (
        insideDia.gte(constants.METRIC_TWO_INCH) &&
        insideDia.gte(smallest) &&
        insideDia.lte(largest) &&
        schedule === pipesjis[i].Schedule
      ) {
        /* eslint-disable-next-line */
        diameter = pipesjis[i].NominalSize.split(' ')[0];
        insideArea = convert.getArea(insideDia);
        pipes.push(
          calcMeterSize.createMeter(
            pipesjis[i].NominalSize,
            'Insertion',
            diameter,
            insideDia.toString(),
            'mm',
            undefined,
            pipesjis[i].Schedule,
            insideArea,
            'mm'
          )
        );
      }
    }
  }

  return pipes;
};

const calcOtherPipe = (punit: string, psize: string, pschedule: string): Meter[] => {
  const name = psize + punit;
  const insideArea = convert.getArea(new Decimal(psize));
  const pipes = [];
  const diameter = psize;

  pipes.push(calcMeterSize.createMeter(name, 'Insertion', diameter, psize, punit, pschedule, '', insideArea, punit));

  return pipes;
};

const getInsertionMeter = (pipeType: string, punit: string, pipeId: string, schedule: string): Meter[] => {
  let meters = [] as Meter[];

  if (pipeType === 'ansi') {
    meters = calcInsertionSTDPipe(punit, pipeId, schedule);
  } else if (pipeType === 'din') {
    meters = calcDINPipe(punit, pipeId);
  } else if (pipeType === 'jis') {
    meters = calcJisPipe(punit, pipeId, schedule);
  } else if (pipeType === 'other') {
    // 1.939 is a 2 inch schedule 80 pipe.
    const inchSize = convert.pipeId(new Decimal(pipeId), 'in', punit);
    if (inchSize.gt(new Decimal('1.939'))) {
      meters = calcOtherPipe(punit, pipeId, schedule);
    }
  }

  return meters;
};

const getProMMeter = (pipeType: string, punit: string, psize: string, schedule: string): Meter[] => {
  const state = store.getState();
  const { username, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  let inchSize = new Decimal(psize);
  if (punit === 'mm') {
    inchSize = convert.pipeId(inchSize, 'in', 'mm');
  }

  const smallest = constants.SMALLEST_INLINE.times(inchSize);
  const largest = constants.LARGEST_INLINE.times(inchSize);
  const pipes = [] as Meter[];
  let inlines;
  let insideDia;
  let insideArea;
  let diameter;

  const res = api.readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'inlinesProM',
    },
  });

  if (res.status !== 'fail') {
    inlines = JSON.parse(JSON.stringify(res.data.data));

    for (let i = 0; i < inlines.length; i++) {
      insideDia = new Decimal(inlines[i].InsideDia);

      if (insideDia.gte(smallest) && insideDia.lte(largest)) {
        /* eslint-disable-next-line */
        diameter = inlines[i].Name.split('-')[0];
        // fix for Heinrichs not following everyone else's naming conventions
        /* eslint-disable-next-line */
        if (isNaN(Number(diameter))) {
          if (diameter.includes('½') || diameter.includes('1/2')) {
            diameter = '.5';
          } else if (diameter.includes('¾') || diameter.includes('3/4')) {
            diameter = '.75';
          } else if (diameter.startsWith('1"')) {
            diameter = '1.0';
          } else if (diameter.startsWith('1,5"')) {
            diameter = '1.5';
          } else if (diameter.startsWith('2"')) {
            diameter = '2.0';
          } else if (diameter.startsWith('3"')) {
            diameter = '3.0';
          } else if (diameter.startsWith('4"')) {
            diameter = '4.0';
          } else if (diameter.startsWith('6"')) {
            diameter = '6.0';
          } else if (diameter.startsWith('8"')) {
            diameter = '8.0';
          } else if (diameter.startsWith('10"')) {
            diameter = '10.0';
          } else if (diameter.startsWith('12"')) {
            diameter = '12.0';
          } else if (diameter.startsWith('Wafer 1"')) {
            diameter = '1.0';
          } else if (diameter.startsWith('Wafer 1,5"')) {
            diameter = '1.5';
          } else if (diameter.startsWith('Wafer 2"')) {
            diameter = '2.0';
          } else if (diameter.startsWith('Wafer 3"')) {
            diameter = '3.0';
          } else if (diameter.startsWith('Wafer 4"')) {
            diameter = '4.0';
          } else if (diameter.startsWith('Special')) {
            diameter = '0.0';
          }
        }
        insideArea = convert.getArea(insideDia);
        pipes.push(
          calcMeterSize.createMeter(
            inlines[i].Name,
            'In-line',
            diameter,
            insideDia.toString(),
            'in',
            undefined,
            schedule,
            insideArea,
            'in'
          )
        );
      }
    }
  }

  return pipes;
};

const getInlineMeter = (pipeType: string, punit: string, psize: string, schedule: string): Meter[] => {
  const state = store.getState();
  const { username, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  let inchSize = new Decimal(psize);
  if (punit === 'mm') {
    inchSize = convert.pipeId(inchSize, 'in', 'mm');
  }

  const smallest = constants.SMALLEST_INLINE.times(inchSize);
  const largest = constants.LARGEST_INLINE.times(inchSize);
  const pipes = [] as Meter[];
  let inlines;
  let insideDia;
  let insideArea;
  let diameter;

  const res = api.readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'inlines',
    },
  });

  if (res.status !== 'fail') {
    inlines = JSON.parse(JSON.stringify(res.data.data));

    for (let i = 0; i < inlines.length; i++) {
      insideDia = new Decimal(inlines[i].InsideDia);

      if (insideDia.gte(smallest) && insideDia.lte(largest)) {
        /* eslint-disable-next-line */
        diameter = inlines[i].Name.split('-')[0];
        // fix for Heinrichs not following everyone else's naming conventions
        /* eslint-disable-next-line */
        if (isNaN(Number(diameter))) {
          if (diameter.includes('½') || diameter.includes('1/2')) {
            diameter = '.5';
          } else if (diameter.includes('¾') || diameter.includes('3/4')) {
            diameter = '.75';
          } else if (diameter.startsWith('1"')) {
            diameter = '1.0';
          } else if (diameter.startsWith('1,5"')) {
            diameter = '1.5';
          } else if (diameter.startsWith('2"')) {
            diameter = '2.0';
          } else if (diameter.startsWith('3"')) {
            diameter = '3.0';
          } else if (diameter.startsWith('4"')) {
            diameter = '4.0';
          } else if (diameter.startsWith('6"')) {
            diameter = '6.0';
          } else if (diameter.startsWith('8"')) {
            diameter = '8.0';
          } else if (diameter.startsWith('10"')) {
            diameter = '10.0';
          } else if (diameter.startsWith('12"')) {
            diameter = '12.0';
          } else if (diameter.startsWith('Wafer 1"')) {
            diameter = '1.0';
          } else if (diameter.startsWith('Wafer 1,5"')) {
            diameter = '1.5';
          } else if (diameter.startsWith('Wafer 2"')) {
            diameter = '2.0';
          } else if (diameter.startsWith('Wafer 3"')) {
            diameter = '3.0';
          } else if (diameter.startsWith('Wafer 4"')) {
            diameter = '4.0';
          } else if (diameter.startsWith('Special')) {
            diameter = '0.0';
          }
        }
        insideArea = convert.getArea(insideDia);
        pipes.push(
          calcMeterSize.createMeter(
            inlines[i].Name,
            'In-line',
            diameter,
            insideDia.toString(),
            'in',
            undefined,
            schedule,
            insideArea,
            'in'
          )
        );
      }
    }
  }

  return pipes;
};

const getInlineReducingMeter = (pipeType: string, punit: string, psize: string, schedule: string): Meter[] => {
  const state = store.getState();
  const { username, selectedPipeOutsideDiameter, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  const pipes = [] as Meter[];
  let inlines;
  let insideDia;
  let insideArea;
  let diameter;
  let name;
  let strSize = selectedPipeOutsideDiameter;

  if (strSize === '12 in Stainless Steel') {
    strSize = '12-inch Stainless Steel';
  } else if (strSize === '12 in Carbon Steel') {
    strSize = '12-inch Carbon Steel';
  } else {
    strSize = `${psize.split(' ')[0]}-inch`;
  }

  const res = api.readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'inlines',
    },
  });

  if (res.status !== 'fail') {
    inlines = res.data.data;
    for (let i = 0; i < inlines.length; i++) {
      insideDia = new Decimal(inlines[i].InsideDia);
      name = inlines[i].Name;

      if (strSize === name) {
        /* eslint-disable-next-line */
        diameter = name.split('-')[0];
        // fix for Heinrichs not following everyone else's naming conventions
        /* eslint-disable-next-line */
        if (isNaN(Number(diameter))) {
          if (diameter.includes('½')) {
            diameter = '.5';
          } else if (diameter.includes('¾')) {
            diameter = '.75';
          } else if (diameter.startsWith('1"')) {
            diameter = '1.0';
          } else if (diameter.startsWith('1,5"')) {
            diameter = '1.5';
          } else if (diameter.startsWith('2"')) {
            diameter = '2.0';
          } else if (diameter.startsWith('3"')) {
            diameter = '3.0';
          } else if (diameter.startsWith('4"')) {
            diameter = '4.0';
          } else if (diameter.startsWith('6"')) {
            diameter = '6.0';
          } else if (diameter.startsWith('8"')) {
            diameter = '8.0';
          } else if (diameter.startsWith('10"')) {
            diameter = '10.0';
          } else if (diameter.startsWith('12"')) {
            diameter = '12.0';
          } else if (diameter.startsWith('Wafer 1"')) {
            diameter = '1.0';
          } else if (diameter.startsWith('Wafer 1,5"')) {
            diameter = '1.5';
          } else if (diameter.startsWith('Wafer 2"')) {
            diameter = '2.0';
          } else if (diameter.startsWith('Wafer 3"')) {
            diameter = '3.0';
          } else if (diameter.startsWith('Wafer 4"')) {
            diameter = '4.0';
          } else if (diameter.startsWith('Special')) {
            diameter = '0.0';
          }
        }
        insideArea = convert.getArea(insideDia);
        pipes.push(
          calcMeterSize.createMeter(
            inlines[i].Name,
            'In-line Reducing',
            diameter,
            insideDia.toString(),
            'in',
            undefined,
            schedule,
            insideArea,
            'in'
          )
        );
      }
    }
  }

  return pipes;
};

const getVorconeMeter = (pipeType: string, punit: string, psize: string, schedule: string): Meter[] => {
  const state = store.getState();
  const { username, selectedPipeOutsideDiameter, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  const pipes = [] as Meter[];
  const betas = ['0.45', '0.55', '0.65', '0.75'];
  let inlines;
  let name;
  let insideDia;
  let insideArea;
  let diameter;
  let strSize = selectedPipeOutsideDiameter;

  if (strSize === '12 in Stainless Steel') {
    strSize = '12-inch Stainless Steel';
  } else if (strSize === '12 in Carbon Steel') {
    strSize = '12-inch Carbon Steel';
  } else {
    strSize = `${selectedPipeOutsideDiameter.split(' ')[0]}-inch`;
  }

  const res = api.readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'inlines',
    },
  });

  if (res.status !== 'fail') {
    inlines = res.data.data;
    for (let i = 0; i < inlines.length; i++) {
      name = inlines[i].Name;

      if (strSize === name) {
        insideDia = new Decimal(inlines[i].InsideDia);
        /* eslint-disable-next-line */
        diameter = name.split('-')[0];
        // fix for Heinrichs not following everyone else's naming conventions
        /* eslint-disable-next-line */
        if (isNaN(Number(diameter))) {
          if (diameter.includes('½')) {
            diameter = '.5';
          } else if (diameter.includes('¾')) {
            diameter = '.75';
          } else if (diameter.startsWith('1"')) {
            diameter = '1.0';
          } else if (diameter.startsWith('1,5"')) {
            diameter = '1.5';
          } else if (diameter.startsWith('2"')) {
            diameter = '2.0';
          } else if (diameter.startsWith('3"')) {
            diameter = '3.0';
          } else if (diameter.startsWith('4"')) {
            diameter = '4.0';
          } else if (diameter.startsWith('6"')) {
            diameter = '6.0';
          } else if (diameter.startsWith('8"')) {
            diameter = '8.0';
          } else if (diameter.startsWith('10"')) {
            diameter = '10.0';
          } else if (diameter.startsWith('12"')) {
            diameter = '12.0';
          } else if (diameter.startsWith('Wafer 1"')) {
            diameter = '1.0';
          } else if (diameter.startsWith('Wafer 1,5"')) {
            diameter = '1.5';
          } else if (diameter.startsWith('Wafer 2"')) {
            diameter = '2.0';
          } else if (diameter.startsWith('Wafer 3"')) {
            diameter = '3.0';
          } else if (diameter.startsWith('Wafer 4"')) {
            diameter = '4.0';
          } else if (diameter.startsWith('Special')) {
            diameter = '0.0';
          }
        }
        insideArea = convert.getArea(insideDia);

        for (let b = 0; b < betas.length; b++) {
          pipes.push(
            calcMeterSize.createMeter(
              inlines[i].Name,
              'In-line',
              diameter,
              insideDia.toString(),
              'in',
              undefined,
              schedule,
              insideArea,
              'in',
              betas[b]
            )
          );
        }
        break;
      }
    }
  }

  return pipes;
};

const getVorconeReducingMeter = (pipeType: string, punit: string, psize: string, schedule: string): Meter[] => {
  const state = store.getState();
  const { username, selectedPipeOutsideDiameter, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  const pipes = [] as Meter[];
  let inlines;
  let insideDia;
  let insideArea;
  let diameter;
  let name;
  const betas = ['0.45', '0.55', '0.65', '0.75'];
  let strSize = selectedPipeOutsideDiameter;

  if (strSize === '12 in Stainless Steel') {
    strSize = '12-inch Stainless Steel';
  } else if (strSize === '12 in Carbon Steel') {
    strSize = '12-inch Carbon Steel';
  } else {
    strSize = `${psize.split(' ')[0]}-inch`;
  }

  const res = api.readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'inlines',
    },
  });
  if (res.status !== 'fail') {
    inlines = res.data.data;
    for (let i = 0; i < inlines.length; i++) {
      insideDia = new Decimal(inlines[i].InsideDia);
      name = inlines[i].Name;

      if (name === strSize) {
        /* eslint-disable-next-line */
        diameter = name.split('-')[0];
        // fix for Heinrichs not following everyone else's naming conventions
        /* eslint-disable-next-line */
        if (isNaN(Number(diameter))) {
          if (diameter.includes('½')) {
            diameter = '.5';
          } else if (diameter.includes('¾')) {
            diameter = '.75';
          } else if (diameter.startsWith('1"')) {
            diameter = '1.0';
          } else if (diameter.startsWith('1,5"')) {
            diameter = '1.5';
          } else if (diameter.startsWith('2"')) {
            diameter = '2.0';
          } else if (diameter.startsWith('3"')) {
            diameter = '3.0';
          } else if (diameter.startsWith('4"')) {
            diameter = '4.0';
          } else if (diameter.startsWith('6"')) {
            diameter = '6.0';
          } else if (diameter.startsWith('8"')) {
            diameter = '8.0';
          } else if (diameter.startsWith('10"')) {
            diameter = '10.0';
          } else if (diameter.startsWith('12"')) {
            diameter = '12.0';
          } else if (diameter.startsWith('Wafer 1"')) {
            diameter = '1.0';
          } else if (diameter.startsWith('Wafer 1,5"')) {
            diameter = '1.5';
          } else if (diameter.startsWith('Wafer 2"')) {
            diameter = '2.0';
          } else if (diameter.startsWith('Wafer 3"')) {
            diameter = '3.0';
          } else if (diameter.startsWith('Wafer 4"')) {
            diameter = '4.0';
          } else if (diameter.startsWith('Special')) {
            diameter = '0.0';
          }
        }
        insideArea = convert.getArea(insideDia);

        for (let b = 0; b < betas.length; b++) {
          pipes.push(
            calcMeterSize.createMeter(
              getReducedSize(selectedPipeOutsideDiameter),
              'In-line Reducing',
              diameter,
              insideDia.toString(),
              'in',
              undefined,
              schedule,
              insideArea,
              'in',
              betas[b]
            )
          );
        }
        break;
      }
    }
  }

  return pipes;
};

const columnData = (): Meter[] => {
  const state = store.getState();
  const {
    meterType,
    pipeInsideDiameter,
    pipeOutsideDiameter,
    pipeSchedule,
    pipeType,
    pipeUnit,
    reducingVorcone,
    reducingVortex,
    sizingGlobals,
    turbineActive,
  } = state;
  const {
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    ElectromagneticProMModel,
  } = sizingGlobals;

  let meters = [] as Meter[];
  let pipeId = pipeInsideDiameter;

  store.dispatch(actions.setSelectedPipeInsideDiameter(pipeId));
  store.dispatch(actions.setSelectedPipeOutsideDiameter(pipeOutsideDiameter));
  if (pipeType === 'other') {
    /* eslint-disable-next-line */
    pipeId = pipeId.split(' ')[0];
    store.dispatch(actions.setSelectedPipeInsideDiameter(pipeId));
  }

  const pisize = pipeUtils.adjustPipeSizeForReducing(pipeOutsideDiameter, reducingVortex, reducingVorcone);

  if (turbineActive) {
    meters = calcTurbinePipe.getTurbineInsertionMeter(pipeType, pipeUnit, pipeId, pipeSchedule);
    if (meters.length === 0) {
      store.dispatch(
        actions.setErrorMessage('No meters were found. The turbine meter line size must be 3 inch (80 mm) or greater.')
      );
    }
  } else if (reducingVortex) {
    meters = getInlineReducingMeter(pipeType, pipeUnit, pisize, '80');
  } else if (reducingVorcone) {
    meters = getVorconeReducingMeter(pipeType, pipeUnit, pisize, '80');
  } else if (meterType && meterType === 'nonreduce_vortex') {
    meters = meters.concat(getInlineMeter(pipeType, pipeUnit, pipeId, pipeSchedule));
  } else if (meterType && meterType === ElectromagneticProMModel) {
    meters = meters.concat(getProMMeter(pipeType, pipeUnit, pipeId, '40'));
  } else if (meterType && meterType === 'vorcone') {
    meters = meters.concat(getVorconeMeter(pipeType, pipeUnit, pipeId, '80'));
  } else if (
    meterType &&
    (meterType === UltrasonicS34Model ||
      meterType === UltrasonicS36Model ||
      meterType === UltrasonicU42Model ||
      meterType === UltrasonicU43Model ||
      meterType === UltrasonicU44Model)
  ) {
    meters = getInsertionMeter(pipeType, pipeUnit, pipeId, pipeSchedule);
  } else {
    meters = getInsertionMeter(pipeType, pipeUnit, pipeId, pipeSchedule);
    meters = meters.concat(getInlineMeter(pipeType, pipeUnit, pipeId, pipeSchedule));
  }

  return meters;
};

export default {
  columnData,
  getReducedSize,
  measuredVelocity,
};
