import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { RootState } from '../../store';
import { CallbackNoParams } from '../../types/callbackTypes';
import Modal from '../common/Modal';
import api from '../../api';
import actions from '../../store/actions';
import utilities from '../../utilities';
import constants from '../../constants';

const PrintHeader = (props: { onClose: CallbackNoParams; logoutHandler: CallbackNoParams }): JSX.Element | null => {
  const dispatch = useDispatch();
  const sizingGlobals = useSelector((state: RootState) => state.sizingGlobals);
  const username = useSelector((state: RootState) => state.username);
  const { VORTEK_NAME } = sizingGlobals;
  const printHeaderRows = useSelector((state: RootState) => state.db.printheader);
  const { onClose, logoutHandler } = props;

  const [loggedIn, setLoggedIn] = useState(true);
  const [originalImage, setOriginalImage] = useState('');
  const [originalHeader, setOriginalHeader] = useState({
    name: sizingGlobals.VORTEK_PRINT_NAME,
    address0: sizingGlobals.VORTEK_ADDRESS0,
    address1: sizingGlobals.VORTEK_ADDRESS1,
    city: sizingGlobals.VORTEK_CITY,
    state: sizingGlobals.VORTEK_STATE,
    postalCode: sizingGlobals.VORTEK_POSTAL_CODE,
    country: sizingGlobals.VORTEK_COUNTRY,
    telephone: sizingGlobals.VORTEK_TELEPHONE,
    fax: sizingGlobals.VORTEK_FAX,
  });
  const [logoImage, setLogoImage] = useState('');
  const [printName, setPrintName] = useState(sizingGlobals.VORTEK_PRINT_NAME);
  const [address0, setAddress0] = useState(sizingGlobals.VORTEK_ADDRESS0);
  const [address1, setAddress1] = useState(sizingGlobals.VORTEK_ADDRESS1);
  const [city, setCity] = useState(sizingGlobals.VORTEK_CITY);
  const [state, setState] = useState(sizingGlobals.VORTEK_STATE);
  const [postalCode, setPostalCode] = useState(sizingGlobals.VORTEK_POSTAL_CODE);
  const [country, setCountry] = useState(sizingGlobals.VORTEK_COUNTRY);
  const [telephone, setTelephone] = useState(sizingGlobals.VORTEK_TELEPHONE);
  const [fax, setFax] = useState(sizingGlobals.VORTEK_FAX);
  const [error, setError] = useState('');

  useEffect(() => {
    const orgImg = api.loadLogo();
    setOriginalImage(orgImg);
    setLogoImage(orgImg);

    let nam = sizingGlobals.VORTEK_PRINT_NAME;
    let addr0 = sizingGlobals.VORTEK_ADDRESS0;
    let addr1 = sizingGlobals.VORTEK_ADDRESS1;
    let cit = sizingGlobals.VORTEK_CITY;
    let stat = sizingGlobals.VORTEK_STATE;
    let post = sizingGlobals.VORTEK_POSTAL_CODE;
    let cntry = sizingGlobals.VORTEK_COUNTRY;
    let tel = sizingGlobals.VORTEK_TELEPHONE;
    let fa = sizingGlobals.VORTEK_FAX;

    if (printHeaderRows.length > 0) {
      const id = `${VORTEK_NAME + username}printheader`;
      const currentPrintHeader = printHeaderRows.find((row) => row._id === id);

      if (currentPrintHeader !== undefined) {
        const data = JSON.parse(JSON.stringify(currentPrintHeader.data));
        if (data[0].name) {
          nam = data[0].name;
          setPrintName(nam);
        }

        if (data[0].address0) {
          addr0 = data[0].address0;
          setAddress0(addr0);
        }

        if (data[0].address1) {
          addr1 = data[0].address1;
          setAddress1(addr1);
        }

        if (data[0].city) {
          cit = data[0].city;
          setCity(cit);
        }

        if (data[0].state) {
          stat = data[0].state;
          setState(stat);
        }

        if (data[0].postalCode) {
          post = data[0].postalCode;
          setPostalCode(post);
        }

        if (data[0].country) {
          cntry = data[0].country;
          setCountry(cntry);
        }

        if (data[0].telephone) {
          tel = data[0].telephone;
          setTelephone(tel);
        }

        if (data[0].fax) {
          fa = data[0].fax;
          setFax(fa);
        }

        setOriginalHeader({
          name: nam,
          address0: addr0,
          address1: addr1,
          city: cit,
          state: stat,
          postalCode: post,
          country: cntry,
          telephone: tel,
          fax: fa,
        });
      } else {
        setOriginalHeader({
          name: printName,
          address0,
          address1,
          city,
          state,
          postalCode,
          country,
          telephone,
          fax,
        });
      }
    } else {
      setOriginalHeader({
        name: printName,
        address0,
        address1,
        city,
        state,
        postalCode,
        country,
        telephone,
        fax,
      });
    }
  }, []);

  const updateImagePreview = (newImage: File) => {
    const fr = new FileReader();
    fr.onload = () => {
      if (typeof fr.result === 'string') setLogoImage(fr.result);
    };
    fr.readAsDataURL(newImage);
  };

  const savePrintHeader = async () => {
    const printHeader = [
      {
        name: printName,
        address0,
        address1,
        city,
        state,
        postalCode,
        country,
        telephone,
        fax,
        version: constants.REVISION,
        creationDate: new Date().toUTCString(),
      },
    ];

    const imageData = [
      {
        name: 'print_header_logo',
        imgdata: logoImage,
        version: constants.REVISION,
        creationDate: new Date().toUTCString(),
      },
    ];

    let printHeaderRes;
    let imageRes;

    if (
      printHeader[0].name !== originalHeader.name ||
      printHeader[0].address0 !== originalHeader.address0 ||
      printHeader[0].address1 !== originalHeader.address1 ||
      printHeader[0].city !== originalHeader.city ||
      printHeader[0].country !== originalHeader.country ||
      printHeader[0].telephone !== originalHeader.telephone ||
      printHeader[0].fax !== originalHeader.fax
    ) {
      printHeaderRes = await api.writeData(VORTEK_NAME, username, 'printheader', printHeader);
    }

    if (logoImage !== originalImage) {
      imageRes = await api.writeData(VORTEK_NAME, username, 'image', imageData);
    }

    if (printHeaderRes || imageRes) {
      if (printHeaderRes && printHeaderRes.status === 'fail' && printHeaderRes.error === 'Unauthorized') {
        setLoggedIn(false);
        dispatch(actions.setForceLogin(true));
        utilities.handleAjaxFailure(printHeaderRes);
      } else if (imageRes && imageRes.status === 'fail' && imageRes.error === 'Unauthorized') {
        setLoggedIn(false);
        dispatch(actions.setForceLogin(true));
        utilities.handleAjaxFailure(imageRes);
      } else if (printHeaderRes && printHeaderRes.status !== 'success') {
        setError(`Failed to save data. Remote error ${printHeaderRes.status}`);
      } else if (imageRes && imageRes.status !== 'success') {
        setError(`Failed to save data. Remote error ${imageRes.status}`);
      } else {
        onClose();
      }
    } else {
      onClose();
      dispatch(actions.setWarningMessage('No changes were made.'));
    }
  };

  return (
    <>
      <Modal title="Header Print Preferences" visible showButtons={false}>
        <div className="flexRow">
          <div className="flexCol white leftRight10 topBottom10">
            <div className="flexRow">
              <label className="printHeaderFormLabel">Name</label>
              <input
                className="printHeaderInput"
                type="text"
                value={printName}
                onChange={(e) => {
                  setPrintName(e.target.value);
                }}
              />
            </div>

            <div className="flexRow">
              <label className="printHeaderFormLabel">Address</label>
              <div className="flexCol">
                <input
                  className="printHeaderInput"
                  type="text"
                  value={address0}
                  onChange={(e) => {
                    setAddress0(e.target.value);
                  }}
                />
                <input
                  className="printHeaderInput"
                  type="text"
                  value={address1}
                  onChange={(e) => {
                    setAddress1(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="flexRow">
              <label className="printHeaderFormLabel">City</label>
              <input
                className="printHeaderInput"
                type="text"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </div>

            <div className="flexRow">
              <label className="printHeaderFormLabel">State</label>
              <input
                className="printHeaderInput"
                type="text"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              />
            </div>

            <div className="flexRow">
              <label className="printHeaderFormLabel">Postal/Zip Code</label>
              <input
                className="printHeaderInput"
                type="text"
                value={postalCode}
                onChange={(e) => {
                  setPostalCode(e.target.value);
                }}
              />
            </div>
            <div className="flexRow">
              <label className="printHeaderFormLabel">Country</label>
              <input
                className="printHeaderInput"
                type="text"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
            </div>

            <div className="flexRow">
              <label className="printHeaderFormLabel">Telephone</label>
              <input
                className="printHeaderInput"
                type="text"
                value={telephone}
                onChange={(e) => {
                  setTelephone(e.target.value);
                }}
              />
            </div>

            <div className="flexRow">
              <label className="printHeaderFormLabel">FAX</label>
              <input
                className="printHeaderInput"
                type="text"
                value={fax}
                onChange={(e) => {
                  setFax(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flexColCenter leftRight10 topBottom10">
            <div
              style={{
                backgroundColor: 'white',
                width: '100%',
                height: '112px',
              }}
            >
              <img src={logoImage} style={{ maxWidth: '200px', maxHeight: '112px' }} alt="" />
            </div>
            <div className="topBottom10">
              <input
                className="printHeaderFileInput"
                type="file"
                onChange={(e) => {
                  if (e && e.target && e.target.files) {
                    updateImagePreview(e.target.files[0]);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="flexCenter topBottom10">
          <button type="button" onClick={() => savePrintHeader()}>
            Save
          </button>
          <button type="button" onClick={() => onClose()}>
            Cancel
          </button>
        </div>
      </Modal>

      {error.length > 0 && (
        <Modal
          visible
          showButtons
          cancelText=""
          title="Error"
          onClose={!loggedIn ? undefined : () => setError('')}
          logoutHandler={!loggedIn ? logoutHandler : undefined}
        >
          <div className="modalContent">{error}</div>
        </Modal>
      )}
    </>
  );
};

export default PrintHeader;
