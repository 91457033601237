export default class Feature {
  Code: string;
  FeatureNumber: string;
  Name: string;
  OrderCode: string;
  Description: string;
  Note: string;

  constructor() {
    this.Code = '';
    this.FeatureNumber = '';
    this.Name = '';
    this.OrderCode = '';
    this.Description = '';
    this.Note = '';
  }
}
