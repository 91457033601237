import store from '../store';
import api from '../api';
import Feature from '../types/Feature';
import actions from '../store/actions';
import utilities from '.';
import calcTurbineMeter from './calc/calcTurbineMeter';

const getFeatureList = (): Feature[] => {
  const state = store.getState();
  const { meterData, meterIndex, meterType, sizingGlobals } = state;
  const {
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    ElectromagneticProMModel,
  } = sizingGlobals;
  const selectedMeter = meterData[meterIndex];

  let featureList = [] as Feature[];
  if (meterType === 'vortex' && selectedMeter.type !== 'Insertion') {
    featureList = api.getSpecifiedFeatureList('m22Feature');
  } else if (meterType === 'nonreduce_vortex') {
    featureList = api.getSpecifiedFeatureList('m24Feature');
  } else if (meterType === 'reduce_vortex') {
    featureList = api.getSpecifiedFeatureList('m24rFeature');
  } else if (meterType === 'vorcone') {
    featureList = api.getSpecifiedFeatureList('mvcFeature');
  } else if (meterType === 'reduce_vorcone') {
    featureList = api.getSpecifiedFeatureList('mvcrFeature');
  } else if (meterType === UltrasonicS34Model) {
    featureList = api.getSpecifiedFeatureList('s34Feature');
  } else if (meterType === UltrasonicS36Model) {
    featureList = api.getSpecifiedFeatureList('s36Feature');
  } else if (meterType === UltrasonicU42Model) {
    featureList = api.getSpecifiedFeatureList('u42Feature');
  } else if (meterType === UltrasonicU43Model) {
    featureList = api.getSpecifiedFeatureList('u43Feature');
  } else if (meterType === UltrasonicU44Model) {
    featureList = api.getSpecifiedFeatureList('u44Feature');
  } else if (meterType === ElectromagneticProMModel) {
    featureList = api.getSpecifiedFeatureList('proMFeature');
  }

  // Insertion, Turbine
  if (!featureList.length) {
    featureList = api.getM23FeatureList();
  }

  return featureList;
};

const getFeature = (key: string, value: string): Feature | undefined => {
  const featureList = getFeatureList();
  for (let i = 0; i < featureList.length; i++) {
    const featureItem: Feature = featureList[i];
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    if (featureItem[key] === value) {
      return featureList[i];
    }
  }

  return undefined;
};

const getFeatureArrayByFeatureKey = (featureKey: string, featureValue: string): Feature[] => {
  const featureList = getFeatureList();
  const list = [] as Feature[];
  for (let i = 0; i < featureList.length; i++) {
    const featureItem: Feature = featureList[i];
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    if (featureItem[featureKey] === featureValue) {
      list.push(featureList[i]);
    }
  }

  return list;
};

const getFeatureArrayByFeatureNumber = (featurenum: string): Feature[] => {
  const featureList = getFeatureList();
  const list = [] as Feature[];
  for (let i = 0; i < featureList.length; i++) {
    const featureItem: Feature = featureList[i];
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    if (featureItem.FeatureNumber === featurenum) {
      list.push(featureList[i]);
    }
  }

  return list;
};

const getFeatureByMultiKeyUsingList = (list: Feature[], keys: string[], values: string[]): Feature | undefined => {
  // sanity check
  if (keys.length !== values.length) {
    return undefined;
  }

  const numKeyValues = keys.length;

  for (let i = 0; i < list.length; i++) {
    let found = true;
    for (let j = 0; j < numKeyValues; j++) {
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line
      if (list[i][keys[j]] !== values[j]) {
        found = false;
        break;
      }
    }

    if (found) {
      return list[i];
    }
  }

  return undefined;
};

const getFeatureByMultiKey = (keys: string[], values: string[]): Feature | undefined => {
  const featureList = getFeatureList();
  const ret = getFeatureByMultiKeyUsingList(featureList, keys, values);
  return ret;
};

const resetSpecifyMeterFields = (): void => {
  store.dispatch(actions.setSpecifyMeterAccuracy(''));
  store.dispatch(actions.setSpecifyMeterApprovals(''));
  store.dispatch(actions.setSpecifyMeterCableLength(''));
  store.dispatch(actions.setSpecifyMeterCommunications(''));
  store.dispatch(actions.setSpecifyMeterDiagAddPressTap(''));
  store.dispatch(actions.setSpecifyMeterDiagDiffPressXMTR(''));
  store.dispatch(actions.setSpecifyMeterDiffPressXMTR(''));
  store.dispatch(actions.setSpecifyMeterDiffPressXMTRMani(''));
  store.dispatch(actions.setSpecifyMeterDisplayOption(''));
  store.dispatch(actions.setSpecifyMeterElectrodeMaterial(''));
  store.dispatch(actions.setSpecifyMeterElectronicsMounting(''));
  store.dispatch(actions.setSpecifyMeterEnergyMeter(''));
  store.dispatch(actions.setSpecifyMeterFaceToFace(''));
  store.dispatch(actions.setSpecifyMeterFlange(''));
  store.dispatch(actions.setSpecifyMeterGrounding(''));
  store.dispatch(actions.setSpecifyMeterInputPower(''));
  store.dispatch(actions.setSpecifyMeterPipeMaterial(''));
  store.dispatch(actions.setSpecifyMeterOutputSignal(''));
  store.dispatch(actions.setSpecifyMeterParentModelNumber(''));
  store.dispatch(actions.setSpecifyMeterProbeLength(''));
  store.dispatch(actions.setSpecifyMeterProcessConnection(''));
  store.dispatch(actions.setSpecifyMeterProcessFluid(''));
  store.dispatch(actions.setSpecifyMeterProcessTemperature(''));
  store.dispatch(actions.setSpecifyMeterRotor(''));
  store.dispatch(actions.setSpecifyMeterTransducer(''));
  store.dispatch(actions.setSpecifyMeterTransducerBracket(''));
};

const createModelCode = (): string => {
  const state = store.getState();
  const {
    accessories,
    meterIndex,
    meterData,
    meterType,
    reducingVortex,
    sizingGlobals,
    specifyMeterAccuracy,
    specifyMeterApprovals,
    specifyMeterCableLength,
    specifyMeterCommunications,
    specifyMeterDiagAddPressTap,
    specifyMeterDiagDiffPressXMTR,
    specifyMeterDiffPressXMTR,
    specifyMeterDiffPressXMTRMani,
    specifyMeterDisplayOption,
    specifyMeterElectrodeMaterial,
    specifyMeterElectronicsMounting,
    specifyMeterEnergyMeter,
    specifyMeterFaceToFace,
    specifyMeterFlange,
    specifyMeterGrounding,
    specifyMeterInputPower,
    specifyMeterLinerMaterial,
    specifyMeterOutputSignal,
    specifyMeterParentModelNumber,
    specifyMeterPipeMaterial,
    specifyMeterPipeSize,
    specifyMeterProbeLength,
    specifyMeterProcessConnection,
    specifyMeterProcessFluid,
    specifyMeterProcessTemperature,
    specifyMeterTransducer,
    specifyMeterTransducerBracket,
  } = state;

  const {
    InlineModel,
    InlineNonReduceModel,
    InlineReduceModel,
    InsertionModel,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    ElectromagneticProMModel,
    VorconeModel,
    VorconeReduceModel,
    VORTEK_NAME,
  } = sizingGlobals;

  let modelNo = '';
  let spc;

  const selectedMeter = meterData[meterIndex];

  if (VORTEK_NAME === utilities.ONICON_NAME) {
    if (
      specifyMeterParentModelNumber === InlineModel ||
      specifyMeterParentModelNumber === InlineReduceModel ||
      specifyMeterParentModelNumber === InlineNonReduceModel ||
      specifyMeterParentModelNumber === VorconeModel ||
      specifyMeterParentModelNumber === VorconeReduceModel
    ) {
      modelNo += specifyMeterParentModelNumber;
      modelNo += `${specifyMeterPipeSize}-`;
      modelNo += specifyMeterFlange;
      modelNo += specifyMeterElectronicsMounting;
      modelNo += `${specifyMeterProcessFluid}-`;
      modelNo += specifyMeterInputPower;
      modelNo += specifyMeterOutputSignal;
      modelNo += specifyMeterProcessTemperature;
      modelNo += specifyMeterEnergyMeter;
      if (meterType === 'reduce_vortex') {
        if (specifyMeterPipeSize === '34') {
          spc = 'R05';
        } else if (specifyMeterPipeSize === '01') {
          spc = 'R05';
        } else if (specifyMeterPipeSize === '15') {
          spc = 'R01';
        } else if (specifyMeterPipeSize === '02') {
          spc = 'R15';
        } else if (specifyMeterPipeSize === '03') {
          spc = 'R02';
        } else if (specifyMeterPipeSize === '04') {
          spc = 'R03';
        } else if (specifyMeterPipeSize === '06') {
          spc = 'R04';
        } else if (specifyMeterPipeSize === '08') {
          spc = 'R06';
        } else if (specifyMeterPipeSize === '10') {
          spc = 'R08';
        } else if (specifyMeterPipeSize === '12') {
          spc = 'R10';
        }
        store.dispatch(actions.setOniconSpc(spc as string));
        modelNo += `-${spc}`;
      }
    } else if (specifyMeterParentModelNumber === InsertionModel || specifyMeterParentModelNumber === TurbineModelShort) {
      modelNo += `${specifyMeterParentModelNumber}-`;
      modelNo += specifyMeterProcessConnection;
      modelNo += specifyMeterElectronicsMounting;
      modelNo += specifyMeterProcessFluid;

      if (meterType === 'turbine') {
        modelNo += `${calcTurbineMeter.getOniconTurbineRotorModelCode(selectedMeter.newPipeData.pipeSize)}-`;
      } else {
        modelNo += '-';
      }

      modelNo += specifyMeterInputPower;
      modelNo += specifyMeterOutputSignal;
      modelNo += specifyMeterProcessTemperature;
      modelNo += specifyMeterEnergyMeter;
    }
  } else if (VORTEK_NAME === utilities.AZBIL_NAME) {
    if (
      specifyMeterParentModelNumber === InlineModel ||
      specifyMeterParentModelNumber === InlineReduceModel ||
      specifyMeterParentModelNumber === InlineNonReduceModel ||
      specifyMeterParentModelNumber === VorconeModel ||
      specifyMeterParentModelNumber === VorconeReduceModel
    ) {
      modelNo += specifyMeterParentModelNumber;
      modelNo += `${specifyMeterProcessFluid}-`;
      modelNo += specifyMeterPipeSize;
      modelNo += specifyMeterPipeMaterial;

      modelNo += `${specifyMeterFlange}-`;

      modelNo += specifyMeterElectronicsMounting;
      modelNo += specifyMeterDisplayOption;
      modelNo += specifyMeterInputPower;
      modelNo += specifyMeterOutputSignal;
      modelNo += specifyMeterProcessTemperature;
      modelNo += `${specifyMeterTransducer}-`;
    } else if (specifyMeterParentModelNumber === InsertionModel) {
      modelNo += specifyMeterParentModelNumber;
      modelNo += `${specifyMeterProcessFluid}-`;

      modelNo += specifyMeterProbeLength;
      modelNo += specifyMeterElectronicsMounting;
      modelNo += specifyMeterDisplayOption;
      modelNo += specifyMeterInputPower;
      modelNo += specifyMeterOutputSignal;
      modelNo += specifyMeterProcessTemperature;
      modelNo += `${specifyMeterTransducer}-`;
      modelNo += `${specifyMeterProcessConnection}-`;
    }

    if (accessories.length) {
      accessories.forEach((accessory) => {
        modelNo += accessory.code;
      });
    } else {
      modelNo += 'XX';
    }
  } else if (VORTEK_NAME === utilities.HEINRICH_NAME) {
    if (
      specifyMeterParentModelNumber === InlineModel ||
      specifyMeterParentModelNumber === InlineReduceModel ||
      specifyMeterParentModelNumber === InlineNonReduceModel ||
      specifyMeterParentModelNumber === VorconeModel ||
      specifyMeterParentModelNumber === VorconeReduceModel
    ) {
      modelNo += `${specifyMeterParentModelNumber}-`;
      modelNo += specifyMeterProcessFluid;
      modelNo += specifyMeterPipeSize;
      modelNo += specifyMeterFlange;
      modelNo += specifyMeterPipeMaterial;

      modelNo += specifyMeterElectronicsMounting.substring(0, 1);

      modelNo += specifyMeterInputPower;
      modelNo += specifyMeterOutputSignal;
      modelNo += specifyMeterProcessTemperature;
      modelNo += specifyMeterTransducer;
    } else if (specifyMeterParentModelNumber === InsertionModel) {
      modelNo += `${specifyMeterParentModelNumber}-`;
      modelNo += specifyMeterProcessFluid;
      modelNo += specifyMeterProbeLength;
      modelNo += specifyMeterElectronicsMounting.substring(0, 1);
      modelNo += specifyMeterInputPower;
      modelNo += specifyMeterOutputSignal;
      modelNo += specifyMeterProcessTemperature;
      modelNo += specifyMeterTransducer;
      modelNo += specifyMeterProcessConnection;
    }
  } else if (VORTEK_NAME === utilities.ARMSTRONG_NAME) {
    if (
      specifyMeterParentModelNumber === InlineModel ||
      specifyMeterParentModelNumber === InlineReduceModel ||
      specifyMeterParentModelNumber === InlineNonReduceModel ||
      specifyMeterParentModelNumber === VorconeModel ||
      specifyMeterParentModelNumber === VorconeReduceModel
    ) {
      modelNo += `${specifyMeterParentModelNumber}-`;
      modelNo += `${specifyMeterFlange}-`;
      modelNo += `${specifyMeterPipeSize}-`;
      modelNo += `${specifyMeterPipeMaterial}-`;
      modelNo += `${specifyMeterElectronicsMounting}-`;
      modelNo += `${specifyMeterProcessFluid}-`;
      modelNo += `${specifyMeterInputPower}-`;
      modelNo += `${specifyMeterOutputSignal}-`;
      modelNo += `${specifyMeterProcessTemperature}-`;
      modelNo += specifyMeterTransducer;
    } else if (specifyMeterParentModelNumber === InsertionModel) {
      modelNo += `${specifyMeterParentModelNumber}-`;
      modelNo += `${specifyMeterProcessConnection}-`;
      modelNo += `${specifyMeterProbeLength}-`;
      modelNo += `${specifyMeterElectronicsMounting}-`;
      modelNo += `${specifyMeterProcessFluid}-`;
      modelNo += `${specifyMeterInputPower}-`;
      modelNo += `${specifyMeterOutputSignal}-`;
      modelNo += `${specifyMeterProcessTemperature}-`;
      modelNo += specifyMeterTransducer;
    }
  } else if (
    specifyMeterParentModelNumber === InlineModel ||
    specifyMeterParentModelNumber === InlineReduceModel ||
    specifyMeterParentModelNumber === InlineNonReduceModel ||
    specifyMeterParentModelNumber === VorconeModel ||
    specifyMeterParentModelNumber === VorconeReduceModel
  ) {
    modelNo += `${specifyMeterParentModelNumber}-`;
    modelNo += `${specifyMeterProcessFluid}-`;
    if (VORTEK_NAME === utilities.INNOVA_NAME) {
      if (reducingVortex) {
        modelNo += 'R-';
      }
      modelNo += specifyMeterFlange;
      modelNo += specifyMeterPipeMaterial;
      modelNo += `${specifyMeterPipeSize}-`;
    } else {
      modelNo += specifyMeterPipeSize;
      modelNo += `${specifyMeterPipeMaterial}-`;
      modelNo += `${specifyMeterFlange}-`;
    }

    modelNo += `${specifyMeterElectronicsMounting}-`;
    modelNo += `${specifyMeterDisplayOption}-`;
    modelNo += `${specifyMeterInputPower}-`;
    modelNo += `${specifyMeterOutputSignal}-`;
    modelNo += `${specifyMeterProcessTemperature}-`;
    modelNo += specifyMeterTransducer;

    if (specifyMeterParentModelNumber === VorconeModel || specifyMeterParentModelNumber === VorconeReduceModel) {
      modelNo += `-${specifyMeterDiffPressXMTR}`;
      modelNo += `-${specifyMeterDiffPressXMTRMani}`;
      modelNo += `-${specifyMeterDiagDiffPressXMTR}`;
      modelNo += `-${specifyMeterDiagAddPressTap}`;
      if (selectedMeter.beta) {
        modelNo += `-${selectedMeter.beta.substring(2)}`;
      }
    }

    if (
      specifyMeterParentModelNumber !== VorconeModel &&
      specifyMeterParentModelNumber !== VorconeReduceModel &&
      VORTEK_NAME === utilities.SPIRAX_NAME
    ) {
      modelNo += `-${specifyMeterApprovals}`;
      modelNo += `-${specifyMeterFaceToFace}`;
    }
  } else if (specifyMeterParentModelNumber === InsertionModel || specifyMeterParentModelNumber === TurbineModelShort) {
    modelNo += `${specifyMeterParentModelNumber}-`;
    modelNo += `${specifyMeterProcessFluid}-`;
    modelNo += `${specifyMeterProbeLength}-`;
    modelNo += `${specifyMeterElectronicsMounting}-`;
    modelNo += `${specifyMeterDisplayOption}-`;
    modelNo += `${specifyMeterInputPower}-`;
    modelNo += `${specifyMeterOutputSignal}-`;
    modelNo += `${specifyMeterProcessTemperature}-`;
    modelNo += `${specifyMeterTransducer}-`;
    modelNo += specifyMeterProcessConnection;

    if (
      specifyMeterParentModelNumber !== VorconeModel &&
      specifyMeterParentModelNumber !== VorconeReduceModel &&
      VORTEK_NAME === utilities.SPIRAX_NAME
    ) {
      modelNo += `-${specifyMeterApprovals}`;
    }

    if (meterType === 'turbine') {
      modelNo += `-${selectedMeter.name}`;
    }
  } else if (specifyMeterParentModelNumber === UltrasonicS34Model) {
    modelNo += `${specifyMeterParentModelNumber}-`;
    modelNo += `${specifyMeterProcessFluid}-`;
    modelNo += `${specifyMeterTransducer}-`;
    modelNo += `${specifyMeterCableLength}-`;
    modelNo += specifyMeterProcessTemperature;

    if (accessories.length) {
      modelNo += '-';
      accessories.forEach((accessory) => {
        modelNo += accessory.code;
      });
    }
  } else if (specifyMeterParentModelNumber === UltrasonicS36Model) {
    modelNo += `${specifyMeterParentModelNumber}-`;
    modelNo += `${specifyMeterProcessFluid}-`;
    modelNo += `${specifyMeterTransducer}-`;
    modelNo += `${specifyMeterCableLength}-`;
    modelNo += specifyMeterCommunications;

    if (accessories.length) {
      modelNo += '-';
      accessories.forEach((accessory) => {
        modelNo += accessory.code;
      });
    }
  } else if (specifyMeterParentModelNumber === UltrasonicU42Model || specifyMeterParentModelNumber === UltrasonicU43Model) {
    modelNo += `${specifyMeterParentModelNumber}-`;
    modelNo += `${specifyMeterOutputSignal}-`;
    modelNo += `${specifyMeterTransducer}-`;
    modelNo += specifyMeterCableLength;

    if (accessories.length) {
      modelNo += '-';
      accessories.forEach((accessory) => {
        modelNo += accessory.code;
      });
    }
  } else if (specifyMeterParentModelNumber === UltrasonicU44Model) {
    modelNo += `${specifyMeterParentModelNumber}-`;
    modelNo += `${specifyMeterOutputSignal}-`;
    modelNo += `${specifyMeterTransducer}-`;
    modelNo += `${specifyMeterTransducerBracket}-`;
    modelNo += specifyMeterCableLength;

    if (accessories.length) {
      modelNo += '-';
      accessories.forEach((accessory) => {
        modelNo += accessory.code;
      });
    }
  } else if (specifyMeterParentModelNumber === ElectromagneticProMModel) {
    modelNo += `${specifyMeterParentModelNumber}-`;
    modelNo += `${specifyMeterProcessFluid}-`;
    modelNo += `${specifyMeterAccuracy}-`;
    modelNo += `${specifyMeterPipeSize}-`;
    modelNo += `${specifyMeterPipeMaterial}-`;
    modelNo += `${specifyMeterProcessConnection}-`;
    modelNo += `${specifyMeterElectronicsMounting}-`;
    modelNo += `${specifyMeterInputPower}-`;
    modelNo += `${specifyMeterCommunications}-`;
    modelNo += `${specifyMeterElectrodeMaterial}-`;
    modelNo += `${specifyMeterLinerMaterial}-`;
    modelNo += `${specifyMeterGrounding}`;

    if (accessories.length) {
      modelNo += '-';
      accessories.forEach((accessory) => {
        modelNo += accessory.code;
      });
    }
  }

  store.dispatch(actions.setModelCode(modelNo));
  return modelNo;
};

export default {
  createModelCode,
  getFeature,
  getFeatureArrayByFeatureKey,
  getFeatureArrayByFeatureNumber,
  getFeatureByMultiKey,
  getFeatureByMultiKeyUsingList,
  getFeatureList,
  resetSpecifyMeterFields,
};
