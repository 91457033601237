import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import constants from '../../constants';
import { RootState } from '../../store';
import actions from '../../store/actions';
import { CallbackNoParams } from '../../types/callbackTypes';
import Customer from '../../types/customer';
import CustomerListItem from '../../types/customerListItem';
import DbRow from '../../types/dbRow';
import utilities from '../../utilities';
import Modal from '../common/Modal';
import UploadCustomer from './uploadCustomer';

const retrieveAllCustomers = (vortekName: string, uname: string): CustomerListItem[] => {
  let newCustomers = [] as CustomerListItem[];
  const res = api.findAllDataForUserInReduxTable(vortekName, uname, 'customer');

  if (res?.length) {
    const responseData = res as unknown as DbRow[];

    for (let i = 0; i < responseData.length; i++) {
      if (responseData[i].data) {
        const customerListItem = new CustomerListItem();
        customerListItem.customer = responseData[i].data as unknown as Customer;
        const existingCustomer = newCustomers.find((c) => c.customer.company_name === customerListItem.customer.company_name);
        if (existingCustomer) {
          newCustomers = newCustomers.filter((c) => c.customer.company_name !== customerListItem.customer.company_name);
        }

        newCustomers.push(customerListItem);
      }
    }
  }

  return newCustomers;
};

const CustomerComponent = (props: { onClose?: CallbackNoParams }): JSX.Element | null => {
  const dispatch = useDispatch();
  const { onClose } = props;
  const state = useSelector((rootState: RootState) => rootState);
  const { customer, sizingGlobals, username } = state;
  const { VORTEK_NAME } = sizingGlobals;
  const [customerLocal, setCustomerLocal] = useState(customer);

  const [allCustomers, setAllCustomers] = useState(retrieveAllCustomers(VORTEK_NAME, username));
  const [showRequired, setShowRequired] = useState(false);
  const [resultPrompt, setResultPrompt] = useState('');
  const [showAlreadyExists, setShowAlreadyExists] = useState(false);
  const [showCustomerAdded, setShowCustomerAdded] = useState(false);
  const [showCustomerReplaced, setShowCustomerReplaced] = useState(false);
  const [showAskDelete, setShowAskDelete] = useState(false);
  const [showCustomerDeleted, setShowCustomerDeleted] = useState(false);
  const [showCustomerUpload, setShowCustomerUpload] = useState(false);
  const [showCustomerUploaded, setShowCustomerUploaded] = useState(false);

  useEffect(() => {
    let cust;
    if (allCustomers.length && customerLocal.customer.company_name) {
      cust = allCustomers.find((c) => c.customer.company_name === customerLocal.customer.company_name);
    }

    if (cust) {
      setCustomerLocal(cust);
      dispatch(actions.setCustomer(cust));
    } else {
      const tempCust = new CustomerListItem();
      setCustomerLocal(tempCust);
      dispatch(actions.setCustomer(tempCust));
    }
  }, [allCustomers, username, VORTEK_NAME]);

  const deleteCustomer = async (): Promise<boolean> => {
    const id = `${VORTEK_NAME}${username}customer${customerLocal.customer.company_name}`;

    const res = await api.deleteItem({
      requestData: {
        client: VORTEK_NAME,
        username,
        collection: 'customer',
        key: customerLocal.customer.company_name,
        _id: id,
      },
    });

    if (res.status === 'fail' && res.error === 'Unauthorized') {
      if (onClose) {
        onClose();
      }

      utilities.handleAjaxFailure(res);
      return false;
    }

    if (res.status === 'fail') {
      setResultPrompt(`Failed to delete customer. Remote error ${res.error}`);
      return false;
    }

    setShowCustomerDeleted(true);
    setAllCustomers(retrieveAllCustomers(VORTEK_NAME, username));
    return true;
  };

  const tryToSaveCustomer = async (displayMessage: boolean): Promise<boolean> => {
    if (
      !customerLocal.customer.company_name ||
      !customerLocal.customer.industry_code ||
      !customerLocal.customer.type_of_contact ||
      !customerLocal.customer.first_name ||
      !customerLocal.customer.last_name ||
      !customerLocal.customer.email ||
      !customerLocal.customer.address1 ||
      !customerLocal.customer.city ||
      !customerLocal.customer.state ||
      !customerLocal.customer.zipcode ||
      !customerLocal.customer.country ||
      !customerLocal.customer.phone_customer
    ) {
      setShowRequired(true);
      return false;
    }

    const id = `${VORTEK_NAME}${username}customer${customerLocal.customer.company_name}`;

    if (!customerLocal.customer.version) {
      customerLocal.customer.version = constants.REVISION;
    }

    if (!customerLocal.customer.creationDate) {
      customerLocal.customer.creationDate = new Date().toUTCString();
    }

    const res = await api.writeItem({
      requestData: {
        client: VORTEK_NAME,
        username,
        key: customerLocal.customer.company_name,
        _id: id,
        overwrite: false,
        collection: 'customer',
        data: {
          client: VORTEK_NAME,
          username,
          key: customerLocal.customer.company_name,
          _id: id,
          data: JSON.parse(JSON.stringify(customerLocal.customer)),
        },
      },
    });

    if (res.status === 'fail' && res.error === 'Unauthorized') {
      if (onClose) {
        onClose();
      }

      utilities.handleAjaxFailure(res);
      return false;
    }

    if (res.status === 'fail') {
      setResultPrompt(`Failed to save customer. Remote error ${res.error}`);
      return false;
    }

    if (displayMessage) {
      if (res.reason === 'already exists') {
        setShowAlreadyExists(true);
        return false;
      }

      setShowCustomerAdded(true);
    }

    return true;
  };

  const forceSaveCustomer = async () => {
    const id = `${VORTEK_NAME}${username}customer${customerLocal.customer.company_name}`;

    const res = await api.writeItem({
      requestData: {
        client: VORTEK_NAME,
        username,
        key: customerLocal.customer.company_name,
        _id: id,
        overwrite: true,
        collection: 'customer',
        data: {
          client: VORTEK_NAME,
          username,
          key: customerLocal.customer.company_name,
          _id: id,
          data: JSON.parse(JSON.stringify(customerLocal.customer)),
        },
      },
    });

    if (res.status === 'fail' && res.error === 'Unauthorized') {
      if (onClose) {
        onClose();
      }

      utilities.handleAjaxFailure(res);
    } else if (res.status === 'fail') {
      setResultPrompt(`Failed to save customer. Remote error ${res.error}`);
    } else {
      setShowCustomerReplaced(true);
    }
  };

  const onCloseUpload = (e: Event, filesUploaded: string | undefined) => {
    setShowCustomerUpload(false);
    if (filesUploaded === 'true') {
      setShowCustomerUploaded(true);
    }

    setAllCustomers(retrieveAllCustomers(VORTEK_NAME, username));
  };

  return (
    <>
      <table style={{ borderWidth: 0 }}>
        <tbody>
          <tr>
            <td className="acenter">
              <label className="customerLabel">Customers</label>
            </td>
            <td className="aright">
              <label className="customerLabel">*Company Name</label>
            </td>
            <td>
              <input
                disabled={customer.customer.company_name === ''}
                name="customer_company_name"
                type="text"
                maxLength={50}
                className="company-name"
                value={customerLocal.customer.company_name}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.company_name = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
            <td className="aright">
              <label className="customerLabel">*Industry Code</label>
            </td>
            <td>
              <select
                name="customer_industry_code"
                disabled={customer.customer.company_name === ''}
                className="ui-dform-select industry_code"
                value={customerLocal.customer.industry_code}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.industry_code = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              >
                <option key="manufacturing" className="ui-dform-option" value="manufacturing">
                  Manufacturing
                </option>
                <option key="environmental" className="ui-dform-option" value="environmental">
                  Environmental
                </option>
                <option key="chemical" className="ui-dform-option" value="chemical">
                  Chemical
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td rowSpan={9}>
              <select
                name="customer_list"
                className="customerSelect"
                size={20}
                value={customerLocal.customer.company_name || undefined}
                onChange={(e) => {
                  if (e.target.value) {
                    const newCustomer = allCustomers.find((c) => c.customer.company_name === e.target.value);
                    if (newCustomer) {
                      setCustomerLocal(newCustomer);
                      dispatch(actions.setCustomer(newCustomer));
                    }
                  } else {
                    const newCustomer = new CustomerListItem();
                    setCustomerLocal(newCustomer);
                    dispatch(actions.setCustomer(newCustomer));
                  }
                }}
              >
                <option value="" />
                {allCustomers.map((c) => (
                  <option key={c.key} value={c.customer.company_name}>
                    {c.customer.company_name}
                  </option>
                ))}
              </select>
            </td>
            <td className="aright">
              <label className="customerLabel">*Type of Contact</label>
            </td>
            <td>
              <select
                name="customer_type_of_contact"
                disabled={customer.customer.company_name === ''}
                className="ui-dform-select type_of_contact"
                value={customerLocal.customer.type_of_contact}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.type_of_contact = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              >
                <option key="billing" className="ui-dform-option" value="billing">
                  Billing
                </option>
                <option key="shipping" className="ui-dform-option" value="shipping">
                  Shipping
                </option>
                <option key="purchasing" className="ui-dform-option" value="purchasing">
                  Purchasing
                </option>
                <option key="user" className="ui-dform-option" value="user">
                  User
                </option>
              </select>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td className="aright">
              <label className="customerLabel">*Name</label>
            </td>
            <td className="nowrap">
              <input
                name="customer_first_name"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={50}
                className="ui-dform-text first-name"
                value={customerLocal.customer.first_name}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.first_name = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
              <input
                name="customer_last_name"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={50}
                className="ui-dform-text last-name"
                value={customerLocal.customer.last_name}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.last_name = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
            <td className="aright">
              <label className="customerLabel">Title</label>
            </td>
            <td>
              <input
                name="customer_title"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={50}
                className="ui-dform-text title"
                value={customerLocal.customer.title_customer}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.title_customer = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="aright">
              <label className="customerLabel">*Email</label>
            </td>
            <td>
              <input
                name="customer_email"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={100}
                className="ui-dform-text customer_email"
                value={customerLocal.customer.email}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.email = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
            <td className="aright">
              <label className="customerLabel">*Phone</label>
            </td>
            <td>
              <input
                name="customer_phone"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={50}
                className="ui-dform-text phone"
                value={customerLocal.customer.phone_customer}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.phone_customer = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="aright">
              <label className="customerLabel">*Address</label>
            </td>
            <td>
              <input
                name="customer_address1"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={100}
                className="ui-dform-text address1"
                value={customerLocal.customer.address1}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.address1 = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
            <td className="aright">
              <label className="customerLabel">Ext</label>
            </td>
            <td>
              <input
                name="customer_extension"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={10}
                className="ui-dform-text extension"
                value={customerLocal.customer.extension_customer}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.extension_customer = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="aright">
              <label className="customerLabel">Address Line 2</label>
            </td>
            <td>
              <input
                name="customer_address2"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={100}
                className="ui-dform-text address2"
                value={customerLocal.customer.address2}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.address2 = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
            <td className="aright">
              <label className="customerLabel">Fax</label>
            </td>
            <td>
              <input
                name="customer_fax"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={50}
                className="ui-dform-text fax"
                value={customerLocal.customer.fax_customer}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.fax_customer = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="aright">
              <label className="customerLabel">*City</label>
            </td>
            <td>
              <input
                name="customer_city"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={100}
                className="ui-dform-text city"
                value={customerLocal.customer.city}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.city = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
            <td className="aright">
              <input
                name="customer_donotprint"
                disabled={customer.customer.company_name === ''}
                type="checkbox"
                className="ui-dform-checkbox"
                checked={customerLocal.customer.donotprint === 'donotprint'}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.donotprint = e.target.checked ? 'donotprint' : 'print';
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
            <td>
              <span className="ui-dform-span">Do Not Print Address</span>
            </td>
          </tr>
          <tr>
            <td className="aright">
              <label className="customerLabel">*State</label>
            </td>
            <td>
              <input
                name="customer_state"
                disabled={customer.customer.company_name === ''}
                type="text"
                className="ui-dform-text state"
                value={customerLocal.customer.state}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.state = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td className="aright">
              <label className="customerLabel">*Postal/Zip Code</label>
            </td>
            <td>
              <input
                name="customer_zip"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={50}
                className="ui-dform-text zipcode"
                value={customerLocal.customer.zipcode}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.zipcode = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td className="aright">
              <label className="customerLabel">*Country</label>
            </td>
            <td>
              <input
                name="customer_country"
                disabled={customer.customer.company_name === ''}
                type="text"
                maxLength={100}
                className="ui-dform-text country"
                value={customerLocal.customer.country}
                onChange={(e) => {
                  const newCustomer = { ...customerLocal };
                  newCustomer.customer.country = e.target.value;
                  setCustomerLocal(newCustomer);
                }}
              />
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <div className="flexRow topBottom20">
        <button
          type="button"
          title="Creates a new customer"
          onClick={() => {
            const cust = allCustomers.find((c) => c.customer.company_name === 'new');
            if (!cust) {
              const newCustomer = new CustomerListItem();
              newCustomer.customer.company_name = 'new';
              newCustomer.key = 'new';
              const allNewCustomers = [...allCustomers];
              allNewCustomers.push(newCustomer);
              setAllCustomers(allNewCustomers);
              setCustomerLocal(newCustomer);
            }
          }}
        >
          New
        </button>
        <button
          type="button"
          title="Removes the currently selected customer"
          className={customerLocal.customer.company_name?.length ? 'aBtu' : 'aBtuDisable'}
          disabled={!customerLocal.customer.company_name?.length}
          onClick={() => {
            setShowAskDelete(true);
          }}
        >
          Delete
        </button>
        <button
          type="button"
          title="Saves the currently selected customer"
          className={customerLocal.customer.company_name?.length ? 'aBtu' : 'aBtuDisable'}
          disabled={!customerLocal.customer.company_name?.length}
          onClick={async () => {
            const result = await tryToSaveCustomer(true);
            if (result) {
              dispatch(actions.setCustomer(customerLocal));
            }
          }}
        >
          Save
        </button>
        <button
          type="button"
          title="Downloads completed customers"
          className={customerLocal.customer.company_name?.length ? 'aBtu' : 'aBtuDisable'}
          disabled={!customerLocal.customer.company_name?.length}
          onClick={async () => {
            const dlCustomerItems = allCustomers.filter((c) => {
              if (
                c.customer.company_name &&
                c.customer.industry_code &&
                c.customer.type_of_contact &&
                c.customer.first_name &&
                c.customer.last_name &&
                c.customer.email &&
                c.customer.address1 &&
                c.customer.city &&
                c.customer.state &&
                c.customer.zipcode &&
                c.customer.country &&
                c.customer.phone_customer
              ) {
                return true;
              }

              return false;
            });
            const dlCustomers = dlCustomerItems.map((c) => c.customer);
            api.downloadFile({
              filename: 'customers.json',
              content: JSON.stringify(dlCustomers),
            });
          }}
        >
          Download
        </button>
        <button
          type="button"
          title="Add customers from a JSON file"
          onClick={() => {
            setShowCustomerUpload(true);
          }}
        >
          Upload
        </button>
      </div>
      <div className="ui-dform-div error_msg" />
      {showRequired && (
        <Modal
          title="Incomplete Customer Information"
          visible
          showButtons={false}
          showCloseButton
          onClose={() => setShowRequired(false)}
        >
          <div className="flexCol modalContent">
            <p>Please fill in all required fields</p>
          </div>
        </Modal>
      )}
      {showAlreadyExists && (
        <Modal title="Replace Customer Information" visible showButtons={false} showCloseButton={false}>
          <div className="flexCol modalContent">
            <p>Customer is already in the database.</p>
            <p>Do you want to replace the customer information?</p>

            <div className="flexRow">
              <button
                type="button"
                onClick={async () => {
                  await forceSaveCustomer();
                  setShowAlreadyExists(false);
                }}
              >
                Yes
              </button>
              <button type="button" onClick={() => setShowAlreadyExists(false)}>
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showCustomerAdded && (
        <Modal title="Success" visible showButtons={false} showCloseButton onClose={() => setShowCustomerAdded(false)}>
          <div className="flexCol modalContent">
            <p>Customer has been successfully added.</p>
          </div>
        </Modal>
      )}
      {showCustomerReplaced && (
        <Modal title="Replaced" visible showButtons={false} showCloseButton onClose={() => setShowCustomerReplaced(false)}>
          <div className="flexCol modalContent">
            <p>Customer has been successfully replaced.</p>
          </div>
        </Modal>
      )}
      {showAskDelete && (
        <Modal title="Delete Customer Information" visible showButtons={false} showCloseButton={false}>
          <div className="flexCol modalContent">
            <p>This will delete the currently selected customer.</p>
            <p>Proceeed?</p>

            <div className="flexRow">
              <button
                type="button"
                onClick={async () => {
                  setShowAskDelete(false);
                  deleteCustomer();
                }}
              >
                Yes
              </button>
              <button type="button" onClick={() => setShowAskDelete(false)}>
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showCustomerDeleted && (
        <Modal
          title="Customer Information Deleted"
          visible
          showButtons={false}
          showCloseButton
          onClose={() => setShowCustomerDeleted(false)}
        >
          <div className="flexCol modalContent">
            <p>Customer has been successfully deleted.</p>
          </div>
        </Modal>
      )}
      {showCustomerUpload && <UploadCustomer onClose={onCloseUpload} />}
      {showCustomerUploaded && (
        <Modal title="Customer(s) Saved" visible showButtons={false} showCloseButton onClose={() => setShowCustomerUploaded(false)}>
          <div className="flexCol modalContent">
            <p>Customer(s) Uploaded.</p>
          </div>
        </Modal>
      )}

      {resultPrompt.length > 0 && (
        <Modal
          visible
          showButtons
          cancelText=""
          okText="Ok"
          title=""
          onClose={() => {
            setResultPrompt('');
          }}
        >
          <div className="modalContent">{resultPrompt}</div>
        </Modal>
      )}
    </>
  );
};

CustomerComponent.defaultProps = {
  onClose: undefined,
};

export default CustomerComponent;
