import Decimal from 'decimal.js';
import PipeData from './types/PipeData';
import packageInfo from '../package.json';

const ZERO = new Decimal('0.0');

const defaultPipeData: PipeData = {
  area: '',
  areaUnit: '',
  beta: '',
  calcDensityMinVal: ZERO,
  calcDensityNomVal: ZERO,
  calcDensityMaxVal: ZERO,
  calcPressureMinVal: ZERO,
  calcPressureNomVal: ZERO,
  calcPressureMaxVal: ZERO,
  calcTemperatureMinVal: ZERO,
  calcTemperatureNomVal: ZERO,
  calcTemperatureMaxVal: ZERO,
  calcVelocityMinVal: ZERO,
  calcVelocityNomVal: ZERO,
  calcVelocityMaxVal: ZERO,
  calcViscosityMinVal: ZERO,
  calcViscosityNomVal: ZERO,
  calcViscosityMaxVal: ZERO,
  insideArea: ZERO,
  insideUnit: '',
  isentropic: ZERO,
  reynoldsMin: '',
  reynoldsNom: '',
  reynoldsMax: '',
  fluidType: '',
  fluid: '',
  pipeType: '',
  pipeSize: '',
  pipeReduceSize: '',
  pipeSchedule: '',
  pipeInsideDiameter: '',
  pipeReduceInsideDiameter: '',
  pipeUnit: '',
  flowMin: '',
  flowNom: '',
  flowMax: '',
  flowUnit: '',
  flowTime: '',
  temperatureMin: '',
  temperatureNom: '',
  temperatureMax: '',
  temperatureUnit: '',
  pressureMin: '',
  pressureNom: '',
  pressureMax: '',
  pressurePrefix: '',
  originalPressurePrefix: '',
  pressureCoefficient: '',
  originalPressureCoefficient: '',
  densityMin: '',
  densityMinDisplay: '',
  densityMinInternalUnit: '',
  densityNom: '',
  densityNomDisplay: '',
  densityNomInternalUnit: '',
  densityMax: '',
  densityMaxDisplay: '',
  densityMaxInternalUnit: '',
  densityUnit: '',
  viscosityMin: '',
  viscosityMinDisplay: '',
  viscosityNom: '',
  viscosityNomDisplay: '',
  viscosityMax: '',
  viscosityMaxDisplay: '',
  viscosityUnit: '',
  velocity: ZERO,
  velocityMin: '',
  velocityMinDisplay: '',
  velocityNom: '',
  velocityNomDisplay: '',
  velocityMax: '',
  velocityMaxDisplay: '',
  velocityUnit: '',
  warningsMin: [],
  warningsNom: [],
  warningsMax: [],
};

const constants = {
  REVISION: packageInfo.version,

  // Fluid types.
  NAME_REAL_GAS: 'Gas',
  NAME_STEAM: 'Steam',
  NAME_IDEAL_GAS: 'Other Gas',
  NAME_LIQUID: 'Liquid',
  NAME_WATER: 'Water',
  NAME_OTHER_LIQUID: 'Other Liquid',

  // Sat steam is the ugly exception, so these are global.
  NAME_SAT_PRESS: 'Saturated Pressure',
  NAME_SAT_TEMP: 'Saturated Temperature',
  NAME_STEAM_PT: 'Superheated',
  NAME_LIQUIFIED_GAS: 'Liquified Gas',

  PI: new Decimal('3.141592654'),
  INSERTION_ID: new Decimal('1.095'),
  MAX_LIQUID_VEL: new Decimal('30.0'),
  MAX_GAS_VEL: new Decimal('300.0'),
  MAX_1_5_GAS_VEL: new Decimal('300.0'),
  MAX_1_GAS_VEL: new Decimal('250.0'),
  MAX_3_4_GAS_VEL: new Decimal('250.0'),
  MAX_1_2_GAS_VEL: new Decimal('175.0'),
  LARGEST_INLINE: new Decimal('1.75'),
  SMALLEST_INLINE: new Decimal('0.5'),
  LARGEST_INSERTION: new Decimal('1.25'),
  SMALLEST_INSERTION: new Decimal('0.5'),
  LRG_TURBINE_INSERTION: new Decimal('1.01'),
  SML_TURBINE_INSERTION: new Decimal('.99'),
  METRIC_TWO_INCH: new Decimal('51.0'),
  METRIC_THREE_INCH: new Decimal('76.0'),
  MAXIMUM_TEMPERATURE: new Decimal('750.0'),

  PRO_M_MIN_VEL: new Decimal('0.98'),
  PRO_M_MAX_VEL: new Decimal('32.8'),

  PRO_M_MIN_GPM_0_5: new Decimal('0.88'),
  PRO_M_MAX_GPM_0_5: new Decimal('26.0'),
  PRO_M_MIN_GPM_0_75: new Decimal('1.3'),
  PRO_M_MAX_GPM_0_75: new Decimal('48.0'),
  PRO_M_MIN_GPM_1_0: new Decimal('2.2'),
  PRO_M_MAX_GPM_1_0: new Decimal('79.0'),
  PRO_M_MIN_GPM_1_5: new Decimal('4.4'),
  PRO_M_MAX_GPM_1_5: new Decimal('198.0'),
  PRO_M_MIN_GPM_2_0: new Decimal('8.8'),
  PRO_M_MAX_GPM_2_0: new Decimal('312.0'),
  PRO_M_MIN_GPM_3_0: new Decimal('22.0'),
  PRO_M_MAX_GPM_3_0: new Decimal('796.0'),
  PRO_M_MIN_GPM_4_0: new Decimal('36.0'),
  PRO_M_MAX_GPM_4_0: new Decimal('1246.0'),
  PRO_M_MIN_GPM_6_0: new Decimal('88.0'),
  PRO_M_MAX_GPM_6_0: new Decimal('2800.0'),
  PRO_M_MIN_GPM_8_0: new Decimal('150.0'),
  PRO_M_MAX_GPM_8_0: new Decimal('4979.0'),
  PRO_M_MIN_GPM_10_0: new Decimal('234.0'),
  PRO_M_MAX_GPM_10_0: new Decimal('7779.0'),
  PRO_M_MIN_GPM_12_0: new Decimal('335.0'),
  PRO_M_MAX_GPM_12_0: new Decimal('11205.0'),
  PRO_M_MIN_GPM_14_0: new Decimal('458.0'),
  PRO_M_MAX_GPM_14_0: new Decimal('15255.0'),
  PRO_M_MIN_GPM_16_0: new Decimal('599.0'),
  PRO_M_MAX_GPM_16_0: new Decimal('19918.0'),
  PRO_M_MIN_GPM_18_0: new Decimal('753.0'),
  PRO_M_MAX_GPM_18_0: new Decimal('25210.0'),
  PRO_M_MIN_GPM_20_0: new Decimal('934.0'),
  PRO_M_MAX_GPM_20_0: new Decimal('31123.0'),
  PRO_M_MIN_GPM_24_0: new Decimal('1343.0'),
  PRO_M_MAX_GPM_24_0: new Decimal('44816.0'),

  // If more ALARM meters are necessary, add them here.
  HART_ALARM: 'Three analog outputs (4-20 mA), three alarms, one pulse, HART',
  MODBUS_ALARM: 'Three analog outputs (4-20 mA), three alarms, one pulse, MODBUS RTU',
  BACNET_ALARM: 'Three analog outputs (4-20 mA), three alarms, one pulse, BACnet MS/TP',

  // If more NO ALARM meters are necessary, add them here.
  HART_ONLY: 'One analog output (4-20 mA), one pulse, HART',
  MODBUS_ONLY: 'One analog output (4-20 mA), one pulse, MODBUS RTU',
  MODBUS_IP: 'One analog output (4-20 mA), one pulse, MODBUS TCP/IP',
  BACNET: 'One analog output (4-20 mA), one pulse, BACnet MS/TP',
  BACNET_IP: 'One analog output (4-20 mA), one pulse, BACnet /IP',

  ONICON_HART_ONLY: '1 Analog Output (4-20), 1 Pulse, 1 Frequency, HART',
  ONICON_MODBUS_ONLY: '1 Analog Output (4-20), 1 Pulse, 1 Frequency, MODBUS',
  ONICON_BACNET: '1 Analog Output (4-20), 1 Alarm, 1 Pulse, 1 Frequency, BACnet',
  ONICON_ALARM_MODBUS: '3 Analog Output (4-20), 3 Alarm, 1 Pulse, 1 Frequency, MODBUS',
  ONICON_ALARM_BACNET: '3 Analog Output (4-20), 3 Alarm, 1 Pulse, 1 Frequency, BACnet',

  bApplicationChangesMade: false,
  g_SelectedMeter: null,

  defaultPipeData,

  VOLUMETRIC: 0,
  MASSFLOW: 1,
  STANDARD: 2,
  NORMAL: 3,

  ZERO,
  POINT5: new Decimal('0.5'),
  POINT75: new Decimal('0.75'),
  ONE: new Decimal('1.0'),
  TWO: new Decimal('2.0'),
  THREE: new Decimal('3.0'),
  FOUR: new Decimal('4.0'),
  FIVE: new Decimal('5.0'),
  SIX: new Decimal('6.0'),
  SEVEN: new Decimal('7.0'),
  EIGHT: new Decimal('8.0'),
  NINE: new Decimal('9.0'),
  D600: new Decimal('600'),
  D900: new Decimal('900'),
  D1440: new Decimal('1440'),

  // Turbine constants
  TOP_TURBINE_HOUSING_AREA: new Decimal('0.397'),
  TOP_TURBINE_HOUSING_HEIGHT: new Decimal('0.53'),
  PICKUP_HOUSING_AREA: new Decimal('0.6857'),
  PICKUP_HOUSING_HEIGHT: new Decimal('0.79'),
  BOTTOM_TURBINE_HOUSING_AREA: new Decimal('0.157').plus(new Decimal('0.107')), // area of center strut and the hub of the rotor housing
  STEM_WIDTH: new Decimal('0.75'),
  ROTOR_CENTER: new Decimal('1.625').div(new Decimal('2.0')),
  TAN_PICKUP: new Decimal('0.118').div(new Decimal('0.79')),
  ROUGHNESS: new Decimal('0.00001'),

  R40_MIN_VEL: new Decimal('3.5'), // Minimum and maximum velocities at atmospheric conditions.
  R40_MAX_VEL: new Decimal('43.0'),
  R30_MIN_VEL: new Decimal('4.0'),
  R30_MAX_VEL: new Decimal('62.5'),
  R25_MIN_VEL: new Decimal('5'),
  R25_MAX_VEL: new Decimal('80.0'),
  R20_MIN_VEL: new Decimal('7.0'),
  R20_MAX_VEL: new Decimal('100.0'),
  R15_MIN_VEL: new Decimal('8.5'),
  R15_MAX_VEL: new Decimal('134.6'),
  R10_MIN_VEL: new Decimal('12.0'),
  R10_MAX_VEL: new Decimal('205.0'),
  L40_MIN_VEL: new Decimal('1.0'),
  L40_MAX_VEL: new Decimal('30.0'),
};

export default constants;
