import { useSelector } from 'react-redux';
import { ChangeEvent, useEffect, useState } from 'react';
import { RootState } from '../../store';
import { CallbackNoParams } from '../../types/callbackTypes';
import Modal from '../common/Modal';
import api from '../../api';
import utilities from '../../utilities';
import constants from '../../constants';

const Liquid = (props: { onClose: CallbackNoParams }): JSX.Element | null => {
  const sizingGlobals = useSelector((state: RootState) => state.sizingGlobals);
  const username = useSelector((state: RootState) => state.username);
  const { VORTEK_NAME } = sizingGlobals;
  const { onClose } = props;
  const otherLiquids = useSelector((state: RootState) => state.db.otherliquid);
  const meterType = useSelector((state: RootState) => state.meterType);

  const [liquidName, setLiquidName] = useState('');
  const [density, setDensity] = useState('62.4');
  const [densityUnits, setDensityUnits] = useState('');
  const [viscosity, setViscosity] = useState('1');
  const [viscosityUnits, setViscosityUnits] = useState('');
  const [soundSpeed, setSoundSpeed] = useState('');
  const [soundSpeedUnits, setSoundSpeedUnits] = useState('');

  const [deletePromptVisible, setDeletePromptVisible] = useState(false);
  const [resultPrompt, setResultPrompt] = useState('');

  // this gives back all other liquids for all users across all OEMs
  const allLiquids = otherLiquids.map((liquid) => {
    return JSON.parse(JSON.stringify(liquid));
  });

  // this narrows down the list to the current OEM and the currently logged in user
  const clientLiquidData = allLiquids?.filter((liquid) => {
    return liquid.username === username && liquid.client === VORTEK_NAME;
  });

  // now grab the gas data for each gas so it is easier to work with
  const liquidData = clientLiquidData?.map((liquid) => {
    return liquid.data;
  });

  const liquidOptions = liquidData.map((liquid) => {
    return (
      <option value={liquid.liquid_name} key={liquid.liquid_name}>
        {liquid.liquid_name}
      </option>
    );
  });

  const onLiquidSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selected = liquidData.find((liquid) => liquid.liquid_name === e.target.value);
    setLiquidName(selected.liquid_name);
    setDensity(selected.liquid_density);
    setDensityUnits(selected.liquid_select_1);
    setViscosity(selected.liquid_viscosity);
    setViscosityUnits(selected.liquid_select_2);
    setSoundSpeed(selected.liquid_sound_speed);
    setSoundSpeedUnits(selected.liquid_select_3);
  };

  const resetFields = () => {
    setLiquidName('');
    setDensity('62.4');
    setViscosity('1');
    setSoundSpeed('');
    setDensityUnits('lb/ft^3');
    setViscosityUnits('cP');
    setSoundSpeedUnits('ft/sec');
  };

  const deleteLiquid = async () => {
    const id = `${VORTEK_NAME + username}otherliquid${liquidName}`;

    const res = await api.deleteItem({
      requestData: {
        _id: id,
        collection: 'otherliquid',
        client: VORTEK_NAME,
        username,
        key: liquidName,
      },
    });

    if (res.status === 'success') {
      setResultPrompt('Liquid Deleted Successfully.');
    } else {
      setResultPrompt(`Failed to delete liquid. Remote error ${res.status}`);
    }
  };

  const saveLiquid = async () => {
    const data = {
      liquid_name: liquidName,
      liquid_density: density,
      liquid_viscosity: viscosity,
      liquid_sound_speed: soundSpeed,
      liquid_select_1: densityUnits,
      liquid_select_2: viscosityUnits,
      liquid_select_3: soundSpeedUnits,
      version: constants.REVISION,
      creationDate: new Date().toUTCString(),
    };

    const id = `${VORTEK_NAME + username}otherliquid${liquidName}`;

    const res = await api.writeItem({
      requestData: {
        _id: id,
        collection: 'otherliquid',
        client: VORTEK_NAME,
        username,
        key: liquidName,
        data: {
          _id: id,
          client: VORTEK_NAME,
          username,
          key: liquidName,
          data: JSON.parse(JSON.stringify(data)),
        },
      },
    });

    if (res.status === 'success') {
      if (utilities.checkValues()) {
        utilities.readyData();
      }

      setResultPrompt('Liquid has been successfully added.');
    } else if (res.status === 'fail' && res.error === 'Unauthorized') {
      if (onClose) {
        onClose();
      }

      utilities.handleAjaxFailure(res);
    } else {
      setResultPrompt(`Failed to save liquid. Remote error ${res.status}`);
    }
  };

  useEffect(() => {
    if (liquidData.length > 0) {
      setLiquidName(liquidData[0].liquid_name);
      setDensity(liquidData[0].liquid_density);
      setDensityUnits(liquidData[0].liquid_select_1);
      setViscosity(liquidData[0].liquid_viscosity);
      setViscosityUnits(liquidData[0].liquid_select_2);
      setSoundSpeed(liquidData[0].liquid_sound_speed);
      setSoundSpeedUnits(liquidData[0].liquid_select_3);
    } else {
      resetFields();
    }
  }, []);

  const { UltrasonicS34Model, UltrasonicS36Model, UltrasonicU42Model, UltrasonicU44Model } = sizingGlobals;

  const soundSpeedVisible =
    (VORTEK_NAME === utilities.PROV_NAME || VORTEK_NAME === utilities.GESTRA_NAME) &&
    (meterType === UltrasonicS34Model ||
      meterType === UltrasonicS36Model ||
      meterType === UltrasonicU42Model ||
      meterType === UltrasonicU44Model);

  return (
    <>
      <Modal title="Add/Edit Liquid" visible showButtons={false} showCloseButton onClose={onClose}>
        <div className="modalContent flexColCenter">
          <div className="flexRow">
            <div className="leftRight10 right10">
              <p>Liquids</p>
              <select
                style={{ width: '130px', height: '150px' }}
                size={10}
                onChange={(e) => onLiquidSelectChange(e)}
                defaultValue={liquidData.length > 0 ? liquidData[0].liquid_name : ''}
              >
                {liquidOptions}
              </select>
            </div>

            <div className="flexColCenter leftRight10">
              <div className="flexRowNoGrow topBottom10">
                <label style={{ flex: '0 0 100px' }}>*Liquid Name</label>
                <input type="text" value={liquidName} onChange={(e) => setLiquidName(e.target.value)} />
              </div>

              <div className="flexRowNoGrow topBottom10">
                <label style={{ flex: '0 0 100px' }}>*Density</label>
                <input type="number" className="right10" value={density} onChange={(e) => setDensity(e.target.value)} />

                <select className="right40" value={densityUnits} onChange={(e) => setDensityUnits(e.target.value)}>
                  <option value="lb/ft^3">lb/ft^3</option>
                  <option value="kg/m^3">kg/m^3</option>
                  <option value="g/cm^3">g/cm^3</option>
                  <option value="lb/in^3">lb/in^3</option>
                </select>
              </div>

              <div className="flexRowNoGrow topBottom10">
                <label style={{ flex: '0 0 100px' }}>*Viscosity</label>
                <input type="number" className="right10" value={viscosity} onChange={(e) => setViscosity(e.target.value)} />

                <select value={viscosityUnits} onChange={(e) => setViscosityUnits(e.target.value)}>
                  <option value="cP">cP</option>
                  <option value="P">P</option>
                </select>
              </div>

              {soundSpeedVisible && (
                <div className="flexRowNoGrow topBottom10">
                  <label style={{ flex: '0 0 100px' }}>Sound Speed</label>
                  <input type="number" className="right10" value={soundSpeed} onChange={(e) => setSoundSpeed(e.target.value)} />

                  <select className="right10" value={soundSpeedUnits} onChange={(e) => setSoundSpeedUnits(e.target.value)}>
                    <option value="ft/sec">ft/sec</option>
                    <option value="m/sec">m/sec</option>
                  </select>
                </div>
              )}
            </div>
          </div>

          <div className="flexCenter topBottom20">
            <button type="button" onClick={resetFields}>
              New Liquid
            </button>
            <button type="button" onClick={() => setDeletePromptVisible(true)}>
              Delete Liquid
            </button>
            <button type="button" onClick={saveLiquid}>
              Store Liquid
            </button>
          </div>
        </div>
      </Modal>

      {deletePromptVisible && (
        <Modal
          visible
          showButtons
          cancelText="No"
          okText="Yes"
          title="Delete Liquid"
          onClose={(e, action) => {
            if (action === 'ok') {
              deleteLiquid();
            }
            setDeletePromptVisible(false);
          }}
        >
          <div className="modalContent">
            This will delete the liquid.
            <br />
            Proceed?
          </div>
        </Modal>
      )}

      {resultPrompt.length > 0 && (
        <Modal
          visible
          showButtons
          cancelText=""
          okText="Ok"
          title=""
          onClose={() => {
            setResultPrompt('');
          }}
        >
          <div className="modalContent">{resultPrompt}</div>
        </Modal>
      )}
    </>
  );
};

export default Liquid;
