import PipeData from '../PipeData';
import ProcessCondition from '../processCondition';

export default abstract class Fluid {
  abstract calculateDensity(processCondition: ProcessCondition): ProcessCondition;

  abstract calculateViscosity(processCondition: ProcessCondition, minNomMax?: string): ProcessCondition;

  abstract calculateValues(pipeData: PipeData, minNomMax?: string): PipeData;
}
