import { useSelector } from 'react-redux';
import { RootState } from '../store';
import Navigator from './nav/Navigator';
import Modal from './common/Modal';
import MeterConditions from './meterConditionComponents/MeterConditions';
import MeterSizing from './meterConditionComponents/MeterSizing';
import MeterSizingVorcone from './meterConditionComponents/MeterSizingVorcone';
import ProcessConditions from './meterConditionComponents/ProcessConditions';
import RightPanel from './RightPanel';
import { CallbackNoParams } from '../types/callbackTypes';
import utilities from '../utilities';

const Main = (props: { logoutHandler: CallbackNoParams }): JSX.Element => {
  const { logoutHandler } = props;
  const appMode = useSelector((state: RootState) => state.appMode);
  const meterType = useSelector((state: RootState) => state.meterType);
  const errorMessage = useSelector((state: RootState) => state.errorMessage);
  const systemMessage = useSelector((state: RootState) => state.systemMessage);
  const warningMessage = useSelector((state: RootState) => state.warningMessage);

  return (
    <>
      <Navigator logoutHandler={logoutHandler} />
      {systemMessage && (
        <Modal title="System Message" visible showButtons={false} showData={false}>
          <div className="modalContent">{systemMessage}</div>
        </Modal>
      )}
      {errorMessage && (
        <Modal title="Error" visible showButtons showData={false} okText="OK" cancelText="" logoutHandler={logoutHandler}>
          <div className="modalContent">{errorMessage}</div>
        </Modal>
      )}
      {warningMessage && (
        <Modal title="Warning" visible showButtons showData={false} okText="OK" cancelText="">
          <div className="modalContent">{warningMessage}</div>
        </Modal>
      )}
      <div className="flexCenter wrapper">
        <div className="mainArea wrapperContent">
          <div className="flexCol">
            <div className="flexRow">
              <div className="contentLeft">
                {appMode === 'meter_conditions' && <MeterConditions />}
                <ProcessConditions />
              </div>
              <RightPanel />
            </div>
            {appMode === 'sizing' && meterType !== utilities.VORCONE_METER && meterType !== utilities.VORCONE_REDUCE_METER && (
              <MeterSizing />
            )}
            {appMode === 'sizing' && (meterType === utilities.VORCONE_METER || meterType === utilities.VORCONE_REDUCE_METER) && (
              <MeterSizingVorcone />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
