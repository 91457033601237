import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../common/Modal';
import Fluids from './Fluids';
import PipeConfiguration from './PipeConfiguration';
import api from '../../api';
import { RootState } from '../../store';
import actions from '../../store/actions';
import utilities from '../../utilities';

const MeterConditions = (): JSX.Element => {
  const dispatch = useDispatch();

  const defaultMeterTypes: JSX.Element[] = [];
  const [meterTypes, setMeterTypes] = useState(defaultMeterTypes);
  const [showSync, setShowSync] = useState(false);
  const [showElectroWarning, setShowElectroWarning] = useState(false);

  const state = useSelector((globalState: RootState) => globalState);
  const { appName, meterType, onlineToken, pipeOutsideDiameter, username, sizingGlobals } = state;

  const {
    reducing,
    s36,
    turbine,
    u44,
    proM,
    ultrasonic,
    UltrasonicS34Model,
    UltrasonicS34ModelStr,
    UltrasonicS36Model,
    UltrasonicS36ModelStr,
    UltrasonicU42Model,
    UltrasonicU42ModelStr,
    UltrasonicU43Model,
    UltrasonicU43ModelStr,
    UltrasonicU44Model,
    UltrasonicU44ModelStr,
    ElectromagneticProMModel,
    vorcone,
    VorconeModelStr,
    VorconeReduceModelStr,
    VORTEK_NAME,
    VORTEK_DISPLAY_NAME,
  } = sizingGlobals;

  let syncClassName = 'hideme';
  if (onlineToken !== 'offline' && !utilities.isHeinrichsOrOnicon() && username !== 'guest') {
    syncClassName = '';
  }

  let meterTypesClassNames = 'drop';
  let meterTypesLabelClassNames = 'namePad';
  if (!reducing && !turbine && !vorcone) {
    meterTypesClassNames = 'drop hideme';
    meterTypesLabelClassNames = 'hideme';
  }

  useEffect(() => {
    const newMeterTypes: JSX.Element[] = [];
    let vortexName;
    let vortexNonReducingName;
    let vortexReducingName;

    if (
      VORTEK_NAME === utilities.PROV_NAME ||
      VORTEK_NAME === utilities.GESTRA_NAME ||
      VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
    ) {
      vortexName = 'M22/M23 Vortex';
      vortexNonReducingName = 'M24 Vortex';
      vortexReducingName = 'M24R Reducing Vortex';
    } else if (VORTEK_NAME === utilities.AZBIL_NAME) {
      vortexName = 'AX22/AX23 Vortex';
      vortexNonReducingName = 'AX24 Vortex';
      vortexReducingName = 'AX24R Reducing Vortex';
    } else if (VORTEK_NAME === utilities.PANAFLOW_NAME) {
      vortexName = 'MV80/MV82 Vortex';
      vortexNonReducingName = 'MV84 Vortex';
      vortexReducingName = 'MV84R Reducing Vortex';
    } else if (VORTEK_NAME === utilities.SPIRAX_NAME) {
      vortexName = 'VLM20/VIM20 Vortex';
    } else if (VORTEK_NAME === utilities.ONICON_NAME) {
      vortexName = 'Vortex';
      vortexReducingName = 'F-26XX Reducing Vortex';
    } else if (VORTEK_NAME === utilities.INNOVA_NAME) {
      vortexName = 'Vortex';
      vortexReducingName = '240S Reducing Vortex';
    } else {
      vortexName = 'Vortex';
    }

    if (vortexName) {
      newMeterTypes.push(
        <option key={utilities.VORTEX_METER} value={utilities.VORTEX_METER}>
          {vortexName}
        </option>
      );
    }

    if (vortexNonReducingName) {
      newMeterTypes.push(
        <option key={utilities.VORTEX_NONREDUCE_METER} value={utilities.VORTEX_NONREDUCE_METER}>
          {vortexNonReducingName}
        </option>
      );
    }

    if (vortexReducingName) {
      newMeterTypes.push(
        <option key={utilities.VORTEX_REDUCE_METER} value={utilities.VORTEX_REDUCE_METER}>
          {vortexReducingName}
        </option>
      );
    }

    // un-hide and hook up turbine extensions if client supports it
    if (turbine) {
      if (VORTEK_NAME === utilities.PROV_NAME || VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME) {
        newMeterTypes.push(
          <option key={utilities.TURBINE_METER} value={utilities.TURBINE_METER}>
            Pro-T Turbine
          </option>
        );
      } else if (VORTEK_NAME === utilities.SPIRAX_NAME) {
        newMeterTypes.push(
          <option key={utilities.TURBINE_METER} value={utilities.TURBINE_METER}>
            RIM20 Turbine
          </option>
        );
      } else {
        newMeterTypes.push(
          <option key={utilities.TURBINE_METER} value={utilities.TURBINE_METER}>
            Turbine Meter
          </option>
        );
      }
    } else {
      dispatch(actions.setTurbineActive(false));
    }

    // un-hide and hook up VorCone extensions if client supports it
    if (vorcone) {
      newMeterTypes.push(
        <option key={utilities.VORCONE_METER} value={utilities.VORCONE_METER}>
          {VorconeModelStr}
        </option>
      );
      newMeterTypes.push(
        <option key={utilities.VORCONE_REDUCE_METER} value={utilities.VORCONE_REDUCE_METER}>
          {VorconeReduceModelStr}
        </option>
      );
    } else {
      dispatch(actions.setVorconeActive(false));
    }

    // un-hide and hook up Ultrasonic extensions if client supports it
    if (ultrasonic) {
      newMeterTypes.push(
        <option key={UltrasonicS34Model} value={UltrasonicS34Model}>
          {UltrasonicS34ModelStr}
        </option>
      );
      // un-hide and hook up Ultrasonic S36 extensions if client supports it
      if (s36) {
        newMeterTypes.push(
          <option key={UltrasonicS36Model} value={UltrasonicS36Model}>
            {UltrasonicS36ModelStr}
          </option>
        );
      }

      newMeterTypes.push(
        <option key={UltrasonicU42Model} value={UltrasonicU42Model}>
          {UltrasonicU42ModelStr}
        </option>
      );
      newMeterTypes.push(
        <option key={UltrasonicU43Model} value={UltrasonicU43Model}>
          {UltrasonicU43ModelStr}
        </option>
      );
    }

    // un-hide and hook up Ultrasonic S44 extensions if client supports it
    if (u44) {
      newMeterTypes.push(
        <option key={UltrasonicU44Model} value={UltrasonicU44Model}>
          {UltrasonicU44ModelStr}
        </option>
      );
    }

    // un-hide and hook up Electromagnetic Pro-M extensions if client supports it
    if (proM) {
      newMeterTypes.push(
        <option key={ElectromagneticProMModel} value={ElectromagneticProMModel}>
          Pro-M Electromagnetic
        </option>
      );
    }

    setMeterTypes(newMeterTypes);
    if (!meterType) {
      dispatch(actions.setMeterType(utilities.VORTEX_METER));
    }
  }, [meterType, VORTEK_NAME]);

  const syncFromServer = useCallback(() => {
    setShowSync(true);
  }, []);

  const doSyncFromServer = useCallback(
    async (e, response) => {
      setShowSync(false);
      if (response === 'ok') {
        // make sure user still has a valid login
        api
          .validateToken({
            client: VORTEK_NAME,
            username,
            collection: '',
          })
          .then(async (validated) => {
            if (validated.status === 'fail') {
              utilities.handleAjaxFailure(validated);
              return;
            }

            await dispatch(actions.setSystemMessage(`Please wait...retrieving ${VORTEK_DISPLAY_NAME} data from the server.`));

            await api.clearCollections({
              requestData: {
                client: VORTEK_NAME,
                username,
                collection: '',
              },
            });
            await api.retrieveRemoteOfflineData();
            await api.retrieveIndexedDbDataToMemory({
              requestData: {
                client: VORTEK_NAME,
                username,
                collection: '',
              },
            });
            dispatch(actions.setSystemMessage(''));
          });
      }
    },
    [username, VORTEK_NAME, VORTEK_DISPLAY_NAME]
  );

  const syncToServer = useCallback(async () => {
    // make sure user still has a valid login
    api
      .validateToken({
        client: VORTEK_NAME,
        username,
        collection: '',
      })
      .then(async (validated) => {
        if (validated.status === 'fail') {
          utilities.handleAjaxFailure(validated);
          return;
        }

        await api.syncOfflineDataToServer();
      });
  }, [username, VORTEK_NAME]);

  const changeMeterType = useCallback(
    (e) => {
      const currentMeterType = e.target.value;
      dispatch(actions.setMeterType(currentMeterType));
      let newTurbineActive = false;
      let newReducingVortex = false;
      let newVorconeActive = false;
      let newReducingVorcone = false;

      if (currentMeterType === utilities.VORTEX_REDUCE_METER) {
        newReducingVortex = true;
      } else if (currentMeterType === utilities.VORCONE_METER) {
        newVorconeActive = true;
      } else if (currentMeterType === utilities.VORCONE_REDUCE_METER) {
        newReducingVorcone = true;
      } else if (currentMeterType === utilities.TURBINE_METER) {
        newTurbineActive = true;
      }

      if (newReducingVortex && pipeOutsideDiameter === '0.5 in') {
        dispatch(actions.setPipeOutsideDiameter('0.75 in'));
      } else if (newReducingVorcone && pipeOutsideDiameter === '0.5 in') {
        dispatch(actions.setPipeOutsideDiameter('3 in'));
      }

      dispatch(actions.setReducingVortex(newReducingVortex));
      dispatch(actions.setVorconeActive(newVorconeActive));
      dispatch(actions.setReducingVorcone(newReducingVorcone));
      dispatch(actions.setTurbineActive(newTurbineActive));

      if (currentMeterType === ElectromagneticProMModel) {
        setShowElectroWarning(true);
      }
    },
    [meterType]
  );

  return (
    <>
      {showSync && (
        <Modal showData={false} okText="Yes" cancelText="No" title="Delete Local Data" visible onClose={doSyncFromServer}>
          <div className="modalContent">
            Are you sure you want to remove ALL
            <br />
            local data and then retrieve the data
            <br /> from the server?
          </div>
        </Modal>
      )}
      {showElectroWarning && (
        <Modal showData={false} okText="" cancelText="" title="Info" visible onClose={() => setShowElectroWarning(false)}>
          <div className="modalContent">Liquid must meet a minimum electrical conductivity of 20 μs/cm or greater</div>
        </Modal>
      )}
      <div className="topSection">
        <div className="flexRow">
          <div className="flexColCenter">
            <div className="flexRowNoGrow">
              <div className="flexCol">
                <div className="flexRow namePad">Application Name</div>
                <div className={`flexRow ${meterTypesLabelClassNames}`}>Meter Type</div>
              </div>
              <div className="flexCol">
                <div className="flexRow bottom6">
                  <input
                    autoComplete="off"
                    value={appName}
                    className="application txtbox"
                    type="text"
                    maxLength={50}
                    onChange={(e) => {
                      dispatch(actions.setAppName(e.target.value));
                    }}
                  />
                </div>
                <div className="flexRow">
                  <select name="meterTypeSelect" value={meterType} className={meterTypesClassNames} onChange={changeMeterType}>
                    {meterTypes}
                  </select>
                </div>
              </div>
              <div className="flexCol flowGuide left25Right55Top6">
                {(VORTEK_NAME === utilities.PROV_NAME || VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME) && (
                  <button type="button" className="btnGreen flowGuide">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.vortekinst.com/files/38d8c494-8acc-42aa-b08e-3346ac49fe5f/VorTek-Instruments-Flowmeter-Selection-Guide.pdf"
                      className="btnGreen flowGuideLink"
                    >
                      Flowmeter Selection Guide
                    </a>
                  </button>
                )}
              </div>
            </div>
            <Fluids />
          </div>
          <div className="flexCol">
            <button type="button" className={`btnGreen topBottom5 ${syncClassName}`} onClick={syncFromServer}>
              <b className="iconGet">FROM SERVER</b>
            </button>
            <button type="button" className={`btnGreen ${syncClassName}`} onClick={syncToServer}>
              <b className="iconSync">TO SERVER</b>
            </button>
          </div>
        </div>
      </div>
      <PipeConfiguration />
    </>
  );
};

export default MeterConditions;
