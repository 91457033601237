import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../api';
import { RootState } from '../../store';
import Application from '../../types/application';
import { CallbackNoParams } from '../../types/callbackTypes';
import DbRow from '../../types/dbRow';
import applicationUtils from '../../utilities/application';
import Modal from '../common/Modal';

const OpenApplication = (props: { onClose: CallbackNoParams }): JSX.Element | null => {
  const { onClose } = props;
  const state = useSelector((globalState: RootState) => globalState);
  const { db, sizingGlobals, username } = state;
  const { VORTEK_NAME } = sizingGlobals;
  const { application } = db;

  const defaultListItems: Application[] = [];
  const [allApplications, setAllApplications] = useState(defaultListItems);
  const [chosenAppName, setChosenAppName] = useState('');

  useEffect(() => {
    const newApplications = [] as Application[];
    const res = api.findAllDataForUserInReduxTable(VORTEK_NAME, username, 'application');

    if (res?.length) {
      const responseData = res as unknown as DbRow[];

      for (let i = 0; i < responseData.length; i++) {
        if (responseData[i].data) {
          newApplications.push(responseData[i].data as unknown as Application);
        }
      }

      setAllApplications(newApplications);
    }
  }, [application, username]);

  return (
    <Modal title="Open Application" visible showButtons={false} showCloseButton onClose={onClose}>
      <div className="flexCol modalContent">
        <p className="flexRowCenter">Choose the application to load</p>
        <table style={{ lineHeight: 25, marginBottom: 10, width: '100%', border: 0 }}>
          <tbody>
            <tr>
              <td align="center">
                <select
                  name="pipeOdSelect"
                  className="drop"
                  value={chosenAppName}
                  onChange={(e) => {
                    setChosenAppName(e.target.value);
                  }}
                >
                  <option key="__blank" value="">
                    &nbsp;
                  </option>
                  {allApplications.map((app) => (
                    <option key={app.appname} value={app.appname}>
                      {app.appname}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flexRow topBottom20">
                  <button
                    type="button"
                    onClick={() => {
                      if (chosenAppName) {
                        applicationUtils.newApplication('yes');
                        applicationUtils.parseApplication(
                          chosenAppName,
                          allApplications.find((a) => a.appname === chosenAppName)
                        );
                        onClose();
                      }
                    }}
                  >
                    Load
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default OpenApplication;
