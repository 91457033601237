import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Decimal from 'decimal.js';
import actions from '../../../store/actions';
import { RootState } from '../../../store';
import api from '../../../api';
import Unit from '../../../types/Unit';

const BarometricPressure = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const defaultListItems: string[] = [];
  const [allPressureUnits, setAllPressureUnits] = useState(defaultListItems);

  const db = useSelector((globalState: RootState) => globalState.db);
  const { pressure } = db;
  const state = useSelector((globalState: RootState) => globalState);
  const { workingDefaults } = state;
  const { defaultValues, defaultUnits } = workingDefaults;
  const baroPressValue = String(new Decimal(defaultValues.baroPressValue));
  const { baroPressUnit } = defaultUnits;
  const getDropDownData = (collection: string): string[] => {
    const table = api.getSpecifiedFeatureList(collection) as unknown as Unit[];
    const data = table.map((r) => {
      return r.abbrv;
    });

    return data;
  };

  const populateFormFields = () => {
    setAllPressureUnits(getDropDownData('pressure'));
  };

  useEffect(() => {
    populateFormFields();
  }, [pressure]);

  return (
    <>
      <div className="modalTabsSubTitle">The atmospheric pressure is the average between absolute and gauge pressures.</div>
      <div className="modalContentTabs">
        <div className="fieldRow">
          <span className="field">
            <div className="fieldTitle">Average Pressure</div>
            <div />
            <input
              type="number"
              step="any"
              defaultValue={baroPressValue}
              onChange={(e) => {
                workingDefaults.newValuesSaved = true;
                workingDefaults.defaultValues.baroPressValue = new Decimal(e.target.value);
                dispatch(actions.setWorkingDefaults(workingDefaults));
              }}
            />
            <span className="leftRight10" />
            <select
              name="flowUnit1"
              className="dropSmall2"
              value={baroPressUnit}
              onChange={(e) => {
                workingDefaults.newValuesSaved = true;
                workingDefaults.defaultUnits.baroPressUnit = e.target.value;
                dispatch(actions.setWorkingDefaults(workingDefaults));
              }}
            >
              {allPressureUnits.map((p) => {
                return (
                  <option key={p} value={p}>
                    {p}
                  </option>
                );
              })}
            </select>
          </span>
        </div>
      </div>
    </>
  );
};

export default BarometricPressure;
