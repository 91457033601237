import constants from '../constants';

/* eslint-disable camelcase */
export default class Customer {
  company_name: string;
  industry_code: string;
  type_of_contact: string;
  first_name: string;
  last_name: string;
  email: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  title_customer?: string;
  phone_customer: string;
  extension_customer?: string;
  fax_customer?: string;
  donotprint: string;
  version?: string;
  creationDate?: string;

  constructor() {
    this.company_name = '';
    this.industry_code = 'manufacturing';
    this.type_of_contact = 'billing';
    this.first_name = '';
    this.last_name = '';
    this.email = '';
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.state = '';
    this.zipcode = '';
    this.country = '';
    this.title_customer = '';
    this.phone_customer = '';
    this.extension_customer = '';
    this.fax_customer = '';
    this.donotprint = 'print';
    this.version = constants.REVISION;
    this.creationDate = new Date().toUTCString();
  }
}
