import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../common/Modal';
import { RootState } from '../../store';
import api from '../../api';
import constants from '../../constants';

const Login = (props: { visible: boolean; message: string }): JSX.Element => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repPassword, setRepPassword] = useState('');
  const [email, setEmail] = useState('');

  const { message, visible } = props;
  const [loginMode, setLoginMode] = useState('login');
  const sizingGlobals = useSelector((state: RootState) => state.sizingGlobals);
  const dbase = useSelector((state: RootState) => state.database);
  const { VORTEK_NAME } = sizingGlobals;
  const [error, setError] = useState(message);

  const showLogin = useCallback(() => {
    setLoginMode('login');
  }, []);

  const showRegister = useCallback(() => {
    setLoginMode('register');
  }, []);

  const showForgotPassword = useCallback(() => {
    setLoginMode('forgotPassword');
  }, []);

  const loginHandler = useCallback(
    async (e, uname, pw) => {
      e.preventDefault();

      // This is now called when the user logs in
      if (dbase) {
        const response = await api.performLogin({
          requestData: {
            client: VORTEK_NAME,
            collection: '',
            username: uname,
            password: pw,
          },
        });

        if (response.status === 'success') {
          setError('');
        } else {
          setError(response.error);
        }
      }
    },
    [dbase, VORTEK_NAME]
  );

  const login = useCallback(
    (e) => {
      if (!username) {
        setError('Username is required');
        return;
      }

      if (!password) {
        setError('Password is required');
        return;
      }
      loginHandler(e, username, password);
    },
    [loginHandler, username, password]
  );

  const loginAsGuest = useCallback(
    (e) => {
      loginHandler(e, 'guest', 'guest');
    },
    [loginHandler]
  );

  const register = useCallback(
    async (e) => {
      e.preventDefault();
      const whitespaceRegex = /\s/g;

      if (!username) {
        setError('Username is required');
        return;
      }

      const match = username.match(/^[A-Za-z][A-Za-z0-9 -]*$/);
      if (!match || username.length < 4 || username.length > 8) {
        setError('Username must start with a letter, be 4-8 chars, and only consist of letters and/or numbers');
        return;
      }

      if (username === 'guest' || username === 'admin' || username === 'DEFAULT') {
        setError('User already exists');
        return;
      }

      if (!password) {
        setError('Password is required');
        return;
      }

      if (password.match(whitespaceRegex)) {
        setError('Password cannot contain any whitespace');
        return;
      }

      if (!repPassword || repPassword !== password) {
        setError('Repeat password does not match password');
        return;
      }

      if (!email) {
        setError('email is required');
        return;
      }

      if (email.match(whitespaceRegex)) {
        setError('Email cannot contain any whitespace');
        return;
      }

      const response = await api.performRegister({
        requestData: {
          client: VORTEK_NAME,
          collection: '',
          username,
          password,
          rep_password: repPassword,
          email,
        },
      });

      if (response.status === 'success') {
        setError('Please click on the link in the email to complete your registration. Remember to check your spam folder!');
      } else {
        setError(response.error);
      }
    },
    [username, password, repPassword, email]
  );

  const forgotPassword = useCallback(
    async (e) => {
      e.preventDefault();
      const whitespaceRegex = /\s/g;

      if (!username) {
        setError('Username is required');
        return;
      }

      const match = username.match(/^[A-Za-z][A-Za-z0-9 -]*$/);
      if (!match || username.length < 4 || username.length > 8) {
        setError('Username must start with a letter, be 4-8 chars, and only consist of letters and/or numbers');
        return;
      }

      if (username === 'guest' || username === 'admin' || username === 'DEFAULT') {
        setError('User already exists');
        return;
      }

      if (!email) {
        setError('email is required');
        return;
      }

      if (email.match(whitespaceRegex)) {
        setError('Email cannot contain any whitespace');
        return;
      }

      const response = await api.performForgotPassword({
        requestData: {
          client: VORTEK_NAME,
          collection: '',
          username,
          email,
        },
      });

      if (response.status === 'success') {
        setError('Please click on the link in the email to change your password. Remember to check your spam folder!');
      } else {
        setError(response.error);
      }
    },
    [username, email]
  );

  return (
    <Modal
      showButtons={false}
      title={`${loginMode === 'login' ? 'Login' : loginMode === 'register' ? 'Register' : 'Forgot Password'}`}
      visible={visible}
    >
      <div className="leftRight10">
        <div className="flexRow white topBottom20">
          Version:{'  '}
          <div className="version_login">{constants.REVISION}</div>
        </div>
        <p className="flexRow white">NOTE: Username is case sensitive</p>
        <fieldset className="loginFieldset">
          <div className="flexRow login_username_div">
            <div className="flexColumn right10 white">Username:</div>
            <input
              className="login_username"
              type="text"
              size={20}
              maxLength={8}
              tabIndex={0}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div className={`flexRow ${loginMode === 'forgotPassword' ? 'hideme' : ''} login_password_div`}>
            <div className="flexColumn right10 white">Password:</div>
            <input
              className="login_password"
              type="password"
              size={20}
              maxLength={50}
              tabIndex={0}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className={`flexRow ${loginMode !== 'register' ? 'hideme' : ''} login_password_repeat_div`}>
            <div className="flexColumn right10 white">Repeat Password:</div>
            <input
              className="login_password_repeat"
              type="password"
              size={20}
              maxLength={50}
              tabIndex={0}
              onChange={(e) => {
                setRepPassword(e.target.value);
              }}
            />
          </div>
          <div className={`flexRow ${loginMode === 'login' ? 'hideme' : ''} login_email_div`}>
            <div className="flexColumn right10 white">Email:</div>
            <input
              className="login_email"
              type="text"
              size={20}
              maxLength={250}
              tabIndex={0}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </fieldset>
        <div className="topBottom10 flexRow">
          <div
            role="link"
            className={`leftRight10 white underline ${loginMode === 'login' ? 'hideme' : ''}`}
            onClick={showLogin}
            onKeyDown={showLogin}
            tabIndex={0}
          >
            Login
          </div>
          <div
            role="link"
            className={`leftRight10 white underline ${loginMode === 'register' ? 'hideme' : ''}`}
            onClick={showRegister}
            onKeyDown={showRegister}
            tabIndex={0}
          >
            Register
          </div>
          <div
            role="link"
            className={`leftRight10 white underline ${loginMode === 'forgotPassword' ? 'hideme' : ''}`}
            onClick={showForgotPassword}
            onKeyDown={showForgotPassword}
            tabIndex={0}
          >
            Forgot Password
          </div>
        </div>
        <div className="topBottom10 flexRow">
          <button type="button" onClick={login} tabIndex={0} className={`${loginMode !== 'login' ? 'hideme' : ''}`}>
            Login
          </button>
          <button type="button" onClick={register} tabIndex={0} className={`${loginMode !== 'register' ? 'hideme' : ''}`}>
            Submit Registration Now
          </button>
          <button
            type="button"
            onClick={forgotPassword}
            tabIndex={0}
            className={`${loginMode !== 'forgotPassword' ? 'hideme' : ''}`}
          >
            Forgot Password
          </button>
          <button type="button" onClick={loginAsGuest} tabIndex={0} className={`${loginMode !== 'login' ? 'hideme' : ''}`}>
            Login As Guest
          </button>
        </div>
        <div className="flexTextRow clientBack redWarning login_error_div">{error || message}</div>
      </div>
    </Modal>
  );
};

export default Login;
