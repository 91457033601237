import { useSelector } from 'react-redux';
import { ChangeEvent, useEffect, useState } from 'react';
import { RootState } from '../../store';
import { CallbackNoParams } from '../../types/callbackTypes';
import Modal from '../common/Modal';
import api from '../../api';
import utilities from '../../utilities';
import constants from '../../constants';

const Gas = (props: { onClose: CallbackNoParams }): JSX.Element | null => {
  const sizingGlobals = useSelector((state: RootState) => state.sizingGlobals);
  const username = useSelector((state: RootState) => state.username);
  const { VORTEK_NAME } = sizingGlobals;
  const { onClose } = props;
  const otherGases = useSelector((state: RootState) => state.db.othergas);

  const [gasName, setGasName] = useState('');
  const [compressibility, setCompressibility] = useState('1');
  const [gravity, setGravity] = useState('1');
  const [viscosity, setViscosity] = useState('0.01');

  const [deletePromptVisible, setDeletePromptVisible] = useState(false);
  const [resultPrompt, setResultPrompt] = useState('');

  // this gives back all gasses for all users across all OEMs
  const allGasses = otherGases.map((gas) => {
    return JSON.parse(JSON.stringify(gas));
  });

  // this narrows down the list to the current OEM and the currently logged in user
  const clientGasData = allGasses?.filter((gas) => {
    return gas.username === username && gas.client === VORTEK_NAME;
  });

  // now grab the gas data for each gas so it is easier to work with
  const gasData = clientGasData?.map((gas) => {
    return gas.data;
  });

  const gasOptions = gasData?.map((gas) => {
    return (
      <option value={gas.gas_name} key={gas.gas_name}>
        {gas.gas_name}
      </option>
    );
  });

  const onGasSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedGas = gasData.find((gas) => gas.gas_name === e.target.value);
    setGasName(selectedGas.gas_name);
    setCompressibility(selectedGas.gas_compressibility);
    setGravity(selectedGas.gas_gravity);
    setViscosity(selectedGas.gas_viscosity);
  };

  const resetGasFields = () => {
    setGasName('');
    setCompressibility('1');
    setGravity('1');
    setViscosity('0.01');
  };

  const deleteGas = async () => {
    const id = `${VORTEK_NAME + username}othergas${gasName}`;

    const res = await api.deleteItem({
      requestData: {
        _id: id,
        collection: 'othergas',
        client: VORTEK_NAME,
        username,
        key: gasName,
      },
    });

    if (res.status === 'success') {
      setResultPrompt('Gas Deleted Successfully.');
    } else {
      setResultPrompt(`Failed to delete gas. Remote error ${res.status}`);
    }
  };

  const saveGas = async () => {
    const data = {
      gas_name: gasName,
      gas_compressibility: compressibility,
      gas_gravity: gravity,
      gas_viscosity: viscosity,
      version: constants.REVISION,
      creationDate: new Date().toUTCString(),
    };

    const id = `${VORTEK_NAME + username}othergas${gasName}`;

    const res = await api.writeItem({
      requestData: {
        _id: id,
        collection: 'othergas',
        client: VORTEK_NAME,
        username,
        key: gasName,
        data: {
          _id: id,
          client: VORTEK_NAME,
          username,
          key: gasName,
          data: JSON.parse(JSON.stringify(data)),
        },
      },
    });

    if (res.status === 'success') {
      if (utilities.checkValues()) {
        utilities.readyData();
      }

      setResultPrompt('Gas has been successfully added.');
    } else if (res.status === 'fail' && res.error === 'Unauthorized') {
      if (onClose) {
        onClose();
      }

      utilities.handleAjaxFailure(res);
    } else {
      setResultPrompt(`Failed to save gas. Remote error ${res.status}`);
    }
  };

  useEffect(() => {
    if (gasData.length > 0) {
      setGasName(gasData[0].gas_name);
      setCompressibility(gasData[0].gas_compressibility);
      setGravity(gasData[0].gas_gravity);
      setViscosity(gasData[0].gas_viscosity);
    }
  }, []);

  return (
    <>
      <Modal title="Add/Edit Gas" visible showButtons={false} showCloseButton onClose={onClose}>
        <div className="flexRow modalContent">
          <div className="leftRight10 right10">
            <p>Gasses</p>
            <select
              style={{ width: '111px', height: '150px' }}
              size={10}
              onChange={(e) => onGasSelectChange(e)}
              defaultValue={gasData.length > 0 ? gasData[0].gas_name : ''}
            >
              {gasOptions}
            </select>
          </div>

          <div className="flexColCenter leftRight10">
            <div className="flexBetween topBottom10">
              <label>*Gas Name</label>
              <input type="text" value={gasName} onChange={(e) => setGasName(e.target.value)} />
            </div>

            <div className="flexBetween topBottom10">
              <label>*Compressibility</label>
              <input type="number" value={compressibility} onChange={(e) => setCompressibility(e.target.value)} />
            </div>

            <div className="flexBetween topBottom10">
              <label>*Specific Gravity</label>
              <input type="number" value={gravity} onChange={(e) => setGravity(e.target.value)} />
            </div>

            <div className="flexBetween topBottom10">
              <label className="right10">*Flowing Viscosity</label>
              <input type="number" value={viscosity} onChange={(e) => setViscosity(e.target.value)} />
            </div>

            <div className="topBottom10">
              <select>
                <option value="cP">cP</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flexCenter topBottom20">
          <button type="button" onClick={resetGasFields}>
            New Gas
          </button>
          <button type="button" onClick={() => setDeletePromptVisible(true)}>
            Delete Gas
          </button>
          <button type="button" onClick={saveGas}>
            Store Gas
          </button>
        </div>
      </Modal>

      {deletePromptVisible && (
        <Modal
          visible
          showButtons
          cancelText="No"
          okText="Yes"
          title="Delete Gas"
          onClose={(e, action) => {
            if (action === 'ok') {
              deleteGas();
            }
            setDeletePromptVisible(false);
          }}
        >
          <div className="modalContent">
            This will delete the gas.
            <br />
            Proceed?
          </div>
        </Modal>
      )}

      {resultPrompt.length > 0 && (
        <Modal
          visible
          showButtons
          cancelText=""
          okText="Ok"
          title=""
          onClose={() => {
            setResultPrompt('');
          }}
        >
          <div className="modalContent">{resultPrompt}</div>
        </Modal>
      )}
    </>
  );
};

export default Gas;
