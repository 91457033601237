import SizingGlobals from '../../types/sizingGlobals';

const OniconSizingGlobals: SizingGlobals = {
  goodUsableColor: '#003300',
  reducing: true,
  turbine: true,
  vorcone: false,
  ultrasonic: false,
  s36: false,
  u44: false,
  proM: false,
  VorconeModel: 'MVC VorCone',
  VorconeReduceModel: 'MVCR Reducing VorCone',
  VorconeModelStr: 'MVC VorCone',
  VorconeReduceModelStr: 'MVCR Reducing VorCone',
  InlineReduceModel: 'F-26',
  InlineNonReduceModel: 'F-28',
  InlineModel: 'F-26',
  InlineModelPrev: '',
  InsertionModel: 'F-2700',
  InsertionModelPrev: '',
  TurbineModel: 'F-1500 Series Insertion Turbine Flow Meter',
  TurbineModelShort: 'F-1500',
  InlineModelStr: 'F-2600 Series',
  InlineNonReduceModelStr: 'F-2800 Series',
  InlineReduceModelStr: 'F-26XX Series',
  InsertionModelStr: 'F-2700 Series',
  UltrasonicS34Model: 'S34',
  UltrasonicS34ModelStr: 'S34 Ultrasonic',
  UltrasonicS36Model: 'S36',
  UltrasonicS36ModelStr: 'S36 Ultrasonic',
  UltrasonicU42Model: 'U42',
  UltrasonicU42ModelStr: 'U42 Ultrasonic',
  UltrasonicU43Model: 'U43',
  UltrasonicU43ModelStr: 'U43 Ultrasonic',
  UltrasonicU44Model: 'U44',
  UltrasonicU44ModelStr: 'U44 Ultrasonic',
  ElectromagneticProMModel: 'Pro-M',
  ElectromagneticProMModelStr: 'Electromagnetic Flow Meter',
  ProductLineStr: 'ONICON',
  HelpMenuStr: 'ONICON Sizer Help...',
  STANDARD_TEMP: '0',
  HIGH_TEMP: '',
  WAFER: '',
  ANSI_150: '1',
  ANSI_300: '3',
  ANSI_600: '',
  ANSI_900: '',
  DN_PN16: 'NODN',
  DN_PN40: 'NODN',
  DN_PN64: 'NODN',
  DN_PN100: 'NODN',
  JIS_10K_J1: '',
  JIS_16K_K: '',
  JIS_20K_J2: '',
  JIS_30K_J3: '',
  JIS_40K_J4: '',
  JIS_10K_Y1: '',
  JIS_20K_Y2: '',
  LocalStr: '1',
  RemoteStr: '2',
  RemoteStr1: '',
  RemoteStr2: '',
  RemoteStr3: '',
  RemoteStr4: '',
  CNPT: '7',
  C150: '',
  C16: '',
  CJ10: '',
  CFJ1K: '',
  C300: '',
  C40: '',
  CJ20: '',
  CFJ2K: '',
  C600: '',
  C64: '',
  CJ30: '',
  CFJ3K: '',
  C900: '',
  CFJ4K: '',
  PNPT: '0',
  P150: '',
  P16: '',
  PJ10: '',
  PGJ1K: '',
  P300: '',
  P40: '',
  PJ20: '',
  PJ30: '',
  PGJ2K: '',
  PGJ3K: '',
  PGJ4K: '',
  PNPTR: '',
  PNPTRE: '',
  P150R: '4',
  P150RE: '',
  P16R: '',
  P16RE: '',
  PJ10R: '',
  PGJ1R: '',
  P300R: '5',
  P300RE: '',
  P40R: '',
  P40RE: '',
  PJ20R: '',
  PGJ2R: '',
  P600R: '',
  P600RE: '',
  P600: '',
  P64R: '',
  P64RE: '',
  PJ30R: '',
  P64: '',
  PGJ3R: '',
  PGJ4R: '',
  ENPTR: '',
  E5016R: '',
  E5040R: '',
  E5064R: '',
  E150R: '',
  E300R: '',
  E600R: '',
  StandardLength: 'SL',
  CompactLength: 'CL',
  ExtendedLength: 'EL',
  V_METER: '9',
  VT_METER: '0',
  VTP_METER: '',
  VTP_METER_P1: '1',
  VTP_METER_P2: '2',
  VTP_METER_P3: '3',
  VTP_METER_P4: '4',
  VTEP_METER: '',
  VETEP_METER: '',
  VTEM_METER: '',
  VTPEM_METER: '',
  VTEPEM_METER: '',
  VETEPEM_METER: '',
  LOOP_OUTPUT: '0',
  LOOP_POWERED: '0',
  VDC_1236_POWERED: '1',
  AC_POWERED: '2',
  SPECIAL_STUFF: '',
  POE: '3',
  ONE_OUTPUT: '0',
  NO_ALARM_MODBUS_OUTPUT: '1',
  NO_ALARM_MODBUS_POE: '5',
  ALARM_MODBUS: '3',
  ALARM_MODBUS_POE: '7',
  BACNET: '2',
  BACNET_POE: '6',
  ALARM_BACNET: '4',
  ALARM_BACNET_POE: '8',
  ALARM_HART: '0',
  MORE_LOOP_POWERED: [],
  INLINE_NON_LOOP_ORDER: ['51', '49', '44', '50', '75', '76', '77', '78'],
  INSERTION_NON_LOOP_ORDER: ['16', '69', '17', '18'],
  NO_PRESSURE_TRANSDUCER: '',
  DIFF_PRESS_XMTR_FACT: '',
  DIFF_PRESS_XMTR_CUST: '',
  DIAG_ADD_PRESS_TAP_FT: '',

  // This is the Meter and code information.
  CODE_NAME: 'Onicon Incorporated',
  CODE_DESCRIPTION: 'ONICON Vortex Flow Meter',
  TURBINE_CODE_NAME: 'F-1500',
  TURBINE_CODE_DESCRIPTION: 'ONICON Turbine Flow Meter',
  DATA_SHEET: 'APPLICATION DATA SHEET',
  DESCRIPTION: 'F-1500, F-2600 and F-2700 Series Vortex Meter Sizing Program. Written for Onicon Incorporated',
  COPYRIGHT:
    'Copyright 2005-2024 Vortek Instruments, LLC.\nAll rights reserved.\nNo warranty is expressed or implied about the accuracy of calculations used in this program.',

  // This is the Onicon Incorporated information
  VORTEK_DISPLAY_NAME: 'Onicon Incorporated',
  VORTEK_PRINT_NAME: 'Onicon Incorporated',
  VORTEK_NAME: 'Onicon Incorporated',
  VORTEK_ADDRESS0: '11451 Belcher Road South',
  VORTEK_ADDRESS1: '',
  VORTEK_CITY: 'Largo',
  VORTEK_STATE: 'Florida',
  VORTEK_POSTAL_CODE: '33773',
  VORTEK_COUNTRY: 'USA',
  VORTEK_TELEPHONE: 'Telephone: 727-447-6140',
  VORTEK_FAX: 'FAX: 727-442-5699',
};

export default OniconSizingGlobals;
