import { CallbackNoParams } from '../../types/callbackTypes';
import application from '../../utilities/application';
import Modal from '../common/Modal';

const UploadApplication = (props: { onClose: CallbackNoParams }): JSX.Element | null => {
  const { onClose } = props;

  return (
    <Modal title="Upload Application" visible showButtons={false} showCloseButton onClose={onClose}>
      <div className="flexColNoGrow modalContent">
        <p className="flexTextRowCenter">Choose the file to upload</p>
        <div className="flexTextRowCenter">
          <input
            type="file"
            name="file"
            data-url="./api/v1/upload?vfiletype=app"
            className="topBottom20 width45"
            onChange={(e) => {
              // HTML5 local file read
              if (window.File && window.FileReader) {
                const f = e?.target?.files ? e.target.files[0] : null;
                const fr = new FileReader();

                fr.onload = (ev2) => {
                  let appName = f?.name || '';
                  const index = appName?.lastIndexOf('.');
                  if (index && index > -1) {
                    appName = appName?.substring(0, index);
                  }

                  let data = ev2?.target?.result as unknown as string;
                  data = data.replace(/\\/g, '');

                  application.newApplication('yes');
                  application.parseApplication(appName, data);
                  onClose();
                };

                if (f) {
                  fr.readAsText(f);
                }
              } else {
                // // HTML5 file read not supported, revert to saving to server
                // appFileUpload.fileupload({
                //   type: 'post',
                //   dataType: 'json',
                //   autoUpload: true,
                //   formData: {
                //     client: vortek.constants.VERSION.VORTEK_NAME,
                //     username: vortek.vars.username,
                //   },
                //   done: function (e, data) {
                //     vortek.vortekToolbar.data.msg_title('Application Saved.', 'Application Saved');
                //   },
                // });
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UploadApplication;
