// Defaults for displaying data and handling
// conditions for calculating meter data
export default class VortekDefaultUnits {
  densityUnit: string;
  viscosityUnit: string;
  velocityUnit: string;
  timeUnit: string;
  pressureDropUnit: string;
  // baroPressValue: Decimal;
  baroPressUnit: string;
  outputPressureDropUnit: string;

  constructor() {
    this.densityUnit = '';
    this.viscosityUnit = '';
    this.velocityUnit = '';
    this.timeUnit = '';
    this.pressureDropUnit = '';
    // baroPressValue: Decimal;
    this.baroPressUnit = '';
    this.outputPressureDropUnit = 'psi';
  }
}
