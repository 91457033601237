import SizingGlobals from '../../types/sizingGlobals';

const HeinrichsSizingGlobals: SizingGlobals = {
  goodUsableColor: '#003300',
  reducing: false,
  turbine: false,
  vorcone: false,
  ultrasonic: false,
  s36: false,
  u44: false,
  proM: false,
  VorconeModel: 'MVC VorCone',
  VorconeReduceModel: 'MVCR Reducing VorCone',
  VorconeModelStr: 'MVC VorCone',
  VorconeReduceModelStr: 'MVCR Reducing VorCone',
  InlineReduceModel: 'DVRR',
  InlineNonReduceModel: 'DVR',
  InlineModel: 'DVH',
  InlineModelPrev: '',
  InsertionModel: 'DVE',
  InsertionModelPrev: '',
  TurbineModel: '',
  TurbineModelShort: '',
  InlineModelStr: 'Heinrichs Model DVH',
  InlineNonReduceModelStr: 'Heinrichs Model DVR',
  InlineReduceModelStr: 'Heinrichs Model DVH',
  InsertionModelStr: 'Heinrichs Model DVE',
  UltrasonicS34Model: 'S34',
  UltrasonicS34ModelStr: 'S34 Ultrasonic',
  UltrasonicS36Model: 'S36',
  UltrasonicS36ModelStr: 'S36 Ultrasonic',
  UltrasonicU42Model: 'U42',
  UltrasonicU42ModelStr: 'U42 Ultrasonic',
  UltrasonicU43Model: 'U43',
  UltrasonicU43ModelStr: 'U43 Ultrasonic',
  UltrasonicU44Model: 'U44',
  UltrasonicU44ModelStr: 'U44 Ultrasonic',
  ElectromagneticProMModel: 'Pro-M',
  ElectromagneticProMModelStr: 'Electromagnetic Flow Meter',
  ProductLineStr: 'Heinrichs',
  HelpMenuStr: 'Heinrichs Sizing Help...',
  STANDARD_TEMP: 'S',
  HIGH_TEMP: 'H',
  WAFER: '1234567',
  ANSI_150: '150',
  ANSI_300: '300',
  ANSI_600: '600',
  ANSI_900: '',
  DN_PN16: '16',
  DN_PN40: '40',
  DN_PN64: '64',
  DN_PN100: '100',
  JIS_10K_J1: 'J10',
  JIS_16K_K: 'J16',
  JIS_20K_J2: 'J20',
  JIS_30K_J3: 'J30',
  JIS_40K_J4: 'J40',
  JIS_10K_Y1: 'Y10',
  JIS_20K_Y2: 'Y20',
  LocalStr: 'L',
  RemoteStr: '',
  RemoteStr1: '',
  RemoteStr2: '',
  RemoteStr3: '',
  RemoteStr4: '',
  CNPT: 'PA',
  C150: 'PB',
  C16: 'PC',
  CJ10: '',
  CFJ1K: '',
  C300: 'PD',
  C40: 'PE',
  CJ20: '',
  CFJ2K: '',
  C600: 'PF',
  C64: 'PG',
  CJ30: '',
  CFJ3K: 'CFJ3K',
  C900: '',
  CFJ4K: '',
  PNPT: 'PH',
  P150: 'PI',
  P16: 'PJ',
  PJ10: '',
  PGJ1K: '',
  P300: 'PK',
  P40: 'PL',
  PJ20: '',
  PJ30: '',
  PGJ2K: '',
  PGJ3K: 'PGJ3K',
  PGJ4K: 'PGJ4K',
  PNPTR: 'PM',
  PNPTRE: '',
  P150R: 'PN',
  P150RE: '',
  P16R: 'PO',
  P16RE: '',
  PJ10R: '',
  PGJ1R: '',
  P300R: 'PP',
  P300RE: '',
  P40R: 'PQ',
  P40RE: '',
  PJ20R: '',
  PGJ2R: '',
  P600R: 'PR',
  P600RE: '',
  P600: '',
  P64R: 'PS',
  P64RE: '',
  PJ30R: '',
  P64: '',
  PGJ3R: 'PGJ3R',
  PGJ4R: '',
  ENPTR: 'PT',
  E5016R: 'PU',
  E5040R: 'PV',
  E5064R: 'PW',
  E150R: 'PY',
  E300R: 'PZ',
  E600R: 'P1',
  StandardLength: 'S',
  CompactLength: 'C',
  ExtendedLength: 'E',
  V_METER: 'V',
  VT_METER: 'T',
  VTP_METER: 'P',
  VTP_METER_P1: '',
  VTP_METER_P2: '',
  VTP_METER_P3: '',
  VTP_METER_P4: '',
  VTEP_METER: 'VTEP',
  VETEP_METER: '',
  VTEM_METER: 'E',
  VTPEM_METER: 'M',
  VTEPEM_METER: '',
  VETEPEM_METER: '',
  LOOP_OUTPUT: '2',
  LOOP_POWERED: 'L',
  VDC_1236_POWERED: 'D',
  AC_POWERED: 'A',
  SPECIAL_STUFF: 'X',
  POE: 'DCHPOE',
  ONE_OUTPUT: 'H',
  NO_ALARM_MODBUS_OUTPUT: 'M',
  NO_ALARM_MODBUS_POE: 'MIP',
  ALARM_MODBUS: '4',
  ALARM_MODBUS_POE: '4IP',
  BACNET: '',
  BACNET_POE: 'IP',
  ALARM_BACNET: '',
  ALARM_BACNET_POE: 'IP',
  ALARM_HART: '3',
  MORE_LOOP_POWERED: [],
  INLINE_NON_LOOP_ORDER: [],
  INSERTION_NON_LOOP_ORDER: [],
  NO_PRESSURE_TRANSDUCER: '0',
  DIFF_PRESS_XMTR_FACT: '',
  DIFF_PRESS_XMTR_CUST: '',
  DIAG_ADD_PRESS_TAP_FT: '',

  CODE_NAME: 'Heinrichs',
  CODE_DESCRIPTION: 'Vortex Flow Meter',
  TURBINE_CODE_NAME: 'Heinrichs',
  TURBINE_CODE_DESCRIPTION: 'Turbine Flow Meter',
  DATA_SHEET: 'APPLICATION DATA SHEET',
  DESCRIPTION: 'Heinrichs Model DVH and Model DVE Vortex Meter Sizing Program. Written for Heinrichs',
  COPYRIGHT:
    'Copyright 2007-2024, VorTek Instruments, LLC\nAll rights reserved.\nCopy and Distribute freely.\nWarning: No warranty is expressed or implied about the accuracy of the calculations used in this program.',

  // This is the Heinrichs information.
  VORTEK_DISPLAY_NAME: 'Heinrichs Messtechnik GmbH',
  VORTEK_PRINT_NAME: 'Heinrichs Messtechnik GmbH',
  VORTEK_NAME: 'Heinrichs Messtechnik GmbH',
  VORTEK_ADDRESS0: 'Robert-Perthel-Stra&szlig;e 9',
  VORTEK_ADDRESS1: '50739 Cologne',
  VORTEK_CITY: '',
  VORTEK_STATE: '',
  VORTEK_POSTAL_CODE: '',
  VORTEK_COUNTRY: 'Germany',
  VORTEK_TELEPHONE: 'Tel: +49-221-49708-0',
  VORTEK_FAX: 'Fax: +49-221-49708-178',
};

export default HeinrichsSizingGlobals;
