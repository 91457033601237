import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Decimal from 'decimal.js';
import _ from 'lodash';
import { RootState } from '../../store';
import utilities from '../../utilities';
import meterSpecify from '../../utilities/meterSpecify';
import Feature from '../../types/Feature';
import actions from '../../store/actions';
import calcTurbineMeter from '../../utilities/calc/calcTurbineMeter';
import meterValidate from '../../utilities/meterValidate';
import PrintMeterInfo from '../PrintMeter';
import pipeUtils from '../../utilities/pipeUtils';

const ModelCodeTab = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const state = useSelector((globalState: RootState) => globalState);
  const {
    meterIndex,
    meterData,
    meterType,
    modelCode,
    oniconSpc,
    provSizingGlobals,
    sizingGlobals,
    specifyMeterAccuracy,
    specifyMeterApprovals,
    specifyMeterCableLength,
    specifyMeterCommunications,
    specifyMeterElectrodeMaterial,
    specifyMeterGrounding,
    specifyMeterDiagAddPressTap,
    specifyMeterDiagDiffPressXMTR,
    specifyMeterDiffPressXMTR,
    specifyMeterDiffPressXMTRMani,
    specifyMeterDisplayOption,
    specifyMeterElectronicsMounting,
    specifyMeterEnergyMeter,
    specifyMeterFaceToFace,
    specifyMeterFlange,
    specifyMeterInputPower,
    specifyMeterOutputSignal,
    specifyMeterParentModelNumber,
    specifyMeterLinerMaterial,
    specifyMeterPipeMaterial,
    specifyMeterPipeSize,
    specifyMeterProbeLength,
    specifyMeterProcessConnection,
    specifyMeterProcessFluid,
    specifyMeterProcessTemperature,
    specifyMeterRotor,
    specifyMeterTagNumber,
    specifyMeterTransducer,
    specifyMeterTransducerBracket,
  } = state;
  const {
    CompactLength,
    InlineModel,
    InlineModelStr,
    InlineNonReduceModel,
    InlineNonReduceModelStr,
    InlineReduceModel,
    InlineReduceModelStr,
    InsertionModel,
    InsertionModelStr,
    LocalStr,
    ProductLineStr,
    TurbineModel,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    ElectromagneticProMModel,
    ElectromagneticProMModelStr,
    VorconeModel,
    VorconeModelStr,
    VorconeReduceModel,
    VorconeReduceModelStr,
    VORTEK_NAME,
  } = sizingGlobals;

  const [shownWarning, setShownWarning] = useState(false);
  const [showPrint, setShowPrint] = useState(false);

  const selectedMeter = meterData[meterIndex];

  const updateTagNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const tag = e?.target?.value;
    if (tag) {
      // eslint-disable-next-line no-control-regex
      const tagMatch = tag?.match(/[^\u0020-\u007e]+/g);
      if (tagMatch) {
        dispatch(
          actions.setErrorMessage(
            'Please enter the tag number with numbers and English. No extended latin or special characters are allowed.'
          )
        );
        return;
      }
    }

    dispatch(actions.setSpecifyMeterTagNumber(tag));
  };

  const getParentModelNumberText = (): string => {
    if (meterType === 'turbine') {
      return TurbineModel;
    }

    if (meterType === 'vorcone') {
      return VorconeModelStr;
    }

    if (meterType === 'reduce_vorcone') {
      return VorconeReduceModelStr;
    }

    if (meterType === 'reduce_vortex') {
      return InlineReduceModelStr;
    }

    if (VORTEK_NAME === utilities.ONICON_NAME) {
      if (selectedMeter.type === 'In-line') {
        return `${ProductLineStr} ${InlineModelStr}`;
      }

      return `${ProductLineStr} ${InsertionModelStr}`;
    }

    if (VORTEK_NAME === utilities.ARMSTRONG_NAME) {
      if (selectedMeter.type === 'In-line') {
        return `${ProductLineStr} Model ${InlineModel}`;
      }

      return `${ProductLineStr} Model ${InsertionModel}`;
    }

    if (VORTEK_NAME === utilities.AZBIL_NAME) {
      if (selectedMeter.type === 'In-line' && meterType === 'vortex') {
        return `${ProductLineStr} Model ${InlineModel}`;
      }

      if (selectedMeter.type === 'In-line Reducing' && meterType === 'vortex') {
        return `${ProductLineStr} Model ${InlineReduceModel}`;
      }

      if (selectedMeter.type === 'In-line' && meterType === 'nonreduce_vortex') {
        return `${ProductLineStr} Model ${InlineNonReduceModel}`;
      }

      return `${ProductLineStr} Model ${InsertionModel}`;
    }

    if (
      meterType === UltrasonicS34Model ||
      meterType === UltrasonicS36Model ||
      meterType === UltrasonicU42Model ||
      meterType === UltrasonicU43Model ||
      meterType === UltrasonicU44Model
    ) {
      return `SONOPRO Model ${meterType}`;
    }

    if (meterType === ElectromagneticProMModel) {
      return ElectromagneticProMModelStr;
    }

    if (meterType === 'nonreduce_vortex') {
      return InlineNonReduceModelStr;
    }

    if (selectedMeter.type === 'In-line') {
      return InlineModelStr;
    }

    return InsertionModelStr;
  };

  const getParentModelNumber = () => {
    if (meterType === 'turbine') {
      return TurbineModelShort;
    }

    if (meterType === 'vorcone') {
      return VorconeModel;
    }

    if (meterType === 'reduce_vorcone') {
      return VorconeReduceModel;
    }

    if (meterType === 'reduce_vortex') {
      return InlineReduceModel;
    }

    if (
      meterType === UltrasonicS34Model ||
      meterType === UltrasonicS36Model ||
      meterType === UltrasonicU42Model ||
      meterType === UltrasonicU43Model ||
      meterType === UltrasonicU44Model ||
      meterType === ElectromagneticProMModel
    ) {
      return meterType;
    }

    if (meterType === 'nonreduce_vortex') {
      return InlineNonReduceModel;
    }

    if (selectedMeter.type === 'In-line') {
      return InlineModel;
    }

    return InsertionModel;
  };

  // Extra rows are in the database for some types of Features, but not used by others
  const checkValidItem = (list: Feature[], item: Feature): boolean => {
    return !list.find((li) => li.Code === item.Code);
  };

  const translateToDisplayName = (s: string): string => {
    const match = s.match(/^([A-Z][a-z]+)([A-Z][a-z]+)?([A-Z]+|[A-Z][a-z]+)?([A-Z]+|[A-Z][a-z]+)*$/);
    let result = s;
    if (match) {
      if (match[1] !== undefined) {
        // eslint-disable-next-line
        result = match[1];

        if (match[2] !== undefined) {
          result += ` ${match[2]}`;
        }

        if (match[3] !== undefined) {
          result += ` ${match[3]}`;
        }

        if (match[4] !== undefined) {
          result += ` ${match[4]}`;
        }
      }
    }

    if (result === 'Display') {
      result = 'Display Option';
    }

    if (VORTEK_NAME === utilities.ONICON_NAME) {
      if (result === 'Size') {
        result = 'Meter Size';
      } else if (result === 'Flange') {
        result = 'Connection Type';
      } else if (result === 'Process Fluid') {
        result = 'Temp. / Press. Comp';
      } else if (result === 'Process Temperature') {
        result = 'Max. Operating Temp.';
      } else if (result === 'Process Connection') {
        result = 'Connection Type';
      }
    }

    if (VORTEK_NAME === utilities.HEINRICH_NAME) {
      if (result === 'Size') {
        result = 'Flow Body';
      } else if (result === 'Material') {
        result = 'Material Flow Body';
      } else if (result === 'Flange') {
        result = 'Process Connection';
      }
    }

    return result;
  };

  const setListValue = (featureList: Feature[]): void => {
    let featureSize;
    let sizeValue;
    switch (featureList[0].Name) {
      case 'FlowBody':
        featureSize = featureList.find((sizeItem: Feature) => {
          return sizeItem.Description === pipeUtils.mapInlineToElectromagneticPipeSize(selectedMeter.newPipeData.pipeSize);
        });
        sizeValue = featureSize?.OrderCode || '';
        dispatch(actions.setSpecifyMeterPipeSize(sizeValue));
        break;

      case 'Size':
        featureSize = featureList.find((sizeItem: Feature) => {
          return sizeItem.Description === selectedMeter.newPipeData.pipeSize;
        });
        sizeValue = featureSize?.OrderCode || '';
        dispatch(actions.setSpecifyMeterPipeSize(sizeValue));
        break;

      default:
        break;
    }
  };

  const setValue = (feature: Feature, featureList: Feature[] = []): string => {
    let value = '';
    let featureItem;

    switch (feature.Name) {
      case 'Accuracy':
        if (!specifyMeterAccuracy) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterAccuracy(value));
        }
        break;

      case 'Approvals':
        if (!specifyMeterApprovals) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterApprovals(value));
        }
        break;

      case 'CableLength':
        if (!specifyMeterCableLength) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterCableLength(value));
        }
        break;

      case 'Communications':
        if (!specifyMeterCommunications) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterCommunications(value));
        }
        break;

      case 'DiagAddPressTap':
        if (!specifyMeterDiagAddPressTap) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterDiagAddPressTap(value));
        }
        break;

      case 'DiagDiffPressXMTR':
        if (!specifyMeterDiagDiffPressXMTR) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterDiagDiffPressXMTR(value));
        }
        break;

      case 'DiffPressXMTR':
        if (!specifyMeterDiffPressXMTR) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterDiffPressXMTR(value));
        }
        break;

      case 'DiffPressXMTRMani':
        if (!specifyMeterDiffPressXMTRMani) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterDiffPressXMTRMani(value));
        }
        break;

      case 'Display':
        if (!specifyMeterDisplayOption) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterDisplayOption(value));
        }
        break;

      case 'ElectrodeMaterial':
        if (!specifyMeterElectrodeMaterial) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterElectrodeMaterial(value));
        }
        break;

      case 'ElectronicsMounting':
      case 'ElectronicsEnclosure':
        if (!specifyMeterElectronicsMounting) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterElectronicsMounting(value));
        }
        break;

      case 'EnergyMeter':
        if (!specifyMeterEnergyMeter) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterEnergyMeter(value));
        }
        break;

      case 'FaceToFace':
        if (!specifyMeterFaceToFace) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterFaceToFace(value));
        }
        break;

      case 'Flange':
        if (!specifyMeterFlange) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterFlange(value));
        }
        break;

      case 'Grounding':
        if (!specifyMeterGrounding) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterGrounding(value));
        }
        break;

      case 'InputPower':
      case 'PowerSupply':
        if (!specifyMeterInputPower) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterInputPower(value));
        }
        break;

      case 'LinerMaterial':
        if (!specifyMeterLinerMaterial) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterLinerMaterial(value));
        }
        break;

      case 'Material':
      case 'MeterBodyMaterial':
        if (!specifyMeterPipeMaterial) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterPipeMaterial(value));
        }
        break;

      case 'Output':
      case 'OutputSignal':
        if (!specifyMeterOutputSignal) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterOutputSignal(value));
        }
        break;

      case 'ParentModelNumber':
        if (!specifyMeterParentModelNumber) {
          value = getParentModelNumber();
          dispatch(actions.setSpecifyMeterParentModelNumber(value));
        }
        break;

      case 'ProbeLength':
        if (!specifyMeterProbeLength) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterProbeLength(value));
        }
        break;

      case 'ProcessConnection':
        if (!specifyMeterProcessConnection) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterProcessConnection(value));
        }
        break;

      case 'ProcessFluid':
      case 'Model':
        if (!specifyMeterProcessFluid) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterProcessFluid(value));
        }
        break;

      case 'ProcessTemperature':
        if (!specifyMeterProcessTemperature) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterProcessTemperature(value));
        }
        break;

      case 'Rotor':
        if (!specifyMeterRotor) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterRotor(value));
        }
        break;

      case 'FlowBody':
      case 'Size':
        featureItem = featureList.find((sizeItem: Feature) => {
          return sizeItem.Description === selectedMeter.name;
        });
        value = featureItem?.OrderCode || '';
        dispatch(actions.setSpecifyMeterPipeSize(value));
        break;

      case 'SPC':
        if (!oniconSpc) {
          value = feature.OrderCode;
          dispatch(actions.setOniconSpc(value));
        }
        break;

      case 'Transducer':
      case 'PressureTransducer':
        if (!specifyMeterTransducer) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterTransducer(value));
        }
        break;

      case 'TransducerBracket':
        if (!specifyMeterTransducerBracket) {
          value = feature.OrderCode;
          dispatch(actions.setSpecifyMeterTransducerBracket(value));
        }
        break;

      default:
        break;
    }

    return value;
  };

  const getValue = (feature: Feature): string => {
    switch (feature.Name) {
      case 'Accuracy':
        return specifyMeterAccuracy;

      case 'Approvals':
        return specifyMeterApprovals;

      case 'CableLength':
        return specifyMeterCableLength;

      case 'Communications':
        return specifyMeterCommunications;

      case 'DiagAddPressTap':
        return specifyMeterDiagAddPressTap;

      case 'DiagDiffPressXMTR':
        return specifyMeterDiagDiffPressXMTR;

      case 'DiffPressXMTR':
        return specifyMeterDiffPressXMTR;

      case 'DiffPressXMTRMani':
        return specifyMeterDiffPressXMTRMani;

      case 'Display':
        return specifyMeterDisplayOption;

      case 'ElectrodeMaterial':
        return specifyMeterElectrodeMaterial;

      case 'ElectronicsMounting':
      case 'ElectronicsEnclosure':
        return specifyMeterElectronicsMounting;

      case 'EnergyMeter':
        return specifyMeterEnergyMeter;

      case 'FaceToFace':
        return specifyMeterFaceToFace;

      case 'Flange':
        return specifyMeterFlange;

      case 'Grounding':
        return specifyMeterGrounding;

      case 'InputPower':
      case 'PowerSupply':
        return specifyMeterInputPower;

      case 'LinerMaterial':
        return specifyMeterLinerMaterial;

      case 'Material':
      case 'MeterBodyMaterial':
        return specifyMeterPipeMaterial;

      case 'Output':
      case 'OutputSignal':
        return specifyMeterOutputSignal;

      case 'ParentModelNumber':
        return specifyMeterParentModelNumber;

      case 'ProbeLength':
        return specifyMeterProbeLength;

      case 'ProcessConnection':
        return specifyMeterProcessConnection;

      case 'ProcessFluid':
      case 'Model':
        return specifyMeterProcessFluid;

      case 'ProcessTemperature':
        return specifyMeterProcessTemperature;

      case 'Rotor':
        return specifyMeterRotor;

      case 'FlowBody':
      case 'Size':
        return specifyMeterPipeSize;

      case 'SPC':
        return oniconSpc as string;

      case 'Transducer':
      case 'PressureTransducer':
        return specifyMeterTransducer;

      case 'TransducerBracket':
        return specifyMeterTransducerBracket;

      default:
        break;
    }

    return '';
  };

  const onChangeHandler = useCallback((e) => {
    if (e) {
      // eslint-disable-next-line
      const value = e.target.value;
      // eslint-disable-next-line
      let name = e.target.name;
      if (name.startsWith('input')) {
        name = name.substring(5);
      }

      switch (name) {
        case 'Accuracy':
          dispatch(actions.setSpecifyMeterAccuracy(value));
          break;

        case 'Approvals':
          dispatch(actions.setSpecifyMeterApprovals(value));
          break;

        case 'CableLength':
          dispatch(actions.setSpecifyMeterCableLength(value));
          break;

        case 'Communications':
          dispatch(actions.setSpecifyMeterCommunications(value));
          break;

        case 'DiagAddPressTap':
          dispatch(actions.setSpecifyMeterDiagAddPressTap(value));
          break;

        case 'DiagDiffPressXMTR':
          dispatch(actions.setSpecifyMeterDiagDiffPressXMTR(value));
          break;

        case 'DiffPressXMTR':
          dispatch(actions.setSpecifyMeterDiffPressXMTR(value));
          break;

        case 'DiffPressXMTRMani':
          dispatch(actions.setSpecifyMeterDiffPressXMTRMani(value));
          break;

        case 'Display':
          dispatch(actions.setSpecifyMeterDisplayOption(value));
          break;

        case 'ElectrodeMaterial':
          dispatch(actions.setSpecifyMeterElectrodeMaterial(value));
          break;

        case 'ElectronicsMounting':
        case 'ElectronicsEnclosure':
          dispatch(actions.setSpecifyMeterElectronicsMounting(value));
          break;

        case 'EnergyMeter':
          dispatch(actions.setSpecifyMeterEnergyMeter(value));
          break;

        case 'FaceToFace':
          dispatch(actions.setSpecifyMeterFaceToFace(value));
          break;

        case 'Flange':
          dispatch(actions.setSpecifyMeterFlange(value));
          break;

        case 'Grounding':
          dispatch(actions.setSpecifyMeterGrounding(value));
          break;

        case 'InputPower':
        case 'PowerSupply':
          dispatch(actions.setSpecifyMeterInputPower(value));
          break;

        case 'LinerMaterial':
          dispatch(actions.setSpecifyMeterLinerMaterial(value));
          break;

        case 'Material':
        case 'MeterBodyMaterial':
          dispatch(actions.setSpecifyMeterPipeMaterial(value));
          break;

        case 'Output':
        case 'OutputSignal':
          dispatch(actions.setSpecifyMeterOutputSignal(value));
          break;

        case 'ParentModelNumber':
          dispatch(actions.setSpecifyMeterParentModelNumber(value));
          break;

        case 'ProbeLength':
          dispatch(actions.setSpecifyMeterProbeLength(value));
          break;

        case 'ProcessConnection':
          dispatch(actions.setSpecifyMeterProcessConnection(value));
          break;

        case 'ProcessFluid':
        case 'Model':
          dispatch(actions.setSpecifyMeterProcessFluid(value));
          break;

        case 'ProcessTemperature':
          dispatch(actions.setSpecifyMeterProcessTemperature(value));
          break;

        case 'Rotor':
          dispatch(actions.setSpecifyMeterRotor(value));
          break;

        case 'FlowBody':
        case 'Size':
          dispatch(actions.setSpecifyMeterPipeSize(value));
          break;

        case 'SPC':
          dispatch(actions.setOniconSpc(value));
          break;

        case 'Transducer':
        case 'PressureTransducer':
          dispatch(actions.setSpecifyMeterTransducer(value));
          break;

        case 'TransducerBracket':
          dispatch(actions.setSpecifyMeterTransducerBracket(value));
          break;

        default:
          break;
      }

      dispatch(actions.setSpecifyMeterWarnings(meterValidate.validateAll()));
      meterSpecify.createModelCode();
    }
  }, []);

  const getMappedLists = () => {
    const mappedLists = [] as any[][];
    const finalMappedLists = [] as any[][];
    const obList = meterSpecify.getFeatureList();
    if (obList.length) {
      let currentFeature = obList[0].FeatureNumber;
      const parentModelIndex = 0;
      let processFluidIndex = 0;
      let sizeIndex = 0;
      let linerIndex = 0;
      let materialIndex = 0;
      let flangeIndex = 0;
      let groundingIndex = 0;
      let elecMountIndex = 0;
      let electrodeMaterialIndex = 0;
      let displayIndex = 0;
      let inputPowerIndex = 0;
      let outputSignalIndex = 0;
      let processTemperatureIndex = 0;
      let transducerIndex = 0;
      let transducerBracketIndex = 0;
      let probeLengthIndex = 0;
      let processConnectionIndex = 0;
      let rotorIndex = 0;
      let cableLengthIndex = 0;
      let communicationsIndex = 0;
      let accuracyIndex = 0;
      let approvalsIndex = 0;
      let faceToFaceIndex = 0;
      let energyMeterIndex = 0;
      let diagAddPressTapIndex = 0;
      let diagDiffPressXMTRIndex = 0;
      let diffPressXMTRIndex = 0;
      let diffPressXMTRManiIndex = 0;
      let spcIndex = 0;
      let mappedList = [] as Feature[];
      const workingList = [] as Feature[];

      obList.forEach((item) => {
        if (item.FeatureNumber === currentFeature) {
          if (checkValidItem(mappedList, item)) {
            mappedList.push(item);
          }
        } else {
          currentFeature = item.FeatureNumber;

          if (checkValidItem(mappedList, item)) {
            mappedLists.push(mappedList);

            if ((item.Name === 'ProcessFluid' || item.Name === 'Model') && processFluidIndex === 0) {
              processFluidIndex = mappedLists.length;
            } else if ((item.Name === 'Size' || item.Name === 'FlowBody') && sizeIndex === 0) {
              sizeIndex = mappedLists.length;
            } else if (item.Name === 'LinerMaterial' && linerIndex === 0) {
              linerIndex = mappedLists.length;
            } else if ((item.Name === 'Material' || item.Name === 'MeterBodyMaterial') && materialIndex === 0) {
              materialIndex = mappedLists.length;
            } else if (item.Name === 'Flange' && flangeIndex === 0) {
              flangeIndex = mappedLists.length;
            } else if (item.Name === 'Grounding' && groundingIndex === 0) {
              groundingIndex = mappedLists.length;
            } else if ((item.Name === 'ElectronicsMounting' || item.Name === 'ElectronicsEnclosure') && elecMountIndex === 0) {
              elecMountIndex = mappedLists.length;
            } else if (item.Name === 'ElectrodeMaterial' && electrodeMaterialIndex === 0) {
              electrodeMaterialIndex = mappedLists.length;
            } else if (item.Name === 'Display' && displayIndex === 0) {
              displayIndex = mappedLists.length;
            } else if ((item.Name === 'InputPower' || item.Name === 'PowerSupply') && inputPowerIndex === 0) {
              inputPowerIndex = mappedLists.length;
            } else if ((item.Name === 'Output' || item.Name === 'OutputSignal') && outputSignalIndex === 0) {
              outputSignalIndex = mappedLists.length;
            } else if (item.Name === 'ProcessTemperature' && processTemperatureIndex === 0) {
              processTemperatureIndex = mappedLists.length;
            } else if ((item.Name === 'Transducer' || item.Name === 'PressureTransducer') && transducerIndex === 0) {
              transducerIndex = mappedLists.length;
            } else if (item.Name === 'TransducerBracket' && transducerBracketIndex === 0) {
              transducerBracketIndex = mappedLists.length;
            } else if (item.Name === 'ProbeLength' && probeLengthIndex === 0) {
              probeLengthIndex = mappedLists.length;
            } else if (item.Name === 'ProcessConnection' && processConnectionIndex === 0) {
              processConnectionIndex = mappedLists.length;
            } else if (item.Name === 'Rotor' && rotorIndex === 0) {
              rotorIndex = mappedLists.length;
            } else if (item.Name === 'CableLength' && cableLengthIndex === 0) {
              cableLengthIndex = mappedLists.length;
            } else if (item.Name === 'Communications' && communicationsIndex === 0) {
              communicationsIndex = mappedLists.length;
            } else if (item.Name === 'Accuracy' && accuracyIndex === 0) {
              accuracyIndex = mappedLists.length;
            } else if (item.Name === 'Approvals' && approvalsIndex === 0) {
              approvalsIndex = mappedLists.length;
            } else if (item.Name === 'FaceToFace' && faceToFaceIndex === 0) {
              faceToFaceIndex = mappedLists.length;
            } else if (item.Name === 'EnergyMeter' && energyMeterIndex === 0) {
              energyMeterIndex = mappedLists.length;
            } else if (item.Name === 'DiagAddPressTap' && diagAddPressTapIndex === 0) {
              diagAddPressTapIndex = mappedLists.length;
            } else if (item.Name === 'DiagDiffPressXMTR' && diagDiffPressXMTRIndex === 0) {
              diagDiffPressXMTRIndex = mappedLists.length;
            } else if (item.Name === 'DiffPressXMTR' && diffPressXMTRIndex === 0) {
              diffPressXMTRIndex = mappedLists.length;
            } else if (item.Name === 'DiffPressXMTRMani' && diffPressXMTRManiIndex === 0) {
              diffPressXMTRManiIndex = mappedLists.length;
            } else if (item.Name === 'SPC' && spcIndex === 0) {
              spcIndex = mappedLists.length;
            }

            mappedList = [] as Feature[];
            mappedList.push(item);
          }
        }
      });

      if (mappedList.length) {
        mappedLists.push(mappedList);
      }

      let sizeMaterial;

      finalMappedLists.push(mappedLists[parentModelIndex]);

      if (
        VORTEK_NAME === utilities.PROV_NAME ||
        VORTEK_NAME === utilities.SPIRAX_NAME ||
        VORTEK_NAME === utilities.GESTRA_NAME ||
        VORTEK_NAME === utilities.PANAFLOW_NAME ||
        VORTEK_NAME === utilities.INNOVA_NAME ||
        VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
      ) {
        if (
          (meterType === 'vortex' ||
            meterType === 'reduce_vortex' ||
            meterType === 'nonreduce_vortex' ||
            meterType === 'vorcone' ||
            meterType === 'reduce_vorcone') &&
          (selectedMeter.type === 'In-line' || selectedMeter.type === 'In-line Reducing')
        ) {
          finalMappedLists.push(mappedLists[processFluidIndex]);
          sizeMaterial = [] as Feature[][];
          // copy size into new array
          sizeMaterial.push(mappedLists[sizeIndex]);
          // copy material into new array
          sizeMaterial.push(mappedLists[materialIndex]);
          finalMappedLists.push(sizeMaterial);
          finalMappedLists.push(mappedLists[flangeIndex]);
          finalMappedLists.push(mappedLists[elecMountIndex]);
          finalMappedLists.push(mappedLists[displayIndex]);
          finalMappedLists.push(mappedLists[inputPowerIndex]);
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[processTemperatureIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
          if (meterType === 'vorcone' || meterType === 'reduce_vorcone') {
            finalMappedLists.push(mappedLists[diffPressXMTRIndex]);
            finalMappedLists.push(mappedLists[diffPressXMTRManiIndex]);
            finalMappedLists.push(mappedLists[diagDiffPressXMTRIndex]);
            finalMappedLists.push(mappedLists[diagAddPressTapIndex]);
          }

          if (VORTEK_NAME === utilities.SPIRAX_NAME && meterType !== 'vorcone' && meterType !== 'reduce_vorcone') {
            finalMappedLists.push(mappedLists[approvalsIndex]);
            finalMappedLists.push(mappedLists[faceToFaceIndex]);
          }
        } else if (selectedMeter.type === 'Insertion' || meterType === 'turbine') {
          finalMappedLists.push(mappedLists[processFluidIndex]);
          finalMappedLists.push(mappedLists[probeLengthIndex]);
          finalMappedLists.push(mappedLists[elecMountIndex]);
          finalMappedLists.push(mappedLists[displayIndex]);
          finalMappedLists.push(mappedLists[inputPowerIndex]);
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[processTemperatureIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
          finalMappedLists.push(mappedLists[processConnectionIndex]);

          if (VORTEK_NAME === utilities.SPIRAX_NAME) {
            finalMappedLists.push(mappedLists[approvalsIndex]);
          }
        } else if (meterType === UltrasonicS34Model) {
          finalMappedLists.push(mappedLists[processFluidIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
          finalMappedLists.push(mappedLists[cableLengthIndex]);
          finalMappedLists.push(mappedLists[processTemperatureIndex]);
        } else if (meterType === UltrasonicS36Model) {
          finalMappedLists.push(mappedLists[processFluidIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
          finalMappedLists.push(mappedLists[cableLengthIndex]);
          finalMappedLists.push(mappedLists[communicationsIndex]);
        } else if (meterType === UltrasonicU42Model || meterType === UltrasonicU43Model) {
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
          finalMappedLists.push(mappedLists[cableLengthIndex]);
        } else if (meterType === UltrasonicU44Model) {
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
          finalMappedLists.push(mappedLists[transducerBracketIndex]);
          finalMappedLists.push(mappedLists[cableLengthIndex]);
        } else if (meterType === ElectromagneticProMModel) {
          finalMappedLists.push(mappedLists[processFluidIndex]);
          finalMappedLists.push(mappedLists[accuracyIndex]);
          sizeMaterial = [] as Feature[][];
          // copy size into new array
          sizeMaterial.push(mappedLists[sizeIndex]);
          // copy material into new array
          sizeMaterial.push(mappedLists[materialIndex]);
          finalMappedLists.push(sizeMaterial);
          finalMappedLists.push(mappedLists[processConnectionIndex]);
          finalMappedLists.push(mappedLists[elecMountIndex]);
          finalMappedLists.push(mappedLists[inputPowerIndex]);
          finalMappedLists.push(mappedLists[communicationsIndex]);
          finalMappedLists.push(mappedLists[electrodeMaterialIndex]);
          finalMappedLists.push(mappedLists[linerIndex]);
          finalMappedLists.push(mappedLists[groundingIndex]);
        }
      } else if (VORTEK_NAME === utilities.ARMSTRONG_NAME) {
        if (selectedMeter.type === 'In-line') {
          finalMappedLists.push(mappedLists[flangeIndex]);
          sizeMaterial = [] as Feature[][];
          // copy size into new array
          sizeMaterial.push(mappedLists[sizeIndex]);
          // copy material into new array
          sizeMaterial.push(mappedLists[materialIndex]);
          finalMappedLists.push(sizeMaterial);
          finalMappedLists.push(mappedLists[elecMountIndex]);
          finalMappedLists.push(mappedLists[processFluidIndex]);
          finalMappedLists.push(mappedLists[inputPowerIndex]);
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[processTemperatureIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
        } else {
          finalMappedLists.push(mappedLists[processConnectionIndex]);
          finalMappedLists.push(mappedLists[probeLengthIndex]);
          finalMappedLists.push(mappedLists[elecMountIndex]);
          finalMappedLists.push(mappedLists[processFluidIndex]);
          finalMappedLists.push(mappedLists[inputPowerIndex]);
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[processTemperatureIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
        }
      } else if (VORTEK_NAME === utilities.AZBIL_NAME) {
        if (selectedMeter.type === 'In-line' || selectedMeter.type === 'In-line Reducing') {
          finalMappedLists.push(mappedLists[processFluidIndex]);
          sizeMaterial = [] as Feature[][];
          // copy size into new array
          sizeMaterial.push(mappedLists[sizeIndex]);
          // copy material into new array
          sizeMaterial.push(mappedLists[materialIndex]);
          finalMappedLists.push(sizeMaterial);
          finalMappedLists.push(mappedLists[flangeIndex]);
          finalMappedLists.push(mappedLists[elecMountIndex]);
          finalMappedLists.push(mappedLists[displayIndex]);
          finalMappedLists.push(mappedLists[inputPowerIndex]);
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[processTemperatureIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
        } else if (selectedMeter.type === 'Insertion') {
          finalMappedLists.push(mappedLists[processFluidIndex]);
          finalMappedLists.push(mappedLists[probeLengthIndex]);
          finalMappedLists.push(mappedLists[elecMountIndex]);
          finalMappedLists.push(mappedLists[displayIndex]);
          finalMappedLists.push(mappedLists[inputPowerIndex]);
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[processTemperatureIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
          if (specifyMeterProbeLength === CompactLength) {
            mappedLists[processConnectionIndex].forEach((item: Feature) => {
              if (!item.OrderCode.startsWith('P')) {
                workingList.push(item);
              }
            });

            finalMappedLists.push(workingList);
          } else {
            finalMappedLists.push(mappedLists[processConnectionIndex]);
          }
        }
      } else if (VORTEK_NAME === utilities.ONICON_NAME) {
        if (selectedMeter.type === 'In-line' || selectedMeter.type === 'In-line Reducing') {
          finalMappedLists.push(mappedLists[sizeIndex]);
          finalMappedLists.push(mappedLists[flangeIndex]);
          finalMappedLists.push(mappedLists[elecMountIndex]);
          finalMappedLists.push(mappedLists[processFluidIndex]);
          finalMappedLists.push(mappedLists[inputPowerIndex]);
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[processTemperatureIndex]);
          finalMappedLists.push(mappedLists[energyMeterIndex]);
          if (selectedMeter.type === 'In-line Reducing') {
            finalMappedLists.push(mappedLists[spcIndex]);
          }
        } else if (selectedMeter.type === 'Insertion' || meterType === 'turbine') {
          finalMappedLists.push(mappedLists[processConnectionIndex]);
          finalMappedLists.push(mappedLists[elecMountIndex]);
          finalMappedLists.push(mappedLists[processFluidIndex]);

          if (meterType === 'turbine') {
            mappedList = [
              {
                Code: '9999999',
                FeatureNumber: '99',
                Name: 'Rotor',
                OrderCode: calcTurbineMeter.getOniconTurbineRotorModelCode(selectedMeter.name),
                Description: selectedMeter.name,
                Note: '',
              },
            ];
            finalMappedLists.push(mappedList);
          }

          finalMappedLists.push(mappedLists[inputPowerIndex]);
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[processTemperatureIndex]);
          finalMappedLists.push(mappedLists[energyMeterIndex]);
        }
      } else if (VORTEK_NAME === utilities.HEINRICH_NAME) {
        if (selectedMeter.type === 'In-line') {
          finalMappedLists.push(mappedLists[processFluidIndex]);
          finalMappedLists.push(mappedLists[sizeIndex]);
          finalMappedLists.push(mappedLists[flangeIndex]);
          finalMappedLists.push(mappedLists[materialIndex]);
          finalMappedLists.push(mappedLists[elecMountIndex]);
          finalMappedLists.push(mappedLists[inputPowerIndex]);
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[processTemperatureIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
        } else {
          finalMappedLists.push(mappedLists[processFluidIndex]);
          finalMappedLists.push(mappedLists[probeLengthIndex]);
          finalMappedLists.push(mappedLists[elecMountIndex]);
          finalMappedLists.push(mappedLists[inputPowerIndex]);
          finalMappedLists.push(mappedLists[outputSignalIndex]);
          finalMappedLists.push(mappedLists[processTemperatureIndex]);
          finalMappedLists.push(mappedLists[transducerIndex]);
          finalMappedLists.push(mappedLists[processConnectionIndex]);
        }
      }

      if (VORTEK_NAME !== utilities.ONICON_NAME && meterType === 'turbine') {
        mappedList = [
          {
            Code: '9999999',
            FeatureNumber: '99',
            Name: 'Rotor',
            OrderCode: selectedMeter.name,
            Description: 'Rotor',
            Note: '',
          },
        ];
        finalMappedLists.push(mappedList);
      }
    }

    return finalMappedLists;
  };

  const populateModelReduxValues = () => {
    const mappedLists = getMappedLists();
    mappedLists.forEach((list) => {
      if (list[0].Name !== undefined) {
        if (
          list[0].Name === 'Flange' &&
          (VORTEK_NAME === utilities.ONICON_NAME || VORTEK_NAME === utilities.AZBIL_NAME) &&
          (selectedMeter.type === 'In-line' || selectedMeter.type === 'In-line Reducing' || meterType === ElectromagneticProMModel)
        ) {
          setValue(
            list.find((item: Feature) => {
              return item.Description === 'ANSI 150 lb Flanged';
            }),
            list
          );
        } else if (
          list[0].Name === 'ProcessTemperature' &&
          (VORTEK_NAME === utilities.ONICON_NAME || VORTEK_NAME === utilities.AZBIL_NAME) &&
          (selectedMeter.type === 'In-line' || selectedMeter.type === 'In-line Reducing' || selectedMeter.type === 'Insertion')
        ) {
          setValue(
            list.find((item: Feature) => {
              return item.Description === 'Standard Temperature' || item.Description === '500 °F';
            }),
            list
          );
        } else {
          setValue(list[0], list);
        }
      } else if (list[0][0].Name === 'Size' || list[0][0].Name === 'FlowBody') {
        setListValue(list[0]);
        if (VORTEK_NAME === utilities.AZBIL_NAME && selectedMeter.type === 'In-line') {
          setValue(
            list[1].find((item: Feature) => {
              return item.Description === '316L Stainless Steel';
            }),
            list[1]
          );
        } else {
          setValue(list[1][0]);
        }
      }
    });
  };

  useEffect(() => {
    populateModelReduxValues();
    dispatch(actions.setSpecifyMeterWarnings(meterValidate.validateAll()));
    meterSpecify.createModelCode();
  }, [meterIndex]);

  const isLegal = (evt: KeyboardEvent): boolean => {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (
      charCode === 8 ||
      charCode === 46 ||
      charCode === 77 ||
      charCode === 188 ||
      charCode === 190 ||
      (charCode >= 35 && charCode <= 40) ||
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 96 && charCode <= 105)
    ) {
      return true;
    }

    return false;
  };

  const electronicsMountingOnClick = (): void => {
    let workingVersion = sizingGlobals;

    if (
      workingVersion.VORTEK_NAME === utilities.SPIRAX_NAME &&
      (specifyMeterParentModelNumber === workingVersion.VorconeModel ||
        specifyMeterParentModelNumber === workingVersion.VorconeReduceModel)
    ) {
      workingVersion = provSizingGlobals;
    }

    // The user has clicked on the text box.  Erase the box, and tell the user to enter only the
    // length.
    if (workingVersion.VORTEK_NAME !== utilities.PANAFLOW_NAME) {
      if (workingVersion.VORTEK_NAME === utilities.ARMSTRONG_NAME) {
        // do nothing, the user needs to choose one of the presented options
      } else if (workingVersion.VORTEK_NAME === utilities.HEINRICH_NAME) {
        if (
          specifyMeterElectronicsMounting !== 'L' &&
          specifyMeterElectronicsMounting !== 'X' &&
          specifyMeterElectronicsMounting !== '2'
        ) {
          dispatch(actions.setSpecifyMeterElectronicsMounting(''));
          if (!shownWarning) {
            dispatch(
              actions.setWarningMessage(
                'Enter the remote cable length in feet or meters (max 100ft/30m). For feet, just enter the number. For meters, add a "m" at the end.'
              )
            );
            setShownWarning(true);
          }
        }
      } else if (specifyMeterElectronicsMounting !== workingVersion.LocalStr) {
        dispatch(actions.setSpecifyMeterElectronicsMounting(''));
        if (!shownWarning) {
          dispatch(
            actions.setWarningMessage(
              'Enter the remote cable length in feet or meters (max 100ft/30m). For feet, just enter the number. For meters, add a "m" at the end.'
            )
          );
          setShownWarning(true);
        }
      }
    }
  };

  // eslint-disable-next-line
  const electronicsMountingOnBlur = (e: any): void => {
    let workingVersion = sizingGlobals;
    let modelVal = e.target.value || specifyMeterElectronicsMounting;

    if (
      workingVersion.VORTEK_NAME === utilities.SPIRAX_NAME &&
      (specifyMeterParentModelNumber === workingVersion.VorconeModel ||
        specifyMeterParentModelNumber === workingVersion.VorconeReduceModel)
    ) {
      workingVersion = provSizingGlobals;
    }

    // Verify that the user has entered a value into the text box.  If it is blank, then enter
    // the default R().
    if (modelVal.length === 0) {
      modelVal = '';
    } else {
      // The user has entered a value.  Place it between the parens.
      let dblValue;
      let unit = 'ft';

      try {
        if (modelVal.endsWith('m')) {
          unit = 'm';
          modelVal = modelVal.substring(0, modelVal.length - 1);
        }

        dblValue = new Decimal(modelVal); // If there is a format problem, this will catch it.

        if (workingVersion.VORTEK_NAME === utilities.ARMSTRONG_NAME) {
          // do nothing, the user needs to choose one of the presented options
        } else if (unit === 'ft') {
          // The value cannot be above 100ft.  Verify this value.
          // eslint-disable-next-line
          if (dblValue.gt(new Decimal('100.0'))) {
            modelVal = '100';
            dispatch(
              actions.setErrorMessage(
                'The remote cable length cannot be above 100ft/30m. The value has been placed at the maximum.'
              )
            );
          }
        } else if (unit === 'm') {
          // The value cannot be above 30m.  Verify this value.
          // eslint-disable-next-line
          if (dblValue.gt(new Decimal('30.0'))) {
            modelVal = '30';
            dispatch(
              actions.setErrorMessage(
                'The remote cable length cannot be above 100ft/30m. The value has been placed at the maximum.'
              )
            );
          }
        }

        // Setup the remote length text.
        dblValue = new Decimal(modelVal); // If there is a format problem, this will catch it.
        if (workingVersion.VORTEK_NAME === utilities.ARMSTRONG_NAME) {
          // do nothing, the user needs to choose one of the presented options
        } else if (workingVersion.VORTEK_NAME === utilities.INNOVA_NAME) {
          modelVal = `E4(${dblValue.toString()}${unit === 'm' ? 'm' : ''})`;
        } else if (workingVersion.VORTEK_NAME === utilities.ONICON_NAME) {
          modelVal = `2(${dblValue.toString()}${unit === 'm' ? 'm' : ''})`;
        } else {
          modelVal = `R(${dblValue.toString()}${unit === 'm' ? 'm' : ''})`;
        }
      } catch (err) {
        modelVal = specifyMeterElectronicsMounting;
        if (workingVersion.VORTEK_NAME === utilities.ARMSTRONG_NAME) {
          // do nothing, the user needs to choose one of the presented options
        } else if (workingVersion.VORTEK_NAME === utilities.INNOVA_NAME) {
          // Determine if this is a text box setup error.
          if (!(modelVal.substring(0, 2) === 'E4' || modelVal.substring(0, 2) === 'E2')) {
            // There was an entry error.
            dispatch(
              actions.setErrorMessage(
                'Value entered was not in the correct format.\nFor feet, just enter the number. For meters, add a "m" at the end..'
              )
            );
            modelVal = workingVersion.RemoteStr;
          }
        } else if (workingVersion.VORTEK_NAME === utilities.HEINRICH_NAME) {
          if (!(modelVal.substring(0, 1) === 'X' || modelVal.substring(0, 1) === 'L')) {
            // There was an entry error.
            dispatch(
              actions.setErrorMessage(
                'Value entered was not in the correct format.\nFor feet, just enter the number. For meters, add a "m" at the end..'
              )
            );
            modelVal = workingVersion.RemoteStr;
          }
        } else if (workingVersion.VORTEK_NAME === utilities.ONICON_NAME) {
          if (!(modelVal.substring(0, 1) === '2' || modelVal.substring(0, 1) === '3' || modelVal.substring(0, 1) === 'L')) {
            // There was an entry error.
            dispatch(
              actions.setErrorMessage(
                'Value entered was not in the correct format.\nFor feet, just enter the number. For meters, add a "m" at the end..'
              )
            );
            modelVal = workingVersion.RemoteStr;
          }
        } else if (!(modelVal.substring(0, 1) === 'R' || modelVal.substring(0, 1) === 'L')) {
          // There was an entry error.
          dispatch(
            actions.setErrorMessage(
              'Value entered was not in the correct format.\nFor feet, just enter the number. For meters, add a "m" at the end..'
            )
          );
          modelVal = workingVersion.RemoteStr;
        }
      }
    }

    if (modelVal) {
      setShownWarning(false);
      dispatch(actions.setSpecifyMeterElectronicsMounting(modelVal));
      meterSpecify.createModelCode();
    }
  };

  const electronicsMountingOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Allow the user to only enter digits and a decimal or comma.
    if (e.shiftKey || e.altKey || e.ctrlKey || isLegal(e) === false) {
      // Not a valid character for a length.
      if (e.preventDefault) {
        e.preventDefault();
      }
    }
  };

  const loadModel = () => {
    const mappedLists = getMappedLists();

    return mappedLists.map((list) => {
      if (list[0].Name !== undefined) {
        const value = getValue(list[0]);
        let selectValue = value;
        const name = list[0].Name;
        let disabled = false;
        let readOnly = true;
        // eslint-disable-next-line
        let onBlur = onChangeHandler;
        // eslint-disable-next-line
        let onClick = () => {};
        // eslint-disable-next-line
        let onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {};

        if (name === 'ElectronicsMounting') {
          if (
            VORTEK_NAME === utilities.ARMSTRONG_NAME ||
            VORTEK_NAME === utilities.AZBIL_NAME ||
            VORTEK_NAME === utilities.ONICON_NAME ||
            VORTEK_NAME === utilities.PANAFLOW_NAME ||
            (VORTEK_NAME === utilities.SPIRAX_NAME &&
              specifyMeterParentModelNumber !== VorconeModel &&
              specifyMeterParentModelNumber !== VorconeReduceModel)
          ) {
            disabled = true;
            readOnly = true;
          } else if (specifyMeterElectronicsMounting === LocalStr || specifyMeterElectronicsMounting === 'X') {
            disabled = true;
            readOnly = true;
          } else {
            disabled = false;
            readOnly = false;
            if (VORTEK_NAME === utilities.INNOVA_NAME) {
              if (value?.startsWith('E4') || value === '' || _.isNumber(Number(value))) {
                selectValue = 'E4()';
              }
            } else if (VORTEK_NAME === utilities.HEINRICH_NAME) {
              if (value?.startsWith('R') || value === '' || _.isNumber(Number(value))) {
                selectValue = 'R';
              }
            } else if (value?.startsWith('R(') || value === '' || _.isNumber(Number(value))) {
              selectValue = 'R()';
            }
            onBlur = electronicsMountingOnBlur;
            onClick = electronicsMountingOnClick;
            onKeyDown = electronicsMountingOnKeyDown;
          }
        }

        let selectList =
          name === 'OutputSignal'
            ? meterValidate.updateOutputSignalCombo(sizingGlobals, specifyMeterProcessFluid, specifyMeterInputPower, true)
            : list;

        // remove Wafer connection from choices if M22 and larger than 6"
        if (
          name === 'Flange' &&
          (meterType === 'vortex' || meterType === 'nonreduce_vortex' || meterType === 'vorcone') &&
          selectedMeter.type === 'In-line' &&
          Number(selectedMeter.diameter) >= 6
        ) {
          selectList = [];
          list.forEach((item) => {
            if (!item.Description.startsWith('Wafer connection')) {
              selectList.push(item);
            }
          });
        }

        // remove Wafer connection from reducing choices if M22 and larger than 4"
        if (
          name === 'Flange' &&
          (meterType === 'reduce_vortex' || meterType === 'reduce_vorcone') &&
          selectedMeter.type === 'In-line Reducing' &&
          Number(selectedMeter.diameter) >= 4
        ) {
          selectList = [];
          list.forEach((item) => {
            if (!item.Description.startsWith('Wafer connection')) {
              selectList.push(item);
            }
          });
        }

        if (VORTEK_NAME === utilities.ONICON_NAME && (name === 'SPC' || name === 'ProcessTemperature')) {
          disabled = true;
          readOnly = true;
        }

        return (
          <li key={name}>
            <label htmlFor={name}>{`${translateToDisplayName(name)}:`}</label>
            <input
              type="text"
              className="modelCodeInput"
              name={`input${name}`}
              readOnly={readOnly}
              disabled={disabled}
              value={value}
              onBlur={onBlur}
              onChange={onChangeHandler}
              onClick={onClick}
              onKeyDown={onKeyDown}
            />
            <select
              className="iselect"
              name={name}
              value={selectValue}
              onChange={(e) => onChangeHandler(e)}
              disabled={
                name === 'ParentModelNumber' ||
                name === 'Size' ||
                name === 'FlowBody' ||
                name === 'Rotor' ||
                (VORTEK_NAME === utilities.ONICON_NAME && (name === 'SPC' || name === 'ProcessTemperature'))
              }
            >
              {selectList.map((f) => (
                <option key={f.Code} value={f.OrderCode}>
                  {f.Description}
                </option>
              ))}
            </select>
          </li>
        );
      }

      if (list[0][0].Name === 'Size' || list[0][0].Name === 'FlowBody') {
        let sizeValue = selectedMeter.newPipeData.pipeSize || getValue(list[0][0]);
        let matValue = specifyMeterPipeMaterial || getValue(list[1][0]);
        let matDisabled = false;
        if (sizeValue.endsWith('Stainless Steel')) {
          matValue = list[1].find((m: Feature) => m.Description === '316L Stainless Steel').OrderCode;
          if (matValue !== specifyMeterPipeMaterial) {
            dispatch(actions.setSpecifyMeterPipeMaterial(matValue));
          }
          matDisabled = true;
        } else if (sizeValue.endsWith('Carbon Steel')) {
          matValue = list[1].find((m: Feature) => m.Description === 'A105 Carbon Steel').OrderCode;
          if (matValue !== specifyMeterPipeMaterial) {
            dispatch(actions.setSpecifyMeterPipeMaterial(matValue));
          }
          matDisabled = true;
        } else if (meterType === ElectromagneticProMModel) {
          sizeValue = pipeUtils.mapInlineToElectromagneticPipeSize(sizeValue);
        }

        return (
          <li key={list[0][0].Name}>
            <label htmlFor={list[0][0].Name}>{`${translateToDisplayName(list[0][0].Name)}:`}</label>
            <input
              type="text"
              className="modelCodeInput"
              name={`input${list[0][0].Name}`}
              readOnly
              value={
                VORTEK_NAME === utilities.ARMSTRONG_NAME
                  ? `${specifyMeterPipeSize}-${matValue}`
                  : `${specifyMeterPipeSize}${matValue}`
              }
            />
            <select className="sizeselect" name={list[0][0].Name} value={sizeValue} disabled onChange={(e) => onChangeHandler(e)}>
              {list[0].map((f: Feature) => (
                <option key={f.Code} value={f.Description}>
                  {f.Description}
                </option>
              ))}
            </select>
            <select
              className="matselect"
              name={list[1][0].Name}
              disabled={matDisabled}
              value={matValue}
              onChange={(e) => onChangeHandler(e)}
            >
              {list[1].map((f: Feature) => (
                <option key={f.Code} value={f.OrderCode}>
                  {f.Description}
                </option>
              ))}
            </select>
          </li>
        );
      }

      return [];
    });
  };

  return (
    <>
      <div className="modalContentTabs">
        <form
          method="post"
          action=""
          onSubmit={() => {
            return false;
          }}
          className="flexRowCenter iform"
          autoComplete="off"
        >
          <ul>
            <li className="iheader">{getParentModelNumberText()}</li>
            <li>
              <label htmlFor="tagNumber">Tag Number:</label>
              <input
                className="itext"
                type="text"
                name="TagNumber"
                maxLength={50}
                style={{ width: '444px' }}
                value={specifyMeterTagNumber || ''}
                onChange={updateTagNumber}
              />
            </li>
            {loadModel()}
            <li>
              <label>&nbsp;</label>
              <button
                type="button"
                className="ibutton printButton"
                onClick={() => {
                  setShowPrint(true);
                }}
              >
                Print
              </button>
            </li>
            <li className="modelCodeField">Model Code:{modelCode}</li>
          </ul>
        </form>
      </div>
      {showPrint && (
        <PrintMeterInfo
          onAfterPrint={() => {
            setShowPrint(false);
          }}
        />
      )}
    </>
  );
};

export default ModelCodeTab;
