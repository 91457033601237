import Decimal from 'decimal.js';
import constants from '../../constants';
import Meter from '../../types/Meter';
import PipeData from '../../types/PipeData';
import ProcessCondition from '../../types/processCondition';
import convert from '../convert';

// Original Author:   C.T. Orleskie
// Date:              20 July 2015

// This file contains calculations for the insertion turbine flow meter.

const calculateTurbineReynoldsNumber = (vAvg: Decimal, pipeData: PipeData, processCondition: ProcessCondition): Decimal => {
  // Calculate the Reynolds number based upon the passed parameters.
  //    dblResult = (vAvg) * (ConvertPipeID_To("in", PipeData)) * (ConvertDensityTo("lb/ft^3", ProcessConditions)) _
  //                / (0.0080636616 * (ConvertViscosityTo("cp", ProcessConditions)))

  const pipeID = convert.pipeID_To('in', pipeData);
  const v0080636616 = new Decimal('0.0080636616');
  const viscosity = convert.viscosityTo('cP', processCondition.dblViscosityValue, processCondition.sViscosityUnit);
  const numerator = vAvg.times(pipeID).times(processCondition.dblDensityValue);
  const denominator = v0080636616.times(viscosity);

  // Calculate the Reynolds number.
  const dblResult = numerator.div(denominator);
  return dblResult;
};

const log10 = (x: Decimal): Decimal => {
  return x.ln().div(new Decimal(10).ln());
};

const f = (fn: Decimal, Re: Decimal, roughness: Decimal): Decimal => {
  //     (                  ) - (                                                            )
  //     (                  ) -           (                                                 )
  //     (                  ) -                                (                           )
  //     (        p1        ) - (         ((   p2          ) + (       (       p3         ))))
  // f = (-1 / Math.Sqrt(fn)) - (2 * Log10((ROUGHNESS / 3.7) + (2.51 / (Math.Sqrt(fn) * Re))))
  const n1 = new Decimal('-1.0');
  const v2 = constants.TWO;
  const v3p7 = new Decimal('3.7');
  const v2p51 = new Decimal('2.51');
  const sqrtFn = fn.sqrt();

  const p1 = n1.div(sqrtFn);
  let p2 = roughness.div(v3p7);
  let p3 = sqrtFn.times(Re);
  p3 = v2p51.div(p3);

  p2 = p2.plus(p3);
  p2 = log10(p2);
  p2 = v2.times(p2);

  const fVal = p1.minus(p2);
  return fVal;
};

const dF = (fn: Decimal, Re: Decimal, roughness: Decimal): Decimal => {
  //                                 (                         p5                                 )
  //                                          (                p4                                )
  //                                          (                p3                )
  //      (   p1 (    fnPow     )) + (        ((      fnPow    )                 ) + (    p2    )))
  // dF = (0.5 / fn ^ (3.0 / 2.0)) + (4.034 / ((fn ^ (3.0 / 2.0) * Re * ROUGHNESS) + (9.287 * fn)))

  const v0p5 = new Decimal('0.5');
  const v4p034 = new Decimal('4.034');
  const v9p287 = new Decimal('9.287');
  const fnPow = fn.pow(1.5);
  const p1 = v0p5.div(fnPow);
  const p2 = v9p287.times(fn);
  const p3 = fnPow.times(Re).times(roughness);
  const p4 = p3.plus(p2);
  const p5 = v4p034.div(p4);
  const dFVal = p1.plus(p5);

  return dFVal;
};

const darbyFrictionFactor = (Re: Decimal, roughness: Decimal): Decimal => {
  // intial guess "f0"
  let fn;
  let Ffunction;
  let FPrime;
  const v0p25 = new Decimal('0.25');
  const v0p2703 = new Decimal('0.2703');
  const v5p74 = new Decimal('5.74');
  let temp;

  //                (                                                     )
  //                      (                                        )
  //                (     ((     p1           ) + (      p2       ))      )
  //    fn = 0.25 / (Log10((0.2703 * ROUGHNESS) + (5.74 / Re ^ 0.9)) ^ 2.0)

  const p1 = v0p2703.times(roughness);
  let p2 = Re.pow(0.9);
  p2 = v5p74.div(p2);
  fn = p1.plus(p2);
  fn = log10(fn);
  fn = fn.pow(2);
  fn = v0p25.div(fn);

  Ffunction = f(fn, Re, roughness);
  FPrime = dF(fn, Re, roughness);
  temp = Ffunction.div(FPrime);
  fn = fn.minus(temp);

  Ffunction = f(fn, Re, roughness);
  FPrime = dF(fn, Re, roughness);
  temp = Ffunction.div(FPrime);
  fn = fn.minus(temp);

  return fn;
};

const calcInsertionDepth = (pipeData: PipeData): Decimal => {
  let insertionDepth;
  const pipeID = convert.pipeID_To('in', pipeData);
  const v10 = new Decimal('10');
  const v5 = new Decimal('5');
  const v2 = constants.TWO;

  if (pipeID.gt(v10)) {
    insertionDepth = v5;
  } else {
    insertionDepth = pipeID.div(v2);
  }

  return insertionDepth;
};

const paiProfileFactorX = (pipeData: PipeData, Re: Decimal, roughness: Decimal): Decimal => {
  const v32 = new Decimal('32.0');
  const v46p08 = new Decimal('46.08');
  const v1 = constants.ONE;
  const v1p44 = new Decimal('1.44');
  const v2 = constants.TWO;
  let numerator;
  let denominator;
  const pipeID = convert.pipeID_To('in', pipeData);
  let dblResult;

  const fVal = darbyFrictionFactor(Re, roughness);
  const sqrtf = fVal.sqrt();

  // s = (f * Re) / (32.0 + (46.08 * Math.Sqrt(f)))
  numerator = fVal.times(Re);

  denominator = v46p08.times(sqrtf);
  denominator = v32.plus(denominator);
  const s = numerator.div(denominator);

  // n = (2.0 - (f * (Re / 32.0))) / (1.44 * Math.Sqrt(f) - 1)
  numerator = Re.div(v32);
  numerator = fVal.times(numerator);
  numerator = v2.minus(numerator);

  denominator = v1p44.times(sqrtf);
  denominator = denominator.minus(v1);

  // ratio of insertion depth to ID
  const n = numerator.div(denominator);

  // rRatio = (((pipeID) / v2) - calcInsertionDepth(PipeData)) / ((pipeID) / v2); // ratio of insertion depth to ID
  numerator = pipeID.div(v2);
  const insertionDepth = calcInsertionDepth(pipeData);
  numerator = numerator.minus(insertionDepth);

  denominator = pipeID.div(v2);

  // ratio of vAvg/vMax
  const rRatio = numerator.div(denominator);

  // vRatio = v1 / (v1 + (v1p44 * f.sqrt())); // ratio of vAvg/vMax
  numerator = v1;

  denominator = v1p44.times(sqrtf);
  denominator = v1.plus(denominator);
  const vRatio = numerator.div(denominator);

  numerator = s.minus(n);
  denominator = n.minus(v1);
  const const1 = numerator.div(denominator);

  numerator = v1.minus(s);
  denominator = n.minus(v1);
  const const2 = numerator.div(denominator);

  // return (v1 + (const1 * rRatio ^ 2) + (const2 * rRatio ^ (2 * n))) / vRatio;
  let result1 = rRatio.pow(v2);
  result1 = const1.times(result1);
  result1 = v1.plus(result1);

  const v2n = v2.times(n);
  let result2 = rRatio.pow(v2n);
  result2 = const2.times(result2);
  dblResult = result1.plus(result2);
  dblResult = dblResult.div(vRatio);

  return dblResult;
};

const calcObscuration = (pipeData: PipeData): Decimal => {
  // all dimension are in inches

  let area;
  let h;
  let h2;
  let Obscuration;
  const pipeID = convert.pipeID_To('in', pipeData);
  const pipeIDDiv2 = pipeID.div(constants.TWO);
  const pi = constants.PI;
  let temp;
  let temp2;

  // pipeArea = CONST_PI * ((ConvertPipeID_To("in", PipeData)) / 2.0) * ((ConvertPipeID_To("in", PipeData)) / 2.0);
  const pipeArea = pi.times(pipeIDDiv2).times(pipeIDDiv2);

  const insertionDepth = calcInsertionDepth(pipeData);

  // start with fixed area,
  // the bottom of the turbine housing (enclosing the rotor) must always be fully inserted
  // and will obscure a minimum of the fixed area.
  area = constants.BOTTOM_TURBINE_HOUSING_AREA;

  if (insertionDepth.lt(constants.ROTOR_CENTER.plus(constants.TOP_TURBINE_HOUSING_HEIGHT))) {
    // insertion is shorter than rotor housing
    temp = insertionDepth.minus(constants.ROTOR_CENTER);
    temp = constants.STEM_WIDTH.times(temp);
    area = area.plus(temp);
  } else if (
    insertionDepth.lt(constants.ROTOR_CENTER.plus(constants.TOP_TURBINE_HOUSING_HEIGHT).plus(constants.PICKUP_HOUSING_HEIGHT))
  ) {
    // insertion is shorter than the pickup housing
    h = insertionDepth.minus(constants.ROTOR_CENTER).minus(constants.TOP_TURBINE_HOUSING_HEIGHT);
    h2 = h.times(h);
    temp = constants.STEM_WIDTH.times(h);
    temp2 = h2.times(constants.TAN_PICKUP);
    area = area.plus(constants.TOP_TURBINE_HOUSING_AREA).plus(temp).plus(temp2);
  } else {
    // just plus stem insertion area
    temp = insertionDepth
      .minus(constants.ROTOR_CENTER)
      .minus(constants.TOP_TURBINE_HOUSING_HEIGHT)
      .minus(constants.PICKUP_HOUSING_HEIGHT);
    temp = constants.STEM_WIDTH.times(temp);
    area = area.plus(constants.TOP_TURBINE_HOUSING_AREA).plus(constants.PICKUP_HOUSING_AREA).plus(temp);
  }

  Obscuration = pipeArea.minus(area);
  Obscuration = Obscuration.div(pipeArea);

  return Obscuration;
};

const turbineVelocity = (pipeData: PipeData, processCondition: ProcessCondition, meter: Meter, minOrMax: string): Decimal => {
  let vAvg;
  let vnew;
  let Re;
  let Pf;
  let RotorVelocity;

  if (minOrMax === 'min') {
    switch (meter.name) {
      case 'R40':
        RotorVelocity = constants.R40_MIN_VEL;
        break;
      case 'R30':
        RotorVelocity = constants.R30_MIN_VEL;
        break;
      case 'R25':
        RotorVelocity = constants.R25_MIN_VEL;
        break;
      case 'R20':
        RotorVelocity = constants.R20_MIN_VEL;
        break;
      case 'R15':
        RotorVelocity = constants.R15_MIN_VEL;
        break;
      case 'R10':
        RotorVelocity = constants.R10_MIN_VEL;
        break;
      case 'L40':
        RotorVelocity = constants.L40_MIN_VEL;
        break;
      default:
        // eslint-disable-next-line
        console.log(`unexpected meter name ${meter.name}`);
        RotorVelocity = constants.ONE;
        break;
    }

    if (meter.name !== 'L40') {
      // Adjust the minimum velocity by the ratio of 0.065 / density.
      // RotorVelocity = RotorVelocity * Math.Sqrt(0.065 / (ConvertDensityTo("lb/ft^3", ProcessConditions)))
      const v065 = new Decimal('0.065');
      const density = processCondition.dblDensityValue; // vortek.fn.convert.densityTo("lb/ft^3", ProcessConditions.dblDensityValue, ProcessConditions.sDensityUnit);
      let val = v065.div(density);
      val = val.sqrt();
      RotorVelocity = RotorVelocity.times(val);
    }
  } else {
    switch (meter.name) {
      case 'R40':
        RotorVelocity = constants.R40_MAX_VEL;
        break;
      case 'R30':
        RotorVelocity = constants.R30_MAX_VEL;
        break;
      case 'R25':
        RotorVelocity = constants.R25_MAX_VEL;
        break;
      case 'R20':
        RotorVelocity = constants.R20_MAX_VEL;
        break;
      case 'R15':
        RotorVelocity = constants.R15_MAX_VEL;
        break;
      case 'R10':
        RotorVelocity = constants.R10_MAX_VEL;
        break;
      case 'L40':
        RotorVelocity = constants.L40_MAX_VEL;
        break;
      default:
        // eslint-disable-next-line
        console.log(`unexpected meter name ${meter.name}`);
        RotorVelocity = constants.ONE;
        break;
    }
  }

  vnew = RotorVelocity;
  const v00001 = new Decimal('0.00001');
  let tmp;
  let comp;

  do {
    vAvg = vnew;
    Re = calculateTurbineReynoldsNumber(vAvg, pipeData, processCondition);
    tmp = paiProfileFactorX(pipeData, Re, constants.ROUGHNESS);
    Pf = constants.ONE.div(tmp);
    vnew = RotorVelocity.times(Pf);
    vnew = vnew.times(calcObscuration(pipeData));
    comp = vAvg.minus(vnew);
    comp = comp.abs();
  } while (comp.gte(v00001));

  return vnew;
};

const turbineVelocityMin = (pipeData: PipeData, processCondition: ProcessCondition, meter: Meter): Meter => {
  const newMeter = { ...meter };

  newMeter.minturvel = turbineVelocity(pipeData, processCondition, meter, 'min');

  return newMeter;
};

const turbineVelocityMax = (pipeData: PipeData, processCondition: ProcessCondition, meter: Meter): Meter => {
  const newMeter = { ...meter };

  newMeter.maxturvel = turbineVelocity(pipeData, processCondition, meter, 'max');

  return newMeter;
};

const getOniconTurbineRotorText = (meterSize: string): string => {
  let oniconRotorText;

  if (meterSize === 'L40') {
    oniconRotorText = 'Liquid';
  } else {
    oniconRotorText = meterSize;
  }

  return oniconRotorText;
};

const getOniconTurbineRotorModelCode = (meterSize: string): string => {
  let oniconRotorModelCode = '7';

  if (meterSize === 'L40') {
    oniconRotorModelCode = '0';
  } else if (meterSize === 'R40') {
    oniconRotorModelCode = '1';
  } else if (meterSize === 'R30') {
    oniconRotorModelCode = '2';
  } else if (meterSize === 'R25') {
    oniconRotorModelCode = '3';
  } else if (meterSize === 'R20') {
    oniconRotorModelCode = '4';
  } else if (meterSize === 'R15') {
    oniconRotorModelCode = '5';
  } else if (meterSize === 'R10') {
    oniconRotorModelCode = '6';
  }

  return oniconRotorModelCode;
};

const getOniconTurbineRotorModelCodeToVortekModelCode = (modelCode: string): string => {
  let vortekRotorModelCode = '';

  if (modelCode === '0') {
    vortekRotorModelCode = 'L40';
  } else if (modelCode === '1') {
    vortekRotorModelCode = 'R40';
  } else if (modelCode === '2') {
    vortekRotorModelCode = 'R30';
  } else if (modelCode === '3') {
    vortekRotorModelCode = 'R25';
  } else if (modelCode === '4') {
    vortekRotorModelCode = 'R20';
  } else if (modelCode === '5') {
    vortekRotorModelCode = 'R15';
  } else if (modelCode === '6') {
    vortekRotorModelCode = 'R10';
  }

  return vortekRotorModelCode;
};

export default {
  turbineVelocityMin,
  turbineVelocityMax,
  getOniconTurbineRotorText,
  getOniconTurbineRotorModelCode,
  getOniconTurbineRotorModelCodeToVortekModelCode,
};
