import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../store/actions';
import { RootState } from '../../store';

const NotesTab = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const specialNotes = useSelector((globalState: RootState) => globalState.specifyMeterSpecialNotes);

  const updateSpecialNotes = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const note = e?.target?.value;
    if (note) {
      // eslint-disable-next-line no-control-regex
      const notesMatch = note?.match(/[^\u0020-\u007e\u0009\u000a\u000d]+/g);
      if (notesMatch) {
        dispatch(actions.setErrorMessage('Please enter the special note in English with no extended latin or special characters.'));
        return;
      }
    }

    dispatch(actions.setSpecifyMeterSpecialNotes(note));
  };

  return (
    <div className="modalContentTabs">
      <div className="flexCol iform">
        <div className="flexRowCenter">Special Notes</div>
        <div className="flexRowCenter">
          <textarea rows={10} cols={75} maxLength={250} value={specialNotes} onChange={updateSpecialNotes} />
        </div>
      </div>
    </div>
  );
};

export default NotesTab;
