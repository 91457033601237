/* eslint-disable camelcase */
import { useSelector } from 'react-redux';
import api from '../../api';
import constants from '../../constants';
import { RootState } from '../../store';
import { CallbackEventOneParam } from '../../types/callbackTypes';
import Customer from '../../types/customer';
import utilities from '../../utilities';
import Modal from '../common/Modal';

const UploadCustomer = (props: { onClose: CallbackEventOneParam<string | undefined> }): JSX.Element | null => {
  const { onClose } = props;
  const state = useSelector((globalState: RootState) => globalState);
  const { sizingGlobals, username } = state;
  const { VORTEK_NAME } = sizingGlobals;

  return (
    <Modal title="Upload Customer" visible showButtons={false} showCloseButton onClose={(e: Event) => onClose(e, 'false')}>
      <div className="flexColNoGrow modalContent">
        <p className="flexTextRowCenter">Choose the file to upload</p>
        <div className="flexTextRowCenter">
          <input
            type="file"
            name="file"
            data-url="./api/v1/upload?vfiletype=app"
            className="topBottom20 width45"
            onChange={(e) => {
              // HTML5 local file read
              if (window.File && window.FileReader) {
                const files = e?.target?.files ? e.target.files : null;

                if (files) {
                  for (let i = 0; i < files.length; i++) {
                    let customerData: Customer;
                    let customerDataList: Customer[];
                    let c;
                    let id;
                    const f = files[i];
                    const reader = new FileReader();

                    // eslint-disable-next-line func-names, @typescript-eslint/no-unused-vars
                    reader.onload = (function (file) {
                      // eslint-disable-next-line func-names
                      return async function (ev3: Event) {
                        let filesUploaded = 'false';
                        const fileData = ev3?.target?.result;

                        if (fileData.indexOf('|') > -1) {
                          let donotprint = '';
                          let customers = fileData.split('\r\n');
                          if (!customers || customers.length === 0) {
                            customers = fileData.split('\n');
                          }

                          for (c = 0; c < customers.length; c++) {
                            // support uploading old format
                            const str_array = customers[c].split('|'); // split line ...

                            const company_name = str_array[0];
                            const industry_code = str_array[1];
                            const type_of_contact = str_array[2];
                            const first_name = str_array[3];
                            const last_name = str_array[4];
                            const email = str_array[5];
                            const address1 = str_array[6];
                            const address2 = str_array[7];
                            const city = str_array[8];
                            const customerState = str_array[9];
                            const zipcode = str_array[10];
                            const country = str_array[11];
                            const title_customer = str_array[12];
                            const phone_customer = str_array[13];
                            const extension_customer = str_array[14];
                            const fax_customer = str_array[15];
                            if (str_array.length > 15) {
                              // eslint-disable-next-line prefer-destructuring
                              donotprint = str_array[16];
                            }

                            customerData = {
                              company_name,
                              industry_code,
                              type_of_contact,
                              first_name,
                              last_name,
                              email,
                              address1,
                              address2,
                              city,
                              state: customerState,
                              zipcode,
                              country,
                              title_customer,
                              phone_customer,
                              extension_customer,
                              fax_customer,
                              donotprint,
                            };

                            id = `${VORTEK_NAME + username}customer${customerData.company_name}`;

                            // eslint-disable-next-line no-await-in-loop
                            const data = await api.writeItem({
                              requestData: {
                                client: VORTEK_NAME,
                                username,
                                key: customerData.company_name,
                                _id: id,
                                overwrite: true,
                                collection: 'customer',
                                data: {
                                  client: VORTEK_NAME,
                                  username,
                                  key: customerData.company_name,
                                  _id: id,
                                  data: JSON.parse(JSON.stringify(customerData)),
                                },
                              },
                            });

                            if (data.status === 'fail') {
                              utilities.handleAjaxFailure(data);
                            } else {
                              filesUploaded = 'true';
                            }
                          }

                          onClose(ev3, filesUploaded);
                        } else {
                          // new format, JSON data
                          customerDataList = JSON.parse(fileData) as unknown as Customer[];
                          for (c = 0; c < customerDataList.length; c++) {
                            id = `${VORTEK_NAME + username}customer${customerDataList[c].company_name}`;
                            if (!customerDataList[c].version) {
                              customerDataList[c].version = constants.REVISION;
                            }

                            if (!customerDataList[c].creationDate) {
                              customerDataList[c].creationDate = new Date().toUTCString();
                            }

                            // eslint-disable-next-line no-await-in-loop
                            const data = await api.writeItem({
                              requestData: {
                                client: VORTEK_NAME,
                                username,
                                key: customerDataList[c].company_name,
                                _id: id,
                                overwrite: true,
                                collection: 'customer',
                                data: {
                                  client: VORTEK_NAME,
                                  username,
                                  key: customerDataList[c].company_name,
                                  _id: id,
                                  data: JSON.parse(JSON.stringify(customerDataList[c])),
                                },
                              },
                            });

                            if (data.status === 'fail') {
                              utilities.handleAjaxFailure(data);
                            } else {
                              filesUploaded = 'true';
                            }
                          }
                          onClose(ev3, filesUploaded);
                        }
                      };
                    })(f);

                    // Read in the image file as a data URL.
                    reader.readAsText(f);
                  }
                }
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UploadCustomer;
