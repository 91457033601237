import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CallbackNoParams } from '../../types/callbackTypes';
import utilities from '../../utilities';
import application from '../../utilities/application';
import Modal from '../common/Modal';
import About from './about';
import PrintHeader from './printHeader';
import ChangeUnits from './changeUnits';
import Options from './options';
import CreateApplication from './createApplication';
import UploadApplication from './uploadApplication';
import DeleteApplication from './deleteApplication';
import OpenApplication from './openApplication';

const Navigator = (props: { logoutHandler: CallbackNoParams }): JSX.Element => {
  const { logoutHandler } = props;
  const onlineToken = useSelector((state: RootState) => state.onlineToken);
  const username = useSelector((state: RootState) => state.username);
  const [showLogout, setShowLogout] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [printHeaderVisible, setPrintHeaderVisible] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [changeUnitsVisible, setChangeUnitsVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [createAppVisible, setCreateAppVisible] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [deleteAppVisible, setDeleteAppVisible] = useState(false);
  const [loadVisible, setLoadVisible] = useState(false);

  let onlineStatus = 'Offline';
  let onlineClass = 'offline';
  if (onlineToken !== 'offline') {
    onlineStatus = 'Online';
    onlineClass = 'online';
  }

  let settingsClass = 'toolbar_settings';
  if (username === 'guest' || utilities.isHeinrichsOrOnicon()) {
    settingsClass = 'toolbar_settings hideme';
  }

  const doLogoutResponse = useCallback(async (e, response) => {
    setShowLogout(false);
    if (response === 'ok') {
      logoutHandler();
    }
  }, []);

  return (
    <>
      {showLogout && (
        <Modal showData={false} okText="Yes" cancelText="No" title="Logout" visible onClose={doLogoutResponse}>
          <div className="modalContent">Are you sure you want to logout?</div>
        </Modal>
      )}
      {message && (
        <Modal title="Message" visible showCloseButton showButtons={false} onClose={() => setMessage('')}>
          <div style={{ color: 'white', padding: 25 }}>{message}</div>
        </Modal>
      )}
      <div className="flexCenter wrapperMenu">
        <div className="menuLeft" />
        <div className="menuBg">
          <div className="menu">
            <ul>
              <li className="toolbar_file">
                <div>File</div>
                <ul>
                  <li className="menu_create_app">
                    <button type="button" className="menu_submenu_button" onClick={() => setCreateAppVisible(true)}>
                      Create Application
                    </button>
                  </li>
                  <li className="menu_load_from_server">
                    <button
                      type="button"
                      className="menu_submenu_button"
                      onClick={() => {
                        setLoadVisible(true);
                      }}
                    >
                      Load Application
                    </button>
                  </li>
                  <li className="menu_save_to_server">
                    <button
                      type="button"
                      className="menu_submenu_button"
                      onClick={async () => {
                        const result = await application.saveApplicationToServer();
                        if (result) {
                          setMessage('Application saved.');
                        } else {
                          // displaying the error is taken care of by save appliation to server
                          setMessage('');
                        }
                      }}
                    >
                      Save Application
                    </button>
                  </li>
                  <li className="menu_delete_app">
                    <button type="button" className="menu_submenu_button" onClick={() => setDeleteAppVisible(true)}>
                      Delete Application
                    </button>
                  </li>
                  <li className="menu_upload_file">
                    <button
                      type="button"
                      className="menu_submenu_button"
                      onClick={() => {
                        setUploadVisible(true);
                      }}
                    >
                      Upload Application
                    </button>
                  </li>
                  <li className="menu_download_file">
                    <button
                      type="button"
                      className="menu_submenu_button"
                      onClick={() => {
                        setMessage(application.downloadApplication());
                      }}
                    >
                      Download Application
                    </button>
                  </li>
                </ul>
              </li>
              <li className="toolbar_tools">
                <div>Tools</div>
                <ul>
                  <li className="menu_options">
                    <button type="button" className="menu_submenu_button" onClick={() => setOptionsVisible(true)}>
                      Options
                    </button>
                  </li>
                  <li className="menu_change_units">
                    <button type="button" className="menu_submenu_button" onClick={() => setChangeUnitsVisible(true)}>
                      Change Units
                    </button>
                  </li>
                </ul>
              </li>
              <li className={settingsClass}>
                <div>Settings</div>
                <ul>
                  <li className="menu_print_header">
                    <button type="button" className="menu_submenu_button" onClick={() => setPrintHeaderVisible(true)}>
                      Print Header
                    </button>
                  </li>
                </ul>
              </li>
              <li className="toolbar_help">
                <div>Help</div>
                <ul>
                  <li className="menu_help">Help</li>
                  <li className="menu_about">
                    <button type="button" className="menu_submenu_button" onClick={() => setAboutVisible(true)}>
                      About
                    </button>
                  </li>
                </ul>
              </li>
              <li className="toolbar_logout">
                <button
                  type="button"
                  className="menuLogout"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowLogout(true);
                  }}
                  onKeyPress={(e) => {
                    e.preventDefault();
                    setShowLogout(true);
                  }}
                  tabIndex={0}
                >
                  Logout
                </button>
              </li>
              <li className="toolbar_blank">
                <div className="menu_blank">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
              </li>
              <li className="toolbar_online">
                <div className={`menu_online_status ${onlineClass}`}>{onlineStatus}</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="menuRight" />
      </div>
      {aboutVisible && <About onClose={() => setAboutVisible(false)} />}
      {printHeaderVisible && <PrintHeader onClose={() => setPrintHeaderVisible(false)} logoutHandler={logoutHandler} />}
      {optionsVisible && <Options onClose={() => setOptionsVisible(false)} />}
      {createAppVisible && <CreateApplication onClose={() => setCreateAppVisible(false)} />}
      {changeUnitsVisible && <ChangeUnits onClose={() => setChangeUnitsVisible(false)} />}
      {uploadVisible && <UploadApplication onClose={() => setUploadVisible(false)} />}
      {deleteAppVisible && <DeleteApplication onClose={() => setDeleteAppVisible(false)} />}
      {loadVisible && <OpenApplication onClose={() => setLoadVisible(false)} />}
    </>
  );
};

export default Navigator;
