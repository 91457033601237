import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { CallbackNoParams } from '../../types/callbackTypes';
import Modal from '../common/Modal';
import api from '../../api';
import utilities from '../../utilities';

const DeleteApplication = (props: { onClose: CallbackNoParams }): JSX.Element | null => {
  const sizingGlobals = useSelector((state: RootState) => state.sizingGlobals);
  const userApplications = useSelector((state: RootState) => state.db.application);
  const username = useSelector((state: RootState) => state.username);
  const { VORTEK_NAME } = sizingGlobals;
  const { onClose } = props;
  const [selectedApp, setSelectedApp] = useState('');
  const [message, setMessage] = useState('');

  // this gives back all applications for all users across all OEMs
  const allApplications = userApplications?.map((app) => {
    return JSON.parse(JSON.stringify(app));
  });

  // this narrows down the list to the current OEM and the currently logged in user
  const clientAppData = allApplications?.filter((app) => {
    return app.username === username && app.client === VORTEK_NAME;
  });

  // now grab the app data for each app so it is easier to work with
  const appData = clientAppData?.map((app) => {
    return app.data;
  });

  const applicationOptions = appData?.map((app) => {
    return (
      <option key={app.appname} value={app.appname}>
        {app.appname}
      </option>
    );
  });

  const deleteSelectedApp = async () => {
    if (selectedApp.length <= 0) {
      return;
    }

    const res = await api.deleteItem({
      requestData: {
        _id: `${VORTEK_NAME + username}application${selectedApp}`,
        collection: 'application',
        client: VORTEK_NAME,
        username,
        key: selectedApp,
      },
    });

    if (res.status === 'success') {
      setMessage('Application Deleted');
    } else if (res.status === 'fail') {
      utilities.handleAjaxFailure(res);
    } else {
      setMessage(`Failed to delete application. Remote error ${res.status}.`);
    }
  };

  useEffect(() => {
    if (userApplications.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setSelectedApp(userApplications[0].key!);
    } else {
      setSelectedApp('');
    }
  }, [userApplications]);

  return (
    <>
      <Modal title="Delete Application" visible showButtons={false} showCloseButton onClose={onClose}>
        <div className="modalContent flexCol">
          <p>Delete Application</p>
          <select value={selectedApp} onChange={(e) => setSelectedApp(e.target.value)}>
            {applicationOptions}
          </select>

          <div className="topBottom10">
            <button type="button" onClick={() => deleteSelectedApp()}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      {message.length > 0 && (
        <Modal title="" visible showButtons={false} showCloseButton onClose={() => setMessage('')}>
          <div className="modalContent">{message}</div>
        </Modal>
      )}
    </>
  );
};

export default DeleteApplication;
