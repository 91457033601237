import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const SizingTab = (): JSX.Element | null => {
  const state = useSelector((globalState: RootState) => globalState);
  const { meterData, meterIndex, meterType } = state;
  const selectedMeter = meterData[meterIndex];

  const flowUnits = `${selectedMeter.newPipeData.flowUnit}/
  ${selectedMeter.newPipeData.flowTime}`;

  return (
    <div className="modalContentTabs">
      <div className="flexRow iform">
        <div className="borderBox">
          <div className="flexRow">
            <div className="flexColCenter nowrap smallBox bold">Meter Type</div>
            <div className="flexCol nowrap smallBox">{selectedMeter.type}</div>
            <div className="flexColCenter2 nowrap smallBox bold">Warnings</div>
          </div>
          <div className="flexRow">
            <div className="flexColCenter nowrap smallBox bold">Size</div>
            <div className="flexCol nowrap smallBox">{selectedMeter.name}</div>
            <div className="flexCol2 nowrap smallBox" />
          </div>
          {(meterType === 'vorcone' || meterType === 'reduce_vorcone') && (
            <div className="flexRow">
              <div className="flexColCenter nowrap smallBox bold">Beta</div>
              <div className="flexCol nowrap smallBox">{selectedMeter.beta}</div>
              <div className="flexCol2 nowrap smallBox" />
            </div>
          )}
          <div className="flexRow">
            <div className="flexColCenter nowrap smallBox bold">Usable Meter</div>
            <div className="flexCol nowrap smallBox">{selectedMeter.usable}</div>
            <div className="flexCol2 nowrap smallBox" />
          </div>
          <div className="flexRow">
            <div className="flexColCenter nowrap smallBox bold">Meter Minimum Flow</div>
            <div className="flexCol nowrap smallBox">{selectedMeter.minflow}</div>
            <div className="flexCol2 nowrap smallBox">{selectedMeter.minflowwarn}</div>
          </div>
          <div className="flexRow">
            <div className="flexColCenter nowrap smallBox bold">Meter Maximum Flow</div>
            <div className="flexCol nowrap smallBox">{selectedMeter.maxflow}</div>
            <div className="flexCol2 nowrap smallBox">{selectedMeter.maxflowwarn}</div>
          </div>
          {(meterType === 'vorcone' || meterType === 'reduce_vorcone') && (
            <>
              <div className="flexRow">
                <div className="flexColCenter nowrap smallBox bold">Meter Minimum DP</div>
                <div className="flexCol nowrap smallBox">{selectedMeter.mindp}</div>
                <div className="flexCol2 nowrap smallBox">{selectedMeter.mindpwarn}</div>
              </div>
              <div className="flexRow">
                <div className="flexColCenter nowrap smallBox bold">Meter Maximum DP</div>
                <div className="flexCol nowrap smallBox">{selectedMeter.maxdp}</div>
                <div className="flexCol2 nowrap smallBox">{selectedMeter.maxdpwarn}</div>
              </div>
            </>
          )}
          {meterType === 'vorcone' || meterType === 'reduce_vorcone' ? (
            <div className="flexRow">
              <div className="flexColCenter nowrap smallBox bold">
                Head Loss @{selectedMeter.newPipeData.flowMax} {flowUnits}
              </div>
              <div className="flexCol nowrap smallBox">{selectedMeter.maxpressloss}</div>
              <div className="flexCol2 nowrap smallBox">{selectedMeter.headlosswarn}</div>
            </div>
          ) : (
            <div className="flexRow">
              <div className="flexColCenter nowrap smallBox bold">
                Pressure Drop @{selectedMeter.newPipeData.flowMax} {flowUnits}
              </div>
              <div className="flexCol nowrap smallBox">{selectedMeter.pressdrop}</div>
              <div className="flexCol2 nowrap smallBox">{selectedMeter.pressdropwarn}</div>
            </div>
          )}
          <div className="flexRow">
            <div className="flexColCenter nowrap smallBox bold">Min Reynolds</div>
            <div className="flexCol nowrap smallBox">{selectedMeter.minrey}</div>
            <div className="flexCol2 nowrap smallBox">{selectedMeter.minreynwarn}</div>
          </div>
          <div className="flexRow">
            <div className="flexColCenter nowrap smallBox bold">Max Reynolds</div>
            <div className="flexCol nowrap smallBox">{selectedMeter.maxrey}</div>
            <div className="flexCol2 nowrap smallBox">{selectedMeter.maxreynwarn}</div>
          </div>
          <div className="flexRow">
            <div className="flexColCenter nowrap smallBox bold">
              Min Velocity @{selectedMeter.newPipeData.flowMin} {flowUnits}
            </div>
            <div className="flexCol nowrap smallBox">{selectedMeter.minvel}</div>
            <div className="flexCol2 nowrap smallBox">{selectedMeter.minvelowarn}</div>
          </div>
          <div className="flexRow">
            <div className="flexColCenter nowrap smallBox bold">
              Nom Velocity @{selectedMeter.newPipeData.flowNom} {flowUnits}
            </div>
            <div className="flexCol nowrap smallBox">{selectedMeter.nomvel}</div>
            <div className="flexCol2 nowrap smallBox">{selectedMeter.nomvelowarn}</div>
          </div>
          <div className="flexRow">
            <div className="flexColCenter nowrap smallBox bold">
              Max Velocity @{selectedMeter.newPipeData.flowMax} {flowUnits}
            </div>
            <div className="flexCol nowrap smallBox">{selectedMeter.maxvel}</div>
            <div className="flexCol2 nowrap smallBox">{selectedMeter.maxvelowarn}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SizingTab;
