/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
import _ from 'lodash';
import Decimal from 'decimal.js';
import DownloadConfig from '../types/downloadConfig';
import db from '../db';
import actions from '../store/actions';
import DbRow from '../types/dbRow';
import ApiConfig from '../types/apiConfig';
import ApiRequest from '../types/apiRequest';
import ApiResponse from '../types/apiResponse';
import store from '../store';
import utilities from '../utilities';
import Feature from '../types/Feature';

const apiCall = async (apiUri: string, apiType: string, data: ApiRequest) => {
  let apiResponse: ApiResponse = {
    status: 'fail',
    data: '',
  };

  try {
    let response;
    const headers: HeadersInit = {
      'Content-Type': 'application/json; charset=utf-8',
    };

    const cookie = utilities.getCookie('user');
    const request: RequestInit = {
      method: apiType,
      headers,
    };

    if (cookie) {
      request.credentials = 'include';
    }

    if (apiType === 'POST' || apiType === 'DELETE') {
      request.body = JSON.stringify(data, null, 2);
      response = await fetch(`/api/v1/${apiUri}/`, request);
    } else if (apiType === 'GET') {
      const params = new URLSearchParams();
      params.append('client', data.client);
      params.append('username', data.username);
      if (apiUri !== 'validateToken') {
        params.append('collection', data.collection);
        if (data.key) {
          params.append('key', data.key);
        }

        if (data._id) {
          params.append('_id', data._id);
        }

        if (data.trydefault) {
          params.append('trydefault', `${data.trydefault}`);
        }

        if (data.overwrite) {
          params.append('overwrite', `${data.overwrite}`);
        }

        if (data.activationId) {
          params.append('activationId', data.activationId);
        }

        if (data.forgotpasswordid) {
          params.append('forgotpasswordid', data.forgotpasswordid);
        }

        if (data.password) {
          params.append('password', data.password);
        }

        if (data.rep_password) {
          params.append('rep_password', data.rep_password);
        }

        if (data.email) {
          params.append('email', data.email);
        }
      }

      response = await fetch(`/api/v1/${apiUri}?${params}`, request);
    }

    if (!response?.ok) {
      apiResponse.status = 'fail';
      apiResponse.error = `${response?.statusText || response?.status}`;
    } else {
      apiResponse = await response.json();
    }
  } catch (err) {
    apiResponse.status = 'fail';
    apiResponse.error = err;
  }

  if (process.env.VORTEK_INTEGRATION === 'integration') {
    // eslint-disable-next-line
    console.log(JSON.stringify(apiResponse));
  }

  return apiResponse;
};

const retrieveDataFromIndexedDbForMemory = async (config: ApiConfig): Promise<ApiResponse> => {
  const apiResponse = await db.getAll(config, false);
  if (apiResponse.status === 'success' && apiResponse.data.length) {
    actions.mapCollectionToAction(config.requestData.collection, apiResponse.data);
  }

  return apiResponse;
};

// find a user in the Redux store
const findUserInRedux = (client: string, username: string) => {
  const query = `${client}${username}vusers${username}`;
  const state = store.getState();
  const users = state.db.vusers;
  const user = users.find((e) => {
    const row = e as unknown as DbRow;
    return row._id === query;
  });

  return user;
};

// find all rows for a user in a collection in the Redux store
const findAllDataForUserInReduxTable = (client: string, username: string, collection: string, id?: string): any[] => {
  const query = `${client}${username}${collection}${id || ''}`;
  const state = store.getState();
  const tableName: keyof typeof state.db = collection as keyof typeof state.db;
  const table = state.db[tableName];
  const data = table.filter((e) => {
    const row = e as unknown as DbRow;
    return row._id.startsWith(query);
  });

  return data;
};

// find a row in a collection in the Redux store
const findDataInRedux = (client: string, username: string, collection: string, id?: string): DbRow | undefined => {
  const query = `${client}${username}${collection}${id || ''}`;
  const state = store.getState();
  const tableName: keyof typeof state.db = collection as keyof typeof state.db;
  const table = state.db[tableName];
  const data = table.find((e) => {
    const row = e as unknown as DbRow;
    return row._id === query;
  });

  return data;
};

const removeFromRedux = (collection: string, dbRow: DbRow) => {
  const state = store.getState();
  const tableName: keyof typeof state.db = collection as keyof typeof state.db;
  const table = [...state.db[tableName]];

  // remove old row if present
  const data = table.filter((e) => {
    const row = e as unknown as DbRow;
    return row._id !== dbRow._id;
  });

  actions.mapCollectionToAction(collection, data);
};

const upsertIntoRedux = (collection: string, dbRow: DbRow) => {
  const state = store.getState();
  const tableName: keyof typeof state.db = collection as keyof typeof state.db;
  const table = [...state.db[tableName]];

  // remove old row if present
  const data = table.filter((e) => {
    const row = e as unknown as DbRow;
    return row._id !== dbRow._id;
  });

  // now put updated row into table
  data.push(dbRow);

  actions.mapCollectionToAction(collection, data);
};

const localLogin = async (config: ApiConfig): Promise<ApiResponse> => {
  const useConfig = {
    ...config,
  };
  useConfig.requestData.collection = 'vusers';
  let apiResponse = await retrieveDataFromIndexedDbForMemory(useConfig);
  if (apiResponse.status !== '200') {
    apiResponse = {
      status: 'fail',
      user: config.requestData.username,
      error: 'User not found',
    };

    const data = findUserInRedux(config.requestData.client, config.requestData.username);
    if (!data) {
      return apiResponse;
    }

    if (data && data.valid) {
      apiResponse = {
        status: 'fail',
        user: config.requestData.username,
        error: 'User has not completed email validation',
      };
    } else {
      apiResponse = {
        status: 'success',
        user: data,
        error: '',
      };

      store.dispatch(actions.setOnlineToken('offline'));
      store.dispatch(actions.setUsername(config.requestData.username));
    }
  }

  return apiResponse;
};

const performLogin = async (config: ApiConfig): Promise<ApiResponse> => {
  const response: ApiResponse = await apiCall('login', 'POST', config.requestData);

  if (response.status !== 'success') {
    if (
      response.error === 'User not found' ||
      response.error === 'User has not completed email validation' ||
      response.error === 'Username or password incorrect'
    ) {
      return response;
    }

    return localLogin(config);
  }

  // const theToken = response.token as string;
  store.dispatch(actions.setOnlineToken('online'));
  store.dispatch(actions.setUsername(config.requestData.username));
  return response;
};

const performActivate = async (config: ApiConfig): Promise<ApiResponse> => {
  return apiCall('activate', 'POST', config.requestData);
};

const performAuthForgotPassword = async (config: ApiConfig): Promise<ApiResponse> => {
  return apiCall('authforgotpassword', 'POST', config.requestData);
};

const performChangePassword = async (config: ApiConfig): Promise<ApiResponse> => {
  return apiCall('changepassword', 'POST', config.requestData);
};

const performForgotPassword = async (config: ApiConfig): Promise<ApiResponse> => {
  return apiCall('forgotpassword', 'POST', config.requestData);
};

const performLogout = async (config: ApiConfig): Promise<ApiResponse> => {
  const response = await apiCall('logout', 'POST', config.requestData);
  store.dispatch(actions.setUsername(''));
  utilities.deleteCookie('username');
  utilities.deleteCookie('authtoken');
  return response;
};

const validateToken = async (req: ApiRequest): Promise<ApiResponse> => {
  const state = store.getState();
  const { onlineToken } = state;
  let objectResult: ApiResponse = {
    status: 'fail',
  };

  if (onlineToken === 'offline' || req.username === 'guest' || utilities.isHeinrichsOrOnicon()) {
    objectResult = {
      status: 'success',
    };
  } else {
    objectResult = await apiCall('validateToken', 'GET', req);
  }

  // The call will only fail with this error if the user is offline, and onlineToken may not
  // have been set yet while checking the cookie, so allow it to proceed.
  if (objectResult.status === 'fail' && objectResult?.error?.message === 'Failed to fetch') {
    objectResult.status = 'success';
    objectResult.error = undefined;
  }
  return objectResult;
};

const performRegister = async (config: ApiConfig): Promise<ApiResponse> => {
  return apiCall('register', 'POST', config.requestData);
};

const deleteItemRemote = async (config: ApiConfig): Promise<ApiResponse> => {
  const state = store.getState();
  const { forceRemote } = state;

  if ((config.requestData.username !== 'guest' && !utilities.isHeinrichsOrOnicon()) || forceRemote) {
    return apiCall('key', 'DELETE', config.requestData);
  }

  const objectResult = {
    status: 'success',
    user: config.requestData.username,
    error: '',
  };

  return objectResult;
};

const deleteItem = async (config: ApiConfig): Promise<ApiResponse> => {
  const state = store.getState();
  const { onlineToken } = state;
  let objectResult: ApiResponse = {
    status: 'fail',
    user: config.requestData.username,
    error: 'Data not deleted',
  };

  removeFromRedux(config.requestData.collection, {
    _id: config.requestData._id || '',
    client: config.requestData.client,
    username: config.requestData.username,
    data: JSON.parse('{}'),
  });

  await db.deleteData(config);

  if (onlineToken === 'offline') {
    objectResult = {
      status: 'success',
      user: config.requestData.username,
      error: '',
    };
  } else {
    return deleteItemRemote(config);
  }

  return objectResult;
};

const deleteOneRemote = async (config: ApiConfig): Promise<ApiResponse> => {
  const state = store.getState();
  const { forceRemote } = state;

  if ((config.requestData.username !== 'guest' && !utilities.isHeinrichsOrOnicon()) || forceRemote) {
    return apiCall('one', 'DELETE', config.requestData);
  }

  const objectResult = {
    status: 'success',
    user: config.requestData.username,
    error: '',
  };

  return objectResult;
};

const deleteOne = async (config: ApiConfig): Promise<ApiResponse> => {
  const state = store.getState();
  const { onlineToken } = state;
  let objectResult: ApiResponse = {
    status: 'fail',
    user: config.requestData.username,
    error: 'Data not deleted',
  };

  removeFromRedux(config.requestData.collection, {
    _id: config.requestData._id || '',
    client: config.requestData.client,
    username: config.requestData.username,
    data: JSON.parse('{}'),
  });

  await db.deleteData(config);

  if (onlineToken === 'offline') {
    objectResult = {
      status: 'success',
      user: config.requestData.username,
      error: '',
    };
  } else {
    return deleteOneRemote(config);
  }

  return objectResult;
};

const readOneSync = (trydefault: boolean, config: ApiConfig): ApiResponse => {
  const objectResult: ApiResponse = {
    status: 'fail',
    user: config.requestData.username,
    error: 'Data not found',
  };

  let result = findDataInRedux(config.requestData.client, config.requestData.username, config.requestData.collection);
  if (result) {
    objectResult.status = 'success';
    objectResult.data = result;
    objectResult.error = '';
    return objectResult;
  }

  if (trydefault && config.requestData.username !== 'DEFAULT') {
    // look again in redux store for data using default user
    result = findDataInRedux(config.requestData.client, 'DEFAULT', config.requestData.collection);
    if (result) {
      objectResult.status = 'success';
      objectResult.data = result;
      objectResult.error = '';
      return objectResult;
    }
  }

  return objectResult;
};

const readOneRemote = async (objectResult: ApiResponse, trydefault: boolean, config: ApiConfig): Promise<ApiResponse> => {
  let localResult = objectResult;
  const state = store.getState();
  const { forceRemote } = state;
  const useConfig = { ...config };

  if (forceRemote) {
    if (!config.requestData.trydefault) {
      useConfig.requestData.trydefault = trydefault;
    }

    // remote lookup
    const response: ApiResponse = await apiCall('one', 'GET', useConfig.requestData);
    if (response.status === 'success') {
      useConfig.requestData.data = response.data;
      upsertIntoRedux(useConfig.requestData.collection, response.data);
      localResult = await db.putData(useConfig);
      if (localResult.status === 'success') {
        return response;
      }
    }
  }

  return localResult;
};

const readOne = async (trydefault: boolean, config: ApiConfig): Promise<ApiResponse> => {
  let objectResult: ApiResponse = readOneSync(trydefault, config);
  if (objectResult.status === 'fail') {
    objectResult = await readOneRemote(objectResult, trydefault, config);
  }

  return objectResult;
};

const writeOneRemote = async (config: ApiConfig): Promise<ApiResponse> => {
  const state = store.getState();
  const { forceRemote } = state;

  if ((config.requestData.username !== 'guest' && !utilities.isHeinrichsOrOnicon()) || forceRemote) {
    return apiCall('one', 'POST', config.requestData);
  }

  const objectResult = {
    status: 'success',
    user: config.requestData.username,
    error: '',
  };

  return objectResult;
};

const writeOne = async (config: ApiConfig): Promise<ApiResponse> => {
  const state = store.getState();
  const { onlineToken } = state;
  let objectResult: ApiResponse = {
    status: 'fail',
    user: config.requestData.username,
    error: 'Data not saved',
  };

  if (config.requestData.data) {
    upsertIntoRedux(config.requestData.collection, config.requestData.data);
    objectResult = await db.putData(config);

    if (onlineToken === 'offline') {
      objectResult = {
        status: 'success',
        user: config.requestData.username,
        data: config.requestData.data,
      };

      return objectResult;
    }

    return writeOneRemote(config);
  }

  return objectResult;
};

const readItemSync = (trydefault: boolean, config: ApiConfig): ApiResponse => {
  const objectResult: ApiResponse = {
    status: 'fail',
    user: config.requestData.username,
    error: 'Data not found',
  };

  // redux lookup
  let res = findDataInRedux(
    config.requestData.client,
    config.requestData.username,
    config.requestData.collection,
    config.requestData.key
  );
  if (!res) {
    if (trydefault && !res && config.requestData.username !== 'DEFAULT') {
      res = findDataInRedux(config.requestData.client, 'DEFAULT', config.requestData.collection, config.requestData.key);
      if (res) {
        objectResult.status = 'success';
        objectResult.data = res;
        objectResult.error = '';
      }
    } else if (res) {
      objectResult.status = 'success';
      objectResult.data = res;
      objectResult.error = '';
    }
  } else {
    objectResult.status = 'success';
    objectResult.data = res;
    objectResult.error = '';
  }

  return objectResult;
};

const readItemRemote = async (objectResult: ApiResponse, trydefault: boolean, config: ApiConfig): Promise<ApiResponse> => {
  const state = store.getState();
  const { forceRemote } = state;
  const useConfig = { ...config };
  let thisResult = { ...objectResult };

  if (forceRemote) {
    if (!useConfig.requestData.trydefault) {
      useConfig.requestData.trydefault = true;
    }

    // remote lookup
    const response: ApiResponse = await apiCall('key', 'GET', useConfig.requestData);

    if (response.status === 'success') {
      useConfig.requestData.data = response.data;
      thisResult = await db.putData(useConfig);
    }
  }

  return thisResult;
};

const readItem = async (trydefault: boolean, config: ApiConfig): Promise<ApiResponse> => {
  let objectResult: ApiResponse = readItemSync(trydefault, config);
  if (objectResult.status === 'fail') {
    objectResult = await readItemRemote(objectResult, trydefault, config);
  }

  return objectResult;
};

const writeItemRemote = (config: ApiConfig) => {
  const state = store.getState();
  const { forceRemote } = state;

  if ((config.requestData.username !== 'guest' && !utilities.isHeinrichsOrOnicon()) || forceRemote) {
    return apiCall('key', 'POST', config.requestData);
  }

  const objectResult = {
    status: 'success',
    user: config.requestData.username,
    error: '',
  };

  return objectResult;
};

const writeItem = async (config: ApiConfig): Promise<ApiResponse> => {
  const state = store.getState();
  const { onlineToken } = state;
  let objectResult: ApiResponse = {
    status: 'fail',
    user: config.requestData.username,
    error: 'Data not saved',
  };

  if (config.requestData.data) {
    upsertIntoRedux(config.requestData.collection, config.requestData.data);
    objectResult = await db.putData(config);

    if (onlineToken === 'offline') {
      objectResult = {
        status: 'success',
        user: config.requestData.username,
        data: config.requestData.data,
      };

      return objectResult;
    }

    return writeItemRemote(config);
  }

  return objectResult;
};

const getSpecifiedFeatureList = (collectionName: string): Feature[] => {
  const state = store.getState();
  const { sizingGlobals, username } = state;
  const { VORTEK_NAME } = sizingGlobals;
  let item;

  const res = readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: collectionName,
    },
  });

  if (res.status !== 'fail') {
    item = res.data.data;
  } else {
    utilities.handleAjaxFailure(res);
  }

  return item;
};

// Insertion
const getM23FeatureList = (): Feature[] => {
  const state = store.getState();
  const { sizingGlobals, turbineActive } = state;
  const { VORTEK_NAME } = sizingGlobals;
  let collectionName;

  if (turbineActive) {
    if (VORTEK_NAME === utilities.ONICON_NAME) {
      collectionName = 'turbineFeature';
    } else {
      collectionName = 'm23Feature';
    }
  } else {
    collectionName = 'm23Feature';
  }

  return getSpecifiedFeatureList(collectionName);
};

const findFluids = (type?: string): any => {
  const state = store.getState();
  const { sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;
  let item;

  const res = readOneSync(false, {
    requestData: {
      client: VORTEK_NAME,
      username: 'DEFAULT',
      collection: 'fluid',
    },
  });

  if (res.status !== 'fail') {
    if (!type) {
      item = res.data.data;
    } else {
      item = utilities.findInArray(res.data.data, type);
    }
  }

  return item;
};

const findMeasurement = (collection: string, unit: string): any => {
  const state = store.getState();
  const { sizingGlobals, username } = state;
  const { VORTEK_NAME } = sizingGlobals;
  let item;

  const res = readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection,
    },
  });

  if (res.status !== 'fail') {
    const result = utilities.findInArray(res.data.data, unit);
    if (result) {
      item = {
        name: result.name,
        abbrv: result.abbrv,
        factor: new Decimal(result.factor),
        offset: result.offset,
        type: result.type,
        keyorder: result.keyorder,
      };
    }
  }

  return item;
};

const findTemperature = (unit: string): any => {
  const item = findMeasurement('temperature', unit);
  if (item && item.offset) {
    item.offset = new Decimal(item.offset);
  }

  return item;
};

const writeIndexedDbData = async (config: ApiConfig, res: ApiResponse) => {
  if (res.status === 'success') {
    const useConfig = {
      ...config,
    };
    useConfig.requestData.data = res.data;
    return db.putData(useConfig);
  }

  return res;
};

// eslint-disable-next-line
const writeData = async (client: string, username: string, collection: string, data: any): Promise<ApiResponse> => {
  const objectResult: ApiResponse = {
    status: 'fail',
    user: username,
    error: 'Data not provided',
  };

  if (data) {
    const id = `${client}${username}${collection}`;
    return writeOne({
      requestData: {
        client,
        username,
        collection,
        _id: id,
        data: {
          client,
          username,
          collection,
          _id: id,
          data: JSON.parse(JSON.stringify(data)),
        },
      },
    });
  }

  return objectResult;
};

const writeDataItems = async (collection: string, items: Array<ApiRequest>): Promise<void> => {
  const results = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const id = `${item.client}${item.username}${collection}${item.key}`;
    item._id = id;
    results.push(
      writeItem({
        requestData: {
          client: item.client,
          username: item.username,
          collection,
          _id: id,
          data: JSON.parse(
            JSON.stringify({
              client: item.client,
              username: item.username,
              _id: id,
              data: item.data,
              key: item.key,
            })
          ),
        },
      })
    );
  }

  await Promise.all(results);
};

const writeUnits = async (config: ApiConfig): Promise<ApiResponse> => {
  const { client } = config.requestData;
  const { username } = config.requestData;

  // write user-override data
  let res = await writeData(client, username, 'density', config.requestData.densityunits || JSON.parse(JSON.stringify('{}')));

  // only send data to callback if there is an error, otherwise the
  // eachSeries short-circuits
  if (res && res.status && res.status !== 'success') {
    return res;
  }

  // write user-override data
  res = await writeData(client, username, 'viscosity', config.requestData.viscosityunits || JSON.parse(JSON.stringify('{}')));

  // only send data to callback if there is an error, otherwise the
  // eachSeries short-circuits
  if (res && res.status && res.status !== 'success') {
    return res;
  }

  // write user-override data
  res = await writeData(client, username, 'length', config.requestData.lengthunits || JSON.parse(JSON.stringify('{}')));

  // only send data to callback if there is an error, otherwise the
  // eachSeries short-circuits
  if (res && res.status && res.status !== 'success') {
    return res;
  }

  // write user-override data
  res = await writeData(client, username, 'time', config.requestData.timeunits || JSON.parse(JSON.stringify('{}')));

  // only send data to callback if there is an error, otherwise the
  // eachSeries short-circuits
  if (res && res.status && res.status !== 'success') {
    return res;
  }

  // write user-override data
  res = await writeData(client, username, 'temperature', config.requestData.temperatureunits || JSON.parse(JSON.stringify('{}')));

  // only send data to callback if there is an error, otherwise the
  // eachSeries short-circuits
  if (res && res.status && res.status !== 'success') {
    return res;
  }

  // write user-override data
  res = await writeData(client, username, 'pressure', config.requestData.pressureunits || JSON.parse(JSON.stringify('{}')));

  // only send data to callback if there is an error, otherwise the
  // eachSeries short-circuits
  if (res && res.status && res.status !== 'success') {
    return res;
  }

  // write user-override data
  res = await writeData(client, username, 'flow', config.requestData.flowunits || JSON.parse(JSON.stringify('{}')));

  return res;
};

/**
 * Wow this really shouldn't work...but it does
 * @param config
 */
const downloadFile = (config: DownloadConfig): void => {
  const state = store.getState();
  const { isIE10or11orEdge } = state;

  if (isIE10or11orEdge) {
    const blobObject = new Blob([config.content]);
    window.navigator.msSaveBlob(blobObject, config.filename);
  } else {
    const el = document.getElementById('jsonDL');
    if (el) {
      el.remove();
    }

    const a = document.body.appendChild(document.createElement('a'));
    a.download = config.filename;
    a.href = `data:text/json;base64,${btoa(config.content)}`;
    a.innerHTML = 'download';
    a.id = 'jsonDL';
    a.click();
    a.remove();
  }
};

const retrieveDataForLocal = async (client: string, username: string, collection: string) => {
  return readOne(false, {
    requestData: {
      client,
      username,
      collection,
      trydefault: false,
    },
  });
};

const retrieveRemoteOfflineData = async (): Promise<void> => {
  return new Promise((resolve) => {
    const state = store.getState();
    const { username, sizingGlobals } = state;
    const { VORTEK_NAME, VORTEK_DISPLAY_NAME } = sizingGlobals;

    store.dispatch(actions.setForceRemote(true));
    store.dispatch(actions.setSystemMessage(`Please wait...retrieving ${VORTEK_DISPLAY_NAME} data.`));

    const client = VORTEK_NAME;
    const defuser = 'DEFAULT';

    const offlineCollections = [
      ['feature', [defuser]],
      ['m22Feature', [defuser]],
      ['m23Feature', [defuser]],
      ['m24Feature', [defuser]],
      ['m24rFeature', [defuser]],
      ['mvcFeature', [defuser]],
      ['mvcrFeature', [defuser]],
      ['s34Feature', [defuser]],
      ['s36Feature', [defuser]],
      ['u42Feature', [defuser]],
      ['u43Feature', [defuser]],
      ['u44Feature', [defuser]],
      ['turbineFeature', [defuser]],
      ['proMFeature', [defuser]],
      ['fluid', [defuser]],
      ['liquid', [defuser]],
      ['pipeSchedules', [defuser]],
      ['ansiPipeSizes', [defuser]],
      ['dinPipeSizes', [defuser]],
      ['jisPipeSizes', [defuser]],
      ['jisPipeSchedules', [defuser]],
      ['gas', [defuser]],
      ['pipesdin', [defuser]],
      ['inlines', [defuser]],
      ['inlinesProM', [defuser]],
      ['flangeTestsAsme', [defuser]],
      ['flangeTestsDin', [defuser]],
      ['flangeTestsJis', [defuser]],
      ['density', [username, defuser]],
      ['viscosity', [username, defuser]],
      ['length', [username, defuser]],
      ['time', [username, defuser]],
      ['temperature', [username, defuser]],
      ['pressure', [username, defuser]],
      ['flow', [username, defuser]],
      ['image', [username, defuser]],
      ['printheader', [username, defuser]],
      ['defaults', [username, defuser]],
      ['vusers', [username]],
      ['customer', [username]],
      ['othergas', [username]],
      ['otherliquid', [username]],
      ['application', [username]],
      ['soundspeed', [defuser]],
      ['pipeMaterial', [defuser]],
      ['linerMaterial', [defuser]],
    ];

    const total = 54;
    let count = 0;

    // use closures to asyncronously retrieve all data
    // eslint-disable-next-line func-names
    offlineCollections.forEach(function (item) {
      const names = item[1] as string[];

      // eslint-disable-next-line func-names
      names.forEach(async function (name) {
        const res = await retrieveDataForLocal(client, name, item[0] as string);
        if (res && res.data && res.status === 'success') {
          await writeIndexedDbData(
            {
              requestData: {
                client,
                collection: item[0] as string,
                username,
                data: res.data,
              },
            },
            res
          );
        }
        count++;
        if (count > total - 1) {
          store.dispatch(actions.setForceRemote(false));
          store.dispatch(actions.setSystemMessage(''));
          resolve();
        }
      });
    });
  });
};

const retrieveIndexedDbDataToMemory = async (config: ApiConfig): Promise<void> => {
  const results = [];
  const useConfig = {
    ...config,
  };

  for (let i = 0; i < db.collections.length; i++) {
    useConfig.requestData.collection = db.collections[i];
    results.push(await retrieveDataFromIndexedDbForMemory(config));
  }

  await Promise.all(results);
};

const clearCollections = async (config: ApiConfig): Promise<void> => {
  return db.clearCollections(config);
};

const fixupData = (foo: any): any => {
  let retFoo = { ...foo };
  let blah;
  if (retFoo && retFoo.data && retFoo.data.data && retFoo.data.data.data) {
    blah = retFoo.data.data;
    retFoo.data = blah;
  }

  if (retFoo['0']) {
    retFoo = retFoo['0'].data;
  }
  return retFoo;
};

const syncOfflineDataToServer = async (): Promise<void> => {
  const state = store.getState();
  const { username, sizingGlobals } = state;
  const { VORTEK_NAME, VORTEK_DISPLAY_NAME } = sizingGlobals;

  store.dispatch(actions.setSystemMessage(`Please wait...syncing ${VORTEK_DISPLAY_NAME} data to the server.`));
  store.dispatch(actions.setForceRemote(true));

  const config: ApiConfig = {
    requestData: {
      client: VORTEK_NAME,
      collection: 'density',
      username,
    },
  };

  let response = await db.getAll(config, true);
  let resDensity;
  if (response.status === 'success' && !_.isEmpty(response.data)) {
    resDensity = response.data;
  }

  config.requestData.collection = 'viscosity';
  response = await db.getAll(config, true);

  let resViscosity;
  if (response.status === 'success' && !_.isEmpty(response.data)) {
    resViscosity = response.data;
  }

  config.requestData.collection = 'length';
  let resLength;
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data)) {
    resLength = response.data;
  }

  config.requestData.collection = 'time';
  let resTime;
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data)) {
    resTime = response.data;
  }

  config.requestData.collection = 'temperature';
  let resTemperature;
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data)) {
    resTemperature = response.data;
  }

  config.requestData.collection = 'pressure';
  let resPressure;
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data)) {
    resPressure = response.data;
  }

  config.requestData.collection = 'flow';
  let resFlow;
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data)) {
    resFlow = response.data;
  }

  if (resFlow && resTemperature && resPressure && resDensity && resViscosity && resLength && resTime) {
    resFlow = fixupData(resFlow);
    resTemperature = fixupData(resTemperature);
    resPressure = fixupData(resPressure);
    resDensity = fixupData(resDensity);
    resViscosity = fixupData(resViscosity);
    resLength = fixupData(resLength);
    resTime = fixupData(resTime);

    await writeUnits({
      requestData: {
        client: VORTEK_NAME,
        collection: '',
        username,
        flowunits: resFlow,
        temperatureunits: resTemperature,
        pressureunits: resPressure,
        densityunits: resDensity,
        viscosityunits: resViscosity,
        lengthunits: resLength,
        timeunits: resTime,
      },
    });
  }

  config.requestData.collection = 'image';
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data) && !_.isEmpty(response.data[0].data)) {
    const resImage = fixupData(response.data);
    if (resImage) {
      await writeData(VORTEK_NAME, username, 'image', resImage);
    }
  }

  config.requestData.collection = 'printheader';
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data) && !_.isEmpty(response.data[0].data)) {
    const resPh = fixupData(response.data);
    if (resPh) {
      await writeData(VORTEK_NAME, username, 'printheader', resPh);
    }
  }

  config.requestData.collection = 'defaults';
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data) && !_.isEmpty(response.data[0].data)) {
    const resDefaults = fixupData(response.data);
    if (resDefaults) {
      await writeData(VORTEK_NAME, username, 'defaults', resDefaults);
    }
  }

  config.requestData.collection = 'application';
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data) && !_.isEmpty(response.data[0].data)) {
    const resApplication = response.data;
    if (resApplication && _.isArray(resApplication) && resApplication.length > 0) {
      await writeDataItems('application', resApplication);
    }
  }

  config.requestData.collection = 'customer';
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data) && !_.isEmpty(response.data[0].data)) {
    const resCustomer = response.data;
    if (resCustomer && _.isArray(resCustomer) && resCustomer.length > 0) {
      await writeDataItems('customer', resCustomer);
    }
  }

  config.requestData.collection = 'othergas';
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data) && !_.isEmpty(response.data[0].data)) {
    const resOtherGas = response.data;
    if (resOtherGas && _.isArray(resOtherGas) && resOtherGas.length > 0) {
      await writeDataItems('othergas', resOtherGas);
    }
  }

  config.requestData.collection = 'otherliquid';
  response = await db.getAll(config, true);
  if (response.status === 'success' && !_.isEmpty(response.data) && !_.isEmpty(response.data[0].data)) {
    const resOtherLiquid = response.data;
    if (resOtherLiquid && _.isArray(resOtherLiquid) && resOtherLiquid.length > 0) {
      await writeDataItems('otherliquid', resOtherLiquid);
    }
  }

  store.dispatch(actions.setForceRemote(false));
  store.dispatch(actions.setSystemMessage(''));
};

const removeUserUnits = async (): Promise<void> => {
  const state = store.getState();
  const { username, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  await deleteOne({
    requestData: {
      _id: `${VORTEK_NAME}${username}flow`,
      client: VORTEK_NAME,
      username,
      collection: 'flow',
    },
  });

  await deleteOne({
    requestData: {
      _id: `${VORTEK_NAME}${username}temperature`,
      client: VORTEK_NAME,
      username,
      collection: 'temperature',
    },
  });

  await deleteOne({
    requestData: {
      _id: `${VORTEK_NAME}${username}pressure`,
      client: VORTEK_NAME,
      username,
      collection: 'pressure',
    },
  });

  await deleteOne({
    requestData: {
      _id: `${VORTEK_NAME}${username}density`,
      client: VORTEK_NAME,
      username,
      collection: 'density',
    },
  });

  await deleteOne({
    requestData: {
      _id: `${VORTEK_NAME}${username}viscosity`,
      client: VORTEK_NAME,
      username,
      collection: 'viscosity',
    },
  });
  await deleteOne({
    requestData: {
      _id: `${VORTEK_NAME}${username}length`,
      client: VORTEK_NAME,
      username,
      collection: 'length',
    },
  });

  await deleteOne({
    requestData: {
      _id: `${VORTEK_NAME}${username}time`,
      client: VORTEK_NAME,
      username,
      collection: 'time',
    },
  });
};

const loadLogo = (imageName = 'print_header_logo'): string => {
  const state = store.getState();
  const { username, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  const result = readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'image',
    },
  });

  let imagesrc;

  if (result && result.status !== 'fail') {
    imagesrc = utilities.findInArrayByName(result.data.data, imageName);
    if (imagesrc) {
      imagesrc = imagesrc.imgdata;
    }
  } else {
    imagesrc = `./images/${encodeURIComponent(VORTEK_NAME)}/${imageName}.png`;
  }

  return imagesrc;
};

export default {
  clearCollections,
  deleteItem,
  deleteOne,
  downloadFile,
  findAllDataForUserInReduxTable,
  findDataInRedux,
  findFluids,
  findMeasurement,
  findTemperature,
  getM23FeatureList,
  getSpecifiedFeatureList,
  loadLogo,
  performActivate,
  performAuthForgotPassword,
  performChangePassword,
  performForgotPassword,
  performLogin,
  performLogout,
  performRegister,
  readItem,
  readItemSync,
  readOne,
  readOneSync,
  removeUserUnits,
  retrieveDataFromIndexedDbForMemory,
  retrieveIndexedDbDataToMemory,
  retrieveRemoteOfflineData,
  syncOfflineDataToServer,
  validateToken,
  writeItem,
  writeOne,
  writeUnits,
  writeData,
};
