import Decimal from 'decimal.js';
import api from '../../api';
import constants from '../../constants';
import store from '../../store';
import actions from '../../store/actions';
import utilities from '../../utilities';
import application from '../../utilities/application';
import calc from '../../utilities/calc';
import convert from '../../utilities/convert';
import PipeData from '../PipeData';
import ProcessCondition from '../processCondition';
import Fluid from './Fluid';

export default class RealGas extends Fluid {
  protected dblConstC3: Decimal;
  protected dblConstC4: Decimal;
  protected dblSpecificGravity: Decimal;
  protected dblCriticalPressure: Decimal;
  protected dblCriticalTemperature: Decimal;

  constructor() {
    super();

    this.dblConstC3 = constants.ZERO;
    this.dblConstC4 = constants.ZERO;
    this.dblSpecificGravity = constants.ZERO;
    this.dblCriticalPressure = constants.ZERO;
    this.dblCriticalTemperature = constants.ZERO;
  }

  findGas = (gasName: string) => {
    const state = store.getState();

    let item = null;
    let i;

    const res = api.readOneSync(true, {
      requestData: {
        client: state.sizingGlobals.VORTEK_NAME,
        username: state.username,
        collection: 'gas',
      },
    });

    if (res.status !== 'fail') {
      if (!gasName) {
        const realres = [];
        for (i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].Mw) {
            realres.push({
              ConstC3: new Decimal(res.data.data[i].ConstC3),
              ConstC4: new Decimal(res.data.data[i].ConstC4),
              Gs: new Decimal(res.data.data[i].Gs),
              Pc: new Decimal(res.data.data[i].Pc),
              Tc: new Decimal(res.data.data[i].Tc),
              Mw: new Decimal(res.data.data[i].Mw),
            });
          } else {
            realres.push({
              ConstC3: new Decimal(res.data.data[i].ConstC3),
              ConstC4: new Decimal(res.data.data[i].ConstC4),
              Gs: new Decimal(res.data.data[i].Gs),
              Pc: new Decimal(res.data.data[i].Pc),
              Tc: new Decimal(res.data.data[i].Tc),
            });
          }
        }

        item = realres;
      } else {
        item = utilities.findInArray(res.data.data, gasName);
        if (item && item.Mw) {
          item = {
            ConstC3: new Decimal(item.ConstC3),
            ConstC4: new Decimal(item.ConstC4),
            Gs: new Decimal(item.Gs),
            Pc: new Decimal(item.Pc),
            Tc: new Decimal(item.Tc),
            Mw: new Decimal(item.Mw),
          };
        } else if (item) {
          item = {
            ConstC3: new Decimal(item.ConstC3),
            ConstC4: new Decimal(item.ConstC4),
            Gs: new Decimal(item.Gs),
            Pc: new Decimal(item.Pc),
            Tc: new Decimal(item.Tc),
          };
        }
      }
    }

    return item;
  };

  gasZ = (processCondition: ProcessCondition): Decimal => {
    // Calculate the real gas compressability.
    let Zr = constants.ONE;
    let k;
    let guess;
    let count;
    let done;
    const MAXLOOP = 25;

    // Convert the temperature and pressure to absolutes.
    const tempK = convert.temperatureTo('Rk', processCondition.dblTemperature, processCondition.sTemperatureUnit);

    const pressPSI = convert.pressureTo(
      'psi-A',
      processCondition.dblPressure,
      processCondition.sPressureUnit,
      processCondition.sPressureGaugeAbsolute
    );

    // Calculate the critical temperature and pressure.
    const Pr = pressPSI.div(this.dblCriticalPressure);
    const Tr = tempK.div(this.dblCriticalTemperature);

    count = 0;
    done = false;
    guess = constants.ONE;

    while (!done && count < MAXLOOP) {
      ++count;
      k = new Decimal('0.0867').times(Pr).div(guess.times(Tr));

      const TrPow15 = Decimal.pow(Tr.toString(), '1.5');
      const left = constants.ONE.div(constants.ONE.minus(k));
      const middle = new Decimal('4.934').div(TrPow15);
      const right = k.div(constants.ONE.plus(k));

      Zr = left.minus(middle.times(right));

      if (Zr.minus(guess).abs().lt(new Decimal('0.001'))) {
        done = true;
      } else {
        guess = Zr;
      }
    }

    if (count >= MAXLOOP) {
      Zr = constants.ONE;
    }

    return Zr;
  };

  calculateDensity = (processCondition: ProcessCondition): ProcessCondition => {
    // This routine will calculate the steam density based upon the user entered process conditions.
    const Z = this.gasZ(processCondition);

    const dblTempK = convert.temperatureTo('K', processCondition.dblTemperature, processCondition.sTemperatureUnit);

    const dblPressATM = convert.pressureTo(
      'atm(STD)-A',
      processCondition.dblPressure,
      processCondition.sPressureUnit,
      processCondition.sPressureGaugeAbsolute
    );

    // Store the calculated information, and units.
    const magicNum = new Decimal('0.0807223');
    const kelvin = new Decimal('273.15');
    // processCondition.dblDensityValue = (dblGasSpecificGravity.div(Z)).times(((0.0807223 * PressATM) / (TempK / 273.15)));
    const right = dblTempK.div(kelvin);
    const middle = magicNum.times(dblPressATM);
    const left = this.dblSpecificGravity.div(Z);

    const newProcessCondition = { ...processCondition };
    newProcessCondition.dblDensityValue = left.times(middle.div(right));
    newProcessCondition.sDensityUnit = 'lb/ft^3';
    return newProcessCondition;
  };

  calculateViscosity = (processCondition: ProcessCondition): ProcessCondition => {
    // Convert the temperature to Kelvin.
    const dblTempK = convert.temperatureTo('K', processCondition.dblTemperature, processCondition.sTemperatureUnit);

    // Calculate the fluid viscosity.
    const pow = Decimal.pow(dblTempK, this.dblConstC4);

    const newProcessCondition = { ...processCondition };
    newProcessCondition.dblViscosityValue = this.dblConstC3.times(pow).times(new Decimal('1000.0'));
    newProcessCondition.sViscosityUnit = 'cP';
    return newProcessCondition;
  };

  calculateValues = (pipeData: PipeData, minNomMax: string): PipeData => {
    const state = store.getState();
    const gas = this.findGas(state.fluid);
    this.dblConstC3 = gas.ConstC3;
    this.dblConstC4 = gas.ConstC4;
    this.dblSpecificGravity = gas.Gs;
    this.dblCriticalPressure = gas.Pc;
    this.dblCriticalTemperature = gas.Tc;

    let [newPipeData, cond] = application.collectApplicationData(pipeData, minNomMax);
    newPipeData.calcPressureMinVal = new Decimal(newPipeData.pressureMin.replace(',', ''));
    newPipeData.calcPressureNomVal = new Decimal(newPipeData.pressureNom.replace(',', ''));
    newPipeData.calcPressureMaxVal = new Decimal(newPipeData.pressureMax.replace(',', ''));
    newPipeData.calcTemperatureMinVal = new Decimal(newPipeData.temperatureMin.replace(',', ''));
    newPipeData.calcTemperatureNomVal = new Decimal(newPipeData.temperatureNom.replace(',', ''));
    newPipeData.calcTemperatureMaxVal = new Decimal(newPipeData.temperatureMax.replace(',', ''));

    // Calculate the application density.
    cond = this.calculateDensity(cond);
    newPipeData.isentropic = new Decimal('1.33');
    newPipeData.densityUnit = cond.sDensityUnit;
    let tmp;
    let val;

    if (minNomMax === 'min') {
      newPipeData.calcDensityMinVal = cond.dblDensityValue;
      newPipeData.densityMin = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'min');
      newPipeData.densityMinDisplay = tmp;
      newPipeData.densityMinInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'nom') {
      newPipeData.calcDensityNomVal = cond.dblDensityValue;
      newPipeData.densityNom = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'nom');
      newPipeData.densityNomDisplay = tmp;
      newPipeData.densityNomInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'max') {
      newPipeData.calcDensityMaxVal = cond.dblDensityValue;
      newPipeData.densityMax = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'max');
      newPipeData.densityMaxDisplay = tmp;
      newPipeData.densityMaxInternalUnit = cond.sDensityUnit;
    }

    store.dispatch(
      actions.setStandardConditions(this.calculateDensity(state.defaults.defaultProcessConditions.standardConditions))
    );

    store.dispatch(actions.setNormalConditions(this.calculateDensity(state.defaults.defaultProcessConditions.normalConditions)));

    cond = this.calculateViscosity(cond);

    if (minNomMax === 'min') {
      newPipeData.calcViscosityMinVal = cond.dblViscosityValue;
      newPipeData.viscosityMin = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'min');
      newPipeData.viscosityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcViscosityNomVal = cond.dblViscosityValue;
      newPipeData.viscosityNom = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'nom');
      newPipeData.viscosityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcViscosityMaxVal = cond.dblViscosityValue;
      newPipeData.viscosityMax = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'max');
      newPipeData.viscosityMaxDisplay = tmp;
    }

    newPipeData.viscosityUnit = cond.sViscosityUnit;

    newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
    if (minNomMax === 'min') {
      newPipeData.calcVelocityMinVal = newPipeData.velocity;
      newPipeData.velocityMin = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'min');
      newPipeData.velocityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcVelocityNomVal = newPipeData.velocity;
      newPipeData.velocityNom = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'nom');
      newPipeData.velocityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcVelocityMaxVal = newPipeData.velocity;
      newPipeData.velocityMax = newPipeData.velocity.toString();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'max');
      newPipeData.velocityMaxDisplay = tmp;
    }

    cond.dblPressure = convert.pressureTo(
      `${newPipeData.originalPressurePrefix}-${newPipeData.originalPressureCoefficient}`,
      cond.dblPressure,
      cond.sPressureUnit,
      cond.sPressureGaugeAbsolute
    );
    newPipeData.pressurePrefix = newPipeData.originalPressurePrefix;
    newPipeData.pressureCoefficient = newPipeData.originalPressureCoefficient;

    if (minNomMax === 'min') {
      newPipeData.calcPressureMinVal = cond.dblPressure;
      newPipeData.pressureMin = cond.dblPressure.toString();
    } else if (minNomMax === 'nom') {
      newPipeData.calcPressureNomVal = cond.dblPressure;
      newPipeData.pressureNom = cond.dblPressure.toString();
    } else if (minNomMax === 'max') {
      newPipeData.calcPressureMaxVal = cond.dblPressure;
      newPipeData.pressureMax = cond.dblPressure.toString();
    }

    return newPipeData;
  };
}
