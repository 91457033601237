import Decimal from 'decimal.js';

// Defaults for displaying data and handling
// conditions for calculating meter data
export default class VortekDefaultValues {
  baroPressValue: Decimal;

  constructor() {
    this.baroPressValue = new Decimal(0.0);
  }
}
