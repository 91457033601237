import Decimal from 'decimal.js';
import PipeData from '../PipeData';
import ProcessCondition from '../processCondition';
import Steam from './Steam';
import convert from '../../utilities/convert';
import application from '../../utilities/application';
import calc from '../../utilities/calc';
import actions from '../../store/actions';
import store from '../../store';

export default class SteamSaturatedPressure extends Steam {
  protected calcSteamTempFromPressure = (processCondition: ProcessCondition): ProcessCondition => {
    // This routine will calculate the saturated steam temperature (in deg F) as a
    // function of the pressure (psi-A).

    let c1;
    let c2;
    let c3;
    let c4;
    let c5;
    let c6;
    let c7;
    let c8;
    let c9;

    const dblPressPSIA = convert.pressureTo(
      'psi-A',
      processCondition.dblPressure,
      processCondition.sPressureUnit,
      processCondition.sPressureGaugeAbsolute
    );

    // APPROXIMATION! No log() function in Decimal
    const lnP = Decimal.ln(dblPressPSIA);

    if (dblPressPSIA.lessThan(new Decimal('11.526'))) {
      // Low Pressure
      c1 = new Decimal('4.6223895');
      c2 = new Decimal('0.328791079');
      c3 = new Decimal('-0.0317464561');
      c4 = new Decimal('0.00626235201');
      c5 = new Decimal('-0.00120301371');
      c6 = new Decimal('0.000168077986');
      c7 = new Decimal('-0.0000354367791');
      c8 = new Decimal('0.0000277471538');
      c9 = new Decimal('-0.00000645624479');
    } else {
      c1 = new Decimal('4.4111081');
      c2 = new Decimal('0.7258719');
      c3 = new Decimal('-0.350837193');
      c4 = new Decimal('0.149368514');
      c5 = new Decimal('-0.0404274452');
      c6 = new Decimal('0.00694505606');
      c7 = new Decimal('-0.000733922786');
      c8 = new Decimal('0.0000435973695');
      c9 = new Decimal('-0.00000111578817');
    }

    // Calculate the temperature from the user entered steam pressure value.  The result of
    // this calculation is in deg F.
    // Math.Exp((((((((c9 * lnP + c8) * lnP + c7) * lnP + c6) * lnP + c5) * lnP + c4) * lnP + c3) * lnP + c2) * lnP + c1);
    let r1 = c9.times(lnP).plus(c8);
    r1 = r1.times(lnP).plus(c7);
    r1 = r1.times(lnP).plus(c6);
    r1 = r1.times(lnP).plus(c5);
    r1 = r1.times(lnP).plus(c4);
    r1 = r1.times(lnP).plus(c3);
    r1 = r1.times(lnP).plus(c2);
    r1 = r1.times(lnP).plus(c1);

    const newProcessCondition = { ...processCondition };

    newProcessCondition.dblTemperature = Decimal.exp(r1);
    newProcessCondition.sTemperatureUnit = 'deg F';

    return newProcessCondition;
  };

  calculateValues = (pipeData: PipeData, minNomMax: string): PipeData => {
    const state = store.getState();

    if (minNomMax === 'min') {
      if (pipeData.flowMin === '' || pipeData.pressureMin === '') {
        return pipeData;
      }
    } else if (minNomMax === 'nom') {
      if (pipeData.flowNom === '' || pipeData.pressureNom === '') {
        return pipeData;
      }
    } else if (minNomMax === 'max') {
      if (pipeData.flowMax === '' || pipeData.pressureMax === '') {
        return pipeData;
      }
    }

    let [newPipeData, cond] = application.collectApplicationData(pipeData, minNomMax);
    newPipeData.calcPressureMinVal = new Decimal(newPipeData.pressureMin.replace(',', ''));
    newPipeData.calcPressureNomVal = new Decimal(newPipeData.pressureNom.replace(',', ''));
    newPipeData.calcPressureMaxVal = new Decimal(newPipeData.pressureMax.replace(',', ''));

    // if steam
    cond = this.calcSteamTempFromPressure(cond);
    newPipeData.isentropic = new Decimal('1.37');
    let tmp;
    let val;

    if (minNomMax === 'min') {
      newPipeData.temperatureMin = cond.dblTemperature.toString();
      cond = this.calculateDensity(cond);
      newPipeData.densityUnit = cond.sDensityUnit;
      newPipeData.calcDensityMinVal = cond.dblDensityValue;
      newPipeData.densityMin = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'min');
      newPipeData.densityMinDisplay = tmp;
      newPipeData.densityMinInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'nom') {
      newPipeData.temperatureNom = cond.dblTemperature.toString();
      cond = this.calculateDensity(cond);
      newPipeData.densityUnit = cond.sDensityUnit;
      newPipeData.calcDensityNomVal = cond.dblDensityValue;
      newPipeData.densityNom = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'nom');
      newPipeData.densityNomDisplay = tmp;
      newPipeData.densityNomInternalUnit = cond.sDensityUnit;
    } else if (minNomMax === 'max') {
      newPipeData.temperatureMax = cond.dblTemperature.toString();
      cond = this.calculateDensity(cond);
      newPipeData.densityUnit = cond.sDensityUnit;
      newPipeData.calcDensityMaxVal = cond.dblDensityValue;
      newPipeData.densityMax = cond.dblDensityValue.toString();
      [tmp, val] = convert.convertAndDisplayDensity(newPipeData, 'max');
      newPipeData.densityMaxDisplay = tmp;
      newPipeData.densityMaxInternalUnit = cond.sDensityUnit;
    }

    store.dispatch(
      actions.setStandardConditions(this.calculateDensity(state.defaults.defaultProcessConditions.standardConditions))
    );

    store.dispatch(actions.setNormalConditions(this.calculateDensity(state.defaults.defaultProcessConditions.normalConditions)));

    cond = this.calculateViscosity(cond);

    if (minNomMax === 'min') {
      newPipeData.calcViscosityMinVal = cond.dblViscosityValue;
      newPipeData.viscosityMin = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'min');
      newPipeData.viscosityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcViscosityNomVal = cond.dblViscosityValue;
      newPipeData.viscosityNom = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'nom');
      newPipeData.viscosityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcViscosityMaxVal = cond.dblViscosityValue;
      newPipeData.viscosityMax = cond.dblViscosityValue.toString();
      [tmp, val] = convert.convertAndDisplayViscosity(newPipeData, 'max');
      newPipeData.viscosityMaxDisplay = tmp;
    }

    newPipeData.viscosityUnit = cond.sViscosityUnit;

    cond.dblPressure = convert.pressureTo(
      `${newPipeData.originalPressurePrefix}-${newPipeData.originalPressureCoefficient}`,
      cond.dblPressure,
      cond.sPressureUnit,
      cond.sPressureGaugeAbsolute
    );
    newPipeData.pressurePrefix = newPipeData.originalPressurePrefix;
    newPipeData.pressureCoefficient = newPipeData.originalPressureCoefficient;

    if (minNomMax === 'min') {
      newPipeData.calcPressureMinVal = cond.dblPressure;
      newPipeData.pressureMin = cond.dblPressure.toString();

      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityMinVal = newPipeData.velocity;
      newPipeData.velocityMin = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'min');
      newPipeData.velocityMinDisplay = tmp;
    } else if (minNomMax === 'nom') {
      newPipeData.calcPressureNomVal = cond.dblPressure;
      newPipeData.pressureNom = cond.dblPressure.toString();

      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityNomVal = newPipeData.velocity;
      newPipeData.velocityNom = newPipeData.velocity.toString();
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'nom');
      newPipeData.velocityNomDisplay = tmp;
    } else if (minNomMax === 'max') {
      newPipeData.calcPressureMaxVal = cond.dblPressure;
      newPipeData.pressureMax = cond.dblPressure.toString();

      newPipeData = calc.fluidCalculationVelocity(newPipeData, cond);
      newPipeData.calcVelocityMaxVal = newPipeData.velocity;
      newPipeData.velocityMax = newPipeData.velocity.toString();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [tmp, val] = convert.convertAndDisplayVelocity(newPipeData, 'max');
      newPipeData.velocityMaxDisplay = tmp;
    }

    cond.dblTemperature = convert.temperatureTo(
      state.processConditions.temperatureUnit,
      cond.dblTemperature,
      cond.sTemperatureUnit
    );

    if (minNomMax === 'min') {
      newPipeData.calcTemperatureMinVal = cond.dblTemperature;
      newPipeData.temperatureMin = cond.dblTemperature.toString();
    } else if (minNomMax === 'nom') {
      newPipeData.calcTemperatureNomVal = cond.dblTemperature;
      newPipeData.temperatureNom = cond.dblTemperature.toString();
    } else if (minNomMax === 'max') {
      newPipeData.calcTemperatureMaxVal = cond.dblTemperature;
      newPipeData.temperatureMax = cond.dblTemperature.toString();
    }

    return newPipeData;
  };
}
