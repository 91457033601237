// This file contains calculations for the VorCone flow meter.

import Decimal from 'decimal.js';
import constants from '../../constants';
import store from '../../store';
import RealGas from '../../types/fluid/RealGas';
import Meter from '../../types/Meter';
import PipeData from '../../types/PipeData';
import ProcessCondition from '../../types/processCondition';
import convert from '../convert';

const dpFlow = (pipeData: PipeData, processCondition: ProcessCondition, meter: Meter, minOrMax: string): Decimal => {
  const state = store.getState();

  let flow = minOrMax === 'min' ? new Decimal(pipeData.flowMin) : new Decimal(pipeData.flowMax);
  let flowUnit = processCondition.sFlowRateUnit;

  if (flowUnit === 'nm^3' || flowUnit === 'scf') {
    // To convert to kg the ideal gas law can be used PV = (m/M) RT; where
    // P is the normal pressure from Tools/Options/Normal Conditions converted to kPa (the value there is already an absolute pressure),
    // V is the flow rate in Nm3 (convert the flow rate to per second so the mass ends up in per second),
    // m is mass in kg,
    // M is molecular weight in kg/k mole,
    // R is the universal gas constant 8.314 pa.m3/mol k,
    // and T is the normal temperature from Tools/Options/Normal Conditions converted to absolute units of kelvin
    // unless the fluidType is Steam, in which case the temperature is forced to 373.15 K.
    const realgas = new RealGas();
    let gas = realgas.findGas(state.fluid);
    if (!gas) {
      gas = state.otherGas;
    }

    if (gas || state.fluidType === 'Steam') {
      if (!gas.Mw) {
        const mwAir = new Decimal('28.9647');
        gas.Mw = mwAir.times(gas.gas_gravity);
      }

      let P;
      let T;
      if (flowUnit === 'nm^3') {
        P = state.defaults.defaultProcessConditions.defaultNmlConditions.dblPressure;
        P = convert.pressureTo(
          'kPa-A',
          P,
          state.defaults.defaultProcessConditions.defaultNmlConditions.sPressureUnit,
          state.defaults.defaultProcessConditions.defaultNmlConditions.sPressureGaugeAbsolute
        );
        T = convert.temperatureTo(
          'K',
          state.defaults.defaultProcessConditions.defaultNmlConditions.dblTemperature,
          state.defaults.defaultProcessConditions.defaultNmlConditions.sTemperatureUnit
        );
      } else {
        P = state.defaults.defaultProcessConditions.defaultStdConditions.dblPressure;
        P = convert.pressureTo(
          'kPa-A',
          P,
          state.defaults.defaultProcessConditions.defaultStdConditions.sPressureUnit,
          state.defaults.defaultProcessConditions.defaultStdConditions.sPressureGaugeAbsolute
        );
        T = convert.temperatureTo(
          'K',
          state.defaults.defaultProcessConditions.defaultStdConditions.dblTemperature,
          state.defaults.defaultProcessConditions.defaultStdConditions.sTemperatureUnit
        );
      }

      P = new Decimal(P.toFixed(3));
      T = new Decimal(T.toFixed(2));

      if (state.fluidType === 'Steam') {
        gas = { Mw: new Decimal('18.0152') };
      }

      if (flowUnit === 'scf') {
        // convert scf to nm^3 ignoring the time part - that is done in the V calculation below
        flow = flow.div(new Decimal('35.31467'));

        // unit is now nm^3
        flowUnit = 'nm^3';
      }

      // make sure the flow rate is in nm^3/sec because it could be in min/hr/day
      const V = convert.flowRateTo(
        flow,
        'nm^3/sec',
        flowUnit,
        processCondition.sFlowRateTime,
        processCondition.dblDensityValue,
        'lb/ft^3'
      );
      const M = gas.Mw;
      const R = new Decimal('8.314');
      const top = M.times(P).times(V);
      const bottom = R.times(T);
      flow = top.div(bottom);
      // round to 4 decimal places
      flow = new Decimal(flow.toFixed(4));
    } else {
      // We couldn't find the gas or the gas had no molecular weight listed. Fall back to old conversion
      // even though it is likely wrong. Should we tell the user?
      flow = convert.flowRateTo(
        flow,
        'kg/sec',
        processCondition.sFlowRateUnit,
        processCondition.sFlowRateTime,
        processCondition.dblDensityValue,
        'lb/ft^3'
      );
    }
  } else {
    // The flow rate units weren't nm^3 or scf, so convert normally
    flow = convert.flowRateTo(
      flow,
      'kg/sec',
      processCondition.sFlowRateUnit,
      processCondition.sFlowRateTime,
      processCondition.dblDensityValue,
      'lb/ft^3'
    );
  }

  const v29373 = new Decimal('293.73');
  const v00401865 = new Decimal('0.00401865');
  const v0696 = new Decimal('0.696');
  const v0649 = new Decimal('0.649');
  const pi = constants.PI;
  const one = constants.ONE;
  const two = new Decimal('2');
  const four = new Decimal('4');
  let densityKgM3 = convert.densityTo('kg/m^3', processCondition.dblDensityValue, 'lb/ft^3');
  densityKgM3 = new Decimal(densityKgM3.toFixed(4));
  const pressurePa = convert.pressureTo(
    'Pa',
    processCondition.dblPressure,
    processCondition.sPressureUnit,
    processCondition.sPressureGaugeAbsolute
  );
  const tempK = convert.temperatureTo('K', processCondition.dblTemperature, processCondition.sTemperatureUnit);
  const { isentropic } = pipeData;
  const beta = meter.beta ? new Decimal(meter.beta) : constants.ZERO;
  const dStd = convert.pipeInsideDiameter_To('m', pipeData.pipeInsideDiameter, pipeData.pipeUnit);
  const exp = new Decimal('0.000016');
  let dAct = tempK.minus(v29373);
  dAct = exp.times(dAct);
  dAct = one.plus(dAct);
  dAct = dStd.times(dAct);

  const pidiv4 = pi.div(four);
  let aAct = dAct.pow(two);
  aAct = pidiv4.times(aAct);

  const at = aAct.times(beta.pow(two));

  let e = beta.pow(four);
  e = one.minus(e);
  e = e.squareRoot();
  e = one.div(e);

  let y = one;
  const cd = new Decimal('0.72');

  // start all calculations as if fluid is a liquid

  // EAC
  const t1 = e.times(at).times(y).times(cd);

  // m / EAC
  let t2 = flow.div(t1);

  // squared
  t2 = t2.pow(two);

  // 2 * p (density)
  let t3 = two.times(densityKgM3);

  // 1 / 2p
  t3 = one.div(t3);

  // DP = 1/2p * (m/EAC)^2
  let dp = t3.times(t2);

  // multiply by the conversion factor from pascals to inches of water column (INWC).  1 pascal = 0.00401865 INWC
  dp = dp.times(v00401865);

  // only continue iterating if fluid is not a liquid
  if (pipeData.fluidType !== 'Liquid' && pipeData.fluidType !== 'Water' && pipeData.fluidType !== 'Other Liquid') {
    for (let i = 0; i < 10; i++) {
      // calculate the new y

      // kP
      let t4 = isentropic.times(pressurePa);
      // DP/kP
      t4 = dp.div(t4);

      // beta^4
      let t5 = beta.pow(four);

      // (0.696 * beta^4)
      t5 = v0696.times(t5);

      // 0.649 + (0.696 * beta^4)
      t5 = v0649.plus(t5);

      // (0.649 + (0.696 * beta^4)) * DP/kP
      t5 = t5.times(t4);

      // 1 - ((0.649 + (0.696 * beta^4)) * DP/kP)
      y = one.minus(t5);

      // EACY
      t5 = e.times(at).times(y).times(cd);

      // m / EACY
      t5 = flow.div(t5);

      // (m / EACY)^2
      t5 = t5.pow(two);

      // (1/2p) * (m / EACY)^2
      dp = t3.times(t5);

      // multiply by mystery value
      dp = dp.times(v00401865);
    }
  }
  return dp;
};

const vorconeDpFlowMin = (pipeData: PipeData, processCondition: ProcessCondition, meter: Meter): Meter => {
  const newMeter = { ...meter };
  newMeter.mindpval = dpFlow(pipeData, processCondition, meter, 'min');
  newMeter.mindp = newMeter.mindpval.toFixed(2);
  return newMeter;
};

const vorconeDpFlowMax = (pipeData: PipeData, processCondition: ProcessCondition, meter: Meter): Meter => {
  const newMeter = { ...meter };
  newMeter.maxdpval = dpFlow(pipeData, processCondition, meter, 'max');
  newMeter.maxdp = newMeter.maxdpval.toFixed(2);
  return newMeter;
};

export default {
  vorconeDpFlowMin,
  vorconeDpFlowMax,
};
