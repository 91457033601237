// Original Author:   C.T. Orleskie
// Date:              20 July 2015

import Decimal from 'decimal.js';
import { VortekMeters } from '.';
import utilities from '..';
import api from '../../api';
import constants from '../../constants';
import store from '../../store';
import Meter from '../../types/Meter';
import convert from '../convert';
import pipeUtils from '../pipeUtils';
import calcMeterSize from './calcMeterSize';

// This module contains the code for calculating the insertion turbine meter sizes.

const calcTurbineInsertionSTDPipe = (punit: string, psize: string, schedule: string): Meter[] => {
  const state = store.getState();
  const { fluidType, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  let mmSize = new Decimal(psize);
  const stdPipeSchedules = pipeUtils.getAnsiPipes(VORTEK_NAME);
  if (punit === 'mm') {
    mmSize = convert.pipeId(mmSize, 'in', 'mm');
  }

  const smallest = constants.SML_TURBINE_INSERTION.times(mmSize);
  const largest = constants.LRG_TURBINE_INSERTION.times(mmSize);
  const pipes = [];
  let i;
  let potential;
  let insideArea;
  let iRowCount;
  let name;
  let iCounter;
  let diameter;

  for (i = 0; i < stdPipeSchedules.length; i++) {
    /* eslint-disable-next-line */
    potential = stdPipeSchedules[i][3];
    if (
      stdPipeSchedules[i][1] === schedule &&
      potential.gt(new Decimal('2.939')) &&
      potential.gt(smallest) &&
      potential.lt(largest)
    ) {
      /* eslint-disable-next-line */
      diameter = stdPipeSchedules[i][0];
      if (diameter === 'Other') {
        diameter = psize;
      } else {
        /* eslint-disable-next-line */
        diameter = diameter.split(' ')[0];
      }

      insideArea = convert.getArea(potential);

      if (fluidType === 'Liquid' || fluidType === 'Water' || fluidType === 'Other Liquid') {
        pipes.push(
          calcMeterSize.createMeter(
            'L40',
            'MODEL_ProT',
            diameter,
            potential,
            'in',
            stdPipeSchedules[i][2],
            schedule,
            insideArea,
            'in'
          )
        );
      } else {
        // It's a gas.  Copy it to the collection all 6 rows, one row for each rotor size.
        iRowCount = 6;
        name = undefined;

        // Collect the information about the rotors to be sized.
        for (iCounter = 0; iCounter < iRowCount; iCounter++) {
          switch (iCounter) {
            case 0:
              name = 'R40';
              break;
            case 1:
              name = 'R30';
              break;
            case 2:
              name = 'R25';
              break;
            case 3:
              name = 'R20';
              break;
            case 4:
              name = 'R15';
              break;
            case 5:
              name = 'R10';
              break;
            default:
              name = '';
          }

          pipes.push(
            calcMeterSize.createMeter(
              name,
              'MODEL_ProT',
              diameter,
              potential,
              'in',
              stdPipeSchedules[i][2],
              schedule,
              insideArea,
              'in'
            )
          );
        }
      }
    }
  }

  return pipes;
};

const calcTurbineDINPipe = (punit: string, psize: string): Meter[] => {
  const state = store.getState();
  const { fluidType, username, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  let mmSize = new Decimal(psize);

  if (punit === 'in') {
    mmSize = convert.pipeId(mmSize, 'mm', 'in');
  }

  const smallest = constants.SML_TURBINE_INSERTION.times(mmSize);
  const largest = constants.LRG_TURBINE_INSERTION.times(mmSize);
  const pipes = [] as Meter[];

  const res = api.readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'pipesdin',
    },
  });

  if (res.status !== 'fail') {
    const pipesdin = res.data.data;
    let insideDia;
    for (let i = 0; i < pipesdin.length; i++) {
      insideDia = new Decimal(pipesdin[i].InsideDia);

      if (insideDia.gte(constants.METRIC_THREE_INCH) && insideDia.gte(smallest) && insideDia.lte(largest)) {
        const diameter = pipesdin[i].DN.split(' ')[0];
        const insideArea = convert.getArea(insideDia);

        if (fluidType === 'Liquid' || fluidType === 'Water' || fluidType === 'Other Liquid') {
          pipes.push(
            calcMeterSize.createMeter('L40', 'MODEL_ProT', diameter, insideDia.toString(), 'mm', undefined, '', insideArea, 'mm')
          );
        } else {
          // It's a gas.  Copy it to the collection all 6 rows, one row for each rotor size.
          const iRowCount = 6;
          let name;

          // Collect the information about the rotors to be sized.
          for (let iCounter = 0; iCounter < iRowCount; iCounter++) {
            switch (iCounter) {
              case 0:
                name = 'R40';
                break;
              case 1:
                name = 'R30';
                break;
              case 2:
                name = 'R25';
                break;
              case 3:
                name = 'R20';
                break;
              case 4:
                name = 'R15';
                break;
              case 5:
                name = 'R10';
                break;
              default:
                name = '';
            }

            pipes.push(
              calcMeterSize.createMeter(name, 'MODEL_ProT', diameter, insideDia.toString(), 'mm', undefined, '', insideArea, 'mm')
            );
          }
        }
      }
    }
  }

  return pipes;
};

const calcTurbineJisPipe = (punit: string, psize: string, schedule: string): Meter[] => {
  const state = store.getState();
  const { fluidType, username, sizingGlobals } = state;
  const { VORTEK_NAME } = sizingGlobals;

  let mmSize = new Decimal(psize);
  if (punit === 'in') {
    mmSize = convert.pipeId(mmSize, 'mm', 'in');
  }

  const smallest = constants.SML_TURBINE_INSERTION.times(mmSize);
  const largest = constants.LRG_TURBINE_INSERTION.times(mmSize);
  const pipes = [] as Meter[];
  let insideDia;
  let i;
  let insideArea;
  let reportedSchedule;
  let pipesjis;
  let diameter;

  const res = api.readOneSync(true, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'jisPipeSchedules',
    },
  });
  if (res.status !== 'fail') {
    pipesjis = res.data.data;

    for (i = 0; i < pipesjis.length; i++) {
      insideDia = new Decimal(pipesjis[i].InsideDiameter);

      if (
        insideDia.gte(constants.METRIC_THREE_INCH) &&
        insideDia.gte(smallest) &&
        insideDia.lte(largest) &&
        schedule === pipesjis[i].Schedule
      ) {
        /* eslint-disable-next-line */
        diameter = pipesjis[i].NominalSize.split(' ')[0];
        insideArea = convert.getArea(insideDia);
        reportedSchedule = pipesjis[i].Schedule;

        if (VORTEK_NAME !== utilities.AZBIL_NAME) {
          reportedSchedule = undefined;
        }

        if (fluidType === 'Liquid' || fluidType === 'Water' || fluidType === 'Other Liquid') {
          pipes.push(
            calcMeterSize.createMeter(
              'L40',
              'MODEL_ProT',
              diameter,
              insideDia.toString(),
              'mm',
              undefined,
              reportedSchedule,
              insideArea,
              'mm'
            )
          );
        } else {
          // It's a gas.  Copy it to the collection all 6 rows, one row for each rotor size.
          const iRowCount = 6;
          let name;

          // Collect the information about the rotors to be sized.
          for (let iCounter = 0; iCounter < iRowCount; iCounter++) {
            switch (iCounter) {
              case 0:
                name = 'R40';
                break;
              case 1:
                name = 'R30';
                break;
              case 2:
                name = 'R25';
                break;
              case 3:
                name = 'R20';
                break;
              case 4:
                name = 'R15';
                break;
              case 5:
                name = 'R10';
                break;
              default:
                name = '';
            }

            pipes.push(
              calcMeterSize.createMeter(
                name,
                'MODEL_ProT',
                diameter,
                insideDia.toString(),
                'mm',
                undefined,
                reportedSchedule,
                insideArea,
                'mm'
              )
            );
          }
        }
      }
    }
  }

  return pipes;
};

const calcTurbineOtherPipe = (punit: string, psize: string): Meter[] => {
  const state = store.getState();
  const { fluidType } = state;

  const insideArea = convert.getArea(new Decimal(psize));
  const pipes = [] as Meter[];
  const diameter = psize;

  if (fluidType === 'Liquid' || fluidType === 'Water' || fluidType === 'Other Liquid') {
    pipes.push(calcMeterSize.createMeter('L40', 'MODEL_ProT', diameter, psize, punit, undefined, '', insideArea, punit));
  } else {
    // It's a gas.  Copy it to the collection all 6 rows, one row for each rotor size.
    const iRowCount = 6;
    let name;

    // Collect the information about the rotors to be sized.
    for (let iCounter = 0; iCounter < iRowCount; iCounter++) {
      switch (iCounter) {
        case 0:
          name = 'R40';
          break;
        case 1:
          name = 'R30';
          break;
        case 2:
          name = 'R25';
          break;
        case 3:
          name = 'R20';
          break;
        case 4:
          name = 'R15';
          break;
        case 5:
          name = 'R10';
          break;
        default:
          name = '';
      }

      pipes.push(calcMeterSize.createMeter(name, 'MODEL_ProT', diameter, psize, punit, undefined, '', insideArea, punit));
    }
  }

  return pipes;
};

const getTurbineInsertionMeter = (pipeType: string, punit: string, psize: string, schedule: string): Meter[] => {
  let meters = [] as Meter[];

  if (pipeType === 'ansi') {
    meters = calcTurbineInsertionSTDPipe(punit, psize, schedule);
  } else if (pipeType === 'din') {
    meters = calcTurbineDINPipe(punit, psize);
  } else if (pipeType === 'jis') {
    meters = calcTurbineJisPipe(punit, psize, schedule);
  } else if (pipeType === 'other') {
    // 1.939 is a 2 inch schedule 80 pipe.
    let inchSize = new Decimal(psize);
    if (punit === 'mm') {
      const vMeters = new VortekMeters();
      inchSize = vMeters.convertPipeId(inchSize, 'in', 'mm');
    }

    if (inchSize.gt(new Decimal('2.939'))) {
      meters = calcTurbineOtherPipe(punit, psize);
    }
  }

  return meters;
};

export default {
  getTurbineInsertionMeter,
};
